
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';
  var context;
class subscriptionEdit extends Component
{
    
    constructor(props) {
        super(props);
        context=this
    }
    state = {inputIsEmpty:true,inputIsEmpty1:true,Publicationsvalue:'0',Vendorvalue:'0',ItemName:'',loader:true,
    Response: [], Vendors:[],Publications:[]}
    onDayChange(day, modifiers, instance) {
        if (instance.getInput().value !== '') {
            context.setState({ inputIsEmpty: false });
            day= formatDate(day, 'DD-MM-YYYY')
            context.setState({ selectedDay: day });
        }else{
            context.setState({ inputIsEmpty: true });
        }
       
       // alert(context.state.inputIsEmpty)
    }
    onDayChange1(day, modifiers, instance) {
        if (instance.getInput().value !== '') {
            context.setState({ inputIsEmpty1: false });
            day= formatDate(day, 'DD-MM-YYYY')
            context.setState({ selectedDay1: day });
        }else{
            context.setState({ inputIsEmpty1: true });
        }
    }
    componentDidMount()
    {
      this.setState({loader:true})

      document.getElementById('editlist').style.display='none';
      
    // urlencoded.append("VenderId", window.location.href.split('EditVendor/')[1]);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        myHeaders.append("Origin", "application/x-www-form-urlencoded");
        
        var urlencoded = new URLSearchParams();
        urlencoded.append("CenterId",'0');
        var VENDOR = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch("https://sureshtimesdealer.cannytechnology.comapi/Vendors", VENDOR)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
          .then(response => 
        
            {
                let data=response.data;
                data.sort(function(a, b) {
                    return a.Name > b.Name;
                  });
        this.setState({
        Vendors: data
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        myHeaders.append("Origin", "application/x-www-form-urlencoded");
        
        var urlencoded = new URLSearchParams();
        urlencoded.append("Selling_Day",'Friday');
        var VENDOR = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };
        
        fetch("https://sureshtimesdealer.cannytechnology.comapi/items", VENDOR)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(response => 
        
        {
            let data=response.response;
            // data.sort(function(a, b) {
            //     return a.Name > b.Name;
            //   });
        this.setState({
            Publications: data
        })



    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    
        var urlencoded = new URLSearchParams();
      
        urlencoded.append("id",window.location.href.split('subscriptionEdit/')[1]);
        
        var User = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch('https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/subscribebyid', User)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(Response => {
this.setState({
  
  
  Vendorvalue:Response.response[0].vendor_id,
  Publicationsvalue:Response.response[0].itemId,
  inputIsEmpty:false,
  inputIsEmpty1:false,
  selectedDay:Response.response[0].copy_start_date,
  selectedDay1:Response.response[0].copy_end_date})

 
document.getElementById('DepotName').value=Response.response[0].depot_name
document.getElementById('Address').value=Response.response[0].address
document.getElementById('Mobile').value=Response.response[0].mobile
document.getElementById('editlist').style.display='block';
document.getElementById('loader').style.display='none';

//console.log(Response.response)

// urlencoded.append("VendorId", this.state.Vendorvalue);
// urlencoded.append("ItemId", this.state.Publicationsvalue);
// urlencoded.append("StartDate", this.state.selectedDay);
// urlencoded.append("EndDate", this.state.selectedDay1);
// urlencoded.append("DepotName", document.getElementById('DepotName').value);
// urlencoded.append("Address", document.getElementById('Address').value);
// urlencoded.append("Mobile", document.getElementById('Mobile').value);
// urlencoded.append("ItemName", ItemName.toString());

        })
      })
    })
  


    }
    render() {
        let VendorOptions = this.state.Vendors.map(v => (
            <option text={v.Name+'-'+v.Center_name} value={v.id}>{v.Name+'-'+v.Center_name}</option>
          ));
          let PublicationsOptions = this.state.Publications.map(v => (
            <option text={v.name+'-'+v.FormalName} value={v.id}>{v.name+'-'+v.FormalName}</option>
            ));


        return (
        
        <div className="logbody" style={{backgroundColor:'#f8f9fc'}}>
  

<div className="logs">
         
       
    </div>
    <div class="container-fluid">
{/* <div style={{  borderBottom:'1px solid #007bff',}}>
<h2 style={{color:'#666', width:'90%',  textAlign:'center', float:'left', fontSize:20, padding:15}}>Meera Newspaper Distributor - Subscription Management</h2>

<a style={{float:'left', padding:15}} href="/">logout</a>
<div style={{clear:'both', float:'none'}}></div>
</div> */}


    <div className="rows" style={{margin:'auto', textAlign:'center'}}>

  


    <div class="card shadow" style={{minHeight:100, maxWidth:600, margin:'auto', marginTop:30, padding:'20px 40px', minWidth:100, }}>



    <div  id='loader' class="loader"></div>  
<div id="editlist">
    <h2 style={{color:'#007bff', textAlign:'left', fontSize:18, padding:5}}>Edit Subscriber</h2>
<div style={{padding:'20px 40px',minWidth:100,}}>
  <div style={{ textAlign:'left', color:'#007bff' }}> 
  Vendor
  </div>

    <select id="Vendor" onChange={this.GetallData} style={{padding:' 5px 10px',width: '100%', float:'left',height:'auto'}}  class="form-control" value={this.state.Vendorvalue} >
    <option value='0'>All Vendor</option>  {VendorOptions}
        </select>
        </div>
        
        <div style={{padding:'0 40px',minWidth:100,}}>
        <div style={{ textAlign:'left', color:'#007bff' }}> 
        Publication
  </div>
    <select id='Item'  style={{padding:' 5px 10px',width: '100%', float:'left',height:'auto'}}  class="form-control" onChange={this.GetallData1} value={this.state.Publicationsvalue}>
    <option value='0'>All Publications</option>  {PublicationsOptions}
        </select>
        </div>
        
        <div style={{padding:'20px 40px',minWidth:100,}}>
        <div style={{ textAlign:'left', color:'#007bff' }}> 
        Copy Start Date
  </div>
        <DayPickerInput
        classNames="form-control"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD-MM-YYYY"
                        placeholder={this.state.selectedDay}
                        dayPickerProps={{

                          localeUtils: MomentLocaleUtils,
                        }}
                        onDayChange={this.onDayChange} 
                      />
      
       </div>


       
       <div style={{padding:'0px 40px',minWidth:100,}}>
       <div style={{ textAlign:'left', color:'#007bff' }}> 
       Copy End Date
  </div>
        <DayPickerInput
        classNames='form-control'
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD-MM-YYYY"
                        placeholder={this.state.selectedDay1}
                        dayPickerProps={{

                          localeUtils: MomentLocaleUtils,
                        }}
                      
                        onDayChange={this.onDayChange1} 
                      />
      
       </div>
  
  
        <div style={{padding:'20px 40px',minWidth:100,}}>
        <div style={{ textAlign:'left', color:'#007bff' }}> 
  Name
  </div>
        <input id="DepotName"   type="text" placeholder='Enter Depot Name' className="form-control"/>  </div>

        <div style={{padding:'0px 40px',minWidth:100,}}>
        <div style={{ textAlign:'left', color:'#007bff' }}> 
        Address
  </div>
        <textarea id="Address" placeholder='Enter Address'  name="w3review" className="form-control" rows="3" cols="20">
</textarea> </div>
       
        <div style={{padding:'20px 40px',minWidth:100,}}>
        <div style={{ textAlign:'left', color:'#007bff' }}> 
        Mobile
  </div>
        <input id="Mobile"   type="number" placeholder='Enter Mobile number' className="form-control"/>  </div>
        <div style={{padding:'10px 40px',minWidth:100, marginBottom:50, textAlign:'center'}}>
  
  <button id='btn' onClick={this.handleChange} class='btn btn-primary'>Update</button> </div>

   </div>
   </div>


</div>



</div>

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
  integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
  crossorigin="anonymous"
/>
</div>
        )}
        GetallData= (event) => 
        {
         // alert(event.target.value)
          this.setState({
            Vendorvalue:event.target.value,
           
          })
        }
        GetallData1= (event) => 
        {
      //   alert(event.target.value)
          this.setState({
            Publicationsvalue:event.target.value,
           // ItemName:event.target.text
          })
        }
        handleChange = event => {

          var v = document.getElementById("Vendor");
       
          var VendorNames = v.options[v.selectedIndex].text;
         // alert(VendorNames)

            if(this.state.Vendorvalue!='0')
{
    if(this.state.Publicationsvalue!='0')
    {
        if(this.state.inputIsEmpty==false)
        {
            if(this.state.inputIsEmpty1==false)
            {

          //       var dateOne = new Date(this.state.selectedDay1.split('-')[2],this.state.selectedDay1.split('-')[1],this.state.selectedDay1.split('-')[0]); //Year, Month, Date    
          //       var dateTwo = new Date(this.state.selectedDay.split('-')[2],this.state.selectedDay.split('-')[1],this.state.selectedDay.split('-')[0]); //Year, Month, Date    
           
           
          //  if (dateOne > dateTwo) {
                
    if(document.getElementById('DepotName').value!="")
    {
      
    
        var e = document.getElementById("Item");
       
        var ItemName = e.options[e.selectedIndex].text;

       document.getElementById('btn').innerHTML='<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Saving...'
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
myHeaders.append("Origin", "application/x-www-form-urlencoded");
var urlencoded = new URLSearchParams();
urlencoded.append("VendorId", this.state.Vendorvalue);
urlencoded.append("VendorName", VendorNames);
urlencoded.append("ItemId", this.state.Publicationsvalue);
urlencoded.append("StartDate", this.state.selectedDay);
urlencoded.append("EndDate", this.state.selectedDay1);
urlencoded.append("DepotName", document.getElementById('DepotName').value);
urlencoded.append("Address", document.getElementById('Address').value);
urlencoded.append("Mobile", document.getElementById('Mobile').value);
urlencoded.append("ItemName", ItemName.toString());
urlencoded.append("group_pub", 'TOI');
urlencoded.append("executive", "Admin");
urlencoded.append("executive_mobile", localStorage.getItem('Mobile'));
urlencoded.append("id",window.location.href.split('subscriptionEdit/')[1]);


var User = {
  method: 'POST',
  headers: myHeaders,
  body: urlencoded,
  redirect: 'follow'
};

fetch('https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/SubscriptionUpdate', User)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
  .then(response =>{
  //  alert(response.response[0].Role)
  
    if(response.statuscode==1)
    {
        alert("Record Updated successfully")
           
              window.location='/#/subscription'
            // window.location.reload()
       
    }else{
        alert(response.message)
    }
    document.getElementById('btn').innerHTML='Add';
}
    )
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
}else{
    alert('Enter the Name')
}
// }else{
//     alert('Copy End  Date should Greater then Copy Start Date')
// }

}else{
    alert('Enter Copy End  date')
}
}else{
    alert('Enter Copy Start date')
}
}else{
    alert('Select Publications')
}
          
        }else{
            alert('Select Vendor')
        }
        
    }
delete= (id) => 
{
  //  alert(id)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("id",id);
    var Subscriptiondel = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch("https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/SubscriptionDelete", Subscriptiondel)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
    
        {
            if(response.statuscode==1)
    {
         alert("Record Deleted Successfully")
    }else{
        alert("Something went wrong")
    }
    window.location.reload()
        })
    }
    }
      

           
             


export default subscriptionEdit;
