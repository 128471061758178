
import React, { Component } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { ApiConstants } from './ApiConstants';
const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;

// const selectRow = {
//   mode: 'checkbox',
//   clickToSelect: true,
//   hideSelectColumn: true,
//   onSelect: (row, isSelect, rowIndex, e) => {
//     //alert( isSelect);
//    // alert(row.Name);
// },
// onSelectAll: (isSelect, rows, e) => {
// //  alert(isSelect);
//   alert(rows.Name);
//   console.log(e);
// }
// };

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
  onSelect: (row, isSelect, rowIndex, e) => {
    alert(row.code);
    console.log(isSelect);
    console.log(rowIndex);
    console.log(e);
  },
  onSelectAll: (isSelect, rows, e) => {
    console.log(isSelect);
    console.log(rows);
    console.log(e);
  }
};
const columns = [{
  dataField: 'id',
  text: 'Code',
  sort: true
}, {
  dataField: 'Name',
  text: 'Name',
  sort: true
}, {
  dataField: 'Center_name',
  text: 'Center',
  sort: true
},
{
  dataField: 'DateOfJoin',
  text: 'DOJ',
  sort: true
},
{
  dataField: 'ContactPerson',
  text: 'Contact Person',
  sort: true
},
{
  dataField: 'ContactNumber',
  text: 'Password',
  sort: true
},
{
  dataField: 'Fld2',
  text: 'Contact Number',
  sort: true
},
{
  dataField: 'Address',
  text: 'Address',
  sort: true
},
,
{
  dataField: 'edit',
  text: 'Edit',
  
 

  events: {
    onClick: (e, column, columnIndex, row, rowIndex) => {
      window.location="/#/EditVendor/"+row.id // Don't use pagination


    }
  }
},
];

export default class Vendor extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      Vendors:[],
      loading:true,
      Centers:[]
    };
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  componentDidMount()
  {
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("CenterId", '0');
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    var Center = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    fetch(ApiConstants.VIEWVENDOR, VENDOR)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {

          fetch(ApiConstants.VIEWCENTER, Center)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(Centersresponse => 
              
              {


                var centerdata= response.data;

                var Vendor = centerdata.map(function (data) {
      
                  return {
                    Location: data.Location,
                    Name:  data.Name,
                    Address:data.Address,
                    id:  data.id,
                    updated_dt:data.updated_dt,
                    Center_name: data.Center_name,
      
                    DateOfJoin: data.DateOfJoin,
      
                    ContactPerson: data.ContactPerson,
                    ContactNumber: data.ContactNumber,
                    Fld2: data.Fld2,
                    
                    edit:'View more'
                  }
                })
      
      
             


          //alert(response.message)
this.setState({
  Vendors:Vendor,
  Centers:Centersresponse.data,
  loading:true
})
          
          console.log(response)


})
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  init(CenterId)
  {
    this.setState({
      Vendors:[],
     
      loading:false
    })
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("CenterId", CenterId);
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(ApiConstants.VIEWVENDOR, VENDOR)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {

          var centerdata= response.data;

  

          var Vendor = centerdata.map(function (data) {

            return {
              Location: data.Location,
              Name:  data.Name,
              Address:data.Address,
              id:  data.id,
              updated_dt:data.updated_dt,
              Center_name: data.Center_name,

              DateOfJoin: data.DateOfJoin,

              ContactPerson: data.ContactPerson,
              ContactNumber: data.ContactNumber,
             
              Fld2: data.Fld2,
              edit:'View more'
            }
          })


          //alert(response.message)
this.setState({
  Vendors:Vendor,
 
  loading:true
})
          
    
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleChange = (event) => {
   //alert(event.target.value );

    this.init(event.target.value)
  };
    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
      let Options = this.state.Centers.map(v => (
        <option value={v.id}>{v.Name}</option>
      ));


        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Vendors</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

           
             <div class="d-sm-flex align-items-center justify-content-between mb-4">
             <div class="form-group">
  <label style={{float:'left',padding:' 5px ',}} for="sel1">Centers:</label>

        <select  style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  class="form-control" value={this.state.value} onChange={this.handleChange}>
        <option value='0'>All Centers</option>  {Options}
        </select>
   
</div>



<a href="/#AddVendor" class="d-none d-sm-inline-block btn btn-primary" style={{marginRight:10, marginTop:16, height:40}}><i style={{fontSize:14, paddingRight:10,}} class="fas fa-file-invoice fa-sm text-white-50"></i>Add Vendor</a>
  
    
    
    
</div>


<div class="row">
   
   {/*Content Column*/}
  <div class="col-lg-12 mb-4">

     {/*Project Card Example*/}
    <div class="card shadow mb-4">
    
      <div class="card-body">
      <div >

    
        {
          this.state.loading?

          this.state.Vendors.length>=1?
          
      <BootstrapTable keyField='code' search hover data={ this.state.Vendors } columns={ columns } />
    :

    
    <div style={{flex:1, textAlign:'center', flexDirection:'row', color:'red', padding:10, fontSize:30,}}>No Vendors Found!</div>
    :

<div  class="loader"></div>
    }
        </div>
      </div>
    </div>

     {/*Color System*/}

  </div>
  </div>



                 </div>
        
           </div>

        )}
    
        }


        
      
