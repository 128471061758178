
import React, { Component,useRef } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { ApiConstants } from './ApiConstants';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

var context;


export default class Settings extends Component
{
  constructor(props) {
    super(props);
    context=this
  
  }
  state = {
 
    FIRMDETAIL:[],
    Password:'',
    ChangePassword:0,
    loading:0,

  };
  logout()
  {
    localStorage.clear();
        window.location='/'
  }
  PasswordhandleChange()
  {
    context.setState({ChangePassword:0})
    
  }
  PasswordPrehandleChange()
  {
    context.setState({ChangePassword:1})
  }
  componentDidMount()
  {
    this.setState({loading:0})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("firmid", localStorage.getItem('userid'));
    
  
 
    var FIRMDETAIL = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
 
          fetch(ApiConstants.FIRMDETAIL, FIRMDETAIL)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(FIRMDETAIL => 
              
              {


               

if(FIRMDETAIL.Firm.length>0)
{
          //alert(response.message)
this.setState({
 
  FIRMDETAIL:FIRMDETAIL.Firm,
  loading:1,
  
  Password:FIRMDETAIL.Firm[0].Password,
  Firm_Name:FIRMDETAIL.Firm[0].name,
  Person:FIRMDETAIL.Firm[0].ContactPerson,
  Mobile:FIRMDETAIL.Firm[0].Mobile,
  // UPI:FIRMDETAIL.Firm[0].UPI,
  
  Business:FIRMDETAIL.Firm[0].Detail,
  Email:FIRMDETAIL.Firm[0].Email,
  Address:FIRMDETAIL.Firm[0].Address,
  City:FIRMDETAIL.Firm[0].city,
  Zipcode:FIRMDETAIL.Firm[0].zipcode,


}) }else{
        this.setState({loading:1,})
}
  
        }
     
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }

  handleChange = (event) => {

//alert(this.state.Password)
    if(document.getElementById('Firm_Name').value!="")
    {
    
         if(document.getElementById('Person').value!="")
        {
          if(document.getElementById('Mobile').value!="")
          { 
          //    if(document.getElementById('UPI').value!="")
          // {
            if(document.getElementById('Business').value!="")
            {
              if(document.getElementById('Email').value!="")
              {
                if(document.getElementById('Address').value!="")
                {
                  if(document.getElementById('City').value!="")
                {
                  if(document.getElementById('Zipcode').value!="")
                  {
                  
                    if(context.state.ChangePassword==0)
                    {
context.savechanges(this.state.Password)
                    }
                    if(context.state.ChangePassword==1)
                    {

                      if(document.getElementById('NewPassword').value!="")
                      {
                        if(document.getElementById('OldPassword').value==this.state.Password)
                        {

context.setState({Password:document.getElementById('NewPassword').value})
let pss=document.getElementById('NewPassword').value;
if(document.getElementById('NewPassword').value==''||pss==undefined)
{
  pss=this.state.Password
}
context.savechanges(pss)

                        }else{
                          alert("Enter Valid Current Password")
                        }
                      }else{
                        context.savechanges()
                      }

                    }
    
    }else{
      alert('Enter Business Zipcode')
  }
}else{
  alert('Enter Business City')
}
  
}else{
  alert('Enter Business Address')
}
            }else{
                alert('Enter Business Email')
            }
          }else{
            alert('Enter Business Detail')
        }
        //   }else{
        //     alert('Enter UPI ID for payment')
        // } 
      
      
      }else{
          alert('Enter Mobile Number')
      }
          }else{
            alert('Enter Firm Name')
        }
            }else{
                alert('Enter Contact Person Name')
            }
  };
  



savechanges(Password)
  {
                    

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Origin", "application/x-www-form-urlencoded");
  var urlencoded = new URLSearchParams();
  urlencoded.append("Firm_Name", document.getElementById('Firm_Name').value);
  urlencoded.append("Person", document.getElementById('Person').value);
 urlencoded.append("Userid", this.state.FIRMDETAIL[0].Userid);
 urlencoded.append("firmid", this.state.FIRMDETAIL[0].id);
//  urlencoded.append("UPI",  document.getElementById('UPI').value);
 urlencoded.append("Business", document.getElementById('Business').value);
 if(Password==undefined)
 {
  Password=this.state.Password
 }
 urlencoded.append("Password", Password);
 
  urlencoded.append("Email", document.getElementById('Email').value);
  urlencoded.append("Address", document.getElementById('Address').value);
  urlencoded.append("City", document.getElementById('City').value);
  urlencoded.append("Zipcode", document.getElementById('Zipcode').value);
  

  urlencoded.append("Mobile", document.getElementById('Mobile').value);

  
  var FIRMDETAILSAVE = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };
  
  fetch(ApiConstants.FIRMDETAILSAVE, FIRMDETAILSAVE)
  .then(dataWrappedByPromise => dataWrappedByPromise.json())
    .then(response =>{
    //  console.log(response)
    if(response.response==1)
    {
      alert("Entered data saved successfully")
    }else{
      alert("Oops something went wrong")
    }
      
  }
      )
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}
    render() {
      
      
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">My Account</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
{this.state.loading?
             <div class="container-fluid">

       <div id="harish" class="row">


  <div  class="col-lg-12 mb-4">
   
   {/*Content Column*/}
 
  <div style={{textAlign:'center', margin:'auto'}} class="col-lg-8 mb-4">

     {/*Illustrations*/}
    <div class="card shadow mb-4">
      <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Account</div>
        <div class="m-0 font-weight-bold text-success">{this.state.FIRMDETAIL[0]!=undefined? this.state.FIRMDETAIL[0].Status:null}</div>
      </div>
      <div class="card-body">
      <div class="chart-area" style={{    height: "auto"}}>
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Firm Name</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" class="form-control " id="Firm_Name" onChange={(event) => {this.setState({Firm_Name:event.target.value})} }  value={this.state.FIRMDETAIL[0]!=undefined? this.state.Firm_Name:null} /></h6>
         </div>
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Contact Person</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" class="form-control " id="Person" onChange={(event) => {this.setState({Person:event.target.value})} }  value={this.state.FIRMDETAIL[0]!=undefined? this.state.Person:null} /></h6>
         </div>
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Mobile</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input class="form-control " type="number" id="Mobile" onChange={(event) => {this.setState({Mobile:event.target.value})} }   value={this.state.FIRMDETAIL[0]!=undefined? this.state.Mobile:null} /></h6>
         </div> 
         
         {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Your UPI ID (Google Pay / PhonePe / Any Other UPI ID)</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input class="form-control " type="text" id="UPI" onChange={(event) => {this.setState({UPI:event.target.value})} }   value={this.state.FIRMDETAIL[0]!=undefined? this.state.UPI:null} /></h6>
         </div> */}


         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Business Detail</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" class="form-control " id="Business" onChange={(event) => {this.setState({Business:event.target.value})} }  value={this.state.FIRMDETAIL[0]!=undefined? this.state.Business:null}  /></h6>
         </div>
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Email</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="Email" class="form-control " id="Email" onChange={(event) => {this.setState({Email:event.target.value})} }  value={this.state.FIRMDETAIL[0]!=undefined? this.state.Email:null} /></h6>
         </div> 
     
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Address</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" class="form-control " id="Address" onChange={(event) => {this.setState({Address:event.target.value})} }  value={this.state.FIRMDETAIL[0]!=undefined? this.state.Address:null} /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">City</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" class="form-control " id="City" onChange={(event) => {this.setState({City:event.target.value})} }  value={this.state.FIRMDETAIL[0]!=undefined? this.state.City:null} /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Zipcode</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" class="form-control " id="Zipcode" onChange={(event) => {this.setState({Zipcode:event.target.value})} }  value={this.state.FIRMDETAIL[0]!=undefined? this.state.Zipcode:null}  /></h6>
         </div> 
         
         {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Total SMS</h6>
      <h6 class="m-0 font-weight-normal text-primary">{this.state.FIRMDETAIL[0]!=undefined? this.state.FIRMDETAIL[0].Total_SMS:null}</h6>

      </div>
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Used SMS</h6>
      <h6 class="m-0 font-weight-normal text-primary">{this.state.FIRMDETAIL[0]!=undefined? this.state.FIRMDETAIL[0].Left_SMS:null}</h6>

      </div>
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Left SMS</h6>
      <h6 class="m-0 font-weight-normal text-primary">{this.state.FIRMDETAIL[0]!=undefined? ( Number(this.state.FIRMDETAIL[0].Total_SMS) - Number(this.state.FIRMDETAIL[0].Left_SMS)):null}</h6>

      </div> */}
{this.state.ChangePassword==0?

      <div class="d-sm-flex align-items-center justify-content-between mb-4">
             <div onClick={this.PasswordPrehandleChange} style={{marginTop:30,marginBottom:30}} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Change Password</div>
   

         </div> :

<div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Current Password</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="Password"  placeholder="Enter Current Password" class="form-control " id="OldPassword"   /></h6>
     
        <h6 class="m-0 font-weight-normal text-primary">New Password</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="Password" placeholder="Enter New Password" class="form-control " id="NewPassword"   /></h6>
       
         
          <div onClick={this.PasswordhandleChange} style={{margin:30}} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> <i class=" fas fa-close fa-sm text-white-50"></i> </div>
   

         </div> 
    }
         <div onClick={this.handleChange} style={{marginTop:30,marginBottom:30}} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Update</div>
   

    

        </div>   </div>
    </div>


   

  </div>
  </div></div>
  </div>
    
                
:<div  class="loader"></div>   
  }
           </div>

        )}
    
        }
      
