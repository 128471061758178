

import { ApplicationConstants } from './ApplicationConstants';

export class ApiConstants {

    public static LOGING                        = ApplicationConstants.API_BASE_URL + "login";
    public static CREATEITEM                        = ApplicationConstants.API_BASE_URL + "items/save";
    public static ADDVENDOR                       = ApplicationConstants.API_BASE_URL + "Vendors/save";
    public static VIEWVENDOR                       = ApplicationConstants.API_BASE_URL + "Vendors";
    public static GETVENDERBYID                       = ApplicationConstants.API_BASE_URL + "Vendors/id";
    public static DELETEVENDER                       = ApplicationConstants.API_BASE_URL + "Vendors/delete";
  
    public static UPDATEVENDER                       = ApplicationConstants.API_BASE_URL + "Vendors/update";
  
    public static CenterAppBilling                     = ApplicationConstants.API_BASE_URL + "newbilling/Outstanding";
   
    
   
    public static VIEWCENTER                       = ApplicationConstants.API_BASE_URL + "Centers";
    public static ADDCENTER                       = ApplicationConstants.API_BASE_URL + "Centers";
    public static GETCENTER                       = ApplicationConstants.API_BASE_URL + "Centers/id";
    public static DELETECENTER                       = ApplicationConstants.API_BASE_URL + "Centers/delete";
    public static UPDATECENTER                       = ApplicationConstants.API_BASE_URL + "Centers/update";

    
    public static VIEWITEM                       = ApplicationConstants.API_BASE_URL + "items";

    public static DELETEITEM                       = ApplicationConstants.API_BASE_URL + "items/delete";
    public static GETITEM                       = ApplicationConstants.API_BASE_URL + "items/getitem";
    public static UPDATEITEM                       = ApplicationConstants.API_BASE_URL + "items/update";
    
    public static GETSUPPLY                       = ApplicationConstants.API_BASE_URL + "CenterSuppy";
   
    public static CENTERGETSUPPLY                       = ApplicationConstants.API_BASE_URL + "CenterSuppy/GetCenterSupply";
   
    public static SAVESUPPLY                       = ApplicationConstants.API_BASE_URL + "CenterSuppy/save";
    public static GETCETERBOOK                       = ApplicationConstants.API_BASE_URL + "CenterBook";
    
    public static SAVECENTERBOOK                       = ApplicationConstants.API_BASE_URL + "CenterBook/save";
    public static MOBILEUSER                       = ApplicationConstants.API_BASE_URL + "UserRoutermobile";
     
    public static GETMOBILEUSER                       = ApplicationConstants.API_BASE_URL + "UserRoutermobile/user";
    public static ADDMOBILEUSER                       = ApplicationConstants.API_BASE_URL + "UserRoutermobile/save";
   
    public static NEWBILLING                       = ApplicationConstants.API_BASE_URL + "newbilling";
    public static SAVEBILLING                     = ApplicationConstants.API_BASE_URL + "newbilling/save";
 
    public static BILLING                     = ApplicationConstants.API_BASE_URL + "newbilling/Billing";
 
    public static GETSALESBILLING  = ApplicationConstants.API_BASE_URL + "CenterBook/GETSALESBILLING";
    public static BULKBILLS  = ApplicationConstants.API_BASE_URL + "newbilling/BulkBill";
 
    public static DASHBOARDITEMSOTHER  = ApplicationConstants.API_BASE_URL + "newbilling/itemsother";

    public static FIRMDETAIL  = ApplicationConstants.API_BASE_URL + "newbilling/firm";

    public static FIRMDETAILSAVE  = ApplicationConstants.API_BASE_URL + "newbilling/firmsave";

    public static GetItemTodaysPrice  = ApplicationConstants.API_BASE_URL + "CenterBook/GetItemTodaysPrice";
    
    public static subscribebyCenter_id  = ApplicationConstants.API_BASE_URL + "UserRoutermobile/subscribebyCenter_id";
   
    public static Dashboard1  = ApplicationConstants.API_BASE_URL + "newbilling/Dashboard1";
   
    public static Dashboard2  = ApplicationConstants.API_BASE_URL + "newbilling/Dashboard2";

    public static DashboardCenterbook  = ApplicationConstants.API_BASE_URL + "newbilling/DashboardCenterbook";
    
    public static ReturnItemswithPrice                       = ApplicationConstants.API_BASE_URL + "Vendors/ReturnItemswithPrice";

    public static GETSALEFORREPORT                       = ApplicationConstants.API_BASE_URL + "CenterBook/GETSALEFORREPORT";

    public static GETSALEFORREPORTALLCENTER                       = ApplicationConstants.API_BASE_URL + "CenterBook/GETSALEFORREPORTALLCENTER";
    public static PaymentHistorySaveVendors  = ApplicationConstants.API_BASE_URL + "newbilling/PaymentHistorySaveVendors";
    public static GenerateBillSaveVendor  = ApplicationConstants.API_BASE_URL + "newbilling/GenerateBillSaveVendor";
    public static SAVEPAYMENTHISTORY                     = ApplicationConstants.API_BASE_URL + "newbilling/PaymentHistorySave";
    public static GETBILLING  = ApplicationConstants.API_BASE_URL + "newbilling/GETBILLING";
    public static VENDORSBYCENTER                       = ApplicationConstants.API_BASE_URL + "Vendors/VendorbyCenter";
    public static GenerateBillModify  = ApplicationConstants.API_BASE_URL + "newbilling/GenerateBillModify";
    public static VIEWCENTER_Item_Vendor  = ApplicationConstants.API_BASE_URL + "Centers/VIEWCENTER_Item_Vendor";
    public static GET_SupplyReport  = ApplicationConstants.API_BASE_URL + "CenterSuppy/GET_SupplyReport";

    public static GETPaymentHistoryReport  = ApplicationConstants.API_BASE_URL + "newbilling/GETPaymentHistoryReport";
    public static savepricebydate                       = ApplicationConstants.API_BASE_URL + "items/savepricebydate";
    public static Getpricebydate                       = ApplicationConstants.API_BASE_URL + "items/Getpricebydate";
 
    public static deletepricebydate                       = ApplicationConstants.API_BASE_URL + "items/deletepricebydate";
    public static deleteBill                = ApplicationConstants.API_BASE_URL + "newbilling/deleteBill";
 
    
}