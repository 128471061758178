
import React, { Component } from 'react';
import { ApiConstants } from './ApiConstants';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import Helmet from 'react-helmet';
var context;
const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;

export default class Addnewitem extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      nonsupplydate:"",
      selectedDay:""
    };
    context=this;
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  PrintingDate=()=>
  {
let vb=0;
   for(let i=0; i<context.state.nonsupplydate.split(',').length; i++)
   {

    // console.log(context.state.nonsupplydate.split(',')[i])
    
    // console.log(this.state.selectedDay)
    //  console.log(context.state.nonsupplydate.split(',')[i]==this.state.selectedDay)
if(context.state.nonsupplydate.split(',')[i]==formatDate(new Date(this.state.selectedDay), 'DD-MM-YYYY'))
{
  vb=1;
}
   }
if(vb==0)
{
    if(context.state.nonsupplydate.length>0 && context.state.nonsupplydate!="")
    {
      context.setState({nonsupplydate: context.state.nonsupplydate+","+formatDate(new Date(this.state.selectedDay), 'DD-MM-YYYY')})
    }else{
      context.setState({nonsupplydate: formatDate(new Date(this.state.selectedDay), 'DD-MM-YYYY')})
  
    }
   //alert(context.state.nonsupplydate)
  }else{
    alert("Already Added")
  }

}
  handleDayChange(selectedDay, modifiers, dayPickerInput) {
   
      context.setState({selectedDay: selectedDay})
  
  }
  handleChange = (event) => {


    if(document.getElementById('name').value!="")
    {
    
         if(document.getElementById('Formalname').value!="")
        {
         var NonSalesDays=context.state.nonsupplydate;
         
            var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", document.getElementById('name').value);
    urlencoded.append("FormalName", document.getElementById('Formalname').value);

    var e = document.getElementById("Issuedropdown");
var value = e.options[e.selectedIndex].value;
var text = e.options[e.selectedIndex].text;

    urlencoded.append("Issue", value);
    
    urlencoded.append("Address", document.getElementById('Address').value);
    urlencoded.append("city", document.getElementById('City').value);
    urlencoded.append("zipcode", document.getElementById('Zipcode').value);
    urlencoded.append("ContactPersion", document.getElementById('ContactPerson').value);
    urlencoded.append("ContactNumber", document.getElementById('ContactNumber').value);

    // urlencoded.append("Price", '[{"Selling_Price":'+document.getElementById('SundaySell').value+',
    // Pure_Price":'+document.getElementById('SundayPure').value+',"Week_Name":"Sunday"},
    // {"Selling_Price":'+document.getElementById('MondaySell').value+',"Pure_Price":
    // '+document.getElementById('MondayPure').value+',"Week_Name":"Monday"},
    // {"Selling_Price":'+document.getElementById('TuesdaySell').value+',"Pure_Price":
    // '+document.getElementById('TuesdayPure').value+',"Week_Name":"Tuesday"},
    // {"Selling_Price":'+document.getElementById('WednesdaySell').value+',"Pure_Price":
    // '+document.getElementById('WednesdayPure').value+',"Week_Name":"Wednesday"},
    // {"Selling_Price":'+document.getElementById('ThursdaySell').value+',"Pure_Price":
    // '+document.getElementById('ThursdayPure').value+',"Week_Name":"Thursday"},
    // {"Selling_Price":'+document.getElementById('FridaySell').value+',"Pure_Price":
    // '+document.getElementById('FridayPure').value+',"Week_Name":"Friday"},
    // {"Selling_Price":'+document.getElementById('SaturdaySell').value+',"Pure_Price":
    // '+document.getElementById('SaturdayPure').value+',"Week_Name":"Saturday"}]');
  
    urlencoded.append("Sunday_Selling_Price", document.getElementById('SundaySell').value);
    urlencoded.append("Sunday_Pure_Price", document.getElementById('SundayPure').value);
   
    urlencoded.append("Monday_Selling_Price", document.getElementById('MondaySell').value);
    urlencoded.append("Monday_Pure_Price", document.getElementById('MondayPure').value);

    urlencoded.append("Tuesday_Selling_Price", document.getElementById('TuesdaySell').value);
    urlencoded.append("Tuesday_Pure_Price", document.getElementById('TuesdayPure').value);

    urlencoded.append("Wednesday_Selling_Price", document.getElementById('WednesdaySell').value);
    urlencoded.append("Wednesday_Pure_Price", document.getElementById('WednesdayPure').value);

    urlencoded.append("Thursday_Selling_Price", document.getElementById('ThursdaySell').value);
    urlencoded.append("Thursday_Pure_Price", document.getElementById('ThursdayPure').value);

    urlencoded.append("Friday_Selling_Price", document.getElementById('FridaySell').value);
    urlencoded.append("Friday_Pure_Price", document.getElementById('FridayPure').value);

    urlencoded.append("Saturday_Selling_Price", document.getElementById('SaturdaySell').value);
    urlencoded.append("Saturday_Pure_Price", document.getElementById('SaturdayPure').value);
    
    urlencoded.append("NonSalesDays", NonSalesDays);
    
    var ITEM = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.CREATEITEM, ITEM)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{
     console.log(response)
        alert("Item Added successfully")

        window.location = "/#/Items";
    }
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
         
            }else{
                alert('Enter Item Formal Name')
            }
            }else{
                alert('Enter Item Name')
            }
  };
    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Add New Item</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">
        <h5 style={{marginTop:23}} class="h5 mb-0 text-gray-800">{`${formatDate(new Date(), 'dddd MMMM Do YYYY')}`}</h5>
      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

            <div class="row">
   
   {/*Content Column*/}


  <div class="col-lg-5 mb-5">
  <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Item Information</div>
       
      </div>
     {/*Illustrations*/}
    <div class="card shadow mb-4">
      {/* <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Balance Amount</div>
        <div class="m-0 font-weight-bold text-primary">Rs. 45,4225</div>
      </div> */}
      <div class="card-body">
      <div class="chart-area" style={{minHeight:'23rem', height:'auto'}}>
       
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Item Name</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text"  id="name"  /></h6>
   
      </div>   
    
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Formal Name</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" placeholder="Display in Center Sheet" id="Formalname" /></h6>
   
      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Issue</h6>
        <h6 class="m-0 font-weight-normal text-primary">
          
        <select id = "Issuedropdown" ref = {(input)=> this.menu = input}>
    <option value="Daily">Daily</option>
    <option value="Weekly">Weekly</option>
 
</select>
           
           </h6>
           </div> 
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Address</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <textarea id="Address">

</textarea></h6>
         </div>   
     
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">City</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="City"  /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Zipcode</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="Zipcode"   /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Contact Person</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="ContactPerson"  /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Contact Number</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="ContactNumber"  name="ContactNumber" /></h6>
         </div> 
      <div class="d-sm-flex align-items-center justify-content-center mb-4">
      
      </div>  
        </div>      </div>
    </div>

     {/*Approach*/}
   

  </div>

  <div class="col-lg-7 mb-7">

{/*Project Card Example*/}
<div class="card shadow mb-4">
<div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Price Detail</div>
       
      </div>
<div class="card-body">
      <div class="chart-area" style={{ height:'auto'}}>
      <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h6 class="m-0 font-weight-normal text-primary"></h6>
        <h6 style={{marginRight:10, marginLeft:'15%'}}  class=" font-weight-bold text-success">Selling Price</h6>
        <h6 style={{color:'#34A853', fontWeight:'bold'}} class=" font-weight-bold text-success"> Pur Price</h6>
       
      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h6 class="m-0 font-weight-bold text-primary">Sunday</h6>
      <h6 style={{marginRight:10, marginLeft:'15%'}} class="font-weight-normal text-primary"> <input id="SundaySell"  placeholder="Selling Price" type="Number"   /></h6>
        <h6 class="font-weight-normal text-primary"> <input id="SundayPure" placeholder="Pur Price"  type="Number"   /></h6>
   
      </div>   
      <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h6 class="m-0 font-weight-bold text-primary">Monday </h6>
      <h6 style={{marginRight:10, marginLeft:'15%'}} class="font-weight-normal text-primary"> <input id="MondaySell"  placeholder="Selling Price" type="Number"   /></h6>
        <h6 class="font-weight-normal text-primary"> <input placeholder="Pur Price" id="MondayPure"  type="Number"   /></h6>
   
      </div> 

      <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h6 class="m-0 font-weight-bold text-primary">Tuesday </h6>
      <h6 style={{marginRight:10, marginLeft:'15%'}} class="font-weight-normal text-primary"> <input id="TuesdaySell"   placeholder="Selling Price" type="Number"   /></h6>
        <h6 class="font-weight-normal text-primary"> <input placeholder="Pur Price" id="TuesdayPure"  type="Number"   /></h6>
   
      </div> 
      <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h6 class="m-0 font-weight-bold text-primary">Wednesday </h6>
      <h6 style={{marginRight:10, marginLeft:'10%'}} class="font-weight-normal text-primary"> <input id="WednesdaySell"  placeholder="Selling Price" type="Number"   /></h6>
        <h6 class="font-weight-normal text-primary"> <input placeholder="Pur Price"  type="Number" id="WednesdayPure"  /></h6>
   
      </div> 
 


      <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h6 class="m-0 font-weight-bold text-primary">Thursday </h6>
      <h6 style={{marginRight:10, marginLeft:'13%'}} class="font-weight-normal text-primary"> <input id="ThursdaySell"  placeholder="Selling Price" type="Number"   /></h6>
        <h6 class="font-weight-normal text-primary"> <input placeholder="Pur Price" id="ThursdayPure" type="Number"   /></h6>
   
      </div> 

      <div class="d-sm-flex align-items-center justify-content-between mb-2">
      <h6 class="m-0 font-weight-bold text-primary">Friday </h6>
      <h6 style={{marginRight:10, marginLeft:'17%'}} class="font-weight-normal text-primary"> <input id="FridaySell" placeholder="Selling Price" type="Number"   /></h6>
        <h6 class="font-weight-normal text-primary"> <input placeholder="Pur Price" id="FridayPure" type="Number"   /></h6>
   
      </div> 

      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-bold text-primary">Saturday </h6>
      <h6 style={{marginRight:10, marginLeft:'14%'}} class="font-weight-normal text-primary"> <input id="SaturdaySell"  placeholder="Selling Price" type="Number"   /></h6>
        <h6 class="font-weight-normal text-primary"> <input placeholder="Pur Price" id="SaturdayPure"  type="Number"   /></h6>
   
      </div> 
    
        </div>      </div>
        </div>  
    
        <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Non Sales days</div>
       
      </div>
        <div class="card-body">
      <div class="chart-area" style={{height:'auto'}}>
       
      <div class="d-sm-flex align-items-center justify-content-start mb-4">
      <Helmet>
        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
      </Helmet>
      <DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format="DD-MM-YYYY"
        placeholder={'Select Non Printing Date'}
        onDayChange={this.handleDayChange} 
        showClear={true}
        clickUnselectsDay={true}
      />
        <div onClick={this.PrintingDate} style={{marginLeft:'3%'}} class="font-weight-normal btn btn-primary">Add </div>
   
      </div>   
    
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-danger">     <i class="fa fa-arrow-alt-circle-right"></i> {this.state.nonsupplydate!=""? 
      
      
      this.state.nonsupplydate.split(',').map(x=> 
      <div>
      <div class='btn' style={{backgroundColor:'#e74a3b', marginLeft:'10px', marginTop:'10px',   color:'#fff'}}>{x}</div> 
      <div class='btn' onClick={(e) => {
   var dates="";
console.log(context.state.nonsupplydate)
console.log(","+context.state.nonsupplydate.split(',')[0])



this.state.nonsupplydate.split(',').map((nx, i)=>

 nx!=x?
 i>0 ?
 dates=dates+','+nx
 : dates=nx
 
 :null
)
if(dates.charAt(0)==',')
{
//  alert(dates)
  dates=dates.substring(1)
}

     if( window.confirm("Delete this Date?"))    
  {
       context.setState({nonsupplydate:dates})
       console.log(context.state.nonsupplydate)
   }
        
    }} style={{backgroundColor:'#e74a3b', marginLeft:'10px', marginTop:'10px', borderRadius:25,   color:'#fff'}}> <i class="fa fa-times"></i> </div> 
      
  </div>
      
      )
    
    
    
    :"All day"}</h6>
   
    </div>  
  
      </div>      </div>

</div>
<div onClick={this.handleChange} style={{marginBottom:30}} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Save All</div>
  
</div>

</div>


                 </div>
        
           </div>

        )}
    
        }
      
