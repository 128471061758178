
import React, { Component } from 'react';
import axios from 'axios';
import classes from "./Dashboard.module.css";
import LineGraph from "./bar";
import Login from './login.js';

import Helmet from 'react-helmet';
import { ApiConstants } from './ApiConstants';

import {  yearLabels,   quarterLabels } from "./mockData";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
var open=0;
export default class Dashboard extends Component
{
  state = {
    labels: yearLabels,
    Items:"",
    Vendors:"",
    Centers:"",
    Monthly_Sales:0,
    annual_sales:0,
    SupplyCenter:"",
    CenterBook:"",
    Allcenterdata:[],
    Total:0,
    date:''
}
componentDidMount()
{

  var date = new Date();
  this.Dashboardstart(formatDate(date, 'YYYY-MM-DD'))


}
Dashboardstart(date)
{
  var d = new Date(date);

this.setState({

 
    SupplyCenter:"",
    CenterBook:"",
    Allcenterdata:[],
    date:formatDate(d, 'dddd MMMM Do YYYY'),
   

})

var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    var d = new Date(date);
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    // var EndDatemonth = new Date(year - 1, month, day);
    // var EndDateweek = new Date(year, month, day-84);
    urlencoded.append("Selling_Day", formatDate(new Date(date), 'dddd'));
    urlencoded.append("StartDate", formatDate(new Date(date), 'DD-MM-YYYY'));
 
    
    var DASHBOARDITEMSOTHER = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    


    fetch(ApiConstants.DashboardCenterbook, DASHBOARDITEMSOTHER)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
    .then(Dashoard3 => {

      let allcenterdata=[];
      let CenterBookdata=[];
let SupplyCenter=Dashoard3.SupplyCenter;

for(let k=0; k<Dashoard3.CenterBook.length; k++)
{
  let CenterBookdataobj={};
      let   CenterBook =   Dashoard3.CenterBook[k].All_JSON_Date
     CenterBook = CenterBook.replace(/\\n/g, "\\n")  
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
// remove non-printable and other non-valid JSON chars
CenterBook = CenterBook.replace(/[\u0000-\u0019]+/g,""); 

CenterBook = JSON.parse(CenterBook);
let totalsale=0,Total=0,Total_Return_Copies=0,Total_Subscription_Copies=0;




CenterBook.map(function(CenterBook)
{
  totalsale=totalsale+parseFloat(CenterBook.Total_Supply_Copies)
  Total=Total+parseFloat(CenterBook.Total)
  Total_Return_Copies=Total_Return_Copies+parseFloat(CenterBook.Total_Return_Copies)
  Total_Subscription_Copies=Total_Subscription_Copies+parseFloat(CenterBook.Total_Subscription_Copies)

})

CenterBookdataobj["CenterId"]=Dashoard3.CenterBook[k].CenterId
CenterBookdataobj["totalsale"]=totalsale
// CenterBookdataobj["Total"]=Total
// CenterBookdataobj["Total_Return_Copies"]=Total_Return_Copies
// CenterBookdataobj["Total_Subscription_Copies"]=Total_Subscription_Copies
CenterBookdata.push(CenterBookdataobj)
}

let AllTotalSupply=0;
let AlltoalSales=0;
let AllTotalUnsold=0;


Dashoard3.Center.map(function(alldata)
{
  let allcenterdataobj={};

  allcenterdataobj["Name"]=alldata.Name
  allcenterdataobj["CenterId"]=alldata.id
  SupplyCenter.map(function(data)
{

  if(data.CenterId==alldata.id)
  {

   
   allcenterdataobj["Totalsupply"]=data.TotalCenter
   allcenterdataobj["TotalUnsold"]=data.TotalUnsold
   allcenterdataobj["TotalDifferece"]=parseFloat(data.TotalCenter)-parseFloat(data.TotalUnsold)
   AllTotalUnsold=AllTotalUnsold+parseFloat(data.TotalUnsold)
   AllTotalSupply=AllTotalSupply+parseFloat(data.TotalCenter)


   
  }

  
})

for(let i=0; i<CenterBookdata.length; i++)
{



  if(alldata.id==CenterBookdata[i].CenterId)
  {
    AlltoalSales=AlltoalSales+parseFloat(CenterBookdata[i].totalsale)

    
    allcenterdataobj["totalsale"]=CenterBookdata[i].totalsale
    // allcenterdataobj["Total"]=CenterBookdata[i].Total
    // allcenterdataobj["Total_Return_Copies"]=CenterBookdata[i].Total_Return_Copies
    // allcenterdataobj["Total_Subscription_Copies"]=CenterBookdata[i].Total_Subscription_Copies

  }

}
allcenterdata.push(allcenterdataobj)
})


// Dashoard3.Center.map(function(alldata)
// {

//   SupplyCenter.map(function(data)
// {

//   if(data.CenterId==alldata.id)
//   {

//    allcenterdata["CenterId"]=data.CenterId
//    allcenterdata["Totalsupply"]=data.TotalCenter
//    allcenterdata["TotalUnsold"]=data.TotalUnsold
  
//   }

  
// })
// })




this.setState({AlltoalSales:AlltoalSales,AllTotalSupply:AllTotalSupply,AllTotalUnsold:AllTotalUnsold, CenterBook:Dashoard3.CenterBook,Allcenterdata:allcenterdata, Centers:Dashoard3.Center, SupplyCenter:Dashoard3.SupplyCenter})



  })

    fetch(ApiConstants.Dashboard1, DASHBOARDITEMSOTHER)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
    .then(Response => {

      // console.log(JSON.stringify(Response))
let managerData=[]
let Sold_managerData=[]
let annual_sales=0;
let Monthly_Sales=0;
let Currnet_Month= Number(formatDate(new Date(), 'MM'))
//alert(Response.barchartMONTH)
let monthcountdata=0;
let monthcount=0;
if(Response.barchartMONTH!=undefined && Response.barchartMONTH!="")
{
 monthcountdata=Response.barchartMONTH[Response.barchartMONTH.length-1].month;

 monthcount=Response.barchartMONTH.length-1;
}
for(let p=0; p<12; p++)
{
  
if(Response.barchartMONTH[monthcount]!=undefined)
{

 // alert(Response.barchartMONTH[monthcount].month==monthcountdata)
  if(Response.barchartMONTH[monthcount].month==(p+1))
{

  managerData[p]=parseFloat(Response.barchartMONTH[monthcount].total_value).toFixed(2)
  annual_sales=annual_sales+ Response.barchartMONTH[monthcount].total_value

  if((p+1)==Currnet_Month)
  {
    Monthly_Sales=Monthly_Sales+ Response.barchartMONTH[monthcount].Sold_copies
  }
  Sold_managerData[p]=Response.barchartMONTH[monthcount].Sold_copies
  monthcount=monthcount-1;
}
else{
  managerData[p]=0
}
}else{
  managerData[p]=0
}

  monthcountdata=monthcountdata-1;
 // alert(monthcount)
}



let managerDataWeek=[]
let Sold_managerWeek=[]
let monthcountdataWeek=0;
let monthcountWeek=0;
if(Response.barchartWEEK!="")
{
 monthcountdataWeek=Response.barchartWEEK[Response.barchartWEEK.length-1].WEEK;

 monthcountWeek=Response.barchartWEEK.length-1;
}


for(let p=0; p<12; p++)
{
  
if(Response.barchartWEEK[monthcountWeek]!=undefined)
{
  //alert(monthcountdata)
 // alert(Response.barchartMONTH[monthcount].month==monthcountdata)
  if(Response.barchartWEEK[monthcountWeek].WEEK==monthcountdataWeek)
{

  managerDataWeek[p]=parseFloat(Response.barchartWEEK[monthcountWeek].total_value).toFixed(2)
  //alert(Response.barchartWEEK[monthcountWeek]!=undefined)
  Sold_managerWeek[p]=Response.barchartWEEK[monthcountWeek].Sold_copies
  monthcountWeek=monthcountWeek-1;
}
else{
  managerDataWeek[p]=0
}
}else{
  managerDataWeek[p]=0
}

  monthcountdataWeek=monthcountdataWeek-1;
 // alert(monthcount)
}


this.setState({ Monthly_Sales:Monthly_Sales,annual_sales:annual_sales,average:managerData, managerQuarterData:Sold_managerWeek, managerData:Sold_managerData, data:Sold_managerData, nationalAverageData:managerData, nationalAverageQuarterData:managerDataWeek})
    fetch(ApiConstants.Dashboard2, DASHBOARDITEMSOTHER)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
    .then(Dashoard2 => {


this.setState({ Items:Dashoard2.items, Vendors:Dashoard2.Vendor})




})
    })


  }
handleButtonClick = e => {

    const { value } = e.target;
    const isAnnual = value === "annual";

    const newData = isAnnual ? this.state.managerData : this.state.managerQuarterData;
    const newLabels = isAnnual ? yearLabels : quarterLabels;
    const newAverage = isAnnual ? this.state.nationalAverageData : this.state.nationalAverageQuarterData;

    this.setState({
        data: newData,
        average: newAverage,
        labels: newLabels
    })
}
logout()
{
  localStorage.clear();
      window.location='/'
}
handleDayChange=(selectedDay, modifiers, dayPickerInput)=> {
  var date = new Date(selectedDay);
  this.Dashboardstart(formatDate(date, 'YYYY-MM-DD'))

}
   openNav()
  {
   // document.getElementById("accordionSidebar")[0].style.display = 'flex';
    document.getElementsByClassName("navbar-nav")[0].style.display = 'flex';
    open=1;
  }
  closeNav()
  {
    document.getElementsByClassName("navbar-nav")[0].style.display = 'none';
    open=0;
  }
    render() {
      
        return (
      <div>
      
         
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

   {/*Sidebar Toggle (Topbar)*/}

{open==undefined || open==0?
  <div onClick={this.openNav} id="sidebarToggleTop" class="btn  d-md-none rounded-circle mr-3">
    <i  id="sidebarToggleTops2" class="fa fa-bars"></i>
  </div>
  :
  <div onClick={this.closeNav} id="sidebarToggleTops" class="btn  d-md-none rounded-circle mr-3">
  <i  id="sidebarToggleTops1" class="fa fa-close"></i>
</div>
  }
  <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
   {/*Topbar Search*/}


   {/*Topbar Navbar*/}

  <ul class="navbar-nav ml-auto">
  {/* <div style={{marginTop: 20}}>
                      <Helmet>
                        <style>{`

.DayPicker-Day{
  color: #333;
}
.DayPicker-Day--today
{
  color:red
}

          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
                      </Helmet>
                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="dddd MMMM Do YYYY"
                        placeholder={`${formatDate(new Date(), 'dddd MMMM Do YYYY')}`}
                        dayPickerProps={{

                          localeUtils: MomentLocaleUtils,
                        }}
                        onDayChange={this.handleDayChange} 
                      />

                    </div> */}

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li key='1' onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
<div class="container-fluid">
              {/*Page Heading*/}
             <div class="d-sm-flex align-items-center justify-content-between mb-4">
              
               {/* <h1 class="h3 mb-0 text-gray-800">Dashboard</h1> */}
               {/* <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> */}
             </div>
   
              {/*Content Row*/}
             <div class="row">
   
                {/*Earnings (Monthly) Card Example*/}
               <div class="col-xl-3 col-md-6 mb-4">
                 <div class="card border-left-primary shadow h-100 py-2">
                   <div class="card-body">
                     <div class="row no-gutters align-items-center">
                       <div class="col mr-2">
                         <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Monthly Sales </div>
        <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.Monthly_Sales} Copies</div>
                       </div>
                       <div class="col-auto">
                         <i class="fas fa-calendar fa-2x text-gray-300"></i>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
   
                {/*Earnings (Monthly) Card Example*/}
               <div class="col-xl-3 col-md-6 mb-4">
                 <div class="card border-left-success shadow h-100 py-2">
                   <div class="card-body">
                     <div class="row no-gutters align-items-center">
                       <div class="col mr-2">
                         <div class="text-xs font-weight-bold text-success text-uppercase mb-1"> Today's Sales </div>
                         <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.AlltoalSales}</div>
                       </div>
                       <div class="col-auto">
                         <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
   
                {/*Earnings (Monthly) Card Example*/}
               <div class="col-xl-3 col-md-6 mb-4">
                 <div class="card border-left-info shadow h-100 py-2">
                   <div class="card-body">
                     <div class="row no-gutters align-items-center">
                       <div class="col mr-2">
                         <div class="text-xs font-weight-bold text-info text-uppercase mb-1"> Today's Supply</div>
                         <div class="row no-gutters align-items-center">
                           <div class="col-auto">
                             <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{this.state.AllTotalSupply}</div>
                           </div>
                           <div class="col">
                             {/* <div class="progress progress-sm mr-2">
                               <div class="progress-bar bg-info" role="progressbar" style={{width: '50%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                             </div> */}
                           </div>
                         </div>
                       </div>
                       <div class="col-auto">
                         <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
   
                {/*Pending Requests Card Example*/}
               <div class="col-xl-3 col-md-6 mb-4">
                 <div class="card border-left-warning shadow h-100 py-2">
                   <div class="card-body">
                     <div class="row no-gutters align-items-center">
                       <div class="col mr-2">
                         <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Today's Unsold</div>
                         <div class="h5 mb-0 font-weight-bold text-gray-800">{this.state.AllTotalUnsold}</div>
                       </div>
                       <div class="col-auto">
                         <i class="fas fa-comments fa-2x text-gray-300"></i>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
   
              {/*Content Row*/}
   
             <div class="row">
   
                {/*Area Chart*/}
                <div class="col-xl-5 col-lg-6">
         
                 <div class="card shadow mb-4">
                    {/*Card Header - Dropdown*/}


                   <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                     <h6 class="m-0 font-weight-bold text-primary">Sales</h6>
                     <div class="dropdown no-arrow">
                     <div>
                      <Helmet>
                        <style>{`

.DayPicker-Day{
  color: #333;
}
.DayPicker-Day--today
{
  color:red
}

          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
                      </Helmet>
                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="dddd MMMM Do YYYY"
                        placeholder={`${formatDate(new Date(), 'dddd MMMM Do YYYY')}`}
                        dayPickerProps={{

                          localeUtils: MomentLocaleUtils,
                        }}
                        onDayChange={this.handleDayChange} 
                      />

                    </div>
                     </div>
                   </div>
                    {/*Card Body*/}
                   <div class="card-body">
                   <div class="chart-area" style={{  overflow:'auto',  height: '24rem'}}>
                      
{
                 this.state.Allcenterdata!=undefined &&  this.state.Allcenterdata.length>0?
                 <div>
                                     {
                                  this.state.Allcenterdata.map((nx, i)=>

                 <div style={{ marginBottom:10, borderRadius:5, border:'1px solid #1A365B' }}>
                     <div style={{ color:'#fff', fontSize:14, textAlign:'center', fontWeight:'bold', padding:3,  backgroundColor:'#1A365B'}}>
                 {nx.Name}         
                 </div>
                 <div style={{padding:10}}>
                
                 <div style={{  border:'1px solid #218838',   padding:2, margin:5, borderRadius:10,}} class=" d-flex flex-row align-items-center justify-content-between">
<div style={{width:'40%', fontWeight:'600', textAlign:'center', fontSize:14, color:'#218838'}}>
Total Sales 
</div>
<div style={{width:'60%',fontWeight:'bold',textAlign:'center',fontSize:14, color:'#218838'}}>
{nx.totalsale!=undefined? nx.totalsale +"Copies":<div style={{color:'red'}}> Not Entered In App!</div>
                 }
</div></div>



<div style={{  border:'1px solid #138496',   padding:2, margin:5, borderRadius:10,}} class=" d-flex flex-row align-items-center justify-content-between">
<div style={{width:'40%', fontWeight:'600', textAlign:'center', fontSize:14, color:'#138496'}}>
Total Supply 
</div>
<div style={{width:'60%',fontWeight:'bold',textAlign:'center',fontSize:14, color:'#138496'}}>

{nx.Totalsupply!=undefined? nx.Totalsupply +"Copies":<div style={{color:'red'}}> Not Entered In App!</div>
                 }
  
</div>
</div>

<div style={{  border:'1px solid #138496',  padding:2, margin:5, borderRadius:10,}} class=" d-flex flex-row align-items-center justify-content-between">
<div style={{width:'40%', fontWeight:'600', textAlign:'center', fontSize:14, color:'#138496'}}>
Unsold
</div>
<div style={{width:'60%',fontWeight:'bold',textAlign:'center',fontSize:14, color:'#138496'}}>

{nx.TotalUnsold!=undefined? nx.TotalUnsold +"Copies":<div style={{color:'red'}}> Not Entered In App!</div>
                 }
</div>
</div>
{/* <div style={{   border:'1px solid #0069D9', padding:2, margin:5, borderRadius:10,}} class=" d-flex flex-row align-items-center justify-content-between">
<div style={{width:'40%', fontWeight:'600', textAlign:'center', fontSize:14, color:'#0069D9'}}>
Difference
</div>
<div style={{width:'60%',fontWeight:'bold',textAlign:'center',fontSize:14, color:'#0069D9'}}>


{nx.TotalDifferece!=undefined? nx.TotalDifferece +"Copies":<div style={{color:'red'}}> Not Entered In App!</div>
                 }
</div>
</div> */}

{/* 

               <h6 class="m-0 font-weight-bold text-primary">{nx.Name}</h6>
             
                    <h6 style={{fontSize:16}} class="m-0 font-weight-bold text-gray-900">{nx.ContactPerson}</h6>
            
                    <h6 style={{fontSize:14}} class="m-0 font-weight-bold text-gray-900">{nx.ContactNumber}</h6>
             */}
                 </div>

                 
                 </div>
                 )    
        }
                 </div>
                 

  :<div style={{marginTop: '55%'}} class="loader"></div>

   
   
   }


                     </div>
                
                   </div>
                 </div>
                 </div>
           
               <div class="col-xl-7 col-lg-6">
                 <div class="card shadow mb-4">
                    {/*Card Header - Dropdown*/}

                   
                   <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                     <h6 class="m-0 font-weight-bold text-primary">Sales Overview</h6>
                
                   </div>
                    {/*Card Body*/}
                   <div class="card-body">
                     <div class="chart-area" >

                       {this.state.managerData!=undefined?
                     <div className={classes.container}>
               

                <div className={classes.buttonContainer}>
                    <button
                        value="annual"
                        onClick={this.handleButtonClick}
                    >
                        Annual
                    </button>

                    <button
                        value="lastquarter"
                        onClick={this.handleButtonClick}
                    >
                        Last Quarter
                    </button>
                </div>

                <LineGraph
                    data={this.state.data}
                    average={this.state.average}
                    labels={this.state.labels} />

            </div>
  :<div style={{marginTop: '50px'}} class="loader"></div>



                       }

                     </div>
                     
                     
                  
                     <div class="mt-4 text-center small" style={{paddingTop:15}}>
                       <span class="mr-2">
                         <i class="fas fa-circle text-primary"></i> Sold Items
                       </span>
                       <span class="mr-2">
                         <i class="fas fa-circle text-success"></i> Total Amount 
                       </span>
                      
                     </div>
                   </div>
                 </div>
               </div>
   
                {/*Pie Chart*/}
              
           
           
             </div>
   
              {/*Content Row*/}
             <div class="row">
   
                {/*Content Column*/}
               <div class="col-lg-6 mb-4">
               <a href="/#/Vendors" style={{textDecoration:'none'}}>
                  {/*Project Card Example*/}
                 <div class="card shadow mb-4">
                   <div class="card-header py-3">
                     <h6 class="m-0 font-weight-bold text-primary">Vendors</h6>
                   </div>
                   <div class="card-body">
                   <div class="chart-area" style={{  overflow:'auto'}}>
                   {
                   this.state.Vendors!=undefined &&  this.state.Vendors.length>0?
<div>
                    {
                 this.state.Vendors.map((nx, i)=>
                      <div style={{margin:10,  border:'1px solid #ddd' }}>
                     
                      <div style={{padding:10}}>
                      
                    <h6 class="m-0 font-weight-bold text-primary">{nx.Name}</h6>
                  
                         <h6 style={{fontSize:16}} class="m-0 font-weight-bold text-gray-900">{nx.ContactPerson}</h6>
                 
                         <h6 style={{fontSize:14}} class="m-0 font-weight-bold text-gray-900">{nx.ContactNumber}</h6>
                 
                      </div>

                      <div style={{ color:'#fff', fontSize:14, textAlign:'center', fontWeight:'bold', padding:3,  backgroundColor:'#1A365B'}}>
                      {nx.Center_name}        
                      </div>
                      </div>
                 )
                    }
                 </div>    :<div style={{marginTop: '25%'}} class="loader"></div>
    } 
                      
                                           </div>
                   </div>
                 </div>
   </a>
                  {/*Color System*/}
        
               </div>
               <div class="col-lg-6 mb-4">
               <a href="/#/Items" style={{textDecoration:'none'}}>
                 <div class="card shadow mb-4">
                    {/*Card Header - Dropdown*/}


                   <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                     <h6 class="m-0 font-weight-bold text-primary">Items</h6>
                     <div class="dropdown no-arrow">
                 
                     </div>
                   </div>
                    {/*Card Body*/}
                   <div class="card-body">
                   <div class="chart-area" style={{  overflow:'auto',  }}>
                      
{
                 this.state.Items!=undefined &&  this.state.Items.length>0?
<div>
                    {
                 this.state.Items.map((nx, i)=>

                 <div style={{margin:6,  border:'1px solid #ddd' }} class=" d-flex flex-row align-items-center justify-content-between">
      
        
        
                 <div style={{width:'30%', color:'#fff', textAlign:'center', fontWeight:'bold', padding:10, margin:6, borderRadius:6, backgroundColor:'#1A365B'}}>
                { nx.FormalName}
                 </div>
                 <div style={{width:'70%'}}>
                 
                 <h6 style={{fontSize:12}} class="m-0 font-weight-bold text-gray-700">{ nx.name}</h6>
                 <h6 class="m-0 font-weight-bold text-primary">Today's Price : Rs. { nx.Selling_Price}</h6>
                 </div> </div>
                 )    
        }
                 </div>
                 

  :<div style={{marginTop: '55%'}} class="loader"></div>

   
   
   }


                     </div>
                
                   </div>
                 </div>
                 </a>   </div>
           
               {/* <div class="col-lg-6 mb-4">
   
               
                  <a href="/#/AllCenter" style={{textDecoration:'none'}}>
                 <div class="card shadow mb-4">
                   <div class="card-header py-3">
                     <h6 class="m-0 font-weight-bold text-primary">Centers</h6>
                   </div>
                   <div class="card-body">
                   <div class="chart-area" style={{  overflow:'auto'}}>
                      
                   {
                   this.state.Centers!=undefined &&  this.state.Centers.length>0?
<div>
                    {
                 this.state.Centers.map((nx, i)=>
                      <div style={{margin:10,  border:'1px solid #ddd' }}>
                     
                      <div style={{padding:10}}>
                      
                    <h5 class="m-0 font-weight-bold text-primary">{nx.Name}</h5>
                  
                         <h6 style={{fontSize:16}} class="m-0 font-weight-bold text-gray-900">{nx.Location}</h6>
                 
                         <h6 style={{fontSize:14}} class="m-0 font-weight-bold text-gray-900">{nx.zipcode}</h6>
                 
                      </div>

                      </div>
 )
}
</div>    :<div style={{marginTop: '25%'}} class="loader"></div>
} 



                      
                     </div>      </div>
                 </div>
                 </a>
                
                
   
               </div>
             */}
            
            
            
             </div>
   
           </div>
        
           </div>

        )}
    
        }
      
