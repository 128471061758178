
import React, { Component } from 'react';
import axios from 'axios';

export default class Home extends Component
{
    render() {
        return (<div className="logbody">
   

<div className="logs">
         
        <div> Log In</div>
    </div>

    <div className="logsubmain">

      

<div className="loglabel">UserName</div>

<input id="Text1" type="text" className="logtext"/>

        <div className="loglabel">Password </div>

<input id="Text2" type="password" className="logtext" />

<div onClick={this.handleChange}  className="logbtn">
    Sign In
</div>
<div className="logivalid" id="invalid"></div>


</div>
</div>

        )}
    
        handleChange = event => {

          //  alert(window.location.href)
             axios.post('http://gobaby.co.in/api/login.ashx?email='+document.getElementById('Text1').value+'&password='+document.getElementById('Text2').value)
             .then(res => {
if(res.data.split(',')[0]=='y')
{
  
    localStorage.setItem('userid', document.getElementById('Text1').value);
    localStorage.setItem('username', res.data.split(',')[1]);
    localStorage.setItem('uid', res.data.split(',')[2]);
    document.getElementById('loginaid').setAttribute('href',"/Profile");

  
  
    document.getElementById('loginaidspan').innerHTML="Welcome "+ localStorage.getItem("username") ;
    let { history } = this.props;

   if(window.location.href.search("loging")!==-1){ history.push({pathname: '/'})}else{window.location.reload()}
  
  
}
                else
           {
               alert("Email and Password does't Matched! Try Again");
               document.getElementById('Text1').value="";
               document.getElementById('Text2').value="";
           }
               console.log(res);
               console.log(res.data);
             })
           }
         
    
    
    }
      
