
import React, { Component } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import Helmet from 'react-helmet';
import { ApiConstants } from './ApiConstants';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { green } from 'ansi-colors';

const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;
var context;

const columns = [{
  dataField: 'id',
  text: 'ID',
  sort: true,
  editable: false
}, {
  dataField: 'Vendor',
  text: 'Vendor',
  sort: true,
  editable: false
}, {
  dataField: 'Total_Items',
  text: 'Total Items',
  sort: true,
  editable: false
},
{
  dataField: 'Total',
  text: 'Total Amount',
  sort: true,
  editable: false
}, {
  dataField: 'Paid',
  text: 'Paid Amount',
  sort: true,
  editable: false
}, {
  dataField: 'Balance',
  text: 'Balance Amount',
  sort: true,
  editable: false
},
{
  dataField: 'User',
  text: 'User',
  sort: true,
  editable: false
}, {

  dataField: 'Approved',
  text: 'Approval Status',
  sort: true,
  style: (cell, row, rowIndex, colIndex) => {
    if (cell == 'Waiting') {
      return {
        backgroundColor: '#2196F3',
        color:'#fff',
      };
    }
    if (cell == 'Disapprove') {
      return {
        backgroundColor: '#DB4638',
        color:'#fff',
      };
    }
    return {
      backgroundColor: '#4CAF50',
      color:'#fff',
    };
  
  },
  editor: {
    type: Type.SELECT,
    options: [{
      value: 'Waiting',
      label: 'Waiting'
    }, {
      value: 'Approved',
      label: 'Approved'
    }, {
      value: 'Disapprove',
      label: 'Disapprove'
    }]
  }
  // formatter: (cellContent, row) => (

   

  //   <select  style={row.Approved=='Waiting' ? {    backgroundColor:'blue', padding:' 5px 10px',   color:'#fff'}: row.Approved=='Approved' ?{    backgroundColor:'green', padding:' 5px 10px',   color:'#fff'}:{    backgroundColor:'red', padding:' 5px 10px',   color:'#fff'}}  class="form-control" id="sel1">
  //    <option style={row.Approved=='Waiting' ? {    backgroundColor:'blue',   color:'#fff'}: row.Approved=='Approved' ?{    backgroundColor:'green',   color:'#fff'}:{    backgroundColor:'red',   color:'#fff'}}> {row.Approved}</option>
  //   <option style={{backgroundColor:'blue', color:'#fff'}}>Waiting</option>
  //   <option style={{backgroundColor:'green',color:'#fff'}}>Approved</option>
  //   <option style={{backgroundColor:'red', color:'#fff'}}>Disapprove</option>


  // </select>


  // )
}

];
const products = [{
  id: '1',
  Vendor: '1',
  Total_Items:'Total_Items',
  Total:'345435',
  Paid:'333',
  Balance:'Balance',
  User:'User',

  Approved:'Approved'
},
{
  id: '2',
  Vendor: '4441',
  Total_Items:'Total_Items',
  
  Total:'345435',
  Paid:'311133',
  Balance:'Balance',
  User:'User',

  Approved:'Waiting'
},



];

const expandRow = {
  onlyOneExpanding: true,
  renderer: row => (
    <div>
      <p>{ `TOI ${row.Vendor}` }</p>
      <p>{ `TOI ${row.id}` }</p>
      <p>{ `TOI ${row.Vendor}` }</p>
    </div>
    
  ),
  showExpandColumn: true
};
export default class Invoice extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      Centers:[],
      Allitems:[],
      AllitemsDivs:[],
      CenterID:'',
      Options:[],
      ItemID:'',
      supplydate:formatDate(new Date(), 'DD-MM-YYYY'),
      Selling_Day:formatDate(new Date(), 'dddd')
    };
    
    context=this
  }

  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
  handleDayChange=(selectedDay, modifiers, dayPickerInput)=> {
    var d = new Date(selectedDay);
  
    //'DD-MM-YYYY'
    // var supplydate = d.getDate()+'-'+(d.getMonth()+1)+'-'+d.getFullYear();
    // console.log(supplydate)
    this.setState({supplydate:formatDate(new Date(selectedDay), 'DD-MM-YYYY')})
    this.GetSupplydata(this.state.CenterID,this.state.ItemID ,formatDate(new Date(selectedDay), 'DD-MM-YYYY'))

//lert(weekdaynow)

   //context.init(weekdaynow)
  }
  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleChange = (event) => {
   // console.log(event.target.value );
   
    console.log(event.target.value );
    this.setState({CenterID:event.target.value })
    this.GetSupplydata(event.target.value,this.state.ItemID ,this.state.supplydate)

  
    //alert(this.state.CenterID)
    // if(this.state.supplydate!=undefined&& this.state.supplydate!='null' && this.state.supplydate!='')
    // {
    // this.GetSupplydata(this.state.supplydate)
    // }else{
    //   this.GetSupplydata(formatDate(new Date(), 'DD-MM-YYYY'))
    // }
  };
  componentDidMount()
  {
    this.setState({loading:false})

    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var VENDOR = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    var urlencoded = new URLSearchParams();
    urlencoded.append("Selling_Day", this.state.Selling_Day);
 
    var VIEWITEM = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.VIEWITEM, VIEWITEM)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {

          fetch(ApiConstants.VIEWCENTER, VENDOR)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(Centersresponse => 
              
              {




          console.log(response.response)
this.setState({
  Allitems:response.response,
  Centers:Centersresponse.data,
  loading:true
})
let cetnrid=''; let Options=[]; let Allitems=[]; let itemid='';
if(this.state.Centers!=null && this.state.Centers!=undefined && this.state.Centers!='' && this.state.Centers!='null')

{
let cuntv=0; 
 Options = this.state.Centers.map(v => (
  cuntv=cuntv+1,
  cuntv==1?cetnrid=v.id:null,

  <option value={v.id}>{v.Name}</option>
));
}

if(this.state.Allitems!=null && this.state.Allitems!=undefined && this.state.Allitems!='' && this.state.Allitems!='null')

{let cunt=0; 
      Allitems = this.state.Allitems.map(item => (
        cunt=cunt+1,
        cunt==1? itemid=item.id:null,
        cunt==1?
        <div id={'item'+item.id} className="selctdItem"   onClick={this.handleClick.bind(this, item.id)} >
{item.name}
        </div>
      
       
        
        :

<div id={'item'+item.id} className="nonselctdItem"   onClick={this.handleClick.bind(this, item.id)} >
{item.name}
        </div>
      ));

      }

this.setState({CenterID:cetnrid, Options:Options,AllitemsDivs:Allitems,ItemID:itemid })

//alert(this.state.CenterID)
          console.log(this.state.ItemID)
          console.log(this.state.CenterID)

        
          this.GetSupplydata(this.state.CenterID,this.state.ItemID ,formatDate(new Date(), 'DD-MM-YYYY'))


})
        }
        




        
        )

        
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  GetSupplydata=(CenterID,ItemID,date)=>
  {
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
   
    urlencoded.append("CenterId", CenterID);
    urlencoded.append("ItemId", ItemID);
    urlencoded.append("Supply_date", date);
   // alert(date)
    var GETSUPPLY = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.GETSUPPLY, GETSUPPLY)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 

{

  this.setState({loading:true})
  console.log(response)
//alert(response.TotalCenter[0].TotalCenter)

this.setState({
  
  TotalCenter:response.TotalCenter[0].TotalCenter,
  TotalUnsold:response.TotalCenter[0].TotalUnsold,
  TotalSubscription:response.TotalCenter[0].TotalSubscription,
  TotalCash:response.TotalCenter[0].TotalCash,
  TotalShortage:response.TotalCenter[0].TotalShortage,
  TotalExcess:response.TotalCenter[0].TotalExcess,
})
if(response.TotalCenter[0].TotalCenter==null)
{
  this.setState({TotalCenter:0})
}
if(response.response!=undefined && response.response!=''&&  response.response[0].TotalSuppy!=undefined && response.response[0].TotalSuppy!='' && response.response[0].TotalSuppy!=null && response.response[0].TotalSuppy!='null')
{  
document.getElementById('Total').value= response.response[0].TotalSuppy
}else{
  document.getElementById('Total').value='0'
}


if(response.response!=undefined && response.response!=''&& response.response[0].Shortage!=undefined && response.response[0].Shortage!='' && response.response[0].Shortage!=null && response.response[0].Shortage!='null')
{  
  document.getElementById('Shortage').value= response.response[0].Shortage
}else{
  document.getElementById('Shortage').value='0'
}




if(response.response!=undefined && response.response!=''&& response.response[0].Subscription!=undefined && response.response[0].Subscription!='' && response.response[0].Subscription!=null && response.response[0].Subscription!='null')
{  
  document.getElementById('Subscription').value= response.response[0].Subscription
}else{
  document.getElementById('Subscription').value='0'
}



if(response.response!=undefined && response.response!=''&& response.response[0].BilledSales!=undefined && response.response[0].BilledSales!='' && response.response[0].BilledSales!=null && response.response[0].BilledSales!='null')
{  
  document.getElementById('Billed').value= response.response[0].BilledSales
}else{
  document.getElementById('Billed').value='0'
}
 
if(response.response!=undefined && response.response!=''&& response.response[0].CreditSales!=undefined && response.response[0].CreditSales!='' && response.response[0].CreditSales!=null && response.response[0].CreditSales!='null')
{  
  document.getElementById('Credit').value= response.response[0].CreditSales
}else{
  document.getElementById('Credit').value='0'
}
 
  
if(response.response!=undefined && response.response!=''&& response.response[0].UnsoldCopies!=undefined && response.response[0].UnsoldCopies!='' && response.response[0].UnsoldCopies!=null && response.response[0].UnsoldCopies!='null')
{  
  document.getElementById('Unsold').value= response.response[0].UnsoldCopies
}else{
  document.getElementById('Unsold').value='0'
}

 


}

        )

        
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

  }
   handleClick(item) {
//alert(item)
    var elms = document.getElementById('Allitems').getElementsByTagName("*");
    for (var i = 0; i < elms.length; i++) {
    
        document.getElementById(elms[i].id).className = "nonselctdItem";
    }
    

 document.getElementById('item'+item).className = "selctdItem";
this.setState({ItemID:item})
 this.GetSupplydata(this.state.CenterID,item,this.state.supplydate)

  }


  savesupply=()=>
  {
 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("CenterId", context.state.CenterID);
    urlencoded.append("ItemId", context.state.ItemID);
    urlencoded.append("Supply_date", context.state.supplydate);

    urlencoded.append("TotalSuppy", document.getElementById('Total').value);
    //urlencoded.append("AdditionalSupply", document.getElementById('Additional').value);
    urlencoded.append("Shortage", document.getElementById('Shortage').value);
   // urlencoded.append("OfficeSales", document.getElementById('Office').value);
   // urlencoded.append("FreeCopies", document.getElementById('FreeCopies').value);
    urlencoded.append("Subscription", document.getElementById('Subscription').value);
    urlencoded.append("BilledSales", document.getElementById('Billed').value);
    urlencoded.append("CreditSales", document.getElementById('Credit').value);
    urlencoded.append("UnsoldCopies", document.getElementById('Unsold').value);


    console.log(formatDate(new Date(), 'DD-MM-YYYY'))
    var GETSUPPLY = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.SAVESUPPLY, GETSUPPLY)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>         
        {
          alert("Center Supply Data saved successfully ")
        }
      )
  }
  // handleClick = () => {
  //   alert('this is:', this);
  // }
    render() {
      
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };

        return (
      <div>
        
<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Center Supply</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>

             <div class="container-fluid">
             <div style={{padding:'10px'}} class="d-sm-flex align-items-center justify-content-between">
  
        <div class="h6 mb-0  btn btn-sm btn-success" style={{ fontWeight:'bold'}}><i class="fas fa-hand-point-right"></i> Total Center Supply : {this.state.TotalCenter}</div>
        <div class="h6 mb-0  btn btn-sm btn-success" style={{ fontWeight:'bold'}}><i class="fas fa-hand-point-right"></i> Total Unsold Copies : {this.state.TotalUnsold}</div>
        <div class="h6 mb-0  btn btn-sm btn-success" style={{ fontWeight:'bold'}}><i class="fas fa-hand-point-right"></i> Total Subscription Copies : {this.state.TotalSubscription}</div>
        </div>
        <div style={{padding:'10px'}} class="d-sm-flex align-items-center justify-content-between mb-4">
  
        <div class="h6 mb-0  btn btn-sm btn-success" style={{ fontWeight:'bold'}}><i class="fas fa-hand-point-right"></i> Total Cash Sales Copies : {this.state.TotalCash}</div>
        <div class="h6 mb-0  btn btn-sm btn-success" style={{ fontWeight:'bold'}}><i class="fas fa-hand-point-right"></i> Total Shortage Copies: {this.state.TotalShortage}</div>
        <div class="h6 mb-0  btn btn-sm btn-success" style={{ fontWeight:'bold'}}><i class="fas fa-hand-point-right"></i> Total Excess Copies : {this.state.TotalExcess}</div>

  </div>
             <div class="card shadow mb-4">
      <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
      <div class="form-group">
  <label style={{float:'left',padding:' 5px ',}} for="sel1">Centers:</label>

  <select  style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  class="form-control" value={this.state.value} onChange={this.handleChange}>
            {this.state.Options}
        </select>
   
</div>
        <div>
        <Helmet>
        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
      </Helmet>
        <DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format="dddd MMMM Do YYYY"
        placeholder={`${formatDate(new Date(), 'dddd MMMM Do YYYY')}`}
        dayPickerProps={{
          
          localeUtils: MomentLocaleUtils,
        }}
        onDayChange={this.handleDayChange} 
      />
        </div>
      </div>


      <div class="card-body">

      <div class="chart-area" style={{minHeight:'23rem'}}>
        


<div id="Allitems" style={{float:'left',  width:'20%'}}>

{/* <div  onClick={this.handleClick.bind(this, 'IOT')} style={{padding:10, textAlign:'center', color:'#fff', backgroundColor:'#233233'}}>

IOT

</div> */}
{this.state.AllitemsDivs}
</div>

{
          this.state.loading?

        <div style={{float:'left', padding:'5%',  border:'1px solid #ccc', width:'80%'}}>
    
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Total Supply</h6>
 <input style={{textAlign:"left", width:'50%'}} type="text" defaultValue='0' id="Total" />

      </div>   
     
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Unsold Copies</h6>
 <input style={{textAlign:"left", width:'50%'}} type="text" defaultValue='0' id='Unsold' />

      </div>

      


    

      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Subscription Copies</h6>
 <input style={{textAlign:"left", width:'50%'}} type="text" defaultValue='0' id='Subscription'  />

      </div>   

      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Cash Sales Copies</h6>
 <input style={{textAlign:"left", width:'50%'}} type="text" defaultValue='0' id='Credit' />

      </div>   

      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Shortage Copies</h6>
 <input style={{textAlign:"left", width:'50%'}} type="text" defaultValue='0' id='Shortage' />

      </div> 

      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Excess Copies</h6>
 <input style={{textAlign:"left", width:'50%'}} type="text" defaultValue='0' id='Billed' />

      </div> 



      <div class="d-sm-flex align-items-center justify-content-center mb-4">
    
      <div   style={{marginRight:'10px', width:'100px'}} onClick={this.savesupply}  class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Save</div>
    
      <div style={{width:'100px'}} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Print</div>
     
      </div>  
        </div> 
        
        :<div class="loader"></div>
        
}</div>
        
             </div>




    </div>


       </div>
        
           </div>

        )}
    
        }


        
      
