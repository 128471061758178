
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Helmet from 'react-helmet';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';
  var context;
class Subscription extends Component
{
    
    constructor(props) {
        super(props);
        context=this
    }
    state = {inputIsEmpty:true,inputIsEmpty1:true,Publicationsvalue:'0',Vendorvalue:'0',ItemName:'',loader:true,
    Response: [], Vendors:[],Publications:[]}
    onDayChange(day, modifiers, instance) {
        if (instance.getInput().value !== '') {
            context.setState({ inputIsEmpty: false });
            day= formatDate(day, 'DD-MM-YYYY')
            context.setState({ selectedDay: day });
        }else{
            context.setState({ inputIsEmpty: true });
        }
       
       // alert(context.state.inputIsEmpty)
    }
    onDayChange1(day, modifiers, instance) {
        if (instance.getInput().value !== '') {
            context.setState({ inputIsEmpty1: false });
            day= formatDate(day, 'DD-MM-YYYY')
            context.setState({ selectedDay1: day });
        }else{
            context.setState({ inputIsEmpty1: true });
        }
    }
    componentDidMount()
    {
    
    
       
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    
        var urlencoded = new URLSearchParams();
       
        var User = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch('https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/executivesubscribe', User)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(Response => {
this.setState({Response:Response.response})


        })

        
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

myHeaders.append("Origin", "application/x-www-form-urlencoded");

var urlencoded = new URLSearchParams();
urlencoded.append("CenterId",'0');
var VENDOR = {
  method: 'POST',
  headers: myHeaders,
  body: urlencoded,
  redirect: 'follow'
};

fetch("https://sureshtimesdealer.cannytechnology.comapi/Vendors", VENDOR)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
  .then(response => 

    {
        let data=response.data;
        data.sort(function(a, b) {
            return a.Name > b.Name;
          });
this.setState({
Vendors: data
})
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

myHeaders.append("Origin", "application/x-www-form-urlencoded");

var urlencoded = new URLSearchParams();
urlencoded.append("Selling_Day",'Friday');
var VENDOR = {
method: 'POST',
headers: myHeaders,
body: urlencoded,
redirect: 'follow'
};

fetch("https://sureshtimesdealer.cannytechnology.comapi/items", VENDOR)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
.then(response => 

{
    let data=response.response;
    // data.sort(function(a, b) {
    //     return a.Name > b.Name;
    //   });
this.setState({
    Publications: data,
    loader:false
})

})
    })

    }
    render() {
        let VendorOptions = this.state.Vendors.map(v => (
            <option text={v.Name+'-'+v.Center_name} value={v.id}>{v.Name+'-'+v.Center_name}</option>
          ));
          let PublicationsOptions = this.state.Publications.map(v => (
            <option text={v.name+'-'+v.FormalName} value={v.id}>{v.name+'-'+v.FormalName}</option>
            ));


          
        const columns = [{
            dataField: 'cust_name',
            text: 'Customer Name'
          }, {
            dataField: 'depot_name',
            text: 'Depot Name'
          }, {
            dataField: 'vendor_name',
            text: 'Vendor Name'
          }];
          const expandRow = {
            renderer: (row) => (
              <div style={{overflowWrap: 'anywhere'}}>
                 <p>Customer Name:  {row.cust_name}</p>
                <p>Publication:  {row.pub}</p>
                <p>vendor Name :  {row.vendor_name}</p>
                <p>Depot Name :  {row.depot_name}</p>
                <p>Address :  {row.address}</p>
                <p>Mobile :  {row.mobile}</p>
              
                <p>Copy Start Date :  {row.copy_start_date}</p>
                <p>Copy End Date :  {row.copy_end_date}</p>
                <p>Executive : {row.executive}</p>
              
                <div>
                  <button onClick={() => this.edit(row.id)}
                    class="d-none d-sm-inline-block btn btn-primary"
                    style={{marginRight: 10}}
                  >
                    Edit
                  </button>
                  <div onClick={() => this.delete(row.id)}  class="d-none d-sm-inline-block btn btn-danger">
                    Delete
                  </div>
                </div>
              </div>
            ),
            showExpandColumn: true,

            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                  return <b>(-)</b>;
                }
                return <b>(+)</b>;
              },
              expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                  return (
                    <b>Less</b>
                  );
                }
                return (
                  <b>More...</b>
                );
            }

          };
        return (
        
        <div className="logbody" style={{backgroundColor:'#f8f9fc'}}>
  

<div className="logs">
         
       
    </div>
    <div class="container-fluid">
{/* <div style={{  borderBottom:'1px solid #007bff',}}>
<h2 style={{color:'#666', width:'90%',  textAlign:'center', float:'left', fontSize:20, padding:15}}>Meera Newspaper Distributor - Subscription Management</h2>

<a style={{float:'left', padding:15}} href="/">logout</a>
<div style={{clear:'both', float:'none'}}></div>
</div> */}


    <div className="row">
<div class="col-md-7 " style={{ minHeight:'100vh'}}>
    <br></br>
    <h2 style={{color:'#007bff', textAlign:'center', fontSize:16, padding:5}}>Subscribers List</h2>

<BootstrapTable  keyField='id' data={ this.state.Response } expandRow={ expandRow } columns={ columns } pagination={ paginationFactory() } />


</div>
<div class="col-md-5 " >

    <div class="card shadow" style={{minHeight:100, marginTop:60, padding:'20px 40px', minWidth:100, }}>

    {this.state.loader==false?
<div>

    <h2 style={{color:'#007bff', textAlign:'left', fontSize:18, padding:5}}>Add New Subscriber</h2>
<div  style={{padding:'20px 40px',minWidth:100,}}>
    <select id='Vendor' onChange={this.GetallData} style={{padding:' 5px 10px',width: '100%', float:'left',height:'auto'}}  class="form-control" value={this.state.Vendorvalue} >
    <option value='0'>All Vendor</option>  {VendorOptions}
        </select>
        </div>
        <div style={{padding:'40px',minWidth:100,}}>
    <select id='Item'  style={{padding:' 5px 10px',width: '100%', float:'left',height:'auto'}}  class="form-control" onChange={this.GetallData1} value={this.state.Publicationsvalue}>
    <option value='0'>All Publications</option>  {PublicationsOptions}
        </select>
        </div>
        
        <div style={{padding:'20px 40px',minWidth:100,}}>
        <div className="">
                      <Helmet>
                        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
                      </Helmet>
        <DayPickerInput
    
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD-MM-YYYY"
                        placeholder="Copy Start Date"
                        dayPickerProps={{

                          localeUtils: MomentLocaleUtils,
                        }}
                        onDayChange={this.onDayChange} 
                      />
      
       </div>
       </div>   <div style={{padding:'0px 40px',minWidth:100,}}>
       <div className="">
                      <Helmet>
                        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
                      </Helmet>
       
    
        <DayPickerInput
       
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD-MM-YYYY"
                        placeholder="Copy End Date"
                        dayPickerProps={{

                          localeUtils: MomentLocaleUtils,
                        }}
                      
                        onDayChange={this.onDayChange1} 
                      />
      
       </div>
  
       </div>
        <div style={{padding:'20px 40px',minWidth:100,}}>
        <input id="DepotName"   type="text" placeholder='Enter Depot Name' className="form-control"/>  </div>

        <div style={{padding:'0px 40px',minWidth:100,}}>
        <textarea id="Address" placeholder='Enter Address'  name="w3review" className="form-control" rows="3" cols="20">
</textarea> </div>
       
        <div style={{padding:'20px 40px',minWidth:100,}}>
        <input id="Mobile"   type="number" placeholder='Enter Mobile number' className="form-control"/>  </div>
        <div style={{padding:'10px 40px',minWidth:100, marginBottom:50, textAlign:'center'}}>
  
  <button id='btn' onClick={this.handleChange} class='btn btn-primary'>Add</button> </div>

   </div>
    :
   <div  class="loader"></div>  }</div>

</div>
</div></div>

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/css/bootstrap.min.css"
  integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
  crossorigin="anonymous"
/>
</div>
        )}
        GetallData= (event) => 
        {
         // alert(event.target.value)
          this.setState({
            Vendorvalue:event.target.value,
           
          })
        }
        GetallData1= (event) => 
        {
      //   alert(event.target.value)
          this.setState({
            Publicationsvalue:event.target.value,
           // ItemName:event.target.text
          })
        }
        handleChange = event => {
         
 

            if(this.state.Vendorvalue!='0')
{
    if(this.state.Publicationsvalue!='0')
    {
        if(this.state.inputIsEmpty==false)
        {
            if(this.state.inputIsEmpty1==false)
            {

                var dateOne = new Date(this.state.selectedDay1.split('-')[2],this.state.selectedDay1.split('-')[1],this.state.selectedDay1.split('-')[0]); //Year, Month, Date    
                var dateTwo = new Date(this.state.selectedDay.split('-')[2],this.state.selectedDay.split('-')[1],this.state.selectedDay.split('-')[0]); //Year, Month, Date    
           
           
           if (dateOne > dateTwo) {
                
    if(document.getElementById('DepotName').value!="")
    {
        var e = document.getElementById("Item");
       
        var ItemName = e.options[e.selectedIndex].text;
        var v = document.getElementById("Vendor");
       
        var VendorNames = v.options[v.selectedIndex].text;
      // alert(VendorNames)
       

       document.getElementById('btn').innerHTML='<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Saving...'
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
myHeaders.append("Origin", "application/x-www-form-urlencoded");
var urlencoded = new URLSearchParams();
urlencoded.append("VendorId", this.state.Vendorvalue);
urlencoded.append("VendorName", VendorNames);


urlencoded.append("ItemId", this.state.Publicationsvalue);
urlencoded.append("StartDate", this.state.selectedDay);
urlencoded.append("EndDate", this.state.selectedDay1);
urlencoded.append("DepotName", document.getElementById('DepotName').value);
urlencoded.append("Address", document.getElementById('Address').value);
urlencoded.append("Mobile", document.getElementById('Mobile').value);
urlencoded.append("ItemName", ItemName.toString());
urlencoded.append("group_pub", 'TOI');
urlencoded.append("executive", "Admin");
urlencoded.append("executive_mobile", localStorage.getItem('Mobile'));

var User = {
  method: 'POST',
  headers: myHeaders,
  body: urlencoded,
  redirect: 'follow'
};

fetch('https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/SubscriptionAddbyexecutive', User)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
  .then(response =>{
  //  alert(response.response[0].Role)
  
    if(response.statuscode==1)
    {
        
           
             // window.location='/subscription'
             window.location.reload()
       
    }else{
        alert(response.message)
    }
    document.getElementById('btn').innerHTML='Add';
}
    )
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
}else{
    alert('Enter the Name')
}
}else{
    alert('Copy End  Date should Greater then Copy Start Date')
}

}else{
    alert('Enter Copy End  date')
}
}else{
    alert('Enter Copy Start date')
}
}else{
    alert('Select Publications')
}
          
        }else{
            alert('Select Vendor')
        }
        
    }

    edit=(id)=>
    {
        window.location="/#/subscriptionEdit/"+id;
    }
delete= (id) => 
{
  //  alert(id)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("id",id);
    var Subscriptiondel = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch("https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/SubscriptionDelete", Subscriptiondel)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
    
        {
            if(response.statuscode==1)
    {
         alert("Record Deleted Successfully")
    }else{
        alert("Something went wrong")
    }
    window.location.reload()
        })
    }
    }
      

           
             


export default Subscription;
