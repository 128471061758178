
import React, { Component,useRef } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { ApiConstants } from './ApiConstants';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
var context;


export default class SubscriberMargin extends Component
{
  constructor(props) {
    super(props);
    context=this
}
state = {inputIsEmpty:true,inputIsEmpty1:true,Publicationsvalue:'0',Vendorvalue:'0',ItemName:'',loader:true,
Response: [], Vendors:[],Publications:[],
Monthlydays:30,
}
onDayChange(day, modifiers, instance) {
    if (instance.getInput().value !== '') {
        context.setState({ inputIsEmpty: false });
        day= formatDate(day, 'DD-MM-YYYY')
        context.setState({ selectedDay: day });
    }else{
        context.setState({ inputIsEmpty: true });
    }
   
   // alert(context.state.inputIsEmpty)
}
  logout()
  {
    localStorage.clear();
        window.location='/'
  }
  GetallData= (event) => 
  {
   // alert(event.target.value)
    this.setState({
      Vendorvalue:event.target.value,
     
    })
  }
  GetallData1= (event) => 
  {
//   alert(event.target.value)
    this.setState({
      Publicationsvalue:event.target.value,
     // ItemName:event.target.text
    })
  }

  GetallData2= (event) => 
  {
//   alert(event.target.value)
    this.setState({
      selectedDay:event.target.value,
     // ItemName:event.target.text
    })
  }
  delete= (id) => 
{
  //  alert(id)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("id",id);
    var Subscriptiondel = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch("https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/SubscriptionCommissionDelete", Subscriptiondel)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
    
        {
            if(response.statuscode==1)
    {
         alert("Record Deleted Successfully")
    }else{
        alert("Something went wrong")
    }
    window.location.reload()
        })
    }
  componentDidMount()
  {
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    
        var urlencoded = new URLSearchParams();
       
        var User = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch('https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/GetsubscribeCommissionAll', User)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(Response => {

var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    
        var urlencoded = new URLSearchParams();
       
        var User = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch('https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/subscribebyCenter_id', User)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(subscribebyCenter => {


          
       let  ResponseSub= Response.response.map(function (data) {
        let Subscription_Copies=0;
        subscribebyCenter.response.map(function (subscribebyCenterdata) {
      
if(subscribebyCenterdata.vendor_id==data.vendor_id && subscribebyCenterdata.itemId==data.itemId )
{ 
  //data=[...data]  
  Subscription_Copies=subscribebyCenterdata.Subscriptionres;

}
        })


        return{
          Subscription_Copies:Subscription_Copies,
          itemId: data.itemId,
          Commission: data.Commission,
          billing_date: data.billing_date,
          center_id: data.center_id,
        pub: data.pub,
        vendor_id: data.vendor_id,
        vendor_name:data.vendor_name,
        id:data.id
          }
          })
          // console.log(Response.response)
          // console.log(ResponseSub)
          
          this.setState({Response:ResponseSub})
        })

        })
      
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

myHeaders.append("Origin", "application/x-www-form-urlencoded");

var urlencoded = new URLSearchParams();
urlencoded.append("CenterId",'0');
var VENDOR = {
method: 'POST',
headers: myHeaders,
body: urlencoded,
redirect: 'follow'
};

fetch("https://sureshtimesdealer.cannytechnology.comapi/Vendors", VENDOR)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
.then(response => 

  {
      let data=response.data;
      data.sort(function(a, b) {
          return a.Name > b.Name;
        });
this.setState({
Vendors: data
})
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

myHeaders.append("Origin", "application/x-www-form-urlencoded");

var urlencoded = new URLSearchParams();
urlencoded.append("Selling_Day",'Friday');
var VENDOR = {
method: 'POST',
headers: myHeaders,
body: urlencoded,
redirect: 'follow'
};

fetch("https://sureshtimesdealer.cannytechnology.comapi/items", VENDOR)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
.then(response => 

{
  let data=response.response;
  // data.sort(function(a, b) {
  //     return a.Name > b.Name;
  //   });
  let datecal = new Date();
  //alert(datecal)
  let month = datecal.getMonth();
  //alert(month)
  let Monthlydays=new Date('2021', month, 0).getDate()
//alert(Monthlydays)
this.setState({
  Publications: data,
  loader:false,
  Monthlydays:Monthlydays
})

})
  })

  }
  edit=(id)=>
  {
      window.location="/#/subscriber_margin_Edit/"+id;
  }

  handleChange = event => {
         
 

if(this.state.Publicationsvalue!='0')
{
if(this.state.selectedDay!=0)
{
   

var e = document.getElementById("Item");

var ItemName = e.options[e.selectedIndex].text;

//alert( this.state.selectedDay)

let commission=document.getElementById('commissionMon').value+','+document.getElementById('commissionTuesday').value+','+document.getElementById('commissionWednesdays').value+','+document.getElementById('commissionThursdays').value+','+document.getElementById('commissionFridays').value+','+document.getElementById('commissionSaturdays').value+','+document.getElementById('commissionsunday').value

//alert(commission)
document.getElementById('btn').innerHTML='<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Saving...'
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
myHeaders.append("Origin", "application/x-www-form-urlencoded");
var urlencoded = new URLSearchParams();




urlencoded.append("ItemId", this.state.Publicationsvalue);
urlencoded.append("BillingDate", this.state.selectedDay);

urlencoded.append("commission", commission);


urlencoded.append("ItemName", ItemName.toString());



var User = {
method: 'POST',
headers: myHeaders,
body: urlencoded,
redirect: 'follow'
};

fetch('https://sureshtimesdealer.cannytechnology.comapi/UserRoutermobile/SubscriptionAddCommission', User)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
.then(response =>{
//  alert(response.response[0].Role)

if(response.statuscode==1)
{

   
     // window.location='/subscription'
     window.location.reload()

}else{
alert(response.message)
}
document.getElementById('btn').innerHTML='Add';
}
)
.then(result => console.log(result))
.catch(error => console.log('error', error));




}else{
alert('Enter Billing date')
}
}else{
alert('Select Publications')
}


}


    render() {
      
      // let VendorOptions = this.state.Vendors.map(v => (
      //   <option text={v.Name+'-'+v.Center_name} value={v.id}>{v.Name+'-'+v.Center_name}</option>
      // ));
      let PublicationsOptions = this.state.Publications.map(v => (
        <option text={v.name+'-'+v.FormalName} value={v.id}>{v.name+'-'+v.FormalName}</option>
        ));


      
    const columns = [ {
        dataField: 'pub',
        text: 'Publication'
      }
      ,
      {
       
        dataField: 'billing_date',
        text: 'Monthly Billing Date'
      }
    
    
    
      ];
      const expandRow = {
        renderer: (row) => (
          <div style={{overflowWrap: 'anywhere'}}>
            <p>Publication:  <b>{row.pub}</b></p>
         
            <p>Monthly Billing Date : <b>{row.billing_date}</b></p>
            <p>Commission per Copy :  <b>
              
            Monday:{row.Commission.split(',')[0]!=''?row.Commission.split(',')[0]:0} , 
            Tuesday:{row.Commission.split(',')[1]!=''?row.Commission.split(',')[1]:0}  , 
            Wednesday:{row.Commission.split(',')[2]!=''?row.Commission.split(',')[2]:0}  , 
            Thursday:{row.Commission.split(',')[3]!=''?row.Commission.split(',')[3]:0} , 
            Friday:{row.Commission.split(',')[4]!=''?row.Commission.split(',')[4]:0}  , 
            Saturday:{row.Commission.split(',')[5]!=''?row.Commission.split(',')[5]:0} , 
            Sunday:{row.Commission.split(',')[6]!=''?row.Commission.split(',')[6]:0}  
              </b></p>
            <p>Total Subscription Copies: <b> {row.Subscription_Copies}</b></p>
            {/* <p>Total Monthly Subscription billing Amount: <b> {row.Subscription_Copies * row.Commission * this.state.Monthlydays}</b></p>
            <p>Note : <b> {row.note}</b></p> */}
        
            <div>
              <button onClick={() => this.edit(row.id)}
                class="d-none d-sm-inline-block btn btn-primary"
                style={{marginRight: 10}}
              >
                Edit
              </button>
              <div onClick={() => this.delete(row.id)}  class="d-none d-sm-inline-block btn btn-danger">
                Delete
              </div>
            </div>
          </div>
        ),
        showExpandColumn: true,

        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
              return <b>(-)</b>;
            }
            return <b>(+)</b>;
          },
          expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
              return (
                <b>Less</b>
              );
            }
            return (
              <b>More...</b>
            );
        }

      };
        return (
      <div >
      {this.state.loader==false?
             <div class="container-fluid">
         
         
             
             
                 <div className="row">
               <div class="col-md-6 " >
             
                 <div class="card shadow" style={{minHeight:100, marginTop:60, padding:'20px 5px', minWidth:100, }}>
             
               
            <div>
             
                 <h2 style={{color:'#007bff', textAlign:'left', fontSize:18, padding:5}}>Add Subscriber Commission</h2>
             {/* <div  style={{padding:'20px 40px',minWidth:100,}}>
                 <select id='Vendor' onChange={this.GetallData} style={{padding:' 5px 10px',width: '100%', float:'left',height:'auto'}}  class="form-control" value={this.state.Vendorvalue} >
                 <option value='0'>All Vendor</option>  {VendorOptions}
                     </select>
                     </div> */}
                     <div style={{padding:'40px',minWidth:100,}}>
               
                     <div>Select Publication  </div>
                 <select id='Item'  style={{padding:' 5px 10px',width: '100%', float:'left',height:'auto'}}  class="form-control" onChange={this.GetallData1} value={this.state.Publicationsvalue}>
                 <option value='0'>All Publications</option>  {PublicationsOptions}
                     </select>
                     </div>
                     {/* <div> Select Monthly Billing Date </div> */}
                     <div style={{padding:'25px 40px',minWidth:100,}}>
                 <div>Select Monthly Billing Date  </div>
                     <select id='Billing'  style={{padding:' 5px 10px',width: '100%', float:'left',height:'auto'}}  class="form-control" onChange={this.GetallData2} value={this.state.selectedDay}>
                 <option value='0'>Select Monthly Billing Date </option> 
                 <option value='1st'>1st </option> 
             <option value='2nd'>2nd </option> 
             <option value='3rd'>3rd </option> 
             <option value='4th'>4th </option> 
             <option value='5th'>5th </option> 
             <option value='6th'>6th </option> 
             <option value='7th'>7th </option> 
             <option value='8th'>8th </option> 
             <option value='9th'>9th </option> 
             <option value='10th'>10th </option> 
             <option value='11th'>11th </option> 
             <option value='12th'>12th </option> 
             <option value='13th'>13th </option> 
             <option value='14th'>14th </option> 
             <option value='15th'>15th </option> 
             <option value='16th'>16th </option> 
             <option value='17th'>17th </option> 
             <option value='18th'>18th </option> 
             <option value='19th'>19th </option> 
             <option value='20th'>20th </option> 
             <option value='21th'>21th </option> 
             <option value='22th'>22th </option> 
             <option value='23th'>23th </option> 
             <option value='24th'>24th </option> 
             <option value='25th'>25th </option> 
             <option value='26th'>26th </option> 
             <option value='27th'>27th </option> 
             <option value='28th'>28th </option> 
             <option value='29th'>29th </option> 
                 <option value='30th'>30th </option> 
                 <option value='31th'>31th </option> 

                     </select>
                    </div>
             
             
               <div style={{margin:38, padding:10, borderRadius:6, border:'1px solid #284169'}}>
               <span style={{color:'#284169', fontWeight:'bold'}}> Commission per copy</span>
                     <div style={{padding:'10px 15px', flex:1, flexDirection:'row',  minWidth:100,}}>
                     <span style={{color:'#284169', fontWeight:'bold'}}>Monday</span>
                     <input id="commissionMon"   type="number" placeholder='0' className="form-control"/>  </div>
                    
                    
                     <div style={{padding:'10px 15px', flex:1, flexDirection:'row',  minWidth:100,}}>
                  
                       <span style={{color:'#284169', fontWeight:'bold'}}>Tuesday</span>
                     <input id="commissionTuesday"   type="number" placeholder='0' className="form-control"/>  
                     
                     </div>

                     <div style={{padding:'10px 15px', flex:1, flexDirection:'row',  minWidth:100,}}>
                  
                  <span style={{color:'#284169', fontWeight:'bold'}}>Wednesday</span>
                <input id="commissionWednesdays"   type="number" placeholder='0' className="form-control"/>  
                
                </div>

                <div style={{padding:'10px 15px', flex:1, flexDirection:'row',  minWidth:100,}}>
                  
                  <span style={{color:'#284169', fontWeight:'bold'}}>Thursday</span>
                  <input id="commissionThursdays"   type="number" placeholder='0' className="form-control"/>   
                </div>


                <div style={{padding:'10px 15px', flex:1, flexDirection:'row',  minWidth:100,}}>
                  
                  <span style={{color:'#284169', fontWeight:'bold'}}>Friday</span>
                  <input id="commissionFridays"   type="number" placeholder='0' className="form-control"/>     </div>

                     <div style={{padding:'10px 15px', flex:1, flexDirection:'row',  minWidth:100,}}>
                  
                  <span style={{color:'#284169', fontWeight:'bold'}}>Saturday</span>
                  <input id="commissionSaturdays"   type="number" placeholder='0' className="form-control"/>     </div>
                  <div style={{padding:'10px 15px', flex:1, flexDirection:'row',  minWidth:100,}}>
                  
                  <span style={{color:'#284169', fontWeight:'bold'}}>Sunday</span>
                  <input id="commissionsunday"   type="number" placeholder='0' className="form-control"/>     </div>

                     </div>
                     {/* <div style={{padding:'0px 40px',minWidth:100,}}>
                     <textarea id="Note" placeholder='Any Note'  name="w3review" className="form-control" rows="3" cols="20">
             </textarea> </div>
                     */}
    <div style={{padding:'10px 40px',minWidth:100, marginBottom:50, textAlign:'center'}}>
               
               <button id='btn' onClick={this.handleChange} class='btn btn-primary'>Add</button> </div>
             
                </div>
               </div>    </div>
               <div class="col-md-6 " style={{ minHeight:'100vh'}}>
                 <br></br>
                 <h2 style={{color:'#007bff', textAlign:'center', fontSize:16, marginLeft:10, padding:5}}>Subscriber's Item List</h2>
             
             <BootstrapTable  keyField='id' data={ this.state.Response } expandRow={ expandRow } columns={ columns } pagination={ paginationFactory() } />
             
             
             </div>
          
             </div>
             </div>
             
             
    
                
:<div  class="loader" style={{marginTop:300}}></div>   
  }
           </div>

        )}
    
        }
      
