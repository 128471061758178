
import React, { Component } from 'react';
import { ApiConstants } from './ApiConstants';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import Helmet from 'react-helmet';
const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;

export default class AddVendor extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      Centers:[]
    };
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  componentDidMount()
  {
    this.setState({loading:false})
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var VENDOR = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    
  
          fetch(ApiConstants.VIEWCENTER, VENDOR)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(Centersresponse => 
              
              {




          //alert(response.message)
this.setState({

  Centers:Centersresponse.data,
  loading:true
})
          
  console.log(Centersresponse)


})
       
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleChange = (event) => {


    if(document.getElementById('name').value!="")
    {
    
         if(document.getElementById('ContactNumber').value!="")
        {
         
            var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("Name", document.getElementById('name').value);
 
    var e = document.getElementById("Centerdropdown");
    var value = e.options[e.selectedIndex].value;
    var text = e.options[e.selectedIndex].text;
   // alert(value)
   
    urlencoded.append("CenterId", value);
    urlencoded.append("Email", document.getElementById('Email').value);
    urlencoded.append("Address", document.getElementById('Address').value);
    urlencoded.append("city", document.getElementById('City').value);
    urlencoded.append("zipcode", document.getElementById('Zipcode').value);
    urlencoded.append("DateOfJoin", formatDate(new Date(), 'DD-MM-YYYY'));
    urlencoded.append("ContactPerson", document.getElementById('ContactPerson').value);
    urlencoded.append("Fld2", document.getElementById('Fld2').value);
    urlencoded.append("ContactNumber", document.getElementById('ContactNumber').value);
    
    
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.ADDVENDOR, VENDOR)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{
      //  console.log(response)
        alert(response.message)

        window.location = "/#/Vendors";
    }
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
         
            }else{
                alert('Enter Contact Number')
            }
            }else{
                alert('Enter Vendor Name')
            }
  };
    render() {
      let Options = this.state.Centers.map(v => (
        <option value={v.id}>{v.Name}</option>
      ));
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Add New Vendor</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">
        <h5 style={{marginTop:23}} class="h5 mb-0 text-gray-800">{`${formatDate(new Date(), 'dddd MMMM Do YYYY')}`}</h5>
      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

            <div class="row">
   
   {/*Content Column*/}


  <div style={{margin:"auto"}} class="col-lg-6 mb-6">
  <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Add Vendor Information</div>
       
      </div>
     {/*Illustrations*/}
    <div class="card shadow mb-4">
      {/* <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Balance Amount</div>
        <div class="m-0 font-weight-bold text-primary">Rs. 45,4225</div>
      </div> */}
      <div class="card-body">
      <div class="chart-area" style={{minHeight:'23rem', height:'auto'}}>
     

  <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary"> Select Center</h6>
     
        <select  id = "Centerdropdown" ref = {(input)=> this.menu = input} style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  class="form-control" value={this.state.value} >
          {Options}
        </select>
      </div>   

      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Vendor Name</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text"  id="name"  /></h6>
   
      </div>   
    
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Address</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <textarea id="Address">

</textarea></h6>
         </div>   
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Email</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="Email" id="Email"  /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">City</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="City"  /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Zipcode</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="Zipcode"   /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Contact Person</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="ContactPerson"  /></h6>
         </div> 



         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Contact Number</h6>
      <input  type="text" id="Fld2"  name="Fld2" />
         </div> 


         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Password</h6>
      <input  type="text" id="ContactNumber"  name="ContactNumber" />
         </div> 


      <div class="d-sm-flex align-items-center justify-content-center mb-4">
      
      </div>  
        </div>      </div>

        
    </div>
    <div style={{textAlign:'center'}}>

   
    <div onClick={this.handleChange} style={{marginBottom:30}} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Save </div>
    </div>
     {/*Approach*/}
   

  </div>

  
</div>


                 </div>
        
           </div>

        )}
    
        }
      
