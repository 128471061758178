import React, { Component } from 'react';
import logo from './logo.png';
import './App.css';
import './vendor/fontawesome-free/css/all.min.css';

import Home from './componets/Home.js';
import Login from './componets/login.js';
import AppView from './AppView.js';
import Billing from './componets/billing.js';
import 'react-notifications/lib/notifications.css';

class App extends Component { state = {
  openLeft: true,
  openRight: true,
  drawerStyle: `
{
"background": "#F9F9F9",
"boxShadow": "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px"
}`,
  relativeWidth: false,
  width: 300,
  noTouchOpen: false,
  noTouchClose: false,
  page:0,
  val:""
};
componentDidMount(){
  document.title = "NewsAgency Billing System"
  //document.title = "Distributor"
  //alert(localStorage.getItem('userid')==null)
}

  
  render() {

    const {
      drawerStyle: stringDrawerStyle,
      openLeft,
      openRight,
      noTouchOpen,
      noTouchClose
    } = this.state;

    let drawerStyle = {}
    try {
      drawerStyle = JSON.parse(stringDrawerStyle)
    } catch (err) {
      console.error('Error parsing JSON: ', err)
    }

    const drawerProps = {
      overlayColor: "rgba(255,255,255,0.6)",
      drawerStyle
    };
    return (
   

      window.location.href.indexOf('/#/')>0?  window.location.href.split('/#/')[1].split('/')[0]!='billing'?localStorage.getItem('userid')!=null?<AppView/> : <Login/>:<Billing/> : <AppView/>
      

    );
  }

logout=()=>
{
this.setState({ openLeft: false })
localStorage.removeItem('userid');
localStorage.removeItem('username');
localStorage.removeItem('uid');

}


}


export default App;
