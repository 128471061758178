
import React, { Component,useRef } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { ApiConstants } from './ApiConstants';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { HashRouter as Router,hashHistory, Route, Link } from 'react-router-dom'
import Helmet from 'react-helmet';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';


import 'moment/locale/it';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
const { SearchBar, ClearSearchButton } = Search;
var context;
const { ExportCSVButton } = CSVExport;

const columns_pay = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true
  },
  
  {
  dataField: 'Billing_Date',
  text: 'Date',
  sort: true
}, {
  dataField: 'amount_paid',
  text: 'Amount Paid',
  sort: true
}, 

{
  dataField: 'payment_id',
  text: 'Payment Id',
  sort: true
},

{
  dataField: 'payment_type',
  text: 'Payment Mode',
  sort: true
},
// {
//   dataField: 'App_Name',
//   text: 'Payment Platform',
//   sort: true
// },


];

const payment = [{
  Date: 'Date',
  Amount: '12332',
  PaymentMode:'Cash',
  
},

{
  Date: 'Date',
  Amount: '12332',
  PaymentMode:'Cash',
  
},


];


export default class NewInvoice extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      Vendors:[],
      loading:true,
      Centers:[],
      SoldCopies:0,
      ReturnSCopies:0,
      TotalAmout:0,
      TotalCenter:0,
      CenterId:"",
      VendorId:"",
      Total_transactions: [],
      supply_date:formatDate(new Date(), 'DD-MM-YYYY'),
      Credit:0,
      Return_Amount:0,
      Amount_Payable:0,
      Amount_Paid:"",
      Balance_Amount:0,
      Payment_Reference1:"",
      Payment_Reference:"",
      VendorBillingData:[],
      Payment_Info_text:"Cash",
      Payment_history:[],
      billingAmount:0,
      prebalance:0,
      Total_Return_Amount:0,
      Tota_Paid_Amount:0,
      ClosingBalance:0,
      Total_transactions_Return:[],
      ReturnSelect:0,
      billNo:"",
Billing_Date:"",
Billing_End_date:"",
Billing_Start_date:"",
Center:"",
Vendor:"",

    };
    context=this

    //alert(this.props.VendorId)
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  printDiv() { 
    var divContents = document.getElementById("printarea").innerHTML; 
    var a = window.open('', '', 'height=700, width=1000'); 
    a.document.write('<html>'); 
    a.document.write('<body>'); 
    a.document.write(divContents); 
    a.document.write('</body></html>'); 
    a.document.close(); 
    a.print(); 
} 

  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
  componentDidMount()
  {
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
   
        urlencoded.append("BillId",window.location.href.split('/SalesInvoice/')[1].split('/')[0]);
       
        var Billed = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
          fetch(ApiConstants.NEWBILLING, Billed)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
          .then(Billed => {


            let billingdetail=Billed.data[0]
      
           
      let CenterId=billingdetail.CenterId
      let from=billingdetail.Billing_Start_date
      let to=billingdetail.Billing_End_date
      let VendorId=billingdetail.VendorId
    


      this.setState({
billNo:billingdetail.id,
Billing_Date:billingdetail.Billing_Date,
Billing_End_date:billingdetail.Billing_End_date,
Billing_Start_date:billingdetail.Billing_Start_date,
Center:billingdetail.Center,
Vendor:billingdetail.Vendor,
        billingAmount:billingdetail.Total_Supply_Amoount,
        prebalance:billingdetail.Pre_Opening_Balance_Amount	,
        Total_Return_Amount:billingdetail.Total_Return_Amount,
        Tota_Paid_Amount:billingdetail.Amount_Paid,
        ClosingBalance:billingdetail.	Opening_Balance_Amount,
      })
               this.GetData(CenterId,from,to,VendorId)


          //alert(response.message)

        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  init(CenterId)
  {

    this.setState({loading:false})
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("CenterId", CenterId);
    urlencoded.append("Billing_date_mode", "2");
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(ApiConstants.VIEWVENDOR, VENDOR)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {

          var centerdata= response.data;

          var Vendor = centerdata.map(function (data) {

            return {
              Location: data.Location,
              Name:  data.Name,
              Address:data.Address,
              id:  data.id,
              updated_dt:data.updated_dt,
              Center_name: data.Center_name,

              DateOfJoin: data.DateOfJoin,

              ContactPerson: data.ContactPerson,
              ContactNumber: data.ContactNumber,
             
              
              edit:'View more'
            }
          })


          //alert(response.message)
this.setState({
  Vendors:Vendor,
  CenterId:CenterId,
  loading:true
})
//this.GetData(CenterId,this.state.supply_date); 
    
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }


  

  PaymenthandleChange= (event) => 
  {
   //alert(event.target.value)
var Payment_Info_text=""
   if(event.target.value=="Online")
   {
    Payment_Info_text="Transaction Id"
   }
   if(event.target.value=="Cheque")
   {
    Payment_Info_text="Cheque No."
   }
    this.setState({
      Payment_Mode:event.target.value,
   Payment_Info_text:Payment_Info_text
    })
    
 
  }
  GetallData= (event) => 
    {
     // alert(event.target.value)
      this.setState({
        VendorId:event.target.value,
        VendorName:event.target.text
      })
      
      this.GetData(this.state.CenterId,this.state.supply_date,event.target.value); 
    }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });


  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
   var d = new Date(to);
      
   // this.savetojson()
    this.GetData(this.state.CenterId, formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(to), 'DD-MM-YYYY'),this.state.VendorId);

    
  }

  
  handleChange = (event) => {

    this.setState({
      ReturnSelect:event.target.value
    })
  };
  calctotals(VendorId,supply_date_from,supply_date_to)  
  {


   var myHeaders = new Headers();
   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
   
   myHeaders.append("Origin", "application/x-www-form-urlencoded");

   var urlencoded = new URLSearchParams();
   urlencoded.append("Billing_date_mode", "2");
   urlencoded.append("VendorId",VendorId);
   urlencoded.append("from",supply_date_from);
   urlencoded.append("to",supply_date_to);
   var BULKBILLS = {
     method: 'POST',
     headers: myHeaders,
     body: urlencoded,
     redirect: 'follow'
   };

   fetch(ApiConstants.BULKBILLS, BULKBILLS)
   .then(dataWrappedByPromise => dataWrappedByPromise.json())
     .then(response =>        
       {
         
     
         let Payment_history=   response.Payment_history

         
     let Payment_history_removezero=[];
     let i=0;
           Payment_history.map(function(data)
           {
     
          
             if(data.amount_paid!=0)
             {
               Payment_history_removezero[i]=data
               i=i+1;
             }
     
           })
     
           Payment_history=Payment_history_removezero
     

    this.setState({

   
       Payment_history:Payment_history
    
    })


 
  })

}


  GetData(CenterId,from,to,VendorId) {
    


    this.setState({ loading: false,Total_transactions:[] })
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("Billing_date_mode", "2");
        urlencoded.append("VendorId",VendorId);
        urlencoded.append("CenterId",CenterId);
        urlencoded.append("from",from);
        urlencoded.append("to",to);
        urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
      //  alert(supply_date)
         
   
        // console.log(CenterId)
        // console.log(supply_date)
        var GETSALESBILLING = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch(ApiConstants.GETSALESBILLING, GETSALESBILLING)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(CETERBOOKRES => {
          var urlencoded = new URLSearchParams();
          urlencoded.append("CenterID",CenterId);
          var VendorbyCenters = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };
   
          fetch(ApiConstants.VENDORSBYCENTER, VendorbyCenters)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
          .then(VendorbyCenter => {
          
     let Vendor=VendorbyCenter.response


       
           let to=CETERBOOKRES[(CETERBOOKRES.length)-2].supply_date

           let from=CETERBOOKRES[0].supply_date
  

let items=[]


let Total_transactions=[];

Total_transactions=CETERBOOKRES[0].All_JSON_Date

Total_transactions=Total_transactions.toString().replace('\\', '')
Total_transactions=JSON.parse(Total_transactions)




for(let k=0; k<Vendor.length; k++)
{

if(Vendor[k].id==VendorId)
{
  // items=Total_transactions[k].Fld1;


  items=JSON.parse(Vendor[k].Fld1)
}
}
if(items==undefined)
{
  items=CETERBOOKRES[(CETERBOOKRES.length)-1]
items=items.toString().replace('\\', '')
items=JSON.parse(items)
items=items.items;
 
}

let columns = []
let j=0;
columns[j]={
  dataField: 'Date',
  text: 'Date',
  sort: true,
  
  style: (cell, row, rowIndex, colIndex) => {
    return { width: "100px", fontSize:11 };
  }
}
for(let i=0; i<items.length; i++)
{

 j=j+1;
  columns[j]={
    dataField: items[i].FormalName,
    text: items[i].FormalName,
    sort: true,
    style: (cell, row, rowIndex, colIndex) => {
      return {  fontSize:11 };
    }
  }

 
}
j=j+1;
columns[j]={
  dataField: 'Total',
  text: 'Total',
  sort: true,
  style: (cell, row, rowIndex, colIndex) => {
    return {  fontSize:11 };
  }
}
//console.log(columns)
this.setState({items:items,columns:columns})

let products=[]
let Total_transactions_Return=[]
let ar=0;
         // alert(CETERBOOKRES.TotalCenter[0].TotalCenter)
         for(let z=0; z<CETERBOOKRES.length; z++)
         {
if(CETERBOOKRES[z].All_JSON_Date!=undefined)
{
          let Total_transactions=[];

            Total_transactions=CETERBOOKRES[z].All_JSON_Date
          
         Total_transactions=Total_transactions.toString().replace('\\', '')
          Total_transactions=JSON.parse(Total_transactions)
       
       


          for(let k=0; k<Total_transactions.length; k++)
          {
          
if(Total_transactions[k].Vendor_Code==VendorId)
{

let itm=[];
let retn=[];
let Amount=0;
let AmountRtn=0;
           for(let m=0; m<Total_transactions[k].transaction.length; m++)
           {
            if(Total_transactions[k].transaction[m]!=undefined)
            {
            Amount=Amount+ parseFloat (Total_transactions[k].transaction[m].Total.toString().replace('Rs.',''))
        
            if(Total_transactions[k].transaction[m].ReturnTotal!=undefined)
            {
            AmountRtn=AmountRtn+ parseFloat (Total_transactions[k].transaction[m].ReturnTotal.toString().replace('Rs.',''))
            }
            if(m==0)
           {
            itm='"'+[Total_transactions[k].transaction[m].Item]+'":"'+ Total_transactions[k].transaction[m].Supply_Copies+'"'
            retn='"'+[Total_transactions[k].transaction[m].Item]+'":"'+ Total_transactions[k].transaction[m].Return_Copies+'"'
         
         
            
          }else{
            itm=itm+',"'+[Total_transactions[k].transaction[m].Item]+'":"'+  Total_transactions[k].transaction[m].Supply_Copies.toString()+'"'
            retn=retn+',"'+[Total_transactions[k].transaction[m].Item]+'":"'+  Total_transactions[k].transaction[m].Return_Copies.toString()+'"'
           
           }
          }

            
        //    Total_transactions[k].transaction.push(transaction[m])
          
           }
//console.log(itm)



let tempitem=[]
let temparry={
  Date: CETERBOOKRES[z].supply_date,
   Total:Amount,
 
  
 }
 let temparryRtn={
  Date: CETERBOOKRES[z].supply_date,
   Total:Amount,
 
  
 }
 itm=itm+',"Date":"'+CETERBOOKRES[z].supply_date+'","Total":"'+parseFloat(Amount).toFixed(2)+'"';


 retn=retn+',"Date":"'+CETERBOOKRES[z].supply_date+'","Total":"'+parseFloat(AmountRtn).toFixed(2)+'"';
 tempitem=itm
//  for(let it=1; it>=itm.split(',').length; it++)
//  {
//   temparry.push()
//  }

//console.log(tempitem)
temparry= '{'+tempitem+'}'
temparryRtn= '{'+retn+'}'
//temparry.push(tempitem)
//console.log(temparry)
        
products.push(JSON.parse(temparry))
Total_transactions_Return.push(JSON.parse(temparryRtn))
ar=ar+Amount

        
}

       
          }
        }
      }
       
      
        this.setState({ loading: true,products:products,  TotalAmout:ar, Total_transactions:products, Total_transactions_Return:Total_transactions_Return })


        
               // this.FeatchItem(Total_transactions, itemsnext,this.state.supply_date ,VendorId)
                this.calctotals(VendorId, from, to)
                
       
        })
  

      })
    
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

  }
 
  
  generatebill()
  {

//alert(Number(context.state.Amount_Paid)<=Number(context.state.Amount_Payable))
if(context.state.Amount_Paid!=undefined && context.state.Amount_Paid!="")
{
  if(Number(context.state.Amount_Paid)<=Number(context.state.Amount_Payable))
{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();

    urlencoded.append("CenterId", context.state.CenterId);
        urlencoded.append("Billing_Date", context.state.supply_date );
        urlencoded.append("VendorId", context.state.VendorId );
        urlencoded.append("Total", context.state.TotalAmout );
        urlencoded.append("Balance_Amount", (Number(context.state.Amount_Payable )-Number(context.state.Amount_Paid )));

        urlencoded.append("Amount_Payable", context.state.Amount_Payable );
        urlencoded.append("Amount_Paid", context.state.Amount_Paid );
        urlencoded.append("Payment_Mode", context.state.Payment_Mode );
        urlencoded.append("Billing_date_mode", "2");
        urlencoded.append("Payment_Reference", context.state.Payment_Reference);
        urlencoded.append("Payment_Reference1", context.state.Payment_Reference1 );

   // alert(date)
    var billing = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.SAVEBILLING, billing)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 

{
  if(response.data.affectedRows>=1)
  {
    alert('Data Saved Successfully')
    context.setState({
      Bill_Link: window.location.href.split('/#')[0]+ "/#/billing/"+context.state.CenterId+"/"+context.state.VendorId+"/"+ context.state.supply_date
    })
   // window.location='#/billing/'+context.state.VendorId+"/"+ context.state.supply_date 
  }
  //console.log(response.response.affectedRows)
//console.log(response.response.affectedRows>=1)

})
//window.print();
 
   
// let table = document.getElementById("table").innerHTML; 

// let totalbox = document.getElementById("totalbox").innerHTML; 
// let CenterName=context.state.Centername;
// let BillNo='1';
// let billingdate=context.state.supply_date
// let VendorName=context.state.VendorName
// let Address='Malleswaram, Bangalore-33'
// let Phone='87654335'
// let Paymentinfo=""
// let Paymentmode="Cash"






// let Totalbills='<div style="flex: 1 1 auto;padding: 1.25rem;justify-content: space-between !important;    display: flex !important;"><div style="width: 50%;margin: auto;min-height: 23rem;"><div style="margin-bottom: 1.5rem !important;    align-items: center !important;justify-content: space-between !important;    display: flex !important;"><span class="m-0 font-weight-normal text-primary">Total Supply Amount </span><span class="m-0 font-weight-normal text-primary">'+context.state.supply_amount+'</span></div><div class="d-sm-flex align-items-center justify-content-between mb-4"><span class="m-0 font-weight-normal text-primary">Total Return Amount</span><span class="m-0 font-weight-normal text-primary"><span class="m-0 font-weight-normal text-primary">Rs. '+context.state.return_amount+'</span></span></div><div class="d-sm-flex align-items-center justify-content-between mb-4"><span class="m-0 font-weight-normal text-primary">Credit</span><span class="m-0 font-weight-normal text-primary">Rs. '+context.state.Credit+'</span></div><div class="d-sm-flex align-items-center justify-content-between mb-4"><span class="m-0 font-weight-normal text-primary">Amount Payable</span><span class="m-0 font-weight-normal text-primary">Rs. '+context.state.Amount_Payable+'</span></div><div class="d-sm-flex align-items-center justify-content-between mb-4"><span class="m-0 font-weight-normal text-primary">Amount Paid</span><span class="m-0 font-weight-normal text-primary">Rs. '+context.state.Amount_Paid+'</span></div><div class="d-sm-flex align-items-center justify-content-between mb-4"><span class="m-0 font-weight-normal text-primary">Payment Mode</span> <span class="m-0 font-weight-normal text-primary">'+Paymentmode+'</span> </div></div> <hr>  </div>'
// let Center='<div style="justify-content: center !important; display: flex !important;"><h3 style="color: #5a5c69 !important;">Center :'+CenterName+' </h3></div>';




// let bill='<div style="    justify-content: space-between !important;    display: flex !important;"><h3 >Bill No. '+BillNo+' </h3><h2 class="h5 mb-0 text-gray-800">Billing date:  '+billingdate+'</h2></div>';
// let Vendor='<div> <h3 style="padding:0; margin:0"> '+VendorName+'  </h3><div style="padding:0; margin:0"> '+Address+'  </div><div style="padding:0; margin:0"> '+Phone+'  </div> </div>'

// let a = window.open('', '', 'height=3508 , width=2480'); 
// a.document.write('<html><head>  <style>  .mb-4,.my-4{margin-bottom:1.5rem!important}.align-items-center{align-items:center!important}.justify-content-between{justify-content:space-between!important ;     display: flex !important;  }.text-gray-800{color:#5a5c69!important}.h5,h5{font-size:1.25rem}.table-bordered{border:1px solid #e3e6f0}.table{width:100%; text-align:center;  margin-bottom:1rem;color:#858796}table{border-collapse:collapse}.card-body{flex:1 1 auto;padding:1.25rem}.text-primary{color:#2a3f54!important;     padding: 0; height: 0;margin: 7px;    font-size: 18px;}   body{margin:0;font-family:Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";font-size:1rem;font-weight:400;line-height:1.5;color:#858796;     font-size: 14px;text-align:left;background-color:#fff}  .table-bordered th, .table-bordered td {padding: 10px;  border: 1px solid #e3e6f0;} .table thead th {  vertical-align: bottom;  border-bottom: 2px solid #e3e6f0; </style> <link rel="stylesheet" href="/App.css"></head>'); 
// a.document.write('<body> '); 

// a.document.write(Center); 
// a.document.write(bill); 
// a.document.write(Vendor)
// a.document.write(Totalbills)

// a.document.write(table); 
// a.document.write('</body></html>'); 
// a.document.close(); 
// a.focus();
// a.print(); 

}

else{
  alert("Paid amount should be less than or equal to Total amount")
}
}
else{
  alert("Please enter Paid amount")
}

  }

  handleDayChange=(selectedDay, modifiers, dayPickerInput)=> {
    var d = new Date(selectedDay);
      this.setState({supply_date:formatDate(new Date(selectedDay), 'DD-MM-YYYY')})
   // this.savetojson()
    this.GetData(this.state.CenterId, formatDate(new Date(selectedDay), 'DD-MM-YYYY'), this.state.VendorId);

  }
    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
      let Options = this.state.Centers.map(v => (
        <option text={v.Name} value={v.id}>{v.Name}</option>
      ));

      let VendorOptions = this.state.Vendors.map(v => (
        <option text={v.Name} value={v.id}>{v.Name}</option>
      ));
      
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h3 class="h4 mb-0 bold text-gray-900" style={{fontSize:18}}>Bill Detail</h3>

   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">
  <button class="btn btn-primary" style={{padding:' 5px 10px',height:'35px', margin:18,  width: 'auto',}} onClick={this.printDiv}>Print It</button>
  <div style={{color:'#555',    marginTop: 17}}>
    
<select  id="CentersSel" style={{padding:' 5px 10px',height:'auto',  width: 'auto',}}  value={this.state.value} class="form-control"  onChange={this.handleChange}>
        
        <option value='0'>Hide Returns </option>
        <option value='1'>Show Returns </option>
        </select>     </div>
      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

          
{
          this.state.loading?
<div id="printarea"  style={{border:'1px solid #ccc', fontSize:13, padding:30, paddingTop:10}}>
   
<div class="row" >

  <div class="col-lg-9" style={{textAlign:'center', color:'#2A3F54'}}>

<b>MEERA NEWS AGENCY</b><br></br>
Dlrs Times Group of Publications<br></br>
#51, CMH Road Indiranagar, Bengaluru<br></br>
  </div>
  <div class="col-lg-3" style={{textAlign:'left', color:'#2A3F54'}}>
  Bill No :{this.state.billNo} <br></br>
 Center Name : {this.state.Center} <br></br>
 Vendor Name :{this.state.Vendor}  <br></br>
Bill Date :{this.state.Billing_Date} <br></br>
From :{this.state.Billing_Start_date} <br></br>
to:{this.state.Billing_End_date} <br></br>

  </div><hr/>
  </div>
{
        this.state.Total_transactions.length>0 
        
       ? this.state.Total_transactions!=undefined?
     


<div id="harish" class="row">
  <div class="col-lg-12 mb-4" style={{padding:0}}>

     {/*Project Card Example*/}
    <div class="card shadow mb-4">
    
      <div class="card-body" >
        Sales Detail
      <div  style={{fontSize:11, overflow:'auto'}}>


      <BootstrapTable keyField='id'  data={ this.state.Total_transactions } columns={ this.state.columns } />
     
       
       
      </div><hr/>
     {this.state.ReturnSelect==1?
     
     <div>
     
      Return Detail
    <div  style={{fontSize:11, overflow:'auto'}}>


    <BootstrapTable keyField='id' style={"font-size:11"} data={ this.state.Total_transactions_Return } columns={ this.state.columns } />
   
     
     
    </div>
    </div>:null}
   
   
   
    </div>
  </div>
     {/*Color System*/}

  </div>

<hr/>
   
   {/*Content Column*/}
   
   <div  class="col-lg-12 mb-4">
   <div class="card shadow mb-4">
   <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Payment History</div>
        <div class="m-0 font-weight-bold text-primary"></div>
      </div>

     {/*Project Card Example*/}
  
  
      <div class="card-body" style={{overflow:'auto', maxHeight:700,}}>
        
      <div >
        {this.state.Payment_history.length>=1?
           <div >
          {/* <ExportCSVButton { ...props.csvProps }>Export</ExportCSVButton>
          <hr /> */}
      <BootstrapTable keyField='id' data={ this.state.Payment_history} columns={ columns_pay } />
      </div >:"No data!" }</div>
      </div>
    </div>

     {/*Color System*/}

  </div>
  <hr/>
  <div  class="col-lg-5 mb-4">

     {/*Illustrations*/}
    <div class="card shadow mb-4" >
      {/* <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Billing</div>
        <div class="m-0 font-weight-bold text-primary"></div>
      </div> */}
      <div class="card-body">
      <div class="chart-area">
        
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <span class="m-0 font-weight-normal text-primary">Bill Amount : </span>
        <span class="m-0 font-weight-normal text-primary">Rs. {parseFloat(this.state.billingAmount.toString().replace('Rs.','')).toFixed(2)}</span>

      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <span class="m-0 font-weight-normal text-primary">Pre Balance  :  </span>
        <span class="m-0 font-weight-normal text-primary"> 
        <span class="m-0 font-weight-normal text-primary">Rs. {this.state.prebalance}</span>
        </span>

      </div> 
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <span class="m-0 font-weight-normal text-primary">Return  : </span>
        <span class="m-0 font-weight-normal text-primary"> 
        <span class="m-0 font-weight-normal text-primary">Rs. {this.state.Total_Return_Amount}</span>
        </span>

      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <span class="m-0 font-weight-normal text-primary">Amount Paid  : </span>
        <span class="m-0 font-weight-normal text-primary">Rs. {this.state.Tota_Paid_Amount}</span>

      </div>   
  

<hr/>
      <div style={{marginBottom:0}} class="d-sm-flex align-items-center justify-content-between mb-4">
      <span class="m-0 font-weight-bold text-primary">Closing Balance  : </span>
        <span class="m-0 font-weight-bold text-primary" style={{textAlign:"left"}}>Rs.{this.state.ClosingBalance}</span>

      </div> 

  


      </div>
     
    
      
      

           </div>


         
           </div>

        
    
     
    

     {/*Approach*/}
   

  </div>
 
 
  
  </div>
:<div style={{textAlign:'center', color:'#000', margin:'auto', padding:10,}}>No data</div>:<div style={{textAlign:'center', color:'#000',margin:'auto', padding:10,}}>No data</div>}

</div>
:<div  class="loader"></div>


    }
                 </div>
               
        
           </div>

        )}
    
        }
      
