
import React, { Component } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { green } from 'ansi-colors';
const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;


const columns = [{
  dataField: 'id',
  text: 'ID',
  sort: true,
  editable: false
}, {
  dataField: 'Vendor',
  text: 'Vendor',
  sort: true,
  editable: false
}, {
  dataField: 'Total_Items',
  text: 'Total Items',
  sort: true,
  editable: false
},
{
  dataField: 'Total',
  text: 'Total Amount',
  sort: true,
  editable: false
}, {
  dataField: 'Paid',
  text: 'Paid Amount',
  sort: true,
  editable: false
}, {
  dataField: 'Balance',
  text: 'Balance Amount',
  sort: true,
  editable: false
},
{
  dataField: 'User',
  text: 'User',
  sort: true,
  editable: false
}, {

  dataField: 'Approved',
  text: 'Approval Status',
  sort: true,
  style: (cell, row, rowIndex, colIndex) => {
    if (cell == 'Waiting') {
      return {
        backgroundColor: '#2196F3',
        color:'#fff',
      };
    }
    if (cell == 'Disapprove') {
      return {
        backgroundColor: '#DB4638',
        color:'#fff',
      };
    }
    return {
      backgroundColor: '#4CAF50',
      color:'#fff',
    };
  
  },
  editor: {
    type: Type.SELECT,
    options: [{
      value: 'Waiting',
      label: 'Waiting'
    }, {
      value: 'Approved',
      label: 'Approved'
    }, {
      value: 'Disapprove',
      label: 'Disapprove'
    }]
  }
  // formatter: (cellContent, row) => (

   

  //   <select  style={row.Approved=='Waiting' ? {    backgroundColor:'blue', padding:' 5px 10px',   color:'#fff'}: row.Approved=='Approved' ?{    backgroundColor:'green', padding:' 5px 10px',   color:'#fff'}:{    backgroundColor:'red', padding:' 5px 10px',   color:'#fff'}}  class="form-control" id="sel1">
  //    <option style={row.Approved=='Waiting' ? {    backgroundColor:'blue',   color:'#fff'}: row.Approved=='Approved' ?{    backgroundColor:'green',   color:'#fff'}:{    backgroundColor:'red',   color:'#fff'}}> {row.Approved}</option>
  //   <option style={{backgroundColor:'blue', color:'#fff'}}>Waiting</option>
  //   <option style={{backgroundColor:'green',color:'#fff'}}>Approved</option>
  //   <option style={{backgroundColor:'red', color:'#fff'}}>Disapprove</option>


  // </select>


  // )
}

];
const products = [{
  id: '1',
  Vendor: '1',
  Total_Items:'Total_Items',
  Total:'345435',
  Paid:'333',
  Balance:'Balance',
  User:'User',

  Approved:'Approved'
},
{
  id: '2',
  Vendor: '4441',
  Total_Items:'Total_Items',
  Total:'345435',
  Paid:'311133',
  Balance:'Balance',
  User:'User',

  Approved:'Waiting'
},



];

const expandRow = {
  onlyOneExpanding: true,
  renderer: row => (
    <div>
      <p>{ `TOI ${row.Vendor}` }</p>
      <p>{ `TOI ${row.id}` }</p>
      <p>{ `TOI ${row.Vendor}` }</p>
    </div>
    
  ),
  showExpandColumn: true
};
export default class Invoice extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
    };
  }

  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleChange = (event) => {
    alert(event.target.value );
  };
    render() {
      
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
        return (
      <div>
        
<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Sales Overview</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">


             <ToolkitProvider
  keyField="id"
  data={ products }
  columns={ columns }
  search 
  
  pagination={ paginationFactory() }

>
              {/*Page Heading*/}
         
   
              {/*Content Row*/}
            
  {
    props => (
      <div>


        <div class="d-sm-flex align-items-center justify-content-between mb-4">

        <h1 class="h3 mb-0 text-gray-800">Sales</h1>


<div>
               <a href="/#NewInvoice" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" style={{marginRight:10}}><i class="fas fa-file-invoice fa-sm text-white-50"></i> New Bill</a>
               <a href="/#SalesInvoice" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Sales Bill</a>
     
           <ExportCSVButton  { ...props.csvProps }>  
           
             <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Export CSV</a>
           </ExportCSVButton> </div>     </div>


           <hr />
           <div class="d-sm-flex align-items-center justify-content-between mb-6">
           <div class="form-group">
  <label style={{float:'left',padding:' 5px ',}} for="sel1">Center:</label>
  <select style={{     padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  class="form-control" id="sel1">
    <option>Indiranagar</option>
    <option>Jayanagar</option>
    <option>J P Nagar</option>
    <option>Hebbal</option>
  </select>
</div>





    
         <div className="InputFromTo">
        <DayPickerInput
          value={from}
          placeholder="From"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            placeholder="To"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
        </span>
     
      </div>

      <div>
         <SearchBar { ...props.searchProps } /></div> 
         
         </div>
        <BootstrapTable
        expandRow={ expandRow }
        cellEdit={ cellEditFactory({
          mode: 'click',
          blurToSave: true,
          nonEditableRows: () => [0, 3]
        }) }
          { ...props.baseProps }
        />
     

      </div>
    )
  }
</ToolkitProvider>  
           </div>
        
           </div>

        )}
    
        }
      
