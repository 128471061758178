
import React, { Component } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Helmet from 'react-helmet';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { ApiConstants } from './ApiConstants';
import 'moment/locale/it';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { green } from 'ansi-colors';
const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;

var context;
const columns = [{
  dataField: 'id',
  text: 'Item ID',
  sort: true,
  editable: false
},{
  dataField: 'name',
  text: 'Name',
  sort: true,
  editable: false
}, {
  dataField: 'Issue',
  text: 'Issue',
  sort: true,
  editable: false
}, {
  dataField: 'Selling_Price',
  text: 'Selling Price',
  sort: true,
  editable: false
},
{
  dataField: 'Pur_Price',
  text: 'Pur Price',
  sort: true,
  editable: false
}, {

  dataField: 'View',
  text: '',
  sort: true,
  editable: false,
  style: (cell, row, rowIndex, colIndex) => {
  
      return {
        backgroundColor: '#2196F3',
        color:'#fff',
     
      };
    
  
  },
}

];


const expandRow = {
  onlyOneExpanding: true,
  renderer: row => (
    <div>
      <p>{ `Item Name :  ${row.name}` }</p>
      <p>{ `Item Formal Name :  ${row.FormalName}` }</p>
      <p>{ `Issue :  ${row.Issue}` }</p>
      <p>{ `Address :  ${row.Address}` }</p>
      <p>{ `zipcode :  ${row.zipcode}` }</p>
      <p>{ `ContactPersion :  ${row.ContactPersion}` }</p>
      <p>{ `Selling Price : Rs.  ${row.Selling_Price}` }</p>
      
      <p>{ `Pure Price : Rs. ${row.Pur_Price}` }</p>
      <p>Selling Day :{context.state.Selling_Day }</p>
      <p>{ `NonSalesDays :   ${row.NonSalesDays!=undefined? row.NonSalesDays:""}` }</p>
    <div style={{flexDirection:'row', flex:1, textAlign:'left'}}>
      <button  onClick={(e) => 
      
     window.location="#/Editnewitem/"+row.id} class="d-none d-sm-inline-block btn btn-primary" style={{marginRight:10}}>Edit</button>
      <div onClick={(e) => 
      
      {
       let id=row.id
//alert(id)

var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

myHeaders.append("Origin", "application/x-www-form-urlencoded");

var urlencoded = new URLSearchParams();
//alert(window.location.href.split('EditCenter/')[1])
urlencoded.append("itemid", id);
var items = {
  method: 'POST',
  headers: myHeaders,
  body: urlencoded,
  redirect: 'follow'
};
fetch(ApiConstants.DELETEITEM, items)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
  .then(response =>{
  //console.log(response)
  window.location.reload();
    alert(response.message)
    window.location.reload();
   // window.location = "/#/Dashboard";
}
    )
  .then(result => console.log(result))
  .catch(error => console.log('error', error));

       // console.log(row.id)
      }
      
      } class="d-none d-sm-inline-block btn btn-danger">Delete</div>

    </div>

    </div>
    
  ),
  showExpandColumn: true
};

export default class Items extends Component
{
  constructor(props) {
    super(props);
    this.state = {
    topitem:[],
    Selling_Day:""
    }
    context=this
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  handleDayChange(selectedDay, modifiers, dayPickerInput) {


    var d = new Date(selectedDay);
  
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
  
    var weekdaynow = weekday[d.getDay()];
//lert(weekdaynow)

   context.init(weekdaynow)
  }
  componentDidMount()
  {
    this.init(formatDate(new Date(), 'dddd'))
    
  }
  
  
  init(Selling_Day)
  {
    this.setState({Selling_Day:Selling_Day})
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("Selling_Day", Selling_Day);
 
    var VIEWITEM = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.VIEWITEM, VIEWITEM)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {
          //alert(response.message)
this.setState({
  topitem:response.response,
  
})
          
         console.log(response.response)
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
    render() {
      
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Items</h1>

           
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}

  
  <ul class="navbar-nav ml-auto">
  <a href="/#Addnewitem" class="d-none d-sm-inline-block btn btn-primary" style={{marginRight:10, marginTop:16, height:40}}><i style={{fontSize:14, paddingRight:10,}} class="fas fa-file-invoice fa-sm text-white-50"></i>Add New Item</a>
  
      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
<div class="container-fluid">

{this.state.topitem.length>0?
<ToolkitProvider
keyField="id"
data={ this.state.topitem }
columns={ columns }
search 

pagination={ paginationFactory() }

>
 {/*Page Heading*/}


 {/*Content Row*/}

{
props => (
<div>

<div class="d-sm-flex align-items-center justify-content-between mb-6">
<Helmet>
        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
      </Helmet>
<DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format="dddd MMMM Do YYYY"
        placeholder={`${formatDate(new Date(), 'dddd MMMM Do YYYY')}`}
        dayPickerProps={{
          
          localeUtils: MomentLocaleUtils,
        }}
        onDayChange={this.handleDayChange} 
      />


<div>
<SearchBar { ...props.searchProps } /></div> 

</div>
<BootstrapTable
expandRow={ expandRow }
cellEdit={ cellEditFactory({
mode: 'click',
blurToSave: true,
nonEditableRows: () => [0, 3]
}) }
{ ...props.baseProps }
/>


</div>
)
}
</ToolkitProvider>  
:    <div style={{flex:1, textAlign:'center', flexDirection:'row', color:'red', padding:10, fontSize:30,}}>

No Items Added

</div>}

</div>
</div>

        )}
    
        }
      
