
import React, { Component } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Helmet from 'react-helmet';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import 'moment/locale/it';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;
const columns = [{
  dataField: 'Date',
  text: 'Date',
  sort: true
}, 
{
  dataField: 'Item',
  text: 'Item',
  sort: true
},
{
  dataField: 'Copies',
  text: 'Copies',
  sort: true
},{
  dataField: 'Returns',
  text: 'Returns',
  sort: true
}, {
  dataField: 'Rate',
  text: 'Rate',
  sort: true
},
{
  dataField: 'Amount',
  text: 'Amount',
  sort: true
}

];

const Returnscolumns = [{
  dataField: 'Date',
  text: 'Date',
  sort: true
}, 
{
  dataField: 'Item',
  text: 'Item',
  sort: true
},
{
  dataField: 'Copies',
  text: 'Copies',
  sort: true
}, {
  dataField: 'Rate',
  text: 'Rate',
  sort: true
},
{
  dataField: 'Amount',
  text: 'Amount',
  sort: true
}

];
const products = [{
  Item: 'TOI',
  Copies: '15',
  Rate:'555',
  Amount:'345435',
 
},
{
  Item: 'DH',
  Copies: '15',
  Rate:'555',
  Amount:'345435',
 
},
{
  Item: 'TOI',
  Copies: '15',
  Rate:'555',
  Amount:'345435',
 
},
{
  Item: 'DH',
  Copies: '15',
  Rate:'555',
  Amount:'345435',
 
},

{
  Item: 'TOI',
  Copies: '15',
  Rate:'555',
  Amount:'345435',
 
},
{
  Item: 'DH',
  Copies: '15',
  Rate:'555',
  Amount:'345435',
 
},




];
export default class Returns extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
    };
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleChange = (event) => {
    alert(event.target.value );
  };
    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Returns</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

             <div class="d-sm-flex align-items-center justify-content-between mb-4">
               <h3 class="h5 mb-0 text-gray-800">Return No. 20</h3>
               <h2 class="h5 mb-0 text-gray-800">Return date: <Helmet>
        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          .mb-4, .my-4

          {
            margin-bottom: 0rem !important;
          }
          `}</style>
      </Helmet>
<DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format="DD-M-YYYY"
        placeholder={`${formatDate(new Date(), 'DD-M-YYYY')}`}
        dayPickerProps={{
          
          localeUtils: MomentLocaleUtils,
        }}
      />
</h2>
               {/* <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> */}
             </div>
             <div class="d-sm-flex align-items-center justify-content-center mb-4">
             <div class="form-group">
  <label style={{float:'left',padding:' 5px ',}} for="sel1">Centers:</label>
  <select style={{     padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  class="form-control" id="sel1">
    <option>Indiranagar</option>
    <option>Jayanagar</option>
    <option>J P Nagar</option>
    <option>Hebbal</option>
  </select>
</div>
<div class="form-group">
  <label style={{float:'left', marginLeft:20,  padding:' 5px ',}} for="sel1">Vendor:</label>
  <select style={{     padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  class="form-control" id="sel1">
    <option>New Agency - 101</option>
    <option>Manasa - 102</option>
    <option>Shankar - 103</option>
    <option>Raj Agency - 104</option>
  </select>
</div>


       

    
</div>
<div class="row">
   
   {/*Content Column*/}
  <div class="col-lg-12 mb-4">

     {/*Project Card Example*/}
    <div class="card shadow mb-4">
    <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Supplied Copies </div>

     
      <div style={{marginBottom:0}} className="InputFromTo">
        <DayPickerInput
          value={from}
          placeholder="From"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            placeholder="To"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
        </span>
     
      </div>  </div>

      <div class="card-body">
      <div >
        
      <BootstrapTable keyField='id' data={ products } columns={ columns } />
        </div>
      </div>
    </div>

     {/*Color System*/}

  </div>





  <div class="col-lg-12 mb-4">

{/*Project Card Example*/}
<div class="card shadow mb-4">
<div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
   <div class="m-0 font-weight-bold text-primary">No. of Returning Copies 
   
  <input  style={{margin:10}} type="text"  name="name" />
     
   <button class="btn btn-primary"> Return</button>
   
   </div>


 <div style={{marginBottom:0}} className="InputFromTo">
  
   

 </div>  </div>

 <div class="card-body">
 <div >
   
 <BootstrapTable keyField='id' data={ products } columns={ Returnscolumns } />
   </div>
 </div>
</div>

{/*Color System*/}

</div>


  </div>


                 </div>
        
           </div>

        )}
    
        }
      
