
import React, { Component } from 'react';
import { ApiConstants } from './ApiConstants';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import Helmet from 'react-helmet';
const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;

export default class EditCenter extends Component
{
  state = {
 
  Item:[],
  SelectedItem:[]
};
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      Item:[],
      SelectedItem:[]
    };
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  componentDidMount()
  {
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    //alert(window.location.href.split('EditCenter/')[1])
    urlencoded.append("CenterId", window.location.href.split('EditCenter/')[1]);
    var GETCENTER = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
  
    fetch(ApiConstants.GETCENTER, GETCENTER)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {


          
          document.getElementById('name').value=response.response[0].Name
          document.getElementById('Location').value=response.response[0].Location
          document.getElementById('Address').value=response.response[0].Address
          document.getElementById('Zipcode').value=response.response[0].zipcode
          
         // console.log(response.response)

          var SelectedItem=response.response[0].Fld1;
          if(SelectedItem!=null && SelectedItem.length>0)
 {
          SelectedItem= SelectedItem.toString().replace('\\', '')
 }
 console.log(JSON.stringify(SelectedItem))
         //alert(SelectedItem)
 this.setState({
   Item:response.Item,
   SelectedItem:JSON.parse(SelectedItem),
 
   Centersid: response.response[0].CenterId
 })
 //alert(response.response[0].CenterId)
 this.sortitem(this.state.SelectedItem)

})
       
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  sortitem(temvar)
  {
    
   var items=this.state.Item
   var SelectedItem=temvar
console.log(temvar)

for(let i=0; i<items.length; i++)
{
  if(SelectedItem!=undefined)
  {
  for(let j=0; j<SelectedItem.length; j++)
  {

    if(SelectedItem[j]!=undefined)
    {
    if( items[i].id===SelectedItem[j].id)
    {
      
      items.splice(i, 1);
    }
  }
  }
  }
}

this.setState({
  Item:items
})



  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
 

  
  handleChange = (event) => {
    //alert(document.getElementById('Location').value)
    
    if(document.getElementById('name').value!="")
    {
    
         
            var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("Name", document.getElementById('name').value);
    urlencoded.append("CenterId", window.location.href.split('EditCenter/')[1]);

   
    urlencoded.append("Location", document.getElementById('Location').value);
    urlencoded.append("Address", document.getElementById('Address').value);
  
    urlencoded.append("Zipcode", document.getElementById('Zipcode').value);
    urlencoded.append("Firm_id", 1);
    urlencoded.append("Fld1", JSON.stringify(this.state.SelectedItem));
    
    var Center = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.UPDATECENTER, Center)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{
      //  console.log(response)
        alert(response.message)

        window.location = "/#/AllCenter";
    }
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
         
            
            }else{
                alert('Enter Center Name')
            }
  };
  DELETECENTER = (event) => {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    //alert(window.location.href.split('EditCenter/')[1])
    urlencoded.append("CenterId", window.location.href.split('EditCenter/')[1]);
    var CENTER = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    fetch(ApiConstants.DELETECENTER, CENTER)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{
      //  console.log(response)
        alert(response.message)

        window.location = "/#/AllCenter";
    }
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
         
  };
  
    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Edit Center</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">
        <h5 style={{marginTop:23}} class="h5 mb-0 text-gray-800">{`${formatDate(new Date(), 'dddd MMMM Do YYYY')}`}</h5>
      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

            <div class="row">
   
   {/*Content Column*/}


  <div style={{margin:"auto"}} class="col-lg-6 mb-6">
  <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Edit Center Information</div>
        <div class="m-0 font-weight-bold text-primary">
          <button onClick={this.DELETECENTER}  class="btn btn-danger">Delete</button>
          </div>
      </div>
     {/*Illustrations*/}
    <div class="card shadow mb-4">
      {/* <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Balance Amount</div>
        <div class="m-0 font-weight-bold text-primary">Rs. 45,4225</div>
      </div> */}
      <div class="card-body">
      <div class="chart-area" style={{ height:'auto'}}>
     

      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Center Name</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text"  id="name"  /></h6>
   
      </div>   
    
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Address</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <textarea id="Address">

</textarea></h6>
         </div>   
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Location / Area</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="Location" id="Location"  /></h6>
         </div> 
     
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Zipcode</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="number" id="Zipcode"   /></h6>
         </div> 
        
      <div class="d-sm-flex align-items-center justify-content-center mb-4">
      
      </div>  
        </div>      </div>

        
    </div>

    
 
     {/*Approach*/}
   

  </div>
  <div style={{margin:"auto", marginTop:40,}} class="col-lg-10 d-sm-flex mb-10">
 
 <div style={{marginRight:'5%'}} class="card shadow col-lg-6 mb-6">
     {/* <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
       <div class="m-0 font-weight-bold text-primary">Balance Amount</div>
       <div class="m-0 font-weight-bold text-primary">Rs. 45,4225</div>
     </div> */}
     <div class="card-body">
     <div class="chart-area" style={{minHeight:'23rem', height:'auto'}}>
    
     <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
       <div class="m-0 font-weight-bold text-primary">All Items</div>
      
     </div>

     
   
    {
    this.state.Item!=undefined &&   this.state.Item.length>0
       ?
      this.state.Item.map(x=>

       <div id={x.id} class="d-sm-flex align-items-center mb-4">
            <div class="btn btn-info" style={{marginRight:5,}}> {x.FormalName}</div>
         <div class="btn btn-info"> {x.name} </div>
     
       <div onClick={(e) => { 
 
   
 var temvar=[];

if( this.state.SelectedItem!=null && this.state.SelectedItem.length>=1)
{
  temvar=this.state.SelectedItem
temvar[temvar.length]=x;
this.setState(
 {
 SelectedItem:temvar,
 }
)
}else{

 temvar[0]=x;
 this.setState(
   {
   SelectedItem:temvar,
   }
 )
}

this.sortitem(temvar)



        }} class="btn-info btn" style={{marginLeft:'6%'}}>     <i class="fa fa-arrow-alt-circle-right"></i>
      </div>
      </div> 
      ):<div> No Items Found </div> 
    }
      
      
       </div> 
       
       
       
            </div>

       
   </div>
   <div class="card shadow  col-lg-6 mb-6">
     {/* <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
       <div class="m-0 font-weight-bold text-primary">Balance Amount</div>
       <div class="m-0 font-weight-bold text-primary">Rs. 45,4225</div>
     </div> */}
     <div class="card-body">
  
     <div class="chart-area" style={{minHeight:'23rem', height:'auto'}}>
    
     <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
       <div class="m-0 font-weight-bold text-primary">Selected Items for Center</div>
      
     </div>
 {
   console.log(this.state.SelectedItem)
 }

     {
      
      this.state.SelectedItem!=undefined && this.state.SelectedItem.length>0
       ?
       
      this.state.SelectedItem.map(x=>

       <div id={x.id} class="d-sm-flex align-items-center mb-4">
     <div  onClick={(e) => { 
 
   
 var temvar=[];
 var items=this.state.Item;

if( this.state.SelectedItem!=null && this.state.SelectedItem.length>=1)
{
  temvar=this.state.SelectedItem

temvar = temvar.filter(item => item !== x)
this.setState(
 {
 SelectedItem:temvar,
 }
)
}
items = items.unshift(x)





console.log(temvar)

        }}  class="btn-info btn" style={{marginRight:'6%'}}>     <i class="fa fa-arrow-alt-circle-left"></i>
     </div>  
     <div class="btn btn-info" style={{marginRight:5,}}> {x.FormalName}</div>
         <div class="btn btn-info"> {x.name} </div>
          </div>
      ):<div> No Selected Items Found </div> 
    }
     
   
       </div> 
       
       
            </div>

       
   </div>
 
   
   </div>

  
</div>
<div style={{clear:'both', float:'none'}}>

</div>
<div style={{textAlign:'center', display:'initial' , margin:'auto', marginTop:30}} class="row">
<div style={{textAlign:'center'}}>

   
    <div onClick={this.handleChange} style={{margin:'auto', marginTop:30, marginBottom:30, textAlign:'center'}} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Update </div>
    </div>

    </div>
                 </div>
        
           </div>

        )}
    
        }
      
