
import React, { Component } from 'react';
import { ApiConstants } from './ApiConstants';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import Helmet from 'react-helmet';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;
var context;
export default class EditVendor extends Component
{
  state = {
 
    Centers:[],
    Item:[],
    SelectedItem:[],
    BillingDate:""
  };
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      Centers:[],
      Item:[],
      SelectedItem:[]
    };
    context=this;
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  handleDayChange=(selectedDay, modifiers, dayPickerInput)=> {
    var d = new Date(selectedDay);
      this.setState({BillingDate:formatDate(new Date(selectedDay), 'DD-MM-YYYY')})

  }
  componentDidMount()
  {
    this.setState({loading:false, BillingDate:""})
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var VENDOR = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    
  
          fetch(ApiConstants.VIEWCENTER, VENDOR)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(Centersresponse => 
              
              {







                this.GetVenderbyid()





          //alert(response.message)
this.setState({

  Centers:Centersresponse.data,
  loading:true
})
          
  console.log(Centersresponse)


})
       
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  GetVenderbyid()
  {
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    //alert(window.location.href.split('EditVendor/')[1])
    urlencoded.append("VenderId", window.location.href.split('EditVendor/')[1]);
    var GETVENDERBYID = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
  
    fetch(ApiConstants.GETVENDERBYID, GETVENDERBYID)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {

         var SelectedItem=response.response[0].Fld1;
         if(SelectedItem!=null && SelectedItem.length>0)
{
         SelectedItem= SelectedItem.toString().replace('\\', '')
}
         console.log(SelectedItem)
this.setState({
  Item:response.Item,
  SelectedItem:JSON.parse(SelectedItem),

  Centersid: response.response[0].CenterId
})
//alert(response.response[0].CenterId)

this.sortitem(this.state.SelectedItem)

        //   if(response.response[0].Fld1==null)
        //  {
        //   this.setState({
       
        //     SelectedItem:response.Item,
        //   })
        //  }else{
        //   this.setState({
       
        //     SelectedItem:response.response[0].Fld1,
        //   })

        
        //  }
        

       
       
       



          document.getElementById('name').value=response.response[0].Name
          document.getElementById('Email').value=response.response[0].Email
          document.getElementById('Address').value=response.response[0].Address
          document.getElementById('City').value=response.response[0].city
          document.getElementById('Zipcode').value=response.response[0].zipcode
        
          document.getElementById('ContactPerson').value=response.response[0].ContactPerson
          document.getElementById('ContactNumber').value=response.response[0].ContactNumber
          document.getElementById('Fld2').value=response.response[0].Fld2
          
          document.getElementById('OpeningBalance').value=response.response[0].OpeningBalance
          
this.setState({BillingDate:response.response[0].Billing_Start_Date})
          console.log(response.response)


})
       
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }


  DELETECENTER = (event) => {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    //alert(window.location.href.split('EditVendor/')[1])
    urlencoded.append("VenderId", window.location.href.split('EditVendor/')[1]);
    var DELETEVENDER = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    fetch(ApiConstants.DELETEVENDER, DELETEVENDER)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{
      //  console.log(response)
        alert(response.message)

        window.location = "/#/Vendors";
    }
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
         
  };
  sortitem(temvar)
  {
    
   var items=this.state.Item
   var SelectedItem=temvar
console.log(temvar)

for(let i=0; i<items.length; i++)
{
  if(SelectedItem!=undefined)
  {
  for(let j=0; j<SelectedItem.length; j++)
  {

    if(SelectedItem[j]!=undefined)
    {
    if( items[i].id===SelectedItem[j].id)
    {
      
      items.splice(i, 1);
    }
  }
  }
  }
}

this.setState({
  Item:items
})



  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleChange = (event) => {


    if(document.getElementById('name').value!="")
    {
    
         if(document.getElementById('ContactNumber').value!="")
        {
         
            var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("Name", document.getElementById('name').value);
 
    var e = document.getElementById("Centerdropdown");
    var value = e.options[e.selectedIndex].value;
    var text = e.options[e.selectedIndex].text;
   // alert(value)
   urlencoded.append("VenderId", window.location.href.split('EditVendor/')[1]);
    urlencoded.append("CenterId", value);
    urlencoded.append("Email", document.getElementById('Email').value);
    urlencoded.append("Address", document.getElementById('Address').value);
    urlencoded.append("city", document.getElementById('City').value);
    urlencoded.append("zipcode", document.getElementById('Zipcode').value);
    
    urlencoded.append("ContactPerson", document.getElementById('ContactPerson').value);
    urlencoded.append("ContactNumber", document.getElementById('ContactNumber').value);
    urlencoded.append("Fld2", document.getElementById('Fld2').value);

    urlencoded.append("OpeningBalance", document.getElementById('OpeningBalance').value);
    urlencoded.append("Billing_Start_Date", this.state.BillingDate);
    urlencoded.append("Fld1", JSON.stringify(this.state.SelectedItem));
    
    console.log(JSON.stringify(this.state.SelectedItem))
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.UPDATEVENDER, VENDOR)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{
      //  console.log(response)
        alert(response.message)

        window.location = "/#/Vendors";
    }
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
         
            }else{
                alert('Enter Password')
            }
            }else{
                alert('Enter Vendor Name')
            }
  };
    render() {

    //  alert(this.state.Centersid)
      let Options = this.state.Centers.map(v => (

        v.id==this.state.Centersid?  <option  selected value={v.id}>{v.Name}</option>:
        <option value={v.id}>{v.Name}</option>


      ));

    //   for ( var i = 0; i < s.options.length; i++ ) {
    //     if ( s.options[i].value == v ) {
    //         s.options[i].selected = true;
    //         return;
    //     }
    // }

      // var e = document.getElementById("Centerdropdown");
      // e.options[e.selectedIndex].value=;
      // var text = e.options[e.selectedIndex].text;

      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Edit Vendor</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">
        <h5 style={{marginTop:23}} class="h5 mb-0 text-gray-800">{`${formatDate(new Date(), 'dddd MMMM Do YYYY')}`}</h5>
      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

            <div class="row">
   
   {/*Content Column*/}


  <div style={{margin:"auto"}} class="col-lg-6 mb-6">
  <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Edit Vendor Information</div>
        <div class="m-0 font-weight-bold text-primary">
          <button onClick={this.DELETECENTER}  class="btn btn-danger">Delete</button>
          </div>
      </div>
     {/*Illustrations*/}
    <div class="card shadow mb-4">
      {/* <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Balance Amount</div>
        <div class="m-0 font-weight-bold text-primary">Rs. 45,4225</div>
      </div> */}
      <div class="card-body">
      <div class="chart-area" style={{minHeight:'23rem', height:'auto'}}>
     

  <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary"> Select Center</h6>
     
        <select  id = "Centerdropdown" ref = {(input)=> this.menu = input} style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  class="form-control" value={this.state.value} >
          {Options}
        </select>
      </div>   

      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Vendor Name</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text"  id="name"  /></h6>
   
      </div>   
    
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Address</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <textarea id="Address">

</textarea></h6>
         </div>   
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Email</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="Email" id="Email"  /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">City</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="City"  /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Zipcode</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="Zipcode"   /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Contact Person</h6>
        <h6 class="m-0 font-weight-normal text-primary"> <input  type="text" id="ContactPerson"  /></h6>
         </div> 
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Contact Number</h6>
      <input  type="text" id="Fld2"  name="Fld2" />
         </div>

          <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Password</h6>
      <input  type="text" id="ContactNumber"  name="Password" />
         </div>  
         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Opening Balance Amount</h6>
      <input  type="number" id="OpeningBalance"  name="OpeningBalance" />
         </div> 

         <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Billing Start Date</h6>
     

      <div className="">
                      <Helmet>
                        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:190px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
                      </Helmet>
                      <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        value={this.state.BillingDate!="" && this.state.BillingDate!=undefined ?this.state.BillingDate:null}
                        format="DD-MM-YYYY"
                        placeholder={`${formatDate(new Date(), 'DD-MM-YYYY')}`}
                        dayPickerProps={{

                          localeUtils: MomentLocaleUtils,
                        }}
                        onDayChange={this.handleDayChange} 
                      />

                    </div>



         </div> 

      <div class="d-sm-flex align-items-center justify-content-center mb-4">
      
      </div>  
        </div> 
        
        
        
             </div>

        
    </div>
    
    </div>
    
  <div style={{margin:"auto", marginTop:40,}} class="col-lg-10 d-sm-flex mb-10">
 
  <div style={{marginRight:'5%'}} class="card shadow col-lg-6 mb-6">
      {/* <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Balance Amount</div>
        <div class="m-0 font-weight-bold text-primary">Rs. 45,4225</div>
      </div> */}
      <div class="card-body">
      <div class="chart-area" style={{minHeight:'23rem', height:'auto'}}>
     
      <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">All Items</div>
       
      </div>

      
    
     {
     this.state.Item!=undefined &&   this.state.Item.length>0
        ?
       this.state.Item.map(x=>

        <div id={x.id} class="d-sm-flex align-items-center mb-4">
             <div class="btn btn-info" style={{marginRight:5,}}> {x.FormalName}</div>
          <div class="btn btn-info"> {x.name} </div>
      
        <div onClick={(e) => { 
  
    
  var temvar=[];

if( this.state.SelectedItem!=null && this.state.SelectedItem.length>=1)
{
   temvar=this.state.SelectedItem
temvar[temvar.length]=x;
this.setState(
  {
  SelectedItem:temvar,
  }
)
}else{

  temvar[0]=x;
  this.setState(
    {
    SelectedItem:temvar,
    }
  )
}

this.sortitem(temvar)



         }} class="btn-info btn" style={{marginLeft:'6%'}}>     <i class="fa fa-arrow-alt-circle-right"></i>
       </div>
       </div> 
       ):<div> No Items Found </div> 
     }
       
       
        </div> 
        
        
        
             </div>

        
    </div>
    <div class="card shadow  col-lg-6 mb-6">
      {/* <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Balance Amount</div>
        <div class="m-0 font-weight-bold text-primary">Rs. 45,4225</div>
      </div> */}
      <div class="card-body">
   
      <div class="chart-area" style={{minHeight:'23rem', height:'auto'}}>
     
      <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Selected Items for Vendor</div>
       
      </div>
  

      {
       
       this.state.SelectedItem!=undefined && this.state.SelectedItem.length>0
        ?
        
       this.state.SelectedItem.map(x=>

        <div id={x.id} class="d-sm-flex align-items-center mb-4">
      <div  onClick={(e) => { 
  
    
  var temvar=[];
  var items=this.state.Item;

if( this.state.SelectedItem!=null && this.state.SelectedItem.length>=1)
{
   temvar=this.state.SelectedItem

temvar = temvar.filter(item => item !== x)
this.setState(
  {
  SelectedItem:temvar,
  }
)
}
items = items.unshift(x)





//console.log(temvar)

         }}  class="btn-info btn" style={{marginRight:'6%'}}>     <i class="fa fa-arrow-alt-circle-left"></i>
      </div>  
      <div class="btn btn-info" style={{marginRight:5,}}> {x.FormalName}</div>
          <div class="btn btn-info"> {x.name} </div>
           </div>
       ):<div> No Selected Items Found </div> 
     }
      
    
        </div> 
        
        
             </div>

        
    </div>
  

    </div>
    <div style={{margin:"auto"}} class="col-lg-6 mb-6">
    <div style={{textAlign:'center'}}>

   
    <div onClick={this.handleChange} style={{marginTop:30,marginBottom:30}} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Update All</div>
   
     {/*Approach*/}
   
     </div>
  </div>

  
</div>


                 </div>
        
           </div>

        )}
    
        }
      
