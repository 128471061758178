
import React, { Component,useRef } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { ApiConstants } from './ApiConstants';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { HashRouter as Router,hashHistory, Route, Link } from 'react-router-dom'
import Helmet from 'react-helmet';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';


import 'moment/locale/it';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Alert } from 'react-bootstrap';
const { SearchBar, ClearSearchButton } = Search;
var context;
const { ExportCSVButton } = CSVExport;

const columns_pay = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true
  },
  
  {
  dataField: 'Billing_Date',
  text: 'Date',
  sort: true
}, {
  dataField: 'amount_paid',
  text: 'Amount Paid',
  sort: true
}, 

{
  dataField: 'payment_id',
  text: 'Payment Ref',
  sort: true
},

{
  dataField: 'payment_type',
  text: 'Payment Mode',
  sort: true
},
// {
//   dataField: 'App_Name',
//   text: 'Payment Platform',
//   sort: true
// },


];

const columns = [];



export default class SalesReport extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: new Date(),
    //  to: new Date(),
    to: undefined,
      
      Vendors:[],
      loading:true,
      Centers:[],
      SoldCopies:0,
      ReturnSCopies:0,
      TotalAmout:0,
      TotalCenter:0,
      butnloaging:0,
      CenterId:"",
      VendorId:"",
      Total_transactions: [],
      supply_date:formatDate(new Date(), 'DD-MM-YYYY'),
      Credit:0,
      Return_Amount:0,
      Amount_Payable:0,
      Amount_Paid:"",
      Balance_Amount:0,
      Payment_Reference1:"",
      Payment_Reference:"",
      VendorBillingData:[],
      Payment_history:[],
      Payment_Info_text:"Cash",
      Payment_Mode:"Cash",
      Total_Return_Amount:0,
     OpeningBalance:0,
     Todays_Paid:0,
     Tota_Paid_Amount:0,
     CETERBOOKRES:[]

    };
    context=this

    //alert(this.props.VendorId)
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
  componentDidMount()
  {
    
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

   
  
    var Center = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
 
          fetch(ApiConstants.VIEWCENTER, Center)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(Centersresponse => 
              
              {


               


          //alert(response.message)
this.setState({
 
  Centers:Centersresponse.data,
  loading:true
})
  
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  init(CenterId)
  {

    this.setState({loading:false})
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("CenterId", CenterId);
    urlencoded.append("Billing_date_mode", "2");
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(ApiConstants.VIEWVENDOR, VENDOR)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {

          var centerdata= response.data;

          // var Vendor = centerdata.map(function (data) {

          //   return {
          //     Location: data.Location,
          //     Name:  data.Name,
          //     Address:data.Address,
          //     id:  data.id,
          //     updated_dt:data.updated_dt,
          //     Center_name: data.Center_name,

          //     DateOfJoin: data.DateOfJoin,

          //     ContactPerson: data.ContactPerson,
          //     ContactNumber: data.ContactNumber,
             
              
          //     edit:'View more'
          //   }
          // })


          //alert(response.message)
this.setState({
  Vendors:centerdata,
  CenterId:CenterId,
  loading:true
})
//this.GetData(CenterId,this.state.supply_date); 
    
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  SavePayment = (event) => {
this.setState({
  butnloaging:1
})
let ur=localStorage.getItem('username');
//alert(ur)
         let amt=document.getElementById('Todays_Paid').value


         if(amt=="" || amt==undefined)
         {
           amt=0
         }
        
         let allpaid= parseFloat(this.state.Tota_Paid_Amount)+ parseFloat(amt);
        
            var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
   
   // alert(value)

   urlencoded.append("CenterId", this.state.CenterId);
   urlencoded.append("Billing_Date", formatDate(new Date(), 'DD-MM-YYYY'));
   urlencoded.append("VendorId",this.state.VendorId);
   urlencoded.append("Total_Supply_Amoount", parseFloat(this.state.TotalAmout.toString().replace('Rs.','')).toFixed(2));
   urlencoded.append("Total_Return_Amount", this.state.Total_Return_Amount);
   urlencoded.append("Amount_Paid",  allpaid);
urlencoded.append("Pre_Opening_Balance_Amount", this.state.OpeningBalance);
urlencoded.append("Amount_Payable", this.state.Amount_Payable);
urlencoded.append("Payment_Reference", amt+" to " + document.getElementById('Payment_id').value+"by"+ur);
urlencoded.append("Payment_Mode",  this.state.Payment_Mode);
urlencoded.append("Opening_Balance_Amount", parseFloat((parseFloat(this.state.Amount_Payable)- parseFloat(amt)).toFixed(2)) );

urlencoded.append("Billing_Start_date", formatDate(new Date(context.state.from), 'DD-MM-YYYY') );
urlencoded.append("Billing_End_date", formatDate(new Date(context.state.to), 'DD-MM-YYYY'));
urlencoded.append("Note", document.getElementById('Note').value);
 
  
    var BillingGenerates = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.GenerateBillSaveVendor, BillingGenerates)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{
        let billing_id=response.data.insertId
let v=1;

context.state.CETERBOOKRES.map(function(data)
{

  let Total_transactions=[];

  Total_transactions=data.All_JSON_Date
let Prebill=data.Prebill
if(Prebill!=undefined)
{
Prebill=data.Prebill
}
if(Total_transactions!=undefined)
{
Total_transactions=Total_transactions.toString().replace('\\', '')
Total_transactions=JSON.parse(Total_transactions)


  for (let j = 0; j < Total_transactions.length; j++) {
    let date=new Date(data.supply_date.split('-')[1]+"/"+data.supply_date.split('-')[0]+"/"+data.supply_date.split('-')[2])
    let dateto=new Date()
    if(date>dateto)
    {
      Total_transactions[j].Approved="Pre-week"
    }
    
   if (Total_transactions[j].Vendor_Code==context.state.VendorId)
    {
  
    Total_transactions[j].Approved = 'Billed'

    if(Prebill!=undefined && Prebill=='y')
    {
      
      Total_transactions[j].Approved = 'Pre-Billed'
      for (let k = 0; k < Total_transactions[j].transaction.length; k++) {
        Total_transactions[j].transaction[k].Pre_Status="Pre-Billed"
        
          Total_transactions[j].transaction[k].PreBilled_Price=Total_transactions[j].transaction[k].Price
          Total_transactions[j].transaction[k].PreBilled_Supply_Copies=Total_transactions[j].transaction[k].Supply_Copies
          Total_transactions[j].transaction[k].PreBilled_Return_Copies="0"
          Total_transactions[j].transaction[k].Total_Return_Copies="0"
          
          Total_transactions[j].transaction[k].Return_Copies="0"
          Total_transactions[j].transaction[k].ReturnTotal="0"
          
          Total_transactions[j].Total_Return_Copies="0"

          Total_transactions[j].transaction[k].PreBilled_ReturnPrice=Total_transactions[j].transaction[k].ReturnPrice
          Total_transactions[j].transaction[k].PreBilled_ReturnTotal=Total_transactions[j].transaction[k].ReturnTotal
        
        
          Total_transactions[j].transaction[k].Bliing_Total=Total_transactions[j].transaction[k].Total

       
      }

    }
    }
  }
      
  
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Origin", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
    
        urlencoded.append("CenterId", context.state.CenterId);
            urlencoded.append("supply_date", data.supply_date );
    
            
            urlencoded.append("All_JSON_Date", JSON.stringify(Total_transactions));
    
       // alert(date)
        var CENTERBOOK = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch(ApiConstants.SAVECENTERBOOK, CENTERBOOK)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
          .then(response1 => 
{


if(v==context.state.CETERBOOKRES.length)
{

  context.setState({
    butnloaging:0
  })
  alert("Bill Generated successfully")

  window.location='/#/SalesInvoice/'+billing_id
  //window.location='/#/SalesReport'
}
v=v+1;
      //  console.log(response)
        
      }
        )
    }


  
      
}
  



)
        .then(result => console.log(result))

        //this.GetData(this.state.CenterId,this.state.from,this.state.to,this.state.VendorId) 

    }
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
         
            }
            
            
         

  PaymenthandleChange= (event) => 
  {
   //alert(event.target.value)
var Payment_Info_text=""
   if(event.target.value=="Online")
   {
    Payment_Info_text="Transaction Id"
   }
   if(event.target.value=="Cheque")
   {
    Payment_Info_text="Cheque No."
   }
    this.setState({
      Payment_Mode:event.target.value,
   Payment_Info_text:Payment_Info_text
    })
    
 
  }
  GetallData= (event) => 
  {
    
    this.setState({
      VendorId:event.target.value,
      VendorName:event.target.text,
      Payment_history:[],
      Total_transactions:[],
    })
this. GetallDataVendors(event.target.value)
  }
  GetallDataVendors= (VendorId) => 
    {
      this.setState({
      
        Payment_history:[],
        Total_transactions:[],
      })

let Billing_Start_Date="0";
let OpeningBalance=0;


var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
 
    urlencoded.append("VendorId",VendorId);
    
  
    var GETSALESBILLING = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
  
    fetch(ApiConstants.GETBILLING, GETSALESBILLING)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
    .then(billings => {

 // alert(JSON.stringify(billings.BILLING[0].Billing_End_date))

 if(billings.BILLING.length>0)
 {
 Billing_Start_Date=billings.BILLING[0].Billing_End_date;
 OpeningBalance=billings.BILLING[0].Opening_Balance_Amount;
  Billing_Start_Date= new Date(Billing_Start_Date.split('-')[2],Billing_Start_Date.split('-')[1]-1,Billing_Start_Date.split('-')[0])

  let d = new Date(Billing_Start_Date);
  d.setDate(d.getDate() + 1);
  Billing_Start_Date=formatDate(d,'DD-MM-YYYY');
  
 }
  

if(Billing_Start_Date==0)
{    this.state.Vendors.map(function(v)
     {
             
       if(VendorId==v.id)
       {
        Billing_Start_Date=v.Billing_Start_Date
        OpeningBalance=v.OpeningBalance
       }

      }
     )
    }else{

let to=Billing_Start_Date;



    }
   if(Billing_Start_Date=="")
   {
    Billing_Start_Date='0'
   }
   if(VendorId==0)
   {
    Billing_Start_Date=""
   }
   
   OpeningBalance=OpeningBalance!=undefined&&OpeningBalance!=null&&OpeningBalance!="" && isNaN(OpeningBalance)==false?OpeningBalance:0

      this.setState({
      
        Billing_Start_Date:formatDate(new Date(Billing_Start_Date.split('-')[2],Billing_Start_Date.split('-')[1]-1,Billing_Start_Date.split('-')[0]), 'MMMM DD, YYYY')  ,
       from:new Date(Billing_Start_Date.split('-')[2],Billing_Start_Date.split('-')[1]-1,Billing_Start_Date.split('-')[0]),
 
        Total_transactions:[],
        OpeningBalance:OpeningBalance
      })

    
      this.GetData(this.state.CenterId, formatDate(new Date(Billing_Start_Date.split('-')[2],Billing_Start_Date.split('-')[1]-1,Billing_Start_Date.split('-')[0]), 'DD-MM-YYYY'), formatDate(new Date(this.state.to), 'DD-MM-YYYY'),VendorId);
  
    //  this.GetData(this.state.CenterId,this.state.supply_date,event.target.value); 
    })
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });


  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
   var d = new Date(to);

   
    this.GetData(this.state.CenterId, formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(to), 'DD-MM-YYYY'),this.state.VendorId);


  }

  
  handleChange = (event) => {
    //console.log(event.target.value)
 
    this.init(event.target.value)
    this.setState({
      Centername:event.target.text,
      Billing_Start_Date:"",
          Total_transactions:[]
    })
    
  };
  calctotals(VendorId,supply_date_from,supply_date_to)
    {


   var myHeaders = new Headers();
   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
   
   myHeaders.append("Origin", "application/x-www-form-urlencoded");

   var urlencoded = new URLSearchParams();
   urlencoded.append("Billing_date_mode", "2");
   urlencoded.append("VendorId",VendorId);
   urlencoded.append("from",supply_date_from);
   urlencoded.append("to",supply_date_to);
   var BULKBILLS = {
     method: 'POST',
     headers: myHeaders,
     body: urlencoded,
     redirect: 'follow'
   };

   fetch(ApiConstants.BULKBILLS, BULKBILLS)
   .then(dataWrappedByPromise => dataWrappedByPromise.json())
     .then(response => 
       
       {
  
       
         var VendorBillingData= response.data;
let Tota_Paid_Amount=0,Amount_Payable=0; 
         let currentdate= response.currentdate;
      let Payment_history=   response.Payment_history



     let Payment_history_removezero=[];
let i=0;
      Payment_history.map(function(data)
      {

        Tota_Paid_Amount=parseFloat(Tota_Paid_Amount)+parseFloat(data.amount_paid)
        if(data.amount_paid!=0)
        {
          Payment_history_removezero[i]=data
          i=i+1;
        }

      })

      Payment_history=Payment_history_removezero

if(VendorBillingData.length>0)
{

  let Amount_Paid=0;



   Amount_Payable=parseFloat(Amount_Payable)+parseFloat(this.state.TotalAmout)
   //console.log(this.state.TotalAmout)
  Amount_Payable=parseFloat(Amount_Payable)+parseFloat(this.state.OpeningBalance)
 // console.log(this.state.OpeningBalance)

  Amount_Payable=parseFloat(Amount_Payable)-parseFloat(this.state.Total_Return_Amount)
 // console.log(this.state.Total_Return_Amount)
  Amount_Payable=parseFloat(Amount_Payable)-parseFloat(Tota_Paid_Amount)
 // console.log(Tota_Paid_Amount)

  Amount_Payable=parseFloat(Amount_Payable).toFixed(2)


  //console.log(Amount_Payable)
  this.setState({

    // Total_Return_Amount:Total_Return_Amount,
    Amount_Paid:Amount_Paid,    
    // TotalAmout:TotalAmout,
    Tota_Paid_Amount:Tota_Paid_Amount,
    Amount_Payable:Amount_Payable,
    Payment_history:Payment_history
  })





}



 
  })

}


  GetData(CenterId,from,to,VendorId) {
    var d = new Date(from.split('-')[1]+"/"+from.split('-')[0]+"/"+from.split('-')[2]);
      
    // this.savetojson()
 let d1=new Date(to.split('-')[1]+"/"+to.split('-')[0]+"/"+to.split('-')[2]);
 let go=0;
    if(d1>=d)
    {
 
      go=0
   
    }else{
      if(d1!=d)
    {
     // alert("Bill is already generated! Please check in Generated Bills")
    }
      go=1
    }
   if(go==0)
{
    this.setState({ loading: false,Total_transactions:[] })
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("Billing_date_mode", "2");
        urlencoded.append("VendorId",VendorId);
        urlencoded.append("CenterId",CenterId);
        urlencoded.append("from",from);
        urlencoded.append("to",to);
        urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
      
         
   
        // console.log(CenterId)
        // console.log(supply_date)
        var GETSALESBILLING = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
      
        fetch(ApiConstants.GETSALESBILLING, GETSALESBILLING)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(CETERBOOKRES => {
       


let items=CETERBOOKRES[(CETERBOOKRES.length)-1]
items=items.toString().replace('\\', '')
items=JSON.parse(items)
items=items.items;
let products=[];
let z=(CETERBOOKRES.length)-2





let CETERBOOKRESPre=[]
for(let y=0; y<(CETERBOOKRES.length-1); y++)
{
  CETERBOOKRESPre[y]=CETERBOOKRES[y]
}

let date=new Date(to.split('-')[1]+"/"+to.split('-')[0]+"/"+to.split('-')[2])
let dateto=new Date(to.split('-')[1]+"/"+to.split('-')[0]+"/"+to.split('-')[2])

  
if(CETERBOOKRESPre.length>0)
{
  date = new Date(CETERBOOKRES[z].supply_date.split('-')[1]+"/"+CETERBOOKRES[z].supply_date.split('-')[0]+"/"+CETERBOOKRES[z].supply_date.split('-')[2]);
  dateto= new Date(CETERBOOKRES[z].supply_date.split('-')[1]+"/"+CETERBOOKRES[z].supply_date.split('-')[0]+"/"+CETERBOOKRES[z].supply_date.split('-')[2]);
  
}

var dateArray = [];

from=from.split('-')[2]+'-'+from.split('-')[1]+'-'+from.split('-')[0];
to=to.split('-')[2]+'-'+to.split('-')[1]+'-'+to.split('-')[0];
var currentDate = moment(from);
var stopDate = moment(to);


while (currentDate <= stopDate) {
    dateArray.push( moment(currentDate).format('DD-MM-YYYY') )
    currentDate = moment(currentDate).add(1, 'days');
}




let MissingDates=[]
 dateArray.map(function (data)  
 {
   let v=0
   CETERBOOKRES.map(function (products)
 {
  
    if(data==products.supply_date)
    {
v=1
    }



  })
  if(v==0)
  {
    MissingDates.push(data)
  }

 }
 )
 
 
 
if(MissingDates.length>0)
{

  products=this.MissingDates(MissingDates,CETERBOOKRESPre,dateto,items,VendorId,CenterId,CETERBOOKRES[CETERBOOKRES.length-1],dateArray)
 // products=this.productsobj(CETERBOOKRES,items,VendorId)


 
 this.setState({ CETERBOOKRES:CETERBOOKRESPre })
 
 
}else{


console.log(CETERBOOKRESPre)

  let productsort=[]
dateArray.map(function (dateArray)
{
  CETERBOOKRESPre.map(function (data)
  {
  

    let  itemsnext=data.All_JSON_Date

 
    itemsnext=JSON.parse(itemsnext)

    let v=0;

    if(dateArray==data.supply_date)
    {



    items.map(function (items)
    {
     


      if(items.NonSalesDays.indexOf(dateArray)<0)
      {
 
     // productsort.push(data)


    }else{
   v=1;

   

for(let i=0; i<itemsnext.length; i++)
{
  for(let j=0; j<itemsnext[i].transaction.length; j++)
  {
    if( itemsnext[i].transaction[j].id==items.id)
    {
    itemsnext[i].transaction[j].Supply_Copies=0
    itemsnext[i].transaction[j].Return_Copies=0
    itemsnext[i].transaction[j].Subscription_Copies=0
    itemsnext[i].transaction[j].Sold_Copies=0
    itemsnext[i].transaction[j].Total=0
    itemsnext[i].transaction[j].ReturnPrice=0
    itemsnext[i].transaction[j].ReturnTotal=0
    }
  }

  itemsnext[i].Total_Supply_Copies=0
  itemsnext[i].Total_Return_Copies=0
  itemsnext[i].Total_Sold_Copies=0
  itemsnext[i].Amount_Paid=0
  itemsnext[i].Total=0
  

}
  data.All_JSON_Date= JSON.stringify(itemsnext);


    }



     
    })
  


      productsort.push(data)
  


}

  } )
}
  )


products=this.productsobj(productsort,items,VendorId)

        this.setState({ loading: true,products:products,CETERBOOKRES:CETERBOOKRESPre, Total_transactions:products })
                     this.calctotals(VendorId, formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(this.state.to), 'DD-MM-YYYY'))



                    }
//}
        })
      
  


    
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

  }
}
 
 
  productsobj(CETERBOOKRES,items,VendorId)
{
  









let j=0;
columns[j]={
  dataField: 'Date',
  text: 'Date',
  sort: true,
  style: (cell, row, rowIndex, colIndex) => {
    return { width: "30%" };
  }
}

j=j+1;
columns[j]={
  dataField: 'Item',
  text: 'Item',
  sort: true
}
j=j+1;
columns[j]={
  dataField: 'Supply_Copies',
  text: 'Supply Copies',
  sort: true
}
j=j+1;
columns[j]={
  dataField: 'Price',
  text: 'Supply Price',
  sort: true
}
j=j+1;
columns[j]={
  dataField: 'Total',
  text: 'Supply Amount',
  sort: true
}

j=j+1;
columns[j]={
  dataField: 'Return_Copies',
  text: 'Return Copies',
  sort: true
}
j=j+1;
columns[j]={
  dataField: 'ReturnPrice',
  text: 'Return Price',
  sort: true
}
j=j+1;
columns[j]={
  dataField: 'ReturnTotal',
  text: 'Return Amount',
  sort: true
}
j=j+1;
columns[j]={
  dataField: 'TotalAmount',
  text: 'Total Amount',
  sort: true
}

this.setState({items:items,columns:columns})

let products=[]
let Total_supply_amount=0;
let Total_Return_Amount=0;

      
         for(let z=0; z<CETERBOOKRES.length; z++)
         {
if(CETERBOOKRES[z].All_JSON_Date!=undefined)
{
          let Total_transactions=[];

            Total_transactions=CETERBOOKRES[z].All_JSON_Date

         
         Total_transactions=Total_transactions.toString().replace('\\', '')
          Total_transactions=JSON.parse(Total_transactions)
       
      
  for (let a = 0; a< Total_transactions.length; a++) {
    if(Total_transactions[a].Vendor_Code==VendorId)
{
    let TotalAmoutPre=0
    let Total_ReturnPre=0

    for (let b = 0; b< Total_transactions[a].transaction.length; b++) {
    let totaltrsupply=0;
    let totaltrreturn=0;



    totaltrsupply=parseFloat(parseFloat(Total_transactions[a].transaction[b].Price)*parseFloat(Total_transactions[a].transaction[b].Supply_Copies))
    totaltrreturn=parseFloat(parseFloat(Total_transactions[a].transaction[b].ReturnPrice)*parseFloat(Total_transactions[a].transaction[b].Return_Copies))
     
      Total_transactions[a].transaction[b].Total=totaltrsupply!=undefined && isNaN(totaltrsupply)==false && totaltrsupply!=null && totaltrsupply!=""? parseFloat(totaltrsupply).toFixed(2):0 
      
      Total_transactions[a].transaction[b].ReturnTotalAll=totaltrreturn!=undefined && isNaN(totaltrreturn)==false && totaltrreturn!=null && totaltrreturn!=""? parseFloat(totaltrreturn).toFixed(2):0 
      
     
      // isNaN(TotalAmout)==false
      totaltrsupply= totaltrsupply!=undefined && isNaN(totaltrsupply)==false && totaltrsupply!=null && totaltrsupply!=""? parseFloat(totaltrsupply):0
    

      
     // TotalAmoutPre=parseFloat(TotalAmoutPre)+totaltrsupply!=undefined && isNaN(totaltrsupply)==false && totaltrsupply!=null && totaltrsupply!=""? parseFloat(totaltrsupply):0
     totaltrreturn=totaltrreturn!=undefined && isNaN(totaltrreturn)==false && totaltrreturn!=null && totaltrreturn!=""? parseFloat(totaltrreturn):0

    TotalAmoutPre=parseFloat(TotalAmoutPre)+parseFloat(totaltrsupply)
      Total_ReturnPre=parseFloat(Total_ReturnPre)+parseFloat(totaltrreturn)
    }
    Total_transactions[a].Total=parseFloat(TotalAmoutPre);
    Total_transactions[a].ReturnTotalAll=parseFloat(Total_ReturnPre);


  // Total_supply_amount=Total_supply_amount+(parseFloat(TotalAmout!=undefined && TotalAmout!=null && TotalAmout!=""? TotalAmout:0))

  // Total_Return_Amount=Total_Return_Amount+(Total_Return!=undefined && Total_Return!=null && Total_Return!=""?parseFloat(Total_Return):0)
 
 
}

    }


          for(let k=0; k<Total_transactions.length; k++)
          {
          
if(Total_transactions[k].Vendor_Code==VendorId)
{
 let TotalAmout=Total_transactions[k].Total
  let Total_Return=Total_transactions[k].ReturnTotalAll

  Total_supply_amount=Total_supply_amount+(parseFloat(TotalAmout!=undefined && TotalAmout!=null && TotalAmout!=""? TotalAmout:0))

 Total_Return_Amount=Total_Return_Amount+(Total_Return!=undefined && Total_Return!=null && Total_Return!=""?parseFloat(Total_Return):0)


  
Total_transactions[k].transaction.map(function(data)

{
if(data.ReturnTotal!=undefined)
{
  data.TotalAmount="Rs."+(parseFloat((parseFloat(data.Total.toString().replace('Rs.',''))-parseFloat(data.ReturnTotal.toString().replace('Rs.','')))).toFixed(2)).toString()
}else{
  data.TotalAmount="Rs."+parseFloat((parseFloat(data.Total.toString().replace('Rs.','')))).toFixed(2)

}
  data.Date=CETERBOOKRES[z].supply_date
  products.push(data)
})

      
}

       
          }
    
        }
      }


      Total_supply_amount=parseFloat(Total_supply_amount).toFixed(2)
      Total_Return_Amount=parseFloat(Total_Return_Amount).toFixed(2)
      this.setState({
        TotalAmout:Total_supply_amount,
        Total_Return_Amount:Total_Return_Amount
      })

      return products;
}


MissingDates(MissingDates,products,dateto,items,VendorId,CenterId,lastproducts,dateArray)
{
//console.log(items)
 //alert(formatDate(new Date(dateto), 'DD-MM-YYYY'))
 //let lastproducts=products[products.length-1]

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Origin", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("Billing_date_mode", "2");
      urlencoded.append("VendorId",VendorId);
      urlencoded.append("CenterId",CenterId);

      urlencoded.append("to",formatDate(new Date(dateto), 'DD-MM-YYYY'));
      urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
    
      let from=dateto.setDate(dateto.getDate() -90);
      urlencoded.append("from",formatDate(new Date(from), 'DD-MM-YYYY'));
      // console.log(CenterId)
      // console.log(supply_date)
      var GETSALESBILLING = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
    
      fetch(ApiConstants.GETSALESBILLING, GETSALESBILLING)
      .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(CETERBOOKRES => {

      let preweek=7;
    //  alert(MissingDates.length)
let nxt=0
let productsdata=""
         for(let z1=0; z1<MissingDates.length; z1++)
         {

          nxt=nxt+1

           let dateMiss= new Date(MissingDates[z1].split('-')[1]+"/"+MissingDates[z1].split('-')[0]+"/"+MissingDates[z1].split('-')[2])
           dateMiss=dateMiss.setDate(dateMiss.getDate() -preweek);
         
           dateMiss=formatDate(new Date(dateMiss), 'DD-MM-YYYY')
            // alert(dateMiss)

       

      
         // alert( MissingDates[z1])

let v=0;
          for(let z=0; z<CETERBOOKRES.length; z++)
          {

    
if(dateMiss==CETERBOOKRES[z].supply_date)
{

  // console.log("dateMiss"+dateMiss)
  // console.log(CETERBOOKRES[z].supply_date)
 // alert(MissingDates[z1])
if(CETERBOOKRES[z].All_JSON_Date!=undefined)
{

  let missdata=[]


missdata[0]=CETERBOOKRES[z]

//console.log(missdata[0].supply_date)
//console.log(MissingDates[z1])
v=1;
missdata[0].supply_date= MissingDates[z1]
missdata[0].date= MissingDates[z1]

missdata[0].key=nxt

// console.log( MissingDates[z1])
productsdata =productsdata+","+ JSON.stringify (missdata[0])



//console.log(productsdata)
    
        }
      }
    }

    // console.log(v)
    // console.log(preweek)
    if(v==0)
    {
      preweek=preweek+7;
      z1=z1-1
    }
  }

  productsdata=productsdata.replace(",","")
  productsdata=  "["+productsdata+"]"


  productsdata= JSON.parse(productsdata)

  productsdata.map(function (dateArray)
  {

    products.push(dateArray)
  })



//productsdata.push(products)


let productsort=[]
dateArray.map(function (dateArray)
{
  products.map(function (data)
  {
  

    let  itemsnext=data.All_JSON_Date

 
    itemsnext=JSON.parse(itemsnext)

    let v=0;

    if(dateArray==data.supply_date)
    {



    items.map(function (items)
    {
     


      if(items.NonSalesDays.indexOf(dateArray)<0)
      {
 
    //  productsort.push(data)


    }else{
   v=1;

   

for(let i=0; i<itemsnext.length; i++)
{
  for(let j=0; j<itemsnext[i].transaction.length; j++)
  {
    if( itemsnext[i].transaction[j].id==items.id)
    {
    itemsnext[i].transaction[j].Supply_Copies=0
    itemsnext[i].transaction[j].Return_Copies=0
    itemsnext[i].transaction[j].Subscription_Copies=0
    itemsnext[i].transaction[j].Sold_Copies=0
    itemsnext[i].transaction[j].Total=0
    itemsnext[i].transaction[j].ReturnPrice=0
    itemsnext[i].transaction[j].ReturnTotal=0
    }
  }

  itemsnext[i].Total_Supply_Copies=0
  itemsnext[i].Total_Return_Copies=0
  itemsnext[i].Total_Sold_Copies=0
  itemsnext[i].Amount_Paid=0
  itemsnext[i].Total=0
  

}
  data.All_JSON_Date= JSON.stringify(itemsnext);


    }



     
    })
  


      productsort.push(data)
  


}

  } )
}
  )



  productsort.push(lastproducts)


  products=this.productsobj(productsort,items,VendorId)
  
  this.setState({ loading: true,products:products,Total_transactions:products })
  this.calctotals(VendorId, formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(this.state.to), 'DD-MM-YYYY'))

  

    })

  }

  handleDayChange=(selectedDay, modifiers, dayPickerInput)=> {
    var d = new Date(selectedDay);
      this.setState({supply_date:formatDate(new Date(selectedDay), 'DD-MM-YYYY')})
   // this.savetojson()
    this.GetData(this.state.CenterId, formatDate(new Date(selectedDay), 'DD-MM-YYYY'), this.state.VendorId);

  }
    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
      let Options = this.state.Centers.map(v => (
        <option text={v.Name} value={v.id}>{v.Name}</option>
      ));

      let VendorOptions = this.state.Vendors.map(v => (
        <option text={v.Name} value={v.id}>{v.Name}</option>
      ));
      
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">New Billing</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

             <div class="d-sm-flex align-items-center justify-content-between mb-4">
               {this.state.billno!=undefined && this.state.billno!=""?
        <h3 class="h5 mb-0 text-gray-800">Bill No. {this.state.billno}</h3>
        :null}
               {/* <h2 class="h5 mb-0 text-gray-800"> date: <Helmet>
        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
      </Helmet>
<DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format="DD-M-YYYY"
        placeholder={`${formatDate(new Date(), 'DD-M-YYYY')}`}
        dayPickerProps={{

          localeUtils: MomentLocaleUtils,
        }}
        onDayChange={this.handleDayChange} 
      />
</h2> */}
               {/* <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> */}
             </div>
             <div class="d-sm-flex align-items-left justify-content-left mb-4">
             <div class="form-group">
  <label style={{float:'left', padding:' 5px '}} for="sel1">Centers:</label>
   <select  id="CentersSel" style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.value} class="form-control"  onChange={this.handleChange}>
        <option value='0'>Select Centers</option>  {Options}
        </select> 

</div>

<div class="form-group">
  <label style={{float:'left', marginLeft:20,  padding:' 5px ',}} for="sel1">Vendor:</label>
  <select style={{     padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.value} class="form-control" onChange={this.GetallData} id="sel1">
  <option value='0'>Select Vendor</option>  {VendorOptions}
  </select>
</div>


{ this.state.Vendors.length>=1?

this.state.Billing_Start_Date!=undefined?
this.state.Billing_Start_Date!=""?
this.state.Billing_Start_Date!="0"?
<div>
         <div style={{marginTop:-22, marginLeft:30}} className="InputFromTo ">
         <div class="h5 mb-0 text-gray-800">Billing dates : </div>


         <input placeholder="To" value={this.state.Billing_Start_Date}/>
        {/* <DayPickerInput
          value={from}
          placeholder="From"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { before: new Date(this.state.Billing_Start_Date.split('-')[2],this.state.Billing_Start_Date.split('-')[1]-1,this.state.Billing_Start_Date.split('-')[0]) },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        /> */}
        
        {' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            placeholder="To"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
        </span>
     
      </div> 
      
{/* 
<div class="form-group" style={{textAlign:'right', fontSize:16,  marginTop:20}}>
<div class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Generate Bill</div>
</div> */}
</div> 
:<div style={{
  padding: 6,
    marginLeft: 30,
    color: "red"
}}>Please Enter Billing Start Date and Opening Balance in <a href='#/Vendors'> Vendor - Edit </a> </div>:null:null:null}


</div>
{
          this.state.loading?
<div>
   
<div style={{fontSize:18, color:'red', textAlign:'center'}}>
{
  this.state.datamessage==true?"Please Enter previous week data! ("+this.state.fromweekdate+" to "+this.state.toweekdate:null
}
  </div>

{
        this.state.Total_transactions.length>0
        
       ? this.state.Total_transactions!=undefined?
       <div id="harish" class="row">
{this.state.Total_transactions.length>0?
<div class="col-lg-12 mb-4" style={{padding:0, overflow:'auto', maxHeight:400}}>

{/*Project Card Example*/}
<div class="card shadow mb-4">

 <div class="card-body">

 <div >
   {this.state.Total_transactions.length>=1?
      <div >
     {/* <ExportCSVButton { ...props.csvProps }>Export</ExportCSVButton>
     <hr /> */}
   
   <ToolkitProvider
keyField="id"
data={ this.state.Total_transactions }
columns={ columns }

bootstrap4={true}
pagination={ paginationFactory() }

>
         {/*Page Heading*/}
    

         {/*Content Row*/}
       
{
props => (
 <div  style={{fontSize:14,}}>
 <ExportCSVButton  { ...props.csvProps }>  
      
        <span style={{margin:0, }} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Download in xlsx</span>
      </ExportCSVButton> 
     <hr />
 <BootstrapTable { ...props.baseProps } />
 </div>
)
}
</ToolkitProvider>  


 </div >:"No records found!" }</div>
 </div>



</div>

{/*Color System*/}

</div>
:null}


<div  class="col-lg-12 mb-4">
   
   {/*Content Column*/}

   <div style={{float:'left'}} class="col-lg-7 mb-4">
   <div class="card shadow mb-4">
   <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Payment History</div>
        <div class="m-0 font-weight-bold text-primary"></div>
      </div>

     {/*Project Card Example*/}
  
  
      <div class="card-body" style={{overflow:'auto', maxHeight:700,}}>
        
      <div>


        {this.state.Payment_history.length>=1?
           <div >
      
      <BootstrapTable keyField='id' data={ this.state.Payment_history} columns={ columns_pay } />
      </div >:"No data!" }
      
      
      </div>
      </div>
    </div>

     {/*Color System*/}

  </div>
 

  <div style={{float:'left'}} class="col-lg-5 mb-4">

     {/*Illustrations*/}
    <div class="card shadow mb-4" style={{height:700}}>
      <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Billing</div>
        <div class="m-0 font-weight-bold text-primary"></div>
      </div>
      <div class="card-body">
      <div class="chart-area">
        
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Total Supply Amount </h6>
        <h6 class="m-0 font-weight-normal text-primary">Rs. {parseFloat(this.state.TotalAmout.toString().replace('Rs.','')).toFixed(2)}</h6>

      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Total Return Amount</h6>
        <h6 class="m-0 font-weight-normal text-primary"> 
        <h6 class="m-0 font-weight-normal text-primary">Rs. {this.state.Total_Return_Amount}</h6>
        </h6>

      </div> 
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Total Paid Amount</h6>
        <h6 class="m-0 font-weight-normal text-primary"> 
        <h6 class="m-0 font-weight-normal text-primary">Rs. {this.state.Tota_Paid_Amount}</h6>
        </h6>

      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Opening Balance Amount</h6>
        <h6 class="m-0 font-weight-normal text-primary">Rs. {this.state.OpeningBalance}</h6>

      </div>   
       
      {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Amount Payable</h6>
      <h6 class="m-0 font-weight-normal text-primary">Rs. {this.state.Amount_Payable}</h6>

      </div>    */}

<hr/>
      <div style={{marginBottom:0}} class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-bold text-primary">Total Payable Amount</h6>
        <h6 class="m-0 font-weight-bold text-primary" style={{textAlign:"left"}}>Rs.{this.state.Amount_Payable}</h6>

      </div> 

      <hr/>


      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary" style={{fontSize:15}}>Amount Paid</h6>
      <input  class="form-control" style={{width:200, borderColor:'#2A3F54'}}  type="number" id="Todays_Paid"  />
    
      </div> 


      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary" style={{fontSize:15}}>Note</h6>
      <textarea  class="form-control" id="Note" style={{width:200}} > </textarea>
    
      </div> 
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Payment Mode</h6>
      <select value={this.state.Payment_Mode} style={{width:200}} class="form-control"  onChange={this.PaymenthandleChange}>
  <option selected value="Cash">Cash</option>
  <option  value="Bank">Bank</option>
  <option  value="Cheque">Cheque</option>
  <option  value="PhonePay">PhonePay</option>
  <option  value="GooglePay">GooglePay</option>
</select>

      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary" style={{fontSize:15}}>Payment Id</h6>
      <input  class="form-control"  style={{width:200}} type="text" id="Payment_id"  />
    
      </div> 
       <div class="d-sm-flex align-items-center justify-content-between mb-4">
        
       <h6 class="m-0 font-weight-normal text-primary"></h6>

       {this.state.butnloaging==0?
          <button class="btn btn-primary"  onClick={this.SavePayment}>Generate Bill
          
          
          </button>:
           <button class="btn btn-primary">Generating...
          
          
           </button>
          
          }
      
      </div>  
      </div>
     
    
      
      

           </div>


         
           </div>

        
    
     
    

     {/*Approach*/}
   

  </div>
  </div>
 

  </div>
:<div style={{textAlign:'center', color:'#000', margin:'auto', padding:10,}}>No data</div>:<div style={{textAlign:'center', color:'#000',margin:'auto', padding:10,}}>No records found</div>}

</div>
:<div  class="loader"></div>
    }
                 </div>
        
           </div>

        )}
    
        }
      
