
import React, { Component } from 'react';
import { ApiConstants } from './ApiConstants';
class Logins extends Component
{

state={Mobile:'',btndis:0}

    render() {
        return (<div className="logbody">
   
   <h2 style={{color:'#337ab7'}}>Admin Log In</h2>
<div className="logs">
         
       
    </div>
    <div class="container">
<div className="row">
<div class="col-md-6 " style={{  margin: "auto"}}>
    <div className="logsubmain">

      
  
<div className="loglabel">Mobile Number</div>

<input id="Mobile"   type="Mobile" className="logtext"/>

        <div className="loglabel">Password </div>

<input id="password" type="password" className="logtext" />

<div onClick={this.handleChange} id='logbtn' className="logbtn">
    Sign In
</div>
<div className="logivalid" id="invalid"></div>
</div>

</div>
</div></div>
</div>
        )}
         
        handleChange = event => {

          

            if(document.getElementById('Mobile').value!="")
{

    if(document.getElementById('password').value!="")
    {
       // document.getElementsByName('logbtn').innerHTML= '<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...'
       // $('#btn-one').html('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Loading...').attr('disabled', true);
     
     
       document.getElementById('logbtn').innerHTML='<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>loading...'
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
myHeaders.append("Origin", "application/x-www-form-urlencoded");
var urlencoded = new URLSearchParams();
urlencoded.append("Mobile", document.getElementById('Mobile').value);
urlencoded.append("Password", document.getElementById('password').value);
var User = {
  method: 'POST',
  headers: myHeaders,
  body: urlencoded,
  redirect: 'follow'
};

fetch(ApiConstants.LOGING, User)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
  .then(response =>{
  //  alert(response.response[0].Role)
  
    if(response.statuscode==1)
    {
        if(response.response[0].Role==0)
        {
              localStorage.setItem('userid', document.getElementById('Mobile').value);
              localStorage.setItem('username', response.response[0].name);
              window.location='/'
        }else{
            alert("You do not have permission to access admin! ")
        }
    }else{
        alert(response.message)
    }
    document.getElementById('logbtn').innerHTML='SIGN IN';
}
    )
  .then(result => console.log(result))
  .catch(error => console.log('error', error));

     
           }else{
               alert('Enter Password')
           }
   
        }else{
            alert('Enter Mobile Number')
        }
        
    }
    }
      

           
             
export default Logins;