
import React, { Component } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { ApiConstants } from './ApiConstants';
const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;

// const selectRow = {
//   mode: 'checkbox',
//   clickToSelect: true,
//   hideSelectColumn: true,
//   onSelect: (row, isSelect, rowIndex, e) => {
//     //alert( isSelect);
//    // alert(row.Name);
// },
// onSelectAll: (isSelect, rows, e) => {
// //  alert(isSelect);
//   alert(rows.Name);
//   console.log(e);
// }
// };

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
  onSelect: (row, isSelect, rowIndex, e) => {
    alert(row.code);
    console.log(isSelect);
    console.log(rowIndex);
    console.log(e);
  },
  onSelectAll: (isSelect, rows, e) => {
    console.log(isSelect);
    console.log(rows);
    console.log(e);
  }
};
const columns = [ 

{
  dataField: 'Center',
  text: 'Center',
  sort: true
}, {
  dataField: 'Vendor',
  text: 'Vendor',
  sort: true
},
{
  dataField: 'Outstanding',
  text: 'Outstanding',
  sort: true
}

];

export default class AllCenter extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      Centers:[],
      loading:true
    };
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  printDiv() { 
    var divContents = document.getElementById("PrintDiv").innerHTML; 
    var a = window.open('', '', 'height=500, width=500'); 
    a.document.write('<html>'); 
    a.document.write('<body>'); 
    a.document.write(divContents); 
    a.document.write('</body></html>'); 
    a.document.close(); 
    a.print(); 
  } 
  componentDidMount()
  {

    this.setState({loading:false})

var VendorList=[]



var myHeaders = new Headers();

var urlencoded = new URLSearchParams();
urlencoded.append("CenterId", '0');
  

myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
myHeaders.append("Origin", "application/x-www-form-urlencoded");
var VENDOR = {
method: 'Post',
redirect: 'follow',
body: urlencoded,
headers: myHeaders,
};

fetch(ApiConstants.VIEWVENDOR, VENDOR)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
.then(VIEWVENDOR => 
  
  {
for(let y=0; y< VIEWVENDOR.data.length; y++)
{
    var myHeaders = new Headers();

    var urlencoded = new URLSearchParams();
        
    urlencoded.append("VendorId", VIEWVENDOR.data[y].id);

    urlencoded.append("Billing_Date", formatDate(new Date(), 'DD-MM-YYYY'));
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var CENTER = {
      method: 'Post',
      redirect: 'follow',
      body: urlencoded,
      headers: myHeaders,
    };
    
    fetch(ApiConstants.CenterAppBilling, CENTER)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {
          //alert(response.message)

          var Billing= response.data;
          var Outstanding=parseFloat(Billing[0].Amount_Payable!=null?Billing[0].Amount_Payable:0)+parseFloat(response.Billing[0].OpeningBalance!=null?response.Billing[0].OpeningBalance:null)- parseFloat(response.PaymentHistory[0].amount_paid!=null?response.PaymentHistory[0].amount_paid:0);
          
          Outstanding=parseFloat(Outstanding).toFixed(2)
       
          var Centers = {
              Center: response.Billing[0].Center_name,
              Vendor:  response.Billing[0].Name,
              Outstanding:Outstanding,
              id:  Billing[0].id}
            
      
          

          // for(let i=0; i<response.data.length; i++)
          // {
          //   centerdata.map({'edit':'edit'})
          // }

VendorList[y]=Centers
console.log(VendorList)
this.setState({
  Centers:VendorList,
  loading:true
})
          
       
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
})
  }

//   Total_All(VendorId)
  
//   {
//    // alert(supply_date)
//    // alert(Total_transactions[0].Total_Supply_Copies)
  
//    let  supply_date = new Date();
//    //Rdate.setDate(Rdate.getDate() -1);
  
//    supply_date= moment(supply_date).format("DD-MM-YYYY");
  
  
//    this.setState({
//     processing:true
//    })
//    let NEWBILLING = {
//     method: "POST",
//     credentials: "same-origin",
//     mode: "same-origin",
//     body: JSON.stringify({
//       VendorId: VendorId,
//       Billing_Date: supply_date,
//     }),
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
  
//       //'X-CSRFToken':  cookie.load('csrftoken')
//     },
//   };
  
  
  
//   return fetch(ApiConstants.CenterAppBilling, NEWBILLING)
//     .then((response) => response.json())
//     .then((response) => {
// console.log(response)

//      let TotalAmout_All=0;
//      let   TotalPaid_All=0;
//      let  TotalBalance_All=0;
//      let Return_All=0;
//   let Opening_Balance=0;
//    //console.log(JSON.stringify(response))
   
     
//      let payeeName="";
//      let Billing_Start_date=0
//      let Billing_End_date=0
  
//       if(response.Billing.length>0)
//       {
      
//         payeeName=response.Billing[0].name
//       }
//     //console.log(JSON.stringify(response))
//       //  console.log(payeeName)
//       if(response.data.length>0)
//       {
//          Billing_Start_date=response.data[response.data.length-1].Billing_Date;
//          Billing_End_date=response.data[0].Billing_Date;
//        response.data.map(function (data) {
//          if(data.Amount_Payable!=null && data.Amount_Payable!=undefined && data.Amount_Payable!='null'&& data.Amount_Payable!="")
//          {
          
//         TotalAmout_All=parseFloat(TotalAmout_All)+parseFloat(data.Amount_Payable);
//      //   Return_All=parseFloat(Return_All)+parseFloat(data.Total_Return_Amount);
           
  
//          }
//     //      if(data.Amount_Paid!=null && data.Amount_Paid!=undefined && data.Amount_Paid!='null'&& data.Amount_Paid!="")
//     //      {
//     //     TotalPaid_All=parseFloat(TotalPaid_All)+parseFloat(data.Amount_Paid);
//     //  //   console.log(TotalPaid_All)
//     //      }
//        })
//       }
  
//       //console.log(response.PaymentHistory[0].amount_paid)
//        if(response.PaymentHistory[0].amount_paid!=null && response.PaymentHistory[0].amount_paid!=undefined &&response.PaymentHistory[0].amount_paid!='null'&& response.PaymentHistory[0].amount_paid!="")
//        {
//        TotalPaid_All=parseFloat(parseFloat(TotalPaid_All)+parseFloat(response.PaymentHistory[0].amount_paid)).toFixed(2)
//        }
  
//       //  console.log(TotalAmout_All)
//       //  console.log(TotalPaid_All)
//        TotalBalance_All=parseFloat(TotalAmout_All)-parseFloat(TotalPaid_All)
  
//       //  console.log(TotalBalance_All)
//       //  TotalBalance_All=parseFloat(TotalBalance_All)-parseFloat(Return_All)
  
  
  
//       //  console.log(TotalBalance_All)
       
//         TotalAmout_All=parseFloat(TotalAmout_All).toFixed(2);
//          TotalPaid_All=parseFloat(TotalPaid_All).toFixed(2);
          
//           //alert(response.Billing[0].OpeningBalance)
//           if(response.Billing[0].OpeningBalance!=undefined && response.Billing[0].OpeningBalance!="")
//           {
//           Opening_Balance=parseFloat(response.Billing[0].OpeningBalance).toFixed(2);
        
//           Billing_End_date=response.Billing[0].Billing_Start_Date
//         }
  
//         //alert(Opening_Balance)
//        // console.log(TotalBalance_All)
//         TotalBalance_All=parseFloat(TotalBalance_All)+parseFloat(Opening_Balance);
//         TotalBalance_All=parseFloat(TotalBalance_All).toFixed(2);
//      //  console.log(TotalBalance_All)
//        this.setState({ 
//         TotalBalance_All:TotalBalance_All,
//         TotalBalance_AllCal:TotalBalance_All,
//         processing:false
//       })
    
  
  
//   })
  
//   }

  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleChange = (event) => {
    alert(event.target.value );
  };
    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 style={{fontSize:18, fontWeight:'bold'}} class="h3 mb-0 text-gray-800">Outstanding Amount on {formatDate(new Date(), 'DD-MM-YYYY')}</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

           
           

<div class="row">
   
   {/*Content Column*/}
  <div class="col-lg-12 mb-4">

     {/*Project Card Example*/}
    <div class="card shadow mb-4">
    
      <div class="card-body">
      <div >
        {
          this.state.loading?

          this.state.Centers.length>=1?

          <div>
              <div id='PrintDiv'>
      <BootstrapTable  keyField='code' search  hover  data={ this.state.Centers } columns={ columns } />
      </div>
      <button style={{    margin: 5,
        marginLeft: 500}} onClick={this.printDiv}>Print It</button>
   </div>
      :

   <div style={{flex:1, textAlign:'center', flexDirection:'row', color:'red', padding:10, fontSize:30,}}>No Centers Found!</div>
   :
        
<div  class="loader"></div>
    }
        </div>
      </div>
    </div>

     {/*Color System*/}

  </div>
  </div>



                 </div>
        
           </div>

        )}
    
        }


        
      
