
import React, { Component,useRef } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { ApiConstants } from './ApiConstants';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { HashRouter as Router,hashHistory, Route, Link } from 'react-router-dom'
import Helmet from 'react-helmet';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';


import 'moment/locale/it';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
const { SearchBar, ClearSearchButton } = Search;
var context;
const { ExportCSVButton } = CSVExport;



export default class SalesReport extends Component
{
  constructor(props) {
    super(props);
 
    this.state = {
      from: new Date(),
      to: new Date(),
      Vendors:[],
      loading:true,
      Centers:[],
      SoldCopies:0,
      ReturnSCopies:0,
      TotalAmout:0,
      TotalCenter:0,
      CenterId:"",
      VendorId:"",
      Total_transactions: [],
      supply_date:formatDate(new Date(), 'DD-MM-YYYY'),
      Credit:0,
      Return_Amount:0,
      Amount_Payable:0,
      Amount_Paid:"",
      Balance_Amount:0,
      Payment_Reference1:"",
      Payment_Reference:"",
      VendorBillingData:[],
      Payment_history:[],
      Payment_Info_text:"Cash",
      Payment_Mode:"Cash",
      Total_Return_Amount:0,
     OpeningBalance:0,
     Todays_Paid:0,
     GeneratedBillDiv:[],
     CETERBOOKRES:[]

    };
    context=this

    //alert(this.props.VendorId)
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
    ViewBill(data)
    {
      window.location='/#/SalesInvoice/'+data
    }
    ReBill(data)
    {
      window.location='/#/Modifybills/'+data
    }
    DeleteBill(id)
    {
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("billid", id);

    var Center = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

            fetch(ApiConstants.deleteBill, Center)
            .then(dataWrappedByPromise => dataWrappedByPromise.json())
              .then(Centersresponse => 
                
                {
                  console.log(Centersresponse)
  alert("Bill No."+id+" is deleted successfully")
  this. GetallDataVendors(this.state.VendorId)
                  
          }
          
          )
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }
  componentDidMount()
  {
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

   
  
    var Center = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
 
          fetch(ApiConstants.VIEWCENTER, Center)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(Centersresponse => 
              
              {


               


          //alert(response.message)
this.setState({
 
  Centers:Centersresponse.data,
  loading:true
})
  
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  init(CenterId)
  {

    this.setState({loading:false,CenterId:CenterId})
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("CenterId", CenterId);
    urlencoded.append("Billing_date_mode", "2");
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(ApiConstants.VIEWVENDOR, VENDOR)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {

          var centerdata= response.data;

          // var Vendor = centerdata.map(function (data) {

          //   return {
          //     Location: data.Location,
          //     Name:  data.Name,
          //     Address:data.Address,
          //     id:  data.id,
          //     updated_dt:data.updated_dt,
          //     Center_name: data.Center_name,

          //     DateOfJoin: data.DateOfJoin,

          //     ContactPerson: data.ContactPerson,
          //     ContactNumber: data.ContactNumber,
             
              
          //     edit:'View more'
          //   }
          // })


          //alert(response.message)
this.setState({
  Vendors:centerdata,
  CenterId:CenterId,
  loading:true
})
//this.GetData(CenterId,this.state.supply_date); 
    
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  SavePayment = (event) => {

let ur=localStorage.getItem('username');
//alert(ur)
         let amt=document.getElementById('Todays_Paid').value
         if(amt=="" || amt==undefined)
         {
           amt=0
         }
         let allpaid= parseFloat(this.state.Tota_Paid_Amount)+ parseFloat(amt);
         //alert(allpaid)
            var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
   
   // alert(value)

   urlencoded.append("CenterId", this.state.CenterId);
   urlencoded.append("Billing_Date", formatDate(new Date(), 'DD-MM-YYYY'));
   urlencoded.append("VendorId",this.state.VendorId);
   urlencoded.append("Total_Supply_Amoount", parseFloat(this.state.TotalAmout.toString().replace('Rs.','')).toFixed(2));
   urlencoded.append("Total_Return_Amount", this.state.Total_Return_Amount);
   urlencoded.append("Amount_Paid",  allpaid);
urlencoded.append("Pre_Opening_Balance_Amount", this.state.OpeningBalance);
urlencoded.append("Amount_Payable", this.state.Amount_Payable);
urlencoded.append("Payment_Reference", amt+" to " + document.getElementById('Payment_id').value+"by"+ur);
urlencoded.append("Payment_Mode",  this.state.Payment_Mode);
urlencoded.append("Opening_Balance_Amount", parseFloat((parseFloat(this.state.Amount_Payable)- parseFloat(allpaid)).toFixed(2)) );
urlencoded.append("Billing_Start_date", formatDate(new Date(context.state.from), 'DD-MM-YYYY') );
urlencoded.append("Billing_End_date", formatDate(new Date(context.state.to), 'DD-MM-YYYY'));
urlencoded.append("Note", document.getElementById('Note').value);
 
  
    var BillingGenerates = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.GenerateBillSaveVendor, BillingGenerates)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{

let billing_id=response.data.insertId

        var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
   
   // alert(value)
   urlencoded.append("CenterId", this.state.CenterId);
   urlencoded.append("Billing_Date", formatDate(new Date(), 'DD-MM-YYYY'));
   urlencoded.append("VendorId",this.state.VendorId);
   urlencoded.append("billing_id", billing_id);
   urlencoded.append("amount_paid",  amt);
   urlencoded.append("payment_type", this.state.Payment_Mode);
urlencoded.append("Payment_Reference", ur);

 
  
    var Payment = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.PaymentHistorySaveVendors, Payment)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{

let v=1;

context.state.CETERBOOKRES.map(function(data)
{

  let Total_transactions=[];

  Total_transactions=data.All_JSON_Date

if(Total_transactions!=undefined)
{
Total_transactions=Total_transactions.toString().replace('\\', '')
Total_transactions=JSON.parse(Total_transactions)


  for (let j = 0; j < Total_transactions.length; j++) {
  
    Total_transactions[j].Approved = 'Billed'
  
  }
      
   



  



        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Origin", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
    
        urlencoded.append("CenterId", context.state.CenterId);
            urlencoded.append("supply_date", data.supply_date );
    
            
            urlencoded.append("All_JSON_Date", JSON.stringify(Total_transactions));
    
       // alert(date)
        var CENTERBOOK = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch(ApiConstants.SAVECENTERBOOK, CENTERBOOK)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
          .then(response1 => 
{
v=v+1;
if(v==context.state.CETERBOOKRES.length)
{
  alert("Data saved successfully")
  context.GetallDataVendors(context.state.VendorId)
}
      //  console.log(response)
        
      }
        )
    }
    })

  
      
}
  



)
        .then(result => console.log(result))

        //this.GetData(this.state.CenterId,this.state.from,this.state.to,this.state.VendorId) 

    }
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
         
            }
            
            
         

  PaymenthandleChange= (event) => 
  {
   //alert(event.target.value)
var Payment_Info_text=""
   if(event.target.value=="Online")
   {
    Payment_Info_text="Transaction Id"
   }
   if(event.target.value=="Cheque")
   {
    Payment_Info_text="Cheque No."
   }
    this.setState({
      Payment_Mode:event.target.value,
   Payment_Info_text:Payment_Info_text
    })
    
 
  }
  GetallData= (event) => 
  {
    
    this.setState({
      VendorId:event.target.value,
      VendorName:event.target.text,
      Payment_history:[],
      Total_transactions:[],
      GeneratedBillDiv:[]
    })
this. GetallDataVendors(event.target.value)
  }
  GetallDataVendors= (VendorId) => 
    {
      this.setState({
        GeneratedBillDiv:[],
        Payment_history:[],
        Total_transactions:[],
      })

let Billing_Start_Date="0";
let OpeningBalance=0;


var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
 
    urlencoded.append("VendorId",VendorId);
    
  
    var GETSALESBILLING = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
  
    fetch(ApiConstants.GETBILLING, GETSALESBILLING)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
    .then(billings => {

 // alert(JSON.stringify(billings.BILLING[0].Billing_End_date))

 if(billings.BILLING.length>0)
 {
 Billing_Start_Date=billings.BILLING[0].Billing_End_date;
 OpeningBalance=billings.BILLING[0].Opening_Balance_Amount;
  Billing_Start_Date= new Date(Billing_Start_Date.split('-')[2],Billing_Start_Date.split('-')[1]-1,Billing_Start_Date.split('-')[0])

  let d = new Date(Billing_Start_Date);
  d.setDate(d.getDate() + 1);
  Billing_Start_Date=formatDate(d,'DD-MM-YYYY');
  
 }
  

if(Billing_Start_Date==0)
{    this.state.Vendors.map(function(v)
     {
             
       if(VendorId==v.id)
       {
        Billing_Start_Date=v.Billing_Start_Date
        OpeningBalance=v.OpeningBalance
       }

      }
     )
    }else{

let to=Billing_Start_Date;




this.setState({
  GeneratedBillDiv: billings.BILLING
})

}
    })
  }


  
  handleChange = (event) => {
    //console.log(event.target.value)
 
    this.init(event.target.value)
    this.setState({
      Centername:event.target.text,
      CenterId:event.target.value,

      Billing_Start_Date:"",
          Total_transactions:[]
    })
    
  };
  
    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
      let Options = this.state.Centers.map(v => (
        <option text={v.Name} value={v.id}>{v.Name}</option>
      ));

      let VendorOptions = this.state.Vendors.map(v => (
        <option text={v.Name} value={v.id}>{v.Name}</option>
      ));
      
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Generated Bills</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

             <div class="d-sm-flex align-items-center justify-content-between mb-4">
               {this.state.billno!=undefined && this.state.billno!=""?
        <h3 class="h5 mb-0 text-gray-800">Bill No. {this.state.billno}</h3>
        :null}
               {/* <h2 class="h5 mb-0 text-gray-800"> date: <Helmet>
        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
      </Helmet>
<DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        format="DD-M-YYYY"
        placeholder={`${formatDate(new Date(), 'DD-M-YYYY')}`}
        dayPickerProps={{

          localeUtils: MomentLocaleUtils,
        }}
        onDayChange={this.handleDayChange} 
      />
</h2> */}
               {/* <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> */}
             </div>
             <div class="d-sm-flex align-items-left justify-content-left mb-4">
             <div class="form-group">
  <label style={{float:'left', padding:' 5px '}} for="sel1">Centers:</label>
   <select  id="CentersSel" style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.value} class="form-control"  onChange={this.handleChange}>
        <option value='0'>Select Centers</option>  {Options}
        </select> 

</div>

<div class="form-group">
  <label style={{float:'left', marginLeft:20,  padding:' 5px ',}} for="sel1">Vendor:</label>
  <select style={{     padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.value} class="form-control" onChange={this.GetallData} id="sel1">
  <option value='0'>Select Vendor</option>  {VendorOptions}
  </select>
</div>




</div>
{
          this.state.loading?
<div>
   

{
      this.state.GeneratedBillDiv.length>0?
   
       <div id="harish" class="row">



  <div  class="col-lg-12 mb-4">
  <div class="card shadow mb-4">
  <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Generated Bills</div>
        <div class="m-0 font-weight-bold text-primary"></div>
      </div>
  <div class="card-body" style={{paddingTop:0}}>



{this.state.GeneratedBillDiv.map((data, i)=>
  <div style={{ border:'1px solid #ccc' ,marginBottom:10, borderRadius:6, width:'100%'}}>  <div class='row' style={{textAlign:'left', fontWeight:500, padding:10,  }}>
    
    <div  class='col-lg-2' ><div> Bill No. </div><div style={{color:'#2A3F54',fontWeight:'bold'}}> {data.id}</div></div>
    <div  class='col-lg-2'><div> Bill Amount </div>
  <div style={{color:'#2A3F54',fontWeight:'bold'}}>Rs. {parseFloat(data.Total_Supply_Amoount).toFixed(2)}</div>
  </div>
  <div  class='col-lg-2'><div> Return  </div>
  <div style={{color:'#2A3F54',fontWeight:'bold'}}>Rs. {parseFloat(data.Total_Return_Amount).toFixed(2)}</div>
  </div>
  <div  class='col-lg-2'>
    <div>Pre Balance </div>
    <div style={{color:'#2A3F54',fontWeight:'bold'}}>Rs. {data.Pre_Opening_Balance_Amount}</div>
    </div>
  <div  class='col-lg-2'><div>Total Paid  </div>
  <div style={{color:'#2A3F54',fontWeight:'bold'}}>Rs. {data.Amount_Paid}</div>
  </div>
  
  <div  class='col-lg-2'>
    <div>Closing Balance  </div>
    <div style={{color:'#2A3F54',fontWeight:'bold'}}>Rs. {data.Opening_Balance_Amount}</div>
    </div>
    
    </div>
    
    <div class='row' style={{textAlign:'left', fontWeight:500, padding:10, paddingTop:10,  }}><div  class='col-lg-2'><div> Billing Date </div><div style={{color:'#2A3F54',fontWeight:'bold'}}>{data.Billing_Date}</div></div><div  class='col-lg-2'><div> From </div><div style={{color:'#2A3F54',fontWeight:'bold'}}> {data.Billing_Start_date}</div></div><div  class='col-lg-2'><div>To </div>
    
    <div style={{color:'#2A3F54',fontWeight:'bold'}}> {data.Billing_End_date}</div></div>
    
    <div  class='col-lg-4'><button style={{fontSize: 14, marginLeft:10,fontWeight: 'bold'}} class='btn btn-outline-primary' onClick={() => {this.ReBill(data.id)}}   >Re-Generate</button> 
    <button style={{fontSize: 14, marginLeft:10, fontWeight: 'bold'}} class='btn btn-outline-primary' onClick={() => {this.ViewBill(data.id)}}   >View Bill</button>
    <button style={{fontSize: 14, marginLeft:10,fontWeight: 'bold'}} class='btn btn-outline-danger' onClick={() => {this.DeleteBill(data.id)}}   >Delete</button>
   
    </div></div>
  
  </div>
)
}


  </div>
  </div>
  </div>
  </div>
:<div style={{textAlign:'center', color:'#000', margin:'auto', padding:10,}}>No records found</div>}

</div>
:<div  class="loader"></div>
    }
                 </div>
        
           </div>

        )}
    
        }
      
