import React from "react";

 
export default class centerReport extends React.Component {

  printDiv() { 
    var divContents = document.getElementById("PrintDiv").innerHTML; 
    var a = window.open('', '', 'height=500, width=500'); 
    a.document.write('<html>'); 
    a.document.write('<body>'); 
    a.document.write(divContents); 
    a.document.write('</body></html>'); 
    a.document.close(); 
    a.print(); 
} 

  render() {
    
   // console.log(this.props.location.state)
    const { ExelData } = this.props.location.state
    const { CenterName } = this.props.location.state
    const { header } = this.props.location.state

    console.log(header)
    let filtered = header.filter(function (el) {
      return el != null;
    });
    let Alldata =ExelData
    console.log("Alldata")
    console.log(Alldata)
    const headerDiv=[];
    const dataDiv=[];
   // const headerobj=JSON.parse(header);
  //let headerlent=(filtered.length-3)
  //alert(headerlent)


 

for(let i=2; i<filtered.length; i++)
{
  headerDiv.push(
            <th scope="col">{filtered[i].title}</th>
    )

    }


    for(let i=0; i<Alldata.length; i++)
    {
 
let dataDivtd=[]
   
    
      for(let h=2; h<filtered.length; h++)
      {
        let v=0;
        for(let j=2; j<filtered.length; j++)
        {
if(filtered[h].dataIndex==Object.keys(Alldata[i])[j])
{ v=1;
 // console.log(v+".,"+filtered[h].dataIndex+","+Object.keys(Alldata[i])[j])
 
      dataDivtd.push(
        <td>{Object.values(Alldata[i])[j]}</td>
        )

      }
    }
    if(v==0)
    {
     // console.log(v+".,"+filtered[h].dataIndex)
      dataDivtd.push(
        <td>0</td>
        )
    }
    
        }

dataDiv.push( <tr>{dataDivtd}</tr>)


      }
        

  //  console.log(ExelData)

  // console.log(Object.values(Alldata[0]).length)
  //   console.log(Object.values(Alldata[0])[0])

    
    return (
      // render logic here
      <div style={{marginTop:30, }}>
      <button style={{    margin: 5,
    marginLeft: 500}} onClick={this.printDiv}>Print It</button>
    


      <div id="PrintDiv" >
<div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
     <div style={{fontWeight:'bold', fontSize:18}}>
{ExelData[0].Date}
      </div>
      <div style={{fontWeight:'bold', fontSize:18}}>
{CenterName}
      </div>
</div>
<div style={{overflow:'overlay'}}>
<table style={{}} class="table table-striped table-dark">
  <thead>
    <tr>
      {headerDiv}
      {/* <th scope="col">Total Supply</th> */}
    </tr>
  </thead>
  <tbody>
  {dataDiv}
  </tbody>
</table></div>
</div>
</div> 
    
    )
  }
}


