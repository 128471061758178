import React, { Component } from 'react';
import logo from './img/meera.png';
import './App.css';
import './vendor/fontawesome-free/css/all.min.css';

import { HashRouter as Router,hashHistory, Route, Link } from 'react-router-dom'

import Home from './componets/Home.js';
import Login from './componets/login.js';
import Dashboard from './componets/Dashboard.js';
import Invoice from './componets/Invoice.js';
import NewInvoice from './componets/NewInvoice.js'
import SalesInvoice from './componets/SalesInvoice.js'
import SalesReport from './componets/SalesReport.js'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import Center from './componets/Center.js'
import Vendor from './componets/Vendor.js'
import Items from './componets/Items.js'
import Addnewitem from './componets/Addnewitem.js' 
import Reciepts from './componets/Reciepts.js'
import Returns from './componets/Returns.js'
import Centerbook from './componets/Centerbook.js'
import AddVendor from './componets/AddVendor.js'
import AllCenter from './componets/AllCenter'
import AddCenter from './componets/AddCenter'
import MobileUsers from './componets/MobileUsers'
import Addmobileuser from './componets/Addmobileuser'
import EditCenter from './componets/EditCenter'
import Editnewitem from './componets/Editnewitem'

import EditVendor from './componets/EditVendor'

import Settings from './componets/Settings'
import Reports from './componets/Reports'
import CenterReport from './componets/CenterReport'
import SubscriberMargin from './componets/subscriber_margin'
import subscriber_margin_Edit from './componets/subscriber_margin_Edit'
import Subscriptions from './componets/subscription'
import SubscriptionsEdit from './componets/subscriptionEdit'
import Modifybills from'./componets/ModifyInvoice'
import Outstanding from './componets/Outstanding'

class MenuItems extends React.Component {
  handleClick = () => this.props.onClick(this.props.index)
 
  render() {
    return <li    className={
        this.props.isActive ? 'nav-item active' : 'nav-item'
      }>
        
        <a class="nav-link collapsed" href={'/#/'+this.props.page} aria-expanded="true" aria-controls="collapseTwo">
          <i class={this.props.icon}></i>
          <span>{ this.props.name }</span>
        </a>
      
      </li>
   

  }
}


class AppView extends Component {
  
  state = {

  activeIndex: 0,
  pagetitle:''
};
logout = () =>
  {
    localStorage.clear();
        window.location='/'
  }

handleClick = (index) => this.setState({ activeIndex: index })
subscribe = (index) => {
  alert(index)
  window.open('http://subscription.cannytechnology.com/')
}
componentDidMount(){
  document.title = "Dashboard "
 // this.setState({ pagetitle: 'Dashboard' })

}


  
  render() {

  
   
    return (
    localStorage.getItem('userid')==null?    
      
     <Login/>
     :
 
 <Router >


 
  <div id="wrapper">

  {/* Sidebar */}
    
    
   
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

       {/*Sidebar - Brand*/}
       
    {/* <a class="sidebar-brand d-flex  align-items-center justify-content-center" style={{backgroundColor:'#589548'}} href="index.html">
     */}
     <a class="sidebar-brand d-flex  align-items-center justify-content-center" style={{backgroundColor:'#D32000'}} href="index.html">
    
      {/* <div class="sidebar-brand-text mx-10">  Distributor / Wholesaler  </div> */}
      {/* <div class="sidebar-brand-text mx-10">Raghu News Agency</div> */}
            {/* <div class="sidebar-brand-text mx-10">News Agency Demo</div> */}

{/* meera */}
        <img className="logoimg" src={logo}/>

        {/* meera */}
      </a>

       {/*Divider*/}
      <hr class="sidebar-divider my-0"/>

       {/*Nav Item - Dashboard*/}
      {/* <li class="nav-item active">
        <a class="nav-link" href="index.html">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span></a>
      </li> */}

    
       {/*Divider*/}
      <hr class="sidebar-divider"/>
       {/*Heading*/}
      <div class="sidebar-heading">
        
      </div>








      <MenuItems page="Dashboard" name="Dashboard" icon='fas fa-fw fa-tachometer-alt' index={1} isActive={ this.state.activeIndex===1 } onClick={ this.handleClick } />
      <MenuItems page="Centerbook" name="Center Book" icon='fas fa-fw fa-cubes' index={2} isActive={ this.state.activeIndex===2 } onClick={ this.handleClick }/>
     
      <MenuItems page="NewInvoice" name="New Billing" icon='fas fa-fw fa-file' index={3} isActive={ this.state.activeIndex===3 } onClick={ this.handleClick }/>
      {/* <MenuItems page="SalesInvoice" name="Sales Bill" icon='fas fa-fw fa-user-friends' index={4} isActive={ this.state.activeIndex===4 } onClick={ this.handleClick }/>
       */}
      <MenuItems page="SalesReport" name="Generated Bills" icon='fas fa-fw fa-file-text' index={4} isActive={ this.state.activeIndex===4 } onClick={ this.handleClick }/>
    
      <MenuItems page="Centers" name="Center Supply" icon='fas fa-fw  fa-truck-moving' index={5} isActive={ this.state.activeIndex===5} onClick={ this.handleClick }/>
     
      <MenuItems page="Outstanding" name="Outstanding Report" icon='fas fa-fw fa-file-alt' index={15} isActive={ this.state.activeIndex===15 } onClick={ this.handleClick }/>
     
      <MenuItems page="Reports" name="Reports" icon='fas fa-fw fa-file-alt' index={13} isActive={ this.state.activeIndex===13 } onClick={ this.handleClick }/>
     
      
      <MenuItems page="AllCenter" name="Centers" icon='fas fa-fw fa-university' index={6} isActive={ this.state.activeIndex===6} onClick={ this.handleClick }/>
    
      <MenuItems page="Vendors" name="Vendors" icon='fas fa-fw fa-user-friends' index={7} isActive={ this.state.activeIndex===7 } onClick={ this.handleClick }/>
      <MenuItems page="Items" name="Items" icon='fas fa-fw fa-newspaper' index={8} isActive={ this.state.activeIndex===8} onClick={ this.handleClick }/>
      <MenuItems page="SubscriberMargin" name="Vendor's Margin" icon='fas fa-fw fa-credit-card' index={9} isActive={ this.state.activeIndex===9} onClick={ this.handleClick }/>
    
      {/* <MenuItems  name="Subscriptions" icon='fas fa-fw fa-credit-card' index={9} isActive={ this.state.activeIndex===9} onClick={() => {  this.handleClick }}/>
     */}
      <MenuItems page="Subscriptions"  name="Subscriptions" icon='fas fa-fw fa-credit-card' index={11} isActive={ this.state.activeIndex===11} />
    
    

     

       {/* <MenuItems page="Returns" name="Return" icon='fas fa-fw fa-sort-amount-down' index={9} isActive={ this.state.activeIndex===9} onClick={ this.handleClick }/> */}
      {/* <MenuItems page="Reciepts" name="Reciepts" icon='fas fa-fw fa-file-alt' index={7} isActive={ this.state.activeIndex===7} onClick={ this.handleClick }/> */}
    
      
      <MenuItems page="MobileUsers" name="Mobile App Users" icon='fas fa-fw fa-mobile-alt' index={10} isActive={ this.state.activeIndex===10} onClick={ this.handleClick }/>
     
      
      
      <MenuItems page="Settings" name="Settings" icon='fas fa-fw fa-cog' index={11} isActive={ this.state.activeIndex===12} />
   

 
    </ul>
     {/*End of Sidebar*/}

     {/*Content Wrapper*/}
    <div id="content-wrapper" class="d-flex flex-column">
   

       {/*Main Content*/}
      <div id="content">

      <Route  path="/Dashboard" component={Dashboard} />

      <Route exact path="/" component={Dashboard} />
      <Route  path="/Centerbook" component={Centerbook} />
      <Route  path="/Sales" component={Invoice} />
      
      <Route  path="/NewInvoice" component={NewInvoice} />
      <Route  path="/SalesInvoice" component={SalesInvoice} />      
      <Route  path="/Centers" component={Center} />
      <Route  path="/Vendors" component={Vendor} />
      <Route  path="/Items" component={Items} />
      <Route  path="/Addnewitem" component={Addnewitem} />
      <Route  path="/Reciepts" component={Reciepts} />
      <Route  path="/Returns" component={Returns} />
      <Route  path="/AddVendor" component={AddVendor} />
      <Route  path="/AllCenter" component={AllCenter} />
      <Route  path="/AddCenter" component={AddCenter} />
      <Route  path="/MobileUsers" component={MobileUsers} />
      <Route  path="/Addmobileuser" component={Addmobileuser} />
      
      <Route  path="/SubscriberMargin" component={SubscriberMargin} />
      
      <Route  path="/EditCenter" component={EditCenter} />
      <Route  path="/Editnewitem" component={Editnewitem} />
      <Route  path="/EditVendor" component={EditVendor} />
     
      <Route  path="/SalesReport" component={SalesReport} />
      
      <Route  path="/centerReport" component={CenterReport} />
      <Route  path="/Settings" component={Settings} />
      <Route  path="/Reports" component={Reports} />
      <Route  path="/Outstanding" component={Outstanding} />
      
      <Route  path="/subscriber_margin_Edit" component={subscriber_margin_Edit} />
      <Route  path="/Modifybills" component={Modifybills} />

      
      <Route
  path="/Subscriptions"
  component={Subscriptions} 
  />   <Route
  path="/subscriptionEdit"
  component={SubscriptionsEdit} 
  />
      
      <NotificationContainer />
      </div>
       {/*End of Main Content*/}

       {/*Footer*/}
      {/* <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright &copy; Your Website 2019</span>
          </div>
        </div>
      </footer> */}
       {/*End of Footer*/}

    </div>

 
    </div>

  
   
  

    </Router>

    
    );
  }




}




export default AppView;
