
import React, { Component,useRef } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { ApiConstants } from './ApiConstants';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { HashRouter as Router,hashHistory, Route, Link } from 'react-router-dom'
import Helmet from 'react-helmet';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';


import 'moment/locale/it';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Alert } from 'react-bootstrap';
const { SearchBar, ClearSearchButton } = Search;
var context;
const { ExportCSVButton } = CSVExport;

const columns_pay = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true
  },
  
  {
  dataField: 'Billing_Date',
  text: 'Date',
  sort: true
}, {
  dataField: 'amount_paid',
  text: 'Amount Paid',
  sort: true
}, 

{
  dataField: 'payment_id',
  text: 'Payment Ref',
  sort: true
},

{
  dataField: 'payment_type',
  text: 'Payment Mode',
  sort: true
},
// {
//   dataField: 'App_Name',
//   text: 'Payment Platform',
//   sort: true
// },


];

const columns = [];
const columns_credit = [];


export default class Modifybills extends Component
{
  constructor(props) {
    super(props);
   
    this.state = {
      from: new Date(),
      to: new Date(),
      Vendors:[],
      loading:true,
      Centers:[],
      SoldCopies:0,
      ReturnSCopies:0,
      TotalAmout:0,
      TotalCenter:0,
      butnloaging:0,
      CenterId:"",
      VendorId:"",
      Total_transactions: [],
      supply_date:formatDate(new Date(), 'DD-MM-YYYY'),
      Credit:0,
      Return_Amount:0,
      Amount_Payable:0,
      Amount_Paid:"",
      Balance_Amount:0,
      Payment_Reference1:"",
      Payment_Reference:"",
      VendorBillingData:[],
      Payment_history:[],
      Payment_Info_text:"Cash",
      Payment_Mode:"Cash",
      Total_Return_Amount:0,
     OpeningBalance:0,
     Todays_Paid:0,
     Total_transactions_Credit:[],
     CETERBOOKRES:[]

    };
    context=this

    //alert(this.props.VendorId)
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
  componentDidMount()
  {
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
   
        urlencoded.append("BillId",window.location.href.split('/Modifybills/')[1].split('/')[0]);
       
        var Billed = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
          fetch(ApiConstants.NEWBILLING, Billed)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
          .then(Billed => {


            let billingdetail=Billed.data[0]
      
           
      let CenterId=billingdetail.CenterId
      let from=billingdetail.Billing_Start_date
      let to=billingdetail.Billing_End_date
      let VendorId=billingdetail.VendorId
    


      this.setState({
billNo:billingdetail.id,
Billing_Date:billingdetail.Billing_Date,
Billing_End_date:billingdetail.Billing_End_date,
Billing_Start_date:billingdetail.Billing_Start_date,
Center:billingdetail.Center,
Vendor:billingdetail.Vendor,
        billingAmount:billingdetail.Amount_Payable,
        OpeningBalance:billingdetail.Pre_Opening_Balance_Amount	,
        Total_Return_Amount:billingdetail.Total_Return_Amount,
     //   Tota_Paid_Amount:billingdetail.Amount_Paid,
        ClosingBalance:billingdetail.Opening_Balance_Amount,
      })
               this.GetData(CenterId,from,to,VendorId,billingdetail.Billing_Date,0)


      

        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  init(CenterId)
  {

    this.setState({loading:false})
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("CenterId", CenterId);
    urlencoded.append("Billing_date_mode", "2");
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(ApiConstants.VIEWVENDOR, VENDOR)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
        
        {

          var centerdata= response.data;

          // var Vendor = centerdata.map(function (data) {

          //   return {
          //     Location: data.Location,
          //     Name:  data.Name,
          //     Address:data.Address,
          //     id:  data.id,
          //     updated_dt:data.updated_dt,
          //     Center_name: data.Center_name,

          //     DateOfJoin: data.DateOfJoin,

          //     ContactPerson: data.ContactPerson,
          //     ContactNumber: data.ContactNumber,
             
              
          //     edit:'View more'
          //   }
          // })


          //alert(response.message)
this.setState({
  Vendors:centerdata,
  CenterId:CenterId,
  loading:true
})
//this.GetData(CenterId,this.state.supply_date); 
    
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  SavePayment = (event) => {
this.setState({
  butnloaging:1
})
let ur=localStorage.getItem('username');
//alert(ur)

         let allpaid= parseFloat(this.state.Tota_Paid_Amount);
        
         //alert(allpaid)
            var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
   
   // alert(value)
   urlencoded.append("Billno", this.state.billNo);
   
    urlencoded.append("Total_Supply_Amoount", parseFloat(this.state.TotalAmout.toString().replace('Rs.','')).toFixed(2));
   urlencoded.append("Total_Return_Amount", this.state.Total_Return_Amount);
   urlencoded.append("Amount_Paid",  allpaid);
//urlencoded.append("Pre_Opening_Balance_Amount", this.state.OpeningBalance);
urlencoded.append("Amount_Payable", this.state.Amount_Payable);
urlencoded.append("Payment_Reference", " Re Generated by "+ur);
urlencoded.append("Opening_Balance_Amount", this.state.Amount_Payable);

urlencoded.append("Billing_Start_date", this.state.Billing_Start_date);
urlencoded.append("Billing_End_date", this.state.Billing_End_date);

 
  
    var BillingGenerates = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.GenerateBillModify, BillingGenerates)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response =>{
       
        alert("Bill Re-Generated successfully")

        window.location='/#/SalesInvoice/'+this.state.billNo
    }
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
         
            }
            
            
         

  PaymenthandleChange= (event) => 
  {
   //alert(event.target.value)
var Payment_Info_text=""
   if(event.target.value=="Online")
   {
    Payment_Info_text="Transaction Id"
   }
   if(event.target.value=="Cheque")
   {
    Payment_Info_text="Cheque No."
   }
    this.setState({
      Payment_Mode:event.target.value,
   Payment_Info_text:Payment_Info_text
    })
    
 
  }
 

//   calctotals(VendorId,supply_date_from,supply_date_to,Tota_Paid_Amount)
//     {

//      // alert(supply_date_from)
//    var myHeaders = new Headers();
//    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
   
//    myHeaders.append("Origin", "application/x-www-form-urlencoded");

//    var urlencoded = new URLSearchParams();
//    urlencoded.append("Billing_date_mode", "2");
//    urlencoded.append("VendorId",VendorId);
//    urlencoded.append("from",supply_date_from);
//    urlencoded.append("to",supply_date_to);
//    var BULKBILLS = {
//      method: 'POST',
//      headers: myHeaders,
//      body: urlencoded,
//      redirect: 'follow'
//    };

//    fetch(ApiConstants.BULKBILLS, BULKBILLS)
//    .then(dataWrappedByPromise => dataWrappedByPromise.json())
//      .then(response => 
       
//        {
//         // var Total_transactions=this.state.Total_transactions;
//         // var CenterSupply=0;
//         // var SoldCopies=0;
//         // var Todays_Paid=0;
//         // var TotalAmout=0;
//         // var Total_Return_Amount=0;
       
//          var VendorBillingData= response.data;
// let Amount_Payable=0; 
//          let currentdate= response.currentdate;
//       let Payment_history=   response.Payment_history

//      // console.log(JSON.stringify(Payment_history))


//      let Payment_history_removezero=[];
// let i=0;
//       Payment_history.map(function(data)
//       {

//         Tota_Paid_Amount=parseFloat(Tota_Paid_Amount)+parseFloat(data.amount_paid)
//         if(data.amount_paid!=0)
//         {
//           Payment_history_removezero[i]=data
//           i=i+1;
//         }

//       })

//       Payment_history=Payment_history_removezero

// if(VendorBillingData.length>0)
// {

//   let Amount_Paid=0;

// // for(let j=0; j<VendorBillingData.length; j++)
// //   {
 
// //     TotalAmout=TotalAmout+(parseFloat(VendorBillingData[j].Amount_Payable!=undefined && VendorBillingData[j].Amount_Payable!=null && VendorBillingData[j].Amount_Payable!=""? VendorBillingData[j].Amount_Payable:0))

// //  Total_Return_Amount=Total_Return_Amount+(VendorBillingData[j].Total_Return_Amount!=undefined && VendorBillingData[j].Total_Return_Amount!=null && VendorBillingData[j].Total_Return_Amount!=""?parseFloat(VendorBillingData[j].Total_Return_Amount):0)
// //   }


//   // TotalAmout=parseFloat(TotalAmout).toFixed(2)
//    Amount_Payable=parseFloat(Amount_Payable)+parseFloat(this.state.TotalAmout)
//   Amount_Payable=parseFloat(Amount_Payable)+parseFloat(this.state.OpeningBalance)

//   Amount_Payable=parseFloat(Amount_Payable)-parseFloat(this.state.Total_Return_Amount)
//   Amount_Payable=parseFloat(Amount_Payable)-parseFloat(Tota_Paid_Amount)


//   Amount_Payable=parseFloat(Amount_Payable).toFixed(2)
//   this.setState({

//     // Total_Return_Amount:Total_Return_Amount,
//     Amount_Paid:Amount_Paid,    
//     // TotalAmout:TotalAmout,
//     Tota_Paid_Amount:Tota_Paid_Amount,
//     Amount_Payable:Amount_Payable,
//     Payment_history:Payment_history
//   })





// }



 
//   })

// }
calctotals(VendorId,supply_date_from,supply_date_to)
{


var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

myHeaders.append("Origin", "application/x-www-form-urlencoded");

var urlencoded = new URLSearchParams();
urlencoded.append("Billing_date_mode", "2");
urlencoded.append("VendorId",VendorId);
urlencoded.append("from",supply_date_from);
urlencoded.append("to",supply_date_to);
var BULKBILLS = {
 method: 'POST',
 headers: myHeaders,
 body: urlencoded,
 redirect: 'follow'
};

fetch(ApiConstants.BULKBILLS, BULKBILLS)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
 .then(response => 
   
   {

   
     var VendorBillingData= response.data;
let Tota_Paid_Amount=0,Amount_Payable=0; 
     let currentdate= response.currentdate;
  let Payment_history=   response.Payment_history



 let Payment_history_removezero=[];
let i=0;
  Payment_history.map(function(data)
  {

    Tota_Paid_Amount=parseFloat(Tota_Paid_Amount)+parseFloat(data.amount_paid)
    if(data.amount_paid!=0)
    {
      Payment_history_removezero[i]=data
      i=i+1;
    }

  })

  Payment_history=Payment_history_removezero

if(VendorBillingData.length>0)
{

let Amount_Paid=0;



Amount_Payable=parseFloat(Amount_Payable)+parseFloat(this.state.TotalAmout)
//console.log(this.state.TotalAmout)
Amount_Payable=parseFloat(Amount_Payable)+parseFloat(this.state.OpeningBalance)
// console.log(this.state.OpeningBalance)

Amount_Payable=parseFloat(Amount_Payable)-parseFloat(this.state.Total_Return_Amount)
// console.log(this.state.Total_Return_Amount)
Amount_Payable=parseFloat(Amount_Payable)-parseFloat(Tota_Paid_Amount)
// console.log(Tota_Paid_Amount)

Amount_Payable=parseFloat(Amount_Payable).toFixed(2)


//console.log(Amount_Payable)
this.setState({

// Total_Return_Amount:Total_Return_Amount,
Amount_Paid:Amount_Paid,    
// TotalAmout:TotalAmout,
Tota_Paid_Amount:Tota_Paid_Amount,
Amount_Payable:Amount_Payable,
Payment_history:Payment_history
})





}




})

}

  GetData(CenterId,from,to,VendorId,Billing_Date,Tota_Paid_Amount) {

    this.setState({ loading: false,Total_transactions:[] })
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("Billing_date_mode", "2");
        urlencoded.append("VendorId",VendorId);
        urlencoded.append("CenterId",CenterId);
        urlencoded.append("from",from);
        urlencoded.append("to",to);
        urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
      
         
   
        // console.log(CenterId)
        // console.log(supply_date)
        var GETSALESBILLING = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
      
        fetch(ApiConstants.GETSALESBILLING, GETSALESBILLING)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(CETERBOOKRES => {
       


let items=CETERBOOKRES[(CETERBOOKRES.length)-1]
items=items.toString().replace('\\', '')
items=JSON.parse(items)
items=items.items;
let products=[];
let z=(CETERBOOKRES.length)-2


let CETERBOOKRESPre=[]
for(let y=0; y<(CETERBOOKRES.length-1); y++)
{
  CETERBOOKRESPre[y]=CETERBOOKRES[y]
}

let date=new Date(to.split('-')[1]+"/"+to.split('-')[0]+"/"+to.split('-')[2])
let dateto=new Date(to.split('-')[1]+"/"+to.split('-')[0]+"/"+to.split('-')[2])

  
if(CETERBOOKRESPre.length>0)
{
  date = new Date(CETERBOOKRES[z].supply_date.split('-')[1]+"/"+CETERBOOKRES[z].supply_date.split('-')[0]+"/"+CETERBOOKRES[z].supply_date.split('-')[2]);
  dateto= new Date(CETERBOOKRES[z].supply_date.split('-')[1]+"/"+CETERBOOKRES[z].supply_date.split('-')[0]+"/"+CETERBOOKRES[z].supply_date.split('-')[2]);
  
}


if(date<new Date(to.split('-')[1]+"/"+to.split('-')[0]+"/"+to.split('-')[2]))
{


     
     
  
      let topre= new Date(to.split('-')[1]+"/"+to.split('-')[0]+"/"+to.split('-')[2]);
    
       var Difference_In_Time =topre.getTime() - dateto.getTime();
  
      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
   //  alert(Difference_In_Days)
      dateto.setDate(dateto.getDate() +1);




  date.setDate(date.getDate() -6);
   
 
   
           let urlencoded = new URLSearchParams();
           urlencoded.append("Billing_date_mode", "2");
           urlencoded.append("VendorId",VendorId);
           urlencoded.append("CenterId",CenterId);
           urlencoded.append("from",from);
           urlencoded.append("to",to);
           urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
         
            
      
           // console.log(CenterId)
           // console.log(supply_date)
           var GETSALES = {
             method: 'POST',
             headers: myHeaders,
             body: urlencoded,
             redirect: 'follow'
           };
         
           fetch(ApiConstants.GETSALESBILLING, GETSALES)
           .then(dataWrappedBy => dataWrappedBy.json())
           .then(CETERBOOKRES1 => {






            CETERBOOKRES1.sort((a, b) => {
//alert(a.supply_date)




if(a.supply_date!=undefined)
{
            let  supply_dates1=a.supply_date.split('-')[1]+"/"+a.supply_date.split('-')[0]+"/"+a.supply_date.split('-')[2]
            let  supply_dates2=b.supply_date.split('-')[1]+"/"+b.supply_date.split('-')[0]+"/"+b.supply_date.split('-')[2]
            
            return new Date(supply_dates1) - new Date(supply_dates2); // descending
}
            })

let Prebilldata=[];

let j=0;
let d=0;
let m=0;

            for(let i=0; i<Difference_In_Days; i++)
            {
         
                 d=d+1
            
              let setdate=new Date( CETERBOOKRES[z].supply_date.split('-')[1]+"/"+CETERBOOKRES[z].supply_date.split('-')[0]+"/"+CETERBOOKRES[z].supply_date.split('-')[2]);
            setdate.setDate(setdate.getDate() +d);
          
              Prebilldata.push({"id":i,"Prebill":'y', "All_JSON_Date":CETERBOOKRES1[j].All_JSON_Date,"supply_date":formatDate(new Date(setdate), 'DD-MM-YYYY'),"supply_date_pre":CETERBOOKRES1[j].supply_date  })
              
             // Prebilldata.push(CETERBOOKRES1[j])
           
              j=j+1
   
              if(j==7)
              {
                j=0
              }
            }
       
    let y=0;
         for(let x=0; x<(Prebilldata.length); x++)
{
   y=(CETERBOOKRESPre.length)
  CETERBOOKRESPre[y]=Prebilldata[x]
y=y+1;
}
    




             products=this.productsobj(CETERBOOKRESPre,items,VendorId,Billing_Date)
          
        this.setState({ loading: true,products:products,CETERBOOKRES:CETERBOOKRESPre,  Total_transactions:products })
        this.calctotals(VendorId, from, to,Tota_Paid_Amount)

              })

}else{

// console.log(items)
// console.log("items")
// console.log(items.length)
// console.log(items[1].FormalName)

 products=this.productsobj(CETERBOOKRES,items,VendorId,Billing_Date)


        this.setState({ loading: true,products:products,CETERBOOKRES:CETERBOOKRESPre, Total_transactions:products })
                     this.calctotals(VendorId, from, to,Tota_Paid_Amount)
          
}
        })
      
  


    
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

  
}
 
 
  productsobj(CETERBOOKRES,items,VendorId,Billing_Date)
{
  
let j=0;
columns[j]={
  dataField: 'Date',
  text: 'Date',
  sort: true,
  style: (cell, row, rowIndex, colIndex) => {
    return { width: "30%" };
  }
}
columns_credit[j]={
  dataField: 'Date',
  text: 'Date',
  sort: true,
  style: (cell, row, rowIndex, colIndex) => {
    return { width: "30%" };
  }
}



j=j+1;
columns[j]={
  dataField: 'Item',
  text: 'Item',
  sort: true
}
columns_credit[j]={
  dataField: 'Item',
  text: 'Item',
  sort: true
}
j=j+1;
columns[j]={
  dataField: 'Supply_Copies',
  text: 'Supply Copies',
  sort: true
}
columns_credit[j]={
  dataField: 'PreBilled_Supply_Copies',
  text: 'Supply Copies',
  sort: true
}
j=j+1;
columns_credit[j]={
  dataField: 'prebill_difference',
  text: 'Difference Copies',
  sort: true
}

//j=j+1;
columns[j]={
  dataField: 'Price',
  text: 'Supply Price',
  sort: true
}
j=j+1;
columns_credit[j]={
  dataField: 'PreBilled_Price',
  text: 'Supply Price',
  sort: true
}

columns[j]={
  dataField: 'Total',
  text: 'Supply Amount',
  sort: true
}
j=j+1;
columns_credit[j]={
  dataField: "Bliing_Total",
  text: 'Difference Amount',
  sort: true
}

columns[j]={
  dataField: 'Return_Copies',
  text: 'Return Copies',
  sort: true
}
j=j+1;
columns_credit[j]={
  dataField: "PreBilled_Return_Copies",
  text: 'Return Copies',
  sort: true
}

columns[j]={
  dataField: 'ReturnPrice',
  text: 'Return Price',
  sort: true
}
j=j+1;
columns_credit[j]={
  dataField: "PreBilled_ReturnPrice",
  text: 'Return Price',
  sort: true
}

columns[j]={
  dataField: 'ReturnTotal',
  text: 'Return Amount',
  sort: true
}
j=j+1;
columns_credit[j]={
  dataField: "PreBilled_ReturnTotal",
  text: 'Return Amount',
  sort: true
}
j=j+1;
columns[j]={
  dataField: 'TotalAmount',
  text: 'Total Amount',
  sort: true
}
j=j+1;
columns_credit[j]={
  dataField: "PreBilled_TotalAmount",
  text: 'Total Amount',
  sort: true
}

this.setState({items:items,columns:columns,columns_credit:columns_credit})

let products=[]
let Total_supply_amount=0;
let Total_Return_Amount=0;
let Total_transactions_Credit=[]
      
         for(let z=0; z<CETERBOOKRES.length; z++)
         {
if(CETERBOOKRES[z].All_JSON_Date!=undefined)
{
          let Total_transactions=[];

            Total_transactions=CETERBOOKRES[z].All_JSON_Date

         
         Total_transactions=Total_transactions.toString().replace('\\', '')
          Total_transactions=JSON.parse(Total_transactions)
       
       


          for(let k=0; k<Total_transactions.length; k++)
          {
          
if(Total_transactions[k].Vendor_Code==VendorId)
{
 let TotalAmout=Total_transactions[k].Total
  let Total_Return=Total_transactions[k].ReturnTotalAll

  Total_supply_amount=Total_supply_amount+(parseFloat(TotalAmout!=undefined && TotalAmout!=null && TotalAmout!=""? TotalAmout:0))

 Total_Return_Amount=Total_Return_Amount+(Total_Return!=undefined && Total_Return!=null && Total_Return!=""?parseFloat(Total_Return):0)


  
Total_transactions[k].transaction.map(function(data)

{
if(data.ReturnTotal!=undefined)
{
  data.TotalAmount="Rs."+(parseFloat(data.Total.toString().replace('Rs.',''))-parseFloat(data.ReturnTotal.toString().replace('Rs.',''))).toString()

  
}else{
  data.TotalAmount="Rs."+(parseFloat(data.Total.toString().replace('Rs.','')))

}
  data.Date=CETERBOOKRES[z].supply_date
  products.push(data)
  let setdatecredit=new Date( CETERBOOKRES[z].supply_date.split('-')[1]+"/"+CETERBOOKRES[z].supply_date.split('-')[0]+"/"+CETERBOOKRES[z].supply_date.split('-')[2]);
  let BillingDate=new Date( Billing_Date.split('-')[1]+"/"+Billing_Date.split('-')[0]+"/"+Billing_Date.split('-')[2]);
    
  if(setdatecredit>BillingDate)
{
  if(data.Pre_Status=="Pre-Billed")
  {

  data.prebill_difference=data.Supply_Copies-data.PreBilled_Supply_Copies
  data.Bliing_Total=data.prebill_difference*data.Price

  data.PreBilled_Return_Copies=data.Return_Copies-data.PreBilled_Return_Copies
  data.PreBilled_ReturnTotal=data.PreBilled_Return_Copies*data.ReturnPrice

  data.PreBilled_TotalAmount= data.Bliing_Total-data.PreBilled_Return_Copies


  Total_transactions_Credit.push(data)
  }
}
})

      
}

       
          }
    
        }
      }


      Total_supply_amount=parseFloat(Total_supply_amount).toFixed(2)
      Total_Return_Amount=parseFloat(Total_Return_Amount).toFixed(2)
      this.setState({
        TotalAmout:Total_supply_amount,
        Total_Return_Amount:Total_Return_Amount,
        Total_transactions_Credit:Total_transactions_Credit
      })

      return products;
}



    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
      let Options = this.state.Centers.map(v => (
        <option text={v.Name} value={v.id}>{v.Name}</option>
      ));

      let VendorOptions = this.state.Vendors.map(v => (
        <option text={v.Name} value={v.id}>{v.Name}</option>
      ));
      
        return (
      <div>
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Re-Generate Bill</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}


   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">
             {
          this.state.loading?
<div>
             <div class="d-sm-flex align-items-center justify-content-between mb-4">
             
        <h3 class="h5 mb-0 text-gray-800">Bill No. {this.state.billNo}</h3>
    
                   </div>
             <div class="d-sm-flex align-items-around justify-content-around mb-4">
             <div class="form-group">
  <label style={{float:'left', fontSize:16, padding:' 5px '}} for="sel1">Centers:</label>
  <label style={{float:'left',fontSize:16, fontWeight:'bold', padding:' 5px '}} for="sel1"> {this.state.Center}</label>

</div>
<div class="form-group">
  <label style={{float:'left', fontSize:16, padding:' 5px '}} for="sel1">Vendor:</label>
  <label style={{float:'left',fontSize:16, fontWeight:'bold', padding:' 5px '}} for="sel1">{this.state.Vendor}</label>

</div>
<div class="form-group">
  <label style={{float:'left', fontSize:16, padding:' 5px '}} for="sel1">From:</label>
  <label style={{float:'left',fontSize:16, fontWeight:'bold', padding:' 5px '}} for="sel1">{this.state.Billing_Start_date}</label>

</div>
<div class="form-group">
  <label style={{float:'left', fontSize:16, padding:' 5px '}} for="sel1">To:</label>
  <label style={{float:'left',fontSize:16, fontWeight:'bold', padding:' 5px '}} for="sel1">{this.state.Billing_End_date}</label>

</div>
</div>

   

{
        this.state.Total_transactions.length>0
        
       ? this.state.Total_transactions!=undefined?
       <div id="harish" class="row">
{this.state.Total_transactions.length>0?
<div class="col-lg-12 mb-4" style={{padding:0, overflow:'auto', maxHeight:400}}>

{/*Project Card Example*/}
<div class="card shadow mb-4">

 <div class="card-body">

 <div >
   {this.state.Total_transactions.length>=1?
      <div >

     {/* <ExportCSVButton { ...props.csvProps }>Export</ExportCSVButton>
     <hr /> */}
   
   <ToolkitProvider
keyField="id"
data={ this.state.Total_transactions }
columns={ columns }

bootstrap4={true}
pagination={ paginationFactory() }

>
         {/*Page Heading*/}
    

         {/*Content Row*/}
       
{
props => (
 <div  style={{fontSize:14,}}>     
 <ExportCSVButton  { ...props.csvProps }>  
 <h4>  Supply detail</h4>
        <span style={{margin:0, }} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Download in xlsx</span>
      </ExportCSVButton> 
     <hr />
 <BootstrapTable { ...props.baseProps } />
 </div>
)
}
</ToolkitProvider>  


 </div >:"No records found!" }</div>


 <div >
  
   </div> </div>
  
  
 


</div>

{/*Color System*/}

</div>
:null}


{/* Total_transactions_Credit */}



<div class="col-lg-12 mb-4" style={{padding:0, overflow:'auto', maxHeight:400}}>

{/*Project Card Example*/}
<div class="card shadow mb-4">

 <div class="card-body">

 <div >
   {this.state.Total_transactions_Credit.length>=1?
      <div >

     {/* <ExportCSVButton { ...props.csvProps }>Export</ExportCSVButton>
     <hr /> */}
   
   <ToolkitProvider
keyField="id"
data={ this.state.Total_transactions_Credit }
columns={ columns_credit }

bootstrap4={true}
pagination={ paginationFactory() }

>
         {/*Page Heading*/}
    

         {/*Content Row*/}
       
{
props => (
 <div  style={{fontSize:14,}}>  
  <h4>  Credit detail</h4>   

     <hr />
 <BootstrapTable { ...props.baseProps } />
 </div>
)
}
</ToolkitProvider>  


 </div >:"No Credit detail found!" }</div>


 <div >
  
   </div> </div>
  
  
 


</div>

{/*Color System*/}

</div>


{/* Total_transactions_Credit */}

<div  class="col-lg-12 mb-4">
   
   {/*Content Column*/}

   <div style={{float:'left'}} class="col-lg-7 mb-4">
   <div class="card shadow mb-4">
   <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Payment History</div>
        <div class="m-0 font-weight-bold text-primary"></div>
      </div>

     {/*Project Card Example*/}
  
  
      <div class="card-body" style={{overflow:'auto', maxHeight:700,}}>
        
      <div>


        {this.state.Payment_history.length>=1?
           <div >
      
      <BootstrapTable keyField='id' data={ this.state.Payment_history} columns={ columns_pay } />
      </div >:"No data!" }
      
      
      </div>
      </div>
    </div>

     {/*Color System*/}

  </div>
 

  <div style={{float:'left'}} class="col-lg-5 mb-4">

     {/*Illustrations*/}
    <div class="card shadow mb-4" style={{height:500}}>
      <div class="card-header d-sm-flex align-items-center justify-content-between mb-4">
        <div class="m-0 font-weight-bold text-primary">Billing</div>
        <div class="m-0 font-weight-bold text-primary"></div>
      </div>
      <div class="card-body">
      <div class="chart-area">
        
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Total Supply Amount </h6>
        <h6 class="m-0 font-weight-normal text-primary">Rs. {parseFloat(this.state.TotalAmout.toString().replace('Rs.','')).toFixed(2)}</h6>

      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Total Return Amount</h6>
        <h6 class="m-0 font-weight-normal text-primary"> 
        <h6 class="m-0 font-weight-normal text-primary">Rs. {this.state.Total_Return_Amount}</h6>
        </h6>

      </div> 
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Total Paid Amount</h6>
        <h6 class="m-0 font-weight-normal text-primary"> 
        <h6 class="m-0 font-weight-normal text-primary">Rs. {this.state.Tota_Paid_Amount}</h6>
        </h6>

      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Opening Balance Amount</h6>
        <h6 class="m-0 font-weight-normal text-primary">Rs. {this.state.OpeningBalance}</h6>

      </div>   
       
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Closing Balance Amount</h6>
      <h6 class="m-0 font-weight-normal text-primary">Rs. {this.state.ClosingBalance}</h6>

      </div>   

<hr/>
      <div style={{marginBottom:0}} class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-bold text-primary">Total Payable Amount</h6>
        <h6 class="m-0 font-weight-bold text-primary" style={{textAlign:"left"}}>Rs.{this.state.Amount_Payable}</h6>

      </div> 

      <hr/>


      {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary" style={{fontSize:15}}>Amount Paid</h6>
      <input  class="form-control" style={{width:200, borderColor:'#2A3F54'}}  type="number" id="Todays_Paid"  />
    
      </div> 


      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary" style={{fontSize:15}}>Note</h6>
      <textarea  class="form-control" id="Note" style={{width:200}} > </textarea>
    
      </div> 
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary">Payment Mode</h6>
      <select value={this.state.Payment_Mode} style={{width:200}} class="form-control"  onChange={this.PaymenthandleChange}>
  <option selected value="Cash">Cash</option>
  <option  value="Bank">Bank</option>
  <option  value="Cheque">Cheque</option>
  <option  value="PhonePay">PhonePay</option>
  <option  value="GooglePay">GooglePay</option>
</select>

      </div>  
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="m-0 font-weight-normal text-primary" style={{fontSize:15}}>Payment Id</h6>
      <input  class="form-control"  style={{width:200}} type="text" id="Payment_id"  />
    
      </div>  */}
       <div class="d-sm-flex align-items-center justify-content-between mb-4">
        
       <h6 class="m-0 font-weight-normal text-primary"></h6>

       {this.state.butnloaging==0?
          <button class="btn btn-primary"  onClick={this.SavePayment}>Re-Generate Bill
          
          
          </button>:
           <button class="btn btn-primary">Generating...
          
          
           </button>
          
          }
      
      </div>  
      </div>
     
    
      
      

           </div>


         
           </div>

        
    
     
    

     {/*Approach*/}
   

  </div>
  </div>
 

  </div>
:<div style={{textAlign:'center', color:'#000', margin:'auto', padding:10,}}>No data</div>:<div style={{textAlign:'center', color:'#000',margin:'auto', padding:10,}}>No records found</div>}

</div>
:<div  class="loader"></div>
    }
                 </div>
        
           </div>

        )}
    
        }
      
