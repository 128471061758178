
import React, { Component,useRef } from 'react';
import axios from 'axios';
import { Tabs,Tab } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { ApiConstants } from './ApiConstants';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { HashRouter as Router,hashHistory, Route, Link } from 'react-router-dom'
import Helmet from 'react-helmet';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';


import 'moment/locale/en-in';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
const { SearchBar, ClearSearchButton } = Search;
var context;
const { ExportCSVButton } = CSVExport;


export default class SalesReport extends Component
{
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,  
      loading:true,
      Centers:[],
      dataDiv:[],
      dateArraytableItem:[],
      CenterId:"",
      headerDiv:[],
      Total_transactions: [],
      Total_transactions_Sales:[],
      supply_date:formatDate(new Date(), 'DD-MM-YYYY'),
      Item:[],
      Itemvalue:0,
      dateArraytable:[],
      Vendor:[],
      dataDivPayments:[],
      valueVendor:0,
      Itemvaluesupply:0

    };
    context=this

    //alert(this.props.VendorId)
  }
  logout()
  {
    localStorage.clear();
        window.location='/login'
  }
  getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
  showFromMonth() {
    const { from, to } = this.state;

    
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
  VendorbyCenter(CenterId)
  {
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

   
     var urlencoded = new URLSearchParams();
    urlencoded.append("CenterId",CenterId);
    urlencoded.append("Billing_date_mode", "2");
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(ApiConstants.VIEWVENDOR, VENDOR)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 
              {


                var centerdata= response.data;

                var Vendor = centerdata.map(function (data) {
      
                  return {
                    Location: data.Location,
                    Name:  data.Name,
                    Address:data.Address,
                    id:  data.id,
                    updated_dt:data.updated_dt,
                    Center_name: data.Center_name,
      
                    DateOfJoin: data.DateOfJoin,
      
                    ContactPerson: data.ContactPerson,
                    ContactNumber: data.ContactNumber,
                   
                    
                    edit:'View more'
                  }
                })
       


          //alert(response.message)
this.setState({
  Vendor:Vendor,loading:true
})
  
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  componentDidMount()
  {
    this.setState({loading:false})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

   
  
    var Center = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
 
          fetch(ApiConstants.VIEWCENTER_Item_Vendor, Center)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(Centersresponse => 
              
              {


               


          //alert(response.message)
this.setState({
//  from: formatDate(new Date().setDate(new Date().getDate()-30), 'DD-MM-YYYY'),
//  to:formatDate(new Date(), 'DD-MM-YYYY'),
  Centers:Centersresponse.Center,

  Item:Centersresponse.Item,
  loading:true
})
  
        }
        
        )
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    
  }
  
  GetDatawillallsupply(CenterId,from,to,VendorId) {
    

    this.setState({ loading: false,Total_transactions:[] })
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("Billing_date_mode", "2");
        urlencoded.append("VendorId",VendorId);
        urlencoded.append("CenterId",CenterId);
        urlencoded.append("from",formatDate(new Date(from), 'DD-MM-YYYY'))
        urlencoded.append("to",formatDate(new Date(to), 'DD-MM-YYYY'));
        urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
      //  alert(supply_date)
         
   
        // console.log(CenterId)
        // console.log(supply_date)
        var GETSALESBILLING = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch(ApiConstants.GETSALESBILLING, GETSALESBILLING)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(CETERBOOKRES => {
        

          var urlencoded = new URLSearchParams();
          urlencoded.append("CenterID",this.state.CenterId);
          var VendorbyCenters = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };
   
          fetch(ApiConstants.VENDORSBYCENTER, VendorbyCenters)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
          .then(VendorbyCenter => {
          
     let Vendor=VendorbyCenter.response



       
           let to=CETERBOOKRES[(CETERBOOKRES.length)-2].supply_date

           let from=CETERBOOKRES[0].supply_date
  

let items=[]


let Total_transactions=[];

Total_transactions=CETERBOOKRES[0].All_JSON_Date

Total_transactions=Total_transactions.toString().replace('\\', '')
Total_transactions=JSON.parse(Total_transactions)




for(let k=0; k<Vendor.length; k++)
{

if(Vendor[k].id==VendorId)
{
  // items=Total_transactions[k].Fld1;


  items=JSON.parse(Vendor[k].Fld1)
}
}

if(items==undefined)
{
  items=CETERBOOKRES[(CETERBOOKRES.length)-1]
items=items.toString().replace('\\', '')
items=JSON.parse(items)
items=items.items;
 
}

let columns = []
let j=0;
columns[j]={
  dataField: 'Date',
  text: 'Date',
  sort: true,
  style: (cell, row, rowIndex, colIndex) => {
    return { width: "100px" };
  }
}
j=j+1;
columns[j]={
  dataField: 'Day',
  text: 'Day',
  sort: true,
  style: (cell, row, rowIndex, colIndex) => {
    return { width: "100px" };
  }
}
for(let i=0; i<items.length; i++)
{

 j=j+1;
  columns[j]={
    dataField: items[i].FormalName,
    text: items[i].FormalName,
    sort: true
  }

 
}
j=j+1;
columns[j]={
  dataField: 'Total',
  text: 'Total',
  sort: true
}
//console.log(columns)
this.setState({items:items,columns:columns})

let products=[]
let Total_transactions_Return=[]
let ar=0;
         // alert(CETERBOOKRES.TotalCenter[0].TotalCenter)
         for(let z=0; z<CETERBOOKRES.length; z++)
         {
if(CETERBOOKRES[z].All_JSON_Date!=undefined)
{
          let Total_transactions=[];

            Total_transactions=CETERBOOKRES[z].All_JSON_Date
          
         Total_transactions=Total_transactions.toString().replace('\\', '')
          Total_transactions=JSON.parse(Total_transactions)
       
       


          for(let k=0; k<Total_transactions.length; k++)
          {
          
if(Total_transactions[k].Vendor_Code==VendorId)
{

let itm=[];
let retn=[];
let Amount=0;
let AmountRtn=0;
           for(let m=0; m<Total_transactions[k].transaction.length; m++)
           {
     
            Amount=Amount+ parseFloat (Total_transactions[k].transaction[m].Total.toString().replace('Rs.',''))
           // AmountRtn=AmountRtn+ parseFloat (Total_transactions[k].transaction[m].ReturnTotal.toString().replace('Rs.',''))
               if(m==0)
           {
            itm='"'+[Total_transactions[k].transaction[m].Item]+'":"'+ Total_transactions[k].transaction[m].Supply_Copies+'"'
            retn='"'+[Total_transactions[k].transaction[m].Item]+'":"'+ Total_transactions[k].transaction[m].Return_Copies+'"'
         
         
            
          }else{
            itm=itm+',"'+[Total_transactions[k].transaction[m].Item]+'":"'+  Total_transactions[k].transaction[m].Supply_Copies.toString()+'"'
            retn=retn+',"'+[Total_transactions[k].transaction[m].Item]+'":"'+  Total_transactions[k].transaction[m].Return_Copies.toString()+'"'
           
           }

            
        //    Total_transactions[k].transaction.push(transaction[m])
          
           }
//console.log(itm)



let tempitem=[]
let temparry={
  Date: CETERBOOKRES[z].supply_date,
   Total:Amount,
 
  
 }
 let temparryRtn={
  Date: CETERBOOKRES[z].supply_date,
   Total:Amount,
 
  
 }



 itm=itm+',"Date":"'+CETERBOOKRES[z].supply_date+'","Day":"'+formatDate(new Date(  CETERBOOKRES[z].supply_date.toString().split('-')[1]+"/"+CETERBOOKRES[z].supply_date.toString().split('-')[0]+"/"+CETERBOOKRES[z].supply_date.toString().split('-')[2]), 'dddd')+'","Total":"'+parseFloat(Amount).toFixed(2)+'"';


 retn=retn+',"Date":"'+CETERBOOKRES[z].supply_date+'","Day":"'+formatDate(new Date(  CETERBOOKRES[z].supply_date.toString().split('-')[1]+"/"+CETERBOOKRES[z].supply_date.toString().split('-')[0]+"/"+CETERBOOKRES[z].supply_date.toString().split('-')[2]), 'dddd') +'","Total":"'+parseFloat(AmountRtn).toFixed(2)+'"';
 tempitem=itm
//  for(let it=1; it>=itm.split(',').length; it++)
//  {
//   temparry.push()
//  }

//console.log(tempitem)
temparry= '{'+tempitem+'}'
temparryRtn= '{'+retn+'}'
//temparry.push(tempitem)
//console.log(temparry)
        
products.push(JSON.parse(temparry))
Total_transactions_Return.push(JSON.parse(temparryRtn))
ar=ar+Amount

        
}

       
          }
        }
      }
       
      
        this.setState({ loading: true,products:products,  TotalAmout:ar, Total_transactions_Sales:products, Total_transactions_Return:Total_transactions_Return })


                
       
        })
  

      })
    
      .then(result => console.log(result))
      .catch(error =>{ console.log('error', error); this.setState({loading:true, })});

  }
  GetallData= (event) => 
    {
     // alert(event.target.value)
      this.setState({
        VendorId:event.target.value,
        VendorName:event.target.text
      })
      
      //this.GetDatabyVendor(this.state.CenterId,this.state.supply_date,event.target.value); 

//alert(event.target.value)
      // this.setState({ to }, this.showFromMonth);
      // var d = new Date(to);
         
      // this.savetojson()
      if(event.target.value==0)
      {
       this.GetData(this.state.CenterId, formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(this.state.to), 'DD-MM-YYYY'));
      }
      if(event.target.value==1)
      {
       this.GetDataALLCenter(formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(this.state.to), 'DD-MM-YYYY'));
      }

      else{
        this.GetData(this.state.CenterId, formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(this.state.to), 'DD-MM-YYYY'));
     
      }
       

    }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });


  }

  handleToChange(to) {
 
    this.setState({ to }, this.showFromMonth);
   var d = new Date(to);
  //  let from=formatDate(new Date().setDate(new Date().getDate()-30), 'MMMM Do YYYY');
  //  if(this.state.from==undefined)
  //  {   this.setState({
  //     from:from
  //   })
  // }
   // this.savetojson()
    
    // if(this.state.CenterId==1)
    //   {
    //    this.GetDataALLCenter(formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(to), 'DD-MM-YYYY'));
    //   }else if(this.state.CenterId==0){
    //     this.GetData(this.state.CenterId, formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(to), 'DD-MM-YYYY'));

    //   }else{
    //     this.GetData(this.state.CenterId, formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(to), 'DD-MM-YYYY'));

    //   }


    this.setState({
      
      value:0,
      Itemvalue:0,
      dateArraytable:[],
      valueVendor:0,
      headerDiv:[], dataDiv:[],VendorId:0,Total_transactions:[],dataDivPayments:[]
    })
  }

  
  handleChange = (event) => {
    console.log(event.target.value)
  
    this.setState({
      Centername:event.target.text,
      CenterId:event.target.value,
      value:event.target.value,
    })
    if(event.target.value==0)
    {
       }
    if(event.target.value==1)
    {
     this.GetDataALLCenter(formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(this.state.to), 'DD-MM-YYYY'));
    }

    else{
      this.GetData(event.target.value, formatDate(new Date(this.state.from), 'DD-MM-YYYY'), formatDate(new Date(this.state.to), 'DD-MM-YYYY'));
    }


  };



  GetData(CenterId,from,to) {
    
if(CenterId=="")
{
  CenterId=0
}

    this.setState({ loading: false,Total_transactions:[] })
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
     
        urlencoded.append("CenterId",CenterId);
        urlencoded.append("from",from);
        urlencoded.append("to",to);
        urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
      //  alert(supply_date)
         
   
        // console.log(CenterId)
        // console.log(supply_date)
        var GETSALESBILLING = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch(ApiConstants.GETSALEFORREPORT, GETSALESBILLING)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(CETERBOOKRES => 
          {
            this.setState({
              Item:CETERBOOKRES.Item
            })
            let allcenterdata=[];
            let CenterBookdata=[];
            


            const headerDiv=[];
            const dataDiv=[];
        

            var dateArray = [];

            from=from.split('-')[2]+'-'+from.split('-')[1]+'-'+from.split('-')[0];
            to=to.split('-')[2]+'-'+to.split('-')[1]+'-'+to.split('-')[0];
            var currentDate = moment(from);
            var stopDate = moment(to);

           
            while (currentDate <= stopDate) {
                dateArray.push( moment(currentDate).format('DD-MM-YYYY') )
                currentDate = moment(currentDate).add(1, 'days');
            }
            console.log(dateArray)
      let SupplyCenter=CETERBOOKRES.SupplyCenter;
      dateArray.map(function(dates)
      {
      for(let k=0; k<CETERBOOKRES.CenterBook.length; k++)
      {
        let CenterBookdataobj={};

            let   CenterBook =   CETERBOOKRES.CenterBook[k].All_JSON_Date
            let supply_date=CETERBOOKRES.CenterBook[k].supply_date
           CenterBook = CenterBook.replace(/\\n/g, "\\n")  
            .replace(/\\'/g, "\\'")
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, "\\&")
            .replace(/\\r/g, "\\r")
            .replace(/\\t/g, "\\t")
            .replace(/\\b/g, "\\b")
            .replace(/\\f/g, "\\f");
      // remove non-printable and other non-valid JSON chars
      CenterBook = CenterBook.replace(/[\u0000-\u0019]+/g,""); 
      
      CenterBook = JSON.parse(CenterBook);



    
        if(supply_date==dates)
        {  
          let arr=[];

          let itemobjTotal={}
          let Total_Supply_Copies=0;
          let Total_Return_Copies=0;
let Total_TotalSuppy=0;
let Total_UnsoldCopies=0;
          itemobjTotal["ITEM"]="Total"
          itemobjTotal["CODE"]="0"
          CETERBOOKRES.Item.map(function(alldata)
          {

             let Supply_Copies=0;
            let Return_Copies=0;
              let itemobj={}
            CenterBook.map(function (CenterBook)
            {  
        
              CenterBook.transaction.map(function (transaction)
              {
                if(alldata.id==transaction.id)
                          {
                {
                  if(isNaN(parseFloat(transaction.Supply_Copies))==false)
                  {
                     Supply_Copies=Supply_Copies+parseFloat(transaction.Supply_Copies)
                  }
                  if(isNaN(parseFloat(transaction.Return_Copies))==false)
                  {
                     Return_Copies=Return_Copies+parseFloat(transaction.Return_Copies)
                   }
                 }
                }

            
          })
          itemobj["SALE"]=Supply_Copies
          itemobj["RTN"]=Return_Copies
          itemobj["DATE"]=dates
         let week=dates.split('-')[2]+'-'+dates.split('-')[1]+'-'+dates.split('-')[0];
          itemobj["WEEK"]=moment(week).format('ddd')
          itemobj["ITEM"]=alldata.FormalName
          itemobj["CODE"]=alldata.id


          
          })


          Total_Supply_Copies=Total_Supply_Copies+parseFloat(Supply_Copies)
          Total_Return_Copies=Total_Return_Copies+parseFloat(Return_Copies)
          SupplyCenter.map(function(SupplyCenter)
          {
     
            if(SupplyCenter.Supply_date==dates)
            { 
             
            if(SupplyCenter.ItemId==alldata.id)
            {
            
              itemobj["SUP"]=SupplyCenter.TotalSuppy
              itemobj["US"]=SupplyCenter.UnsoldCopies

              Total_TotalSuppy=Total_TotalSuppy+parseFloat(SupplyCenter.TotalSuppy)
              Total_UnsoldCopies=Total_UnsoldCopies+parseFloat(SupplyCenter.UnsoldCopies)
            }
          }

          })
          itemobjTotal["SALE"]=Total_Supply_Copies
          itemobjTotal["RTN"]=Total_Return_Copies
          itemobjTotal["SUP"]=Total_TotalSuppy
          itemobjTotal["US"]=Total_UnsoldCopies


          
          arr.push(itemobj)
        //  arr.push(itemobjTotal)
          
          })
          arr.push(itemobjTotal)
          CenterBookdata.push(arr)

      

        
      
        }
      
    }



    })



{
  CETERBOOKRES.Item.push(

{
  FormalName: "Total",
NonSalesDays: "",
Price: "0"

}

  )
}

headerDiv.push(
  <td style={{textAlign:'center',borderRight:'1px solid #CCC', width:150}}>DATE</td>    )
  headerDiv.push(
    <td style={{textAlign:'center',borderRight:'1px solid #CCC', width:80}}>DAY</td>      )



    for(let m=0; m<CETERBOOKRES.Item.length; m++)
    {
      

      headerDiv.push(
        <td>
        <table style={{textAlign:'center', color:'#ffffff', }}>
        
        <tr style={{textAlign:'center',borderBottom:'1px solid #CCC',borderRight:'1px solid #CCC' }}>
        
        <td  colspan="4">{ CETERBOOKRES.Item[m].FormalName}</td>
        
        </tr>
        <tr>
        <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>SUP</td>
        <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>US</td>
        <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>RTN</td>
        <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>SALE</td>
        </tr>
        </table>
        </td>
)
      }

let alldivs=[];
dataDiv.push(   <tr>{headerDiv}</tr>)
      
    CenterBookdata.map(function(data)
    {

let dataDivs=[];

dataDivs.push(
<td style={{textAlign:'center',borderRight:'1px solid #CCC', width:150}}>{data[0].DATE}</td>
)
dataDivs.push(
<td style={{textAlign:'center',borderRight:'1px solid #CCC', width:80}}>{data[0].WEEK}</td>
)
      data.map(function (innerdata)
      {



        dataDivs.push(
          
          <td>
          <table style={{textAlign:'center',  color:'#ffffff',}}>
         
          <tr>
          <td style={{textAlign:'center', color:'#ffffff', width:30,borderRight:'1px solid #CCC'}}>{innerdata.SUP}</td>
          <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>{innerdata.US}</td>
          <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>{innerdata.RTN}</td>
          <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>{innerdata.SALE}</td>
          </tr>
          </table>
          </td>
  )

    
      })

      dataDiv.push(   <tr style={{borderTop:'1px solid #ccc'}}>{dataDivs}</tr>)
      

    }
    
    )



this.setState({headerDiv:headerDiv, dataDiv:dataDiv,loading:true})

    
    
      
          })
  


    
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

  }
  GetDataALLCenter(from,to) {
    


    
    this.setState({ loading: false,Total_transactions:[] })
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("CenterId",this.state.CenterId);
        urlencoded.append("from",from);
        urlencoded.append("to",to);
        urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
      //  alert(supply_date)
         
   
        // console.log(CenterId)
        // console.log(supply_date)
        var GETSALESBILLING = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch(ApiConstants.GETSALEFORREPORTALLCENTER, GETSALESBILLING)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(CETERBOOKRES => 
          {
this.setState({
  Item:CETERBOOKRES.Item
})
            let allcenterdata=[];
            let CenterBookdata=[];
            


            const headerDiv=[];
            const dataDiv=[];
        

            var dateArray = [];

            from=from.split('-')[2]+'-'+from.split('-')[1]+'-'+from.split('-')[0];
            to=to.split('-')[2]+'-'+to.split('-')[1]+'-'+to.split('-')[0];
            var currentDate = moment(from);
            var stopDate = moment(to);

           
            while (currentDate <= stopDate) {
                dateArray.push( moment(currentDate).format('DD-MM-YYYY') )
                currentDate = moment(currentDate).add(1, 'days');
            }
           
      let SupplyCenter=CETERBOOKRES.SupplyCenter;

 
      dateArray.map(function(dates)
      {

       
      for(let k=0; k<CETERBOOKRES.CenterBook.length; k++)
      {
        let CenterBookdataobj={};

            let   CenterBook =   CETERBOOKRES.CenterBook[k].All_JSON_Date
            let supply_date=CETERBOOKRES.CenterBook[k].supply_date
           CenterBook = CenterBook.replace(/\\n/g, "\\n")  
            .replace(/\\'/g, "\\'")
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, "\\&")
            .replace(/\\r/g, "\\r")
            .replace(/\\t/g, "\\t")
            .replace(/\\b/g, "\\b")
            .replace(/\\f/g, "\\f");
      // remove non-printable and other non-valid JSON chars
      CenterBook = CenterBook.replace(/[\u0000-\u0019]+/g,""); 
      
      CenterBook = JSON.parse(CenterBook);
    
        if(supply_date==dates)
        {  
          let arr=[];
          
          CETERBOOKRES.Item.map(function(alldata)
          {

            let Supply_Copies=0;
          let Return_Copies=0;
              let itemobj={}
            CenterBook.map(function (CenterBook)
            {  
              let item="";
              CenterBook.transaction.map(function (transaction)
              {
               
                if(alldata.id==transaction.id)
                          {
                         if(isNaN(parseFloat(transaction.Supply_Copies))==false)
                         {
                            Supply_Copies=Supply_Copies+parseFloat(transaction.Supply_Copies)
                         }
                         if(isNaN(parseFloat(transaction.Return_Copies))==false)
                         {
                            Return_Copies=Return_Copies+parseFloat(transaction.Return_Copies)
                          }
                        }
          

            
          })
         
      
        
          itemobj["ITEM"]=alldata.FormalName
          itemobj["CODE"]=alldata.id



          
          })
          itemobj["DATE"]=dates
          let week=dates.split('-')[2]+'-'+dates.split('-')[1]+'-'+dates.split('-')[0];
           itemobj["WEEK"]=moment(week).format('ddd')
          itemobj["SALE"]=Supply_Copies
          itemobj["RTN"]=Return_Copies
          SupplyCenter.map(function(SupplyCenter)
          {
     
            if(SupplyCenter.Supply_date==dates)
            { 
             
            if(SupplyCenter.ItemId==alldata.id)
            {
            
              itemobj["SUP"]=SupplyCenter.TotalSuppy
              itemobj["US"]=SupplyCenter.UnsoldCopies
              
            }
          }

          })
       
          arr.push(itemobj)
          })
          CenterBookdata.push(arr)

      

        
      
        }
      
    }

//     allcenterdata.push(dates)
//     allcenterdata.push(Supply_Copies)
//     allcenterdata.push(Return_Copies)
 
    })
  //  CenterBookdata=JSON.stringify(CenterBookdata)
  //  CenterBookdata=JSON.parse(CenterBookdata)
  //  console.log(CenterBookdata)
    let Alldata=[]
    dateArray.map(function(dates)
      { 
      
          let arr=[];
    
        
          let itemobjTotal={}
  
          itemobjTotal["ITEM"]="Total"
          itemobjTotal["CODE"]="0"

          let Total_Supply_Copies=0;
          let Total_Return_Copies=0;
          let Total_UnsoldCopies=0;
          let Total_TotalSuppy=0;
        CETERBOOKRES.Item.map(function(alldata)
        { 
          let itemobj={}

          itemobj["ITEM"]=alldata.FormalName
          itemobj["CODE"]=alldata.id

       
          let Supply_Copies=0;
          let Return_Copies=0;
          let UnsoldCopies=0;
          let TotalSuppy=0;
          CenterBookdata.map(function (dataAll)
          {

dataAll.map(function (data)
{
    
          if(data.DATE==dates)
          { 
      
           if(alldata.id==data.CODE)
           {
         
            Supply_Copies=Supply_Copies+parseFloat(data.SALE)
            Return_Copies=Return_Copies+parseFloat(data.RTN)
            TotalSuppy=TotalSuppy+parseFloat(data.SUP)
            UnsoldCopies=UnsoldCopies+parseFloat(data.US)
           }
           
          }
         
        })
      })
        itemobj["SALE"]=Supply_Copies
        itemobj["RTN"]=Return_Copies
        itemobj["SUP"]=TotalSuppy
        itemobj["US"]=UnsoldCopies         
        itemobj["DATE"]=dates
        let week=dates.split('-')[2]+'-'+dates.split('-')[1]+'-'+dates.split('-')[0];
        itemobj["WEEK"]=moment(week).format('ddd')       
        arr.push(itemobj)
      
        Total_Supply_Copies=Total_Supply_Copies+Supply_Copies
        Total_Return_Copies=Total_Return_Copies+Return_Copies
        Total_UnsoldCopies=Total_UnsoldCopies+UnsoldCopies
        Total_TotalSuppy=Total_TotalSuppy+TotalSuppy
    })
    

    itemobjTotal["SALE"]=Total_Supply_Copies
    itemobjTotal["RTN"]=Total_Return_Copies
    itemobjTotal["SUP"]=Total_TotalSuppy
    itemobjTotal["US"]=Total_UnsoldCopies         
    itemobjTotal["DATE"]=dates
    let week=dates.split('-')[2]+'-'+dates.split('-')[1]+'-'+dates.split('-')[0];
    itemobjTotal["WEEK"]=moment(week).format('ddd')    
    
    
    arr.push(itemobjTotal)
      Alldata.push(arr)


    })

    CenterBookdata=[];
    console.log(Alldata)
    CenterBookdata=Alldata
   
headerDiv.push(
  <td style={{textAlign:'center',borderRight:'1px solid #CCC', width:150}}>DATE</td>    )
  headerDiv.push(
    <td style={{textAlign:'center',borderRight:'1px solid #CCC', width:80}}>DAY</td>      )

{
  CETERBOOKRES.Item.push(

{
  FormalName: "Total",
NonSalesDays: "",
Price: "0"

}

  )
}

    for(let m=0; m<CETERBOOKRES.Item.length; m++)
    {
      

      headerDiv.push(
        <td>
        <table style={{textAlign:'center', color:'#ffffff', }}>
        
        <tr style={{textAlign:'center',borderBottom:'1px solid #CCC',borderRight:'1px solid #CCC' }}>
        
        <td  colspan="4">{ CETERBOOKRES.Item[m].FormalName}</td>
        
        </tr>
        <tr>
        <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>SUP</td>
        <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>US</td>
        <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>RTN</td>
        <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>SALE</td>
        </tr>
        </table>
        </td>
)
      }

let alldivs=[];
dataDiv.push(   <tr>{headerDiv}</tr>)
      
    CenterBookdata.map(function(data)
    {

let dataDivs=[];

dataDivs.push(
<td style={{textAlign:'center',borderRight:'1px solid #CCC', width:150}}>{data[0].DATE}</td>
)
dataDivs.push(
<td style={{textAlign:'center',borderRight:'1px solid #CCC', width:80}}>{data[0].WEEK}</td>
)
      data.map(function (innerdata)
      {



        dataDivs.push(
          
          <td>
          <table style={{textAlign:'center',  color:'#ffffff',}}>
         
          <tr>
          <td style={{textAlign:'center', color:'#ffffff', width:30,borderRight:'1px solid #CCC'}}>{innerdata.SUP}</td>
          <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>{innerdata.US}</td>
          <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>{innerdata.RTN}</td>
          <td style={{textAlign:'center',width:30,borderRight:'1px solid #CCC'}}>{innerdata.SALE}</td>
          </tr>
          </table>
          </td>
  )

    
      })

      dataDiv.push(   <tr style={{borderTop:'1px solid #ccc'}}>{dataDivs}</tr>)
      

    }
    
    )



this.setState({headerDiv:headerDiv, dataDiv:dataDiv,loading:true})

    
    
      
          })
  


    
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

  }
  handleChangeItems= (event) => 
  {
    
  
    this.setState({
      loading:false,
      Itemvalue:event.target.value
    })
    let Itemvalue=event.target.value

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("CenterId",context.state.CenterId);
        urlencoded.append("from", formatDate(new Date(context.state.from), 'DD-MM-YYYY'));
        urlencoded.append("to", formatDate(new Date(context.state.to), 'DD-MM-YYYY'));
        urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
      //  alert(supply_date)
         
   
        // console.log(CenterId)
        // console.log(supply_date)
        var GETSALESBILLING = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch(ApiConstants.GET_SupplyReport, GETSALESBILLING)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(CETERBOOKRES => 
          {
    
            var dateArray = [];
            var dateArraytable = [];
            let dateArraytabletr=[];
            let Total_supply=0
            let Totalamount=0;
let from=formatDate(new Date(context.state.from), 'DD-MM-YYYY');
let to=formatDate(new Date(context.state.to), 'DD-MM-YYYY');
            from=from.split('-')[2]+'-'+from.split('-')[1]+'-'+from.split('-')[0];
            to=to.split('-')[2]+'-'+to.split('-')[1]+'-'+to.split('-')[0];
            var currentDate = moment(from);
            var stopDate = moment(to);

        
           if(Itemvalue!=0)
           {
            while (currentDate <= stopDate) {
              let day=moment(currentDate).format('dddd') 
                dateArray.push( moment(currentDate).format('DD-MM-YYYY') )
                dateArray.push( moment(currentDate).format('dddd') )
let TotalSupply=0;let centerdata=[],centerdata1=[]
                CETERBOOKRES.CenterSupply.map(function(data)
                {  
                  if(data.ItemId==Itemvalue)
                  {
                  let date=new Date(data.Supply_date.split('-')[1]+'/'+data.Supply_date.split('-')[0]+'/'+data.Supply_date.split('-')[2])


                  if(new Date(date).getTime()==new Date(currentDate).getTime())
                  {
                 
                    TotalSupply= parseFloat(TotalSupply)+ parseFloat(data.TotalSuppy)
                    if(TotalSupply=="" || isNaN(parseFloat(TotalSupply))==true)
                    {
                      TotalSupply=0
                    }
                   
                    centerdata.push(
                      <tr> 
                      <td>{data.centername}</td> 
                      </tr>)
                          centerdata1.push(
                            <tr> 
                           <td>{isNaN(parseFloat(data.TotalSuppy))==true?0:parseFloat(data.TotalSuppy)}</td> 
                            </tr>)
                  }
                 
                }
                })

                dateArray.push(TotalSupply)
let price=0;




CETERBOOKRES.Price.map(function(data)
{  
  
  if(data.Itemid==Itemvalue)
  {

                if(day=='Sunday')
                {
                  price=data.Sunday_Pure_Price
                }
                if(day=='Monday')
                {
                  price=data.Monday_Pure_Price
                }
                if(day=='Thursday')
                {
                  price=data.Thursday_Pure_Price
                }
                if(day=='Tuesday')
                {
                  price=data.Tuesday_Pure_Price
                }
                if(day=='Wednesday')
                {
                  price=data.Wednesday_Pure_Price
                }
                if(day=='Friday')
                {
                  price=data.Friday_Pure_Price
                }
                if(day=='Saturday')
                {
                  price=data.Saturday_Pure_Price
                }

            

           
              }
            })
            
              // _Pure_Price
          if(TotalSupply=="" || isNaN(parseFloat(TotalSupply))==true)
          {
            TotalSupply=0
          }
          if(price=="" || isNaN(parseFloat(price))==true)
          {
            price=0
          }
              Totalamount=parseFloat(Totalamount)+(parseFloat(TotalSupply)*parseFloat(price))
              Total_supply=parseFloat(Total_supply)+parseFloat(TotalSupply)
              dateArraytabletr.push(
              <tr> <th>{moment(currentDate).format('DD-MM-YYYY')}</th> <td>{moment(currentDate).format('dddd')}</td> 
              <td>{price}</td>  <td>{TotalSupply}</td>  <td>Rs.{parseFloat(parseFloat(TotalSupply)*parseFloat(price)).toFixed(2) }</td>
              </tr>)
              dateArraytabletr.push(
                <tr>
                  <td> </td><td>  </td>
                  <td> 
                <table style={{color: '#000'}} class="table table-info table-striped">
               {centerdata}
                </table></td>
                  <td> 
                <table style={{color: '#000'}}  class="table table-info table-striped">
               {centerdata1}
                </table></td>
                </tr>
                )

                currentDate = moment(currentDate).add(1, 'days');
          }
           }
          dateArraytable.push(
          <table  class="table table-dark table-striped">
  <thead>
    <tr>
      <th >Date</th>
      <th>Day</th>
      <th >Purchage Rate</th>
      <th >Supply Copies</th>
      <th >Amount</th>
    </tr>
  </thead>
  <tbody>
{dateArraytabletr}

<tr style={{ borderTop:'2px solid #fff'}}>
      <th ></th>
      <th></th>
      <th >Total</th>
      <th >{Total_supply}</th>
      <th >Rs.{parseFloat(Totalamount).toFixed(2)}</th>
    </tr>
  </tbody>
</table>
  
  )


          this.setState({dateArraytable:dateArraytable, loading:true,})
          
        
  })


} 

handleChangeItemsbysupply= (event) => 
{
  

  this.setState({
    loading:false,
    Itemvaluesupply:event.target.value
  })
  let Itemvaluesupply=event.target.value

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Origin", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("CenterId",context.state.CenterId);
      urlencoded.append("from", formatDate(new Date(context.state.from), 'DD-MM-YYYY'));
      urlencoded.append("to", formatDate(new Date(context.state.to), 'DD-MM-YYYY'));
      urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
    //  alert(supply_date)
       
 
      // console.log(CenterId)
      // console.log(supply_date)
      var GETSALESBILLING = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
      
      fetch(ApiConstants.GET_SupplyReport, GETSALESBILLING)
      .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(CETERBOOKRES => 
        {
  
          var dateArray = [];
          var dateArraytable = [];
          let dateArraytabletr=[];
          let Total_supply=0
          let Totalamount=0;
let from=formatDate(new Date(context.state.from), 'DD-MM-YYYY');
let to=formatDate(new Date(context.state.to), 'DD-MM-YYYY');
          from=from.split('-')[2]+'-'+from.split('-')[1]+'-'+from.split('-')[0];
          to=to.split('-')[2]+'-'+to.split('-')[1]+'-'+to.split('-')[0];
          var currentDate = moment(from);
          var stopDate = moment(to);

      
         if(Itemvaluesupply!=0)
         {
          while (currentDate <= stopDate) {
            let day=moment(currentDate).format('dddd') 
              dateArray.push( moment(currentDate).format('DD-MM-YYYY') )
              dateArray.push( moment(currentDate).format('dddd') )
let TotalSupply=0;let centerdata=[],centerdata1=[]
              CETERBOOKRES.CenterSupply.map(function(data)
              {  
                if(data.ItemId==Itemvaluesupply)
                {
                let date=new Date(data.Supply_date.split('-')[1]+'/'+data.Supply_date.split('-')[0]+'/'+data.Supply_date.split('-')[2])


                if(new Date(date).getTime()==new Date(currentDate).getTime())
                {
               
                  TotalSupply= parseFloat(TotalSupply)+ parseFloat(data.TotalSuppy)
                  if(TotalSupply=="" || isNaN(parseFloat(TotalSupply))==true)
                  {
                    TotalSupply=0
                  }
                 
                  centerdata.push(
                    <tr> 
                    <td>{data.CenterId}</td> 
                    </tr>)

                    let v=0;
                 

                    // console.log(v)
for(let c=0; c<context.state.Centers.length; c++)
{
  
  
                    if(context.state.Centers[c].id==data.CenterId)
                    {
             
                        centerdata1[c]={id:data.CenterId,  td:<td>{isNaN(parseFloat(data.TotalSuppy))==true?0:parseFloat(data.TotalSuppy)}</td>}
                   
                    }
            
                  //  console.log(context.state.Centers[c].Name)
                    
                  }

                  // if(v==0)
                  // {
                  //   centerdata1.push(
                    
                  //     <td>0</td> 
                  //    )
                  // }
                }
               
              }
              })

              dateArray.push(TotalSupply)
let price=0;


let centerdata12=[]

for(let c=0; c<context.state.Centers.length; c++)
{
  if(centerdata1[c]!=undefined)
  {
if(centerdata1[c].id==context.state.Centers[c].id)
{
  centerdata12.push(centerdata1[c].td)
}
else{
  centerdata12.push(<td>0</td> )
}
  }else{
    centerdata12.push(<td>0</td> )
  }
}

CETERBOOKRES.Price.map(function(data)
{  

if(data.Itemid==Itemvaluesupply)
{

              if(day=='Sunday')
              {
                price=data.Sunday_Pure_Price
              }
              if(day=='Monday')
              {
                price=data.Monday_Pure_Price
              }
              if(day=='Thursday')
              {
                price=data.Thursday_Pure_Price
              }
              if(day=='Tuesday')
              {
                price=data.Tuesday_Pure_Price
              }
              if(day=='Wednesday')
              {
                price=data.Wednesday_Pure_Price
              }
              if(day=='Friday')
              {
                price=data.Friday_Pure_Price
              }
              if(day=='Saturday')
              {
                price=data.Saturday_Pure_Price
              }

          

         
            }
          })
          
            // _Pure_Price
        if(TotalSupply=="" || isNaN(parseFloat(TotalSupply))==true)
        {
          TotalSupply=0
        }
        if(price=="" || isNaN(parseFloat(price))==true)
        {
          price=0
        }
            Totalamount=parseFloat(Totalamount)+(parseFloat(TotalSupply)*parseFloat(price))
            Total_supply=parseFloat(Total_supply)+parseFloat(TotalSupply)
            dateArraytabletr.push(
            <tr> <th>{moment(currentDate).format('DD-MM-YYYY')}</th> <td>{moment(currentDate).format('dddd')}</td> 
            <td>{price}</td>  
            
           
           
           {centerdata12}
           
      
            
            <td>{TotalSupply}</td>  <td>Rs.{parseFloat(parseFloat(TotalSupply)*parseFloat(price)).toFixed(2) }</td>
            
            
         
            
            </tr>)
            // dateArraytabletr.push(
            // //   <tr>
            // //     <td> </td><td>  </td>
            // //     <td> 
            // //   <table style={{color: '#000'}} class="table table-info table-striped">
            // //  {centerdata}
            // //   </table></td>
            // //     <td> 
            // //   <table style={{color: '#000'}}  class="table table-info table-striped">
            // //  {centerdata1}
            // //   </table></td>
            // //   </tr>
          
            //   )

              currentDate = moment(currentDate).add(1, 'days');
        }
         }

        let centerdatalist=[]
    context.state.Centers.map(function(Centers){
      centerdatalist.push(    <th >{Centers.Name}</th>)
         })


        dateArraytable.push(
          <div>
        <table  style={{fontSize:12, textAlign:'center', width:2000}} class="table table-striped">
<thead>
  <tr >
    <th style={{width:100}}>Date</th>
    <th>Day</th>
    <th >Purchage Rate</th>

  {centerdatalist}

    <th >Total Copies</th>
    <th >Amount</th>
  </tr>
</thead>
<tbody>
{dateArraytabletr}


</tbody>
</table>
<div style={{fontWeight:'bold', fontSize:14, }}>

  <span style={{margin:10, padding:10}}>
Total Supply Copies : { Total_supply}</span>

<span style={{margin:10, padding:10}}>
Total Amount : Rs.{parseFloat(Totalamount).toFixed(2)}</span>

</div></div>
)


        this.setState({dateArraytableItem:dateArraytable, loading:true,})
        
      
})


}
handleChangeVendor= (event) => 
{
  

  this.setState({
    loading:false,
    VendorId:event.target.value,
    valueVendor1:event.target.value
  })
  let VendorId=event.target.value

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Origin", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("VendorId",VendorId);
      urlencoded.append("Billing_Start_Date",  formatDate(new Date(this.state.from), 'DD-MM-YYYY')  );
      urlencoded.append("Billing_End_Date", formatDate(new Date(this.state.to), 'DD-MM-YYYY') );
    //  alert(supply_date)
       
 
      // console.log(CenterId)
      // console.log(supply_date)
      var GETSALESBILLING = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
      


      
      fetch(ApiConstants.GETPaymentHistoryReport, GETSALESBILLING)
      .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(PaymentHistory => 
        {
let Payment_history=[]
let Payment_history_data=[]
let Payment=PaymentHistory.response
let allAmt=0;
          console.log(PaymentHistory.response)

          Payment.sort((a, b) => {
              if(a.Billing_Date!=undefined)
              {
                          let  supply_dates1=a.Billing_Date.split('-')[1]+"/"+a.Billing_Date.split('-')[0]+"/"+a.Billing_Date.split('-')[2]
                          let  supply_dates2=b.Billing_Date.split('-')[1]+"/"+b.Billing_Date.split('-')[0]+"/"+b.Billing_Date.split('-')[2]
                          
                          return new Date(supply_dates1) - new Date(supply_dates2); // descending
              } }
              )
          Payment.map(function(Payment)
          {
         if(Payment.amount_paid!=0)
         {
          allAmt=allAmt+parseFloat(Payment.amount_paid)
          Payment_history_data.push(
           
            <tr key={Payment.id}> <th>{Payment.Billing_Date}</th> <td>{Payment.amount_paid}</td> 
            <td  style={{ fontSize:14}}>{Payment.payment_type}</td>  <td>{Payment.payment_id}</td>  <td >
              <div style={{ width:150, wordWrap:'break-word', fontSize:12}}>
              {Payment.billing_id}
              </div>
              </td></tr>)
         } 
        })
    Payment_history.push(
            <table  class="table table-dark table-striped">
    <thead>
      <tr>
        <th >Date</th>
        <th>Amount</th>
        <th >Mode</th>
        <th >Reference</th>
        <th >Payment id</th>
      
        <th ></th>
      </tr>
    </thead>
    <tbody>
  {Payment_history_data}
  
  <tr style={{ borderTop:'2px solid #fff'}}>
        <th >Total</th>
        <th>Rs.{parseFloat(allAmt).toFixed(2)}</th>
        <th ></th>
        <th ></th>
        <th ></th>
      </tr>
    </tbody>
  </table>
    
    )
    this.setState({
      dataDivPayments:Payment_history, loading:true,
    })

        })

      }


  printDiv() { 
    var divContents = document.getElementById("PrintDiv").innerHTML; 
    var a = window.open('', '', 'height=500, width=500'); 
    a.document.write('<html>'); 
    a.document.write('<body>'); 
    a.document.write(divContents); 
    a.document.write('</body></html>'); 
    a.document.close(); 
    a.print(); 
} 
PrintDivsupplys() { 
  var divContents = document.getElementById("PrintDivsupplys").innerHTML; 
  var a = window.open('', '', 'height=500, width=500'); 
  a.document.write('<html>'); 
  a.document.write('<body>'); 
  a.document.write(divContents); 
  a.document.write('</body></html>'); 
  a.document.close(); 
  a.print(); 
} 



printDivSupply() { 
  var divContents = document.getElementById("PrintDivSupply").innerHTML; 
  var a = window.open('', '', 'height=500, width=500'); 
  a.document.write('<html>'); 
  a.document.write('<body>'); 
  a.document.write(divContents); 
  a.document.write('</body></html>'); 
  a.document.close(); 
  a.print(); 
} 

PrintDivPayment() { 
  var divContents = document.getElementById("PrintDivPayment").innerHTML; 
  var a = window.open('', '', 'height=500, width=500'); 
  a.document.write('<html>'); 
  a.document.write('<body>'); 
  a.document.write(divContents); 
  a.document.write('</body></html>'); 
  a.document.close(); 
  a.print(); 
} 
PrintDivCenter() { 
  var divContents = document.getElementById("PrintDivCenter").innerHTML; 
  var a = window.open('', '', 'height=500, width=500'); 
  a.document.write('<html>'); 
  a.document.write('<body>'); 
  a.document.write(divContents); 
  a.document.write('</body></html>'); 
  a.document.close(); 
  a.print(); 
}

  handleDayChange=(selectedDay, modifiers, dayPickerInput)=> {
    var d = new Date(selectedDay);
      this.setState({supply_date:formatDate(new Date(selectedDay), 'DD-MM-YYYY')})
   // this.savetojson()
    this.GetData(this.state.CenterId, formatDate(new Date(selectedDay), 'DD-MM-YYYY'), this.state.VendorId);

  }
    render() {
      const { from, to } = this.state;
      const modifiers = { start: from, end: to };
      let Options = this.state.Centers.map(v => (
        <option text={v.Name} value={v.id}>{v.Name}</option>
      ));
      let OptionsItem=[]
      if(this.state.Item.length>0)
      {
       OptionsItem = this.state.Item.map(v => (
        <option text={v.FormalName} value={v.id}>{v.FormalName}</option>
      ));
       }
      
       let OptionsVendor=[]
       if(this.state.Vendor.length>0)
       {
        OptionsVendor = this.state.Vendor.map(v => (
         <option text={v.Name} value={v.id}>{v.Name}</option>
       ));
        }
        return (
      <div>


        
           <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h1 class="h3 mb-0 text-gray-800">Reports</h1>
   {/*Sidebar Toggle (Topbar)*/}
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

   {/*Topbar Search*/}
   <div class="d-sm-flex align-items-left justify-content-left mb-4">
         




    
         <div style={{marginTop: 24, marginLeft:30}} className="InputFromTo">


           <div>  <Helmet>
                        <style>{`

.DayPicker-Day{
  color: #333;
}
.DayPicker-Day--today
{
  color:red
}

          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPicker-Caption{
            color: #333;
          }
          
          
          `}</style>
                      </Helmet>
       
        <DayPickerInput
          value={from}
          placeholder="From"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
           
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            placeholder="To"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
        </span>
     
      </div></div>
   
  
</div>

   {/*Topbar Navbar*/}
  <ul class="navbar-nav ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

     {/*Nav Item - User Information*/}
    <li  onClick={this.logout}

    
    class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
            </a>
       {/*Dropdown - User Information*/}
          </li>

  </ul>

</nav>
             <div class="container-fluid">

         
            

<div >
   

{
   this.state.from!=undefined && this.state.from!=undefined?  
       <div id="harish" class="row">

<Tabs defaultActiveKey="SupplyByVendor" id="uncontrolled-tab-example" className="mb-3">
  

 
  <Tab eventKey="SupplyByVendor" title="Sales Report">
 
  <div style={{padding:0,overflow:'auto', maxWidth:'36%'}}>

{/*Project Card Example*/}
<div class="card shadow" style={{ width:3000,}}>

 <div class="card-body" >

 {this.state.Centers.length>0?   <div >
  <label style={{float:'left', padding:' 5px '}} for="sel1">Center:</label>
   <select  id="CentersSel2" style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.value2} class="form-control" onChange={(event) =>{
   this.VendorbyCenter(event.target.value)
     this.setState({CenterId:event.target.value, Total_transactions_Sales:[], value2:event.target.value,})}}>
        <option value='0'>Select Center</option>    {Options}
        </select> 

</div>:null}

{this.state.Vendor.length>0?   <div >
  <label style={{float:'left', padding:' 5px '}} for="sel1">Vendor:</label>
   <select  id="CentersSelVendor" style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.valueVendor} class="form-control" onChange={(event) => 
{
  this.setState({valueVendor:event.target.value})
  this.GetDatawillallsupply(this.state.CenterId,this.state.from,this.state.to, event.target.value)
  
}}>
        <option value='0'>Select Vendor</option>   {OptionsVendor}
        </select> 

</div>:null}


<div style={{clear:'both', float:'none'}}></div>

{
          this.state.loading?
          <div >
{this.state.Total_transactions_Sales.length>0?
  <div  id="PrintDivSupply" class="card-body">
  Sales Detail
<div  style={{fontSize:11, width:1200, overflow:'auto'}}>


<BootstrapTable keyField='id' style={"font-size:11"} data={ this.state.Total_transactions_Sales } columns={ this.state.columns } />

 
 
</div><hr/>

<div>

Return Detail
<div  style={{fontSize:11,width:1200, overflow:'auto'}}>


<BootstrapTable keyField='id' style={"font-size:11"} data={ this.state.Total_transactions_Return } columns={ this.state.columns } />



</div>


</div>



</div>
    :null}
{this.state.Total_transactions.length>0?
<button style={{    margin: 5,
marginLeft: 500}} onClick={this.printDivSupply}>Print It</button>
    :null}

</div>
:<div  class="loader" style={{marginLeft:'15%'}}>

</div>}
</div>

</div>

{/*Color System*/}

</div>    

  </Tab>
  <Tab eventKey="Payment" title="Payment History">
  <div style={{padding:0,overflow:'auto', maxWidth:'80%'}}>

{/*Project Card Example*/}
<div class="card shadow" style={{ width:1200,}}>

 <div class="card-body" >
 {this.state.Centers.length>0?   <div >
  <label style={{float:'left', padding:' 5px '}} for="sel1">Center:</label>
   <select  id="CentersSel1" style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.value1} class="form-control" onChange={(event) =>{
   this.VendorbyCenter(event.target.value)
     this.setState({CenterId:event.target.value,  value1:event.target.value,})}}>
        <option value='0'>Select Center</option>    {Options}
        </select> 

</div>:null}
 {this.state.Vendor.length>0?   <div class="form-group">
  <label style={{float:'left', padding:' 5px '}} for="sel1">Vendor:</label>
   <select  id="CentersSel" style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.valueVendor1} class="form-control"  onChange={this.handleChangeVendor}>
        <option value='0'>Select Vendor</option>   {OptionsVendor}
        </select> 

</div>:null}

<div style={{clear:'both', float:'none'}}></div>

{
          this.state.loading?
          <div>
          <div id="PrintDivPayment" >
 
{this.state.dataDivPayments}

 </div>
 <button style={{    margin: 5,
  marginLeft: 500}} onClick={this.PrintDivPayment}>Print It</button>
     </div>
:<div  class="loader" style={{marginLeft:'15%'}}>

</div>}
</div>

</div>

{/*Color System*/}

</div>  
  </Tab>

  <Tab eventKey="Item" title="Item Report">
  <div style={{padding:0,overflow:'auto'}}>

{/*Project Card Example*/}
<div class="card shadow" style={{ width:1000, overflow:'auto'}}>

 <div class="card-body" >
 <div  >
 {this.state.Centers.length>0?   <div class="form-group">
  <label style={{float:'left', padding:' 5px '}} for="sel1">Item:</label>
   <select  id="CentersSelOptionsItem" style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.Itemvalue} class="form-control"  onChange={this.handleChangeItems}>
        <option value='0'>Select Item</option>    {OptionsItem}
        </select> 

</div>:null}

<div style={{clear:'both', float:'none'}}></div>

{
          this.state.loading?
          <div>
<div id="PrintDiv">
  {this.state.dateArraytable}
  </div>
{this.state.dateArraytable.length>0?
  <button style={{    margin: 5,
marginLeft: 500}} onClick={this.printDiv}>Print It</button>
  
    :null}
  </div>
:<div  class="loader">
  
</div>}
</div>
 </div>
</div>

{/*Color System*/}

</div>   

  </Tab>
  
  
  <Tab eventKey="Center" title="Center Report">
  <div style={{padding:0,overflow:'auto', maxWidth:'36%'}}>

{/*Project Card Example*/}
<div class="card shadow" style={{ width:3000,}}>

 <div class="card-body" >

 {this.state.Centers.length>0?   <div class="form-group">
  <label style={{float:'left', padding:' 5px '}} for="sel1">Center:</label>
   <select  id="CentersSel" style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.value} class="form-control"  onChange={this.handleChange}>
        <option value='0'>Select Centers</option>  <option value='1'>ALL Centers</option>  {Options}
        </select> 

</div>:null}



<div style={{clear:'both', float:'none'}}></div>

{
          this.state.loading?<div>
          <div id="PrintDivCenter" >
 <table  style={{textAlign:'center',backgroundColor:'#001E41', color:'#ffffff', fontSize:12, fontWeight:600, padding:0, margin:0, border:'1px solid #CCC'}} >


{this.state.dataDiv}

</table> </div>

<button style={{    margin: 5,
  marginLeft: 500}} onClick={this.PrintDivCenter}>Print It</button>
     </div>

:<div  class="loader" style={{marginLeft:'15%'}}>

</div>}
</div>

</div>

{/*Color System*/}

</div>  

  {/* <button style={{    margin: 5,
marginLeft: 500}} onClick={this.printDiv}>Print It</button> */}

  </Tab>
 <Tab eventKey="Supply" title="Supply Report">
  <div style={{padding:0,overflow:'auto'}}>

{/*Project Card Example*/}
<div class="card shadow" style={{ width:1000, overflow:'auto'}}>

 <div class="card-body" >
 <div  >
 {this.state.Centers.length>0?   <div class="form-group">
  <label style={{float:'left', padding:' 5px '}} for="sel1">Item:</label>
   <select  id="CentersSelOptionsItemSupply" style={{padding:' 5px 10px', float:'left',height:'auto',  width: 'auto',}}  value={this.state.Itemvaluesupply} class="form-control"  onChange={this.handleChangeItemsbysupply}>
        <option value='0'>Select Item</option>    {OptionsItem}
        </select> 

</div>:null}

<div style={{clear:'both', float:'none'}}></div>

{
          this.state.loading?
          <div>
<div id="PrintDivsupplys">
  {this.state.dateArraytableItem}
  </div>
{this.state.dateArraytableItem.length>0?
  <button style={{    margin: 5,
marginLeft: 500}} onClick={this.PrintDivsupplys}>Print It</button>
  
    :null}
  </div>
:<div  class="loader">
  
</div>}
</div>
 </div>
</div>

{/*Color System*/}

</div>   

  </Tab>
  

</Tabs>


</div>:<div style={{color:'red', fontWeight:'500', fontSize:20, textAlign:'center'}}>Select From date and To date for getting Reports!</div>
}

</div>


    
                 </div>
        
           </div>

        )}
    
        }
      
