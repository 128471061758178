import React, { Component } from 'react';
import {
 
  Link

} from "react-router-dom";
import Helmet from 'react-helmet';
import List from 'a-chart'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { ApiConstants } from './ApiConstants';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import XLSX from 'xlsx'
import { ExportSheet } from 'react-xlsx-sheet'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { Button,Modal } from 'react-bootstrap';
import emailjs from 'emailjs-com';
var columnsItem=[]
const { SearchBar, ClearSearchButton } = Search;
var context;
var typeweek=0;

const columns = [{
  dataField: 'Vendor_Code',
  text: 'Vendor Code',
  sort: true,
  editable: false
}, {
  dataField: 'Vendor_Name',
  text: 'Vendor Name',
  sort: true,
  editable: false
}, {
  dataField: 'Total_Items',
  text: 'Total Items',
  sort: true,
  editable: false,

},
{
  dataField: 'Total_Supply_Copies',
  text: 'Total Supply Copies',
  sort: true,
  editable: false
},
{
  dataField: 'Total_Return_Copies',
  text: 'Total Return Copies',
  sort: true,
  editable: false
},
{
  dataField: 'Total_Subscription_Copies',
  text: 'Total Subscription Copies',
  sort: true,
  editable: false
},
{
  dataField: 'Total_Sold_Copies',
  text: 'Sold Copies',
  sort: true,
  editable: false
},
{
  dataField: 'Total',
  text: 'Total Amount',
  sort: true,
  editable: false
},

{
  dataField: 'User',
  text: 'User',
  sort: true,
  editable: false
}, {

  dataField: 'Approved',
  text: 'Approval Status',
  sort: true,
  style: (cell, row, rowIndex, colIndex) => {
    if (cell == 'Waiting') {
      return {
        backgroundColor: '#2196F3',
        color: '#fff',
      };
    }
    if (cell == 'Pre-week') {
      return {
        backgroundColor: 'red',
        color: '#fff',
      };
    }
    if (cell == 'Disapprove') {
      return {
        backgroundColor: '#DB4638',
        color: '#fff',
      };
    }
    if (cell == 'New') {
      return {
        backgroundColor: '#2A3F54',
        color: '#fff',
      };
    }
    return {
      backgroundColor: '#1cc88a',
      color: '#fff',
    };

  },
  editor: {
    type: Type.SELECT,
    options: [{
      value: 'Waiting',
      label: 'Waiting'
    }, {
      value: 'Approved',
      label: 'Approved'
    }, {
      value: 'Disapprove',
      label: 'Disapprove'
    },{
      value: 'Pre-week',
      label: 'Pre-week'
    }
  
    
  
  ]
  }
  // formatter: (cellContent, row) => (



  //   <select  style={row.Approved=='Waiting' ? {    backgroundColor:'blue', padding:' 5px 10px',   color:'#fff'}: row.Approved=='Approved' ?{    backgroundColor:'green', padding:' 5px 10px',   color:'#fff'}:{    backgroundColor:'red', padding:' 5px 10px',   color:'#fff'}}  class="form-control" id="sel1">
  //    <option style={row.Approved=='Waiting' ? {    backgroundColor:'blue',   color:'#fff'}: row.Approved=='Approved' ?{    backgroundColor:'green',   color:'#fff'}:{    backgroundColor:'red',   color:'#fff'}}> {row.Approved}</option>
  //   <option style={{backgroundColor:'blue', color:'#fff'}}>Waiting</option>
  //   <option style={{backgroundColor:'green',color:'#fff'}}>Approved</option>
  //   <option style={{backgroundColor:'red', color:'#fff'}}>Disapprove</option>


  // </select>


  // )
}

];




export default class Invoice extends Component {



  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      exp:0,
      extenednon:[],
      Total_transactions: [],
      to: undefined,
      Centers: [],
      CETERBOOKRESVendor:2,
      TotalCenter:0,
      getSelected:[],
       CenterSupply:0,
       loading:true,
 SoldCopies:0,
 TotalCenterSupply:0,
 ReturnSCopies:0,
 TotalAmout:0,
 expanded:[],
 ExelHeader:[],
 ExelData:[],
 ErrorReturn:"N",
 ErrorhandelAPIMessage:"",
 Location:"",
 Subscriptionresponse:[],
 CenterName:"",
 TotalUnsold:0,show:false,
      supply_date:formatDate(new Date(), 'DD-MM-YYYY')
    };
    context=this
  }

  
  printDiv() { 
    var divContents = document.getElementById("PrintDiv").innerHTML; 
    var a = window.open('', '', 'height=500, width=500'); 
    a.document.write('<html>'); 
    a.document.write('<body>'); 
    a.document.write(divContents); 
    a.document.write('</body></html>'); 
    a.document.close(); 
    a.print(); 
} 


  GetData(CenterId,supply_date) {
    
    typeweek=0;

    this.setState({ loading: false,ExelData:[], ExelHeader:[],Total_transactions:[] })
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

let newdate=supply_date.split('-')[2]+','+supply_date.split('-')[1]+','+supply_date.split('-')[0]

//alert(formatDate(new Date(newdate), 'dddd'))
        var urlencoded = new URLSearchParams();
   
        urlencoded.append("CenterId",CenterId);
        urlencoded.append("supply_date",supply_date);
        urlencoded.append("Selling_Day", formatDate(new Date(newdate), 'dddd'));
       /// alert(formatDate(supply_date, 'DD-MM-YYYY'))

        let day=supply_date.toString().split('-')[0]
        let Month=supply_date.toString().split('-')[1]
        let year=supply_date.toString().split('-')[2]
         var date = new Date(year+"-"+Month+"-"+day);
       //  alert(year+"-"+Month+"-"+day)
        // date.setDate(date.getDate() -7);
        // let formatedate=formatDate(supply_date, 'DD-MM-YYYY');
      let days=supply_date.toString().split('-')[0];
        //alert(supply_date.toString().split('-')[0])
        date.setDate(date.getDate() -7);
     // alert(date)
       urlencoded.append("weeklast_date", formatDate(date, 'DD-MM-YYYY'));
      //  //console.log(CenterId)
       // //console.log(supply_date)
        var GETCETERBOOK = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch(ApiConstants.GETCETERBOOK, GETCETERBOOK)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(CETERBOOKRES => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
          myHeaders.append("Origin", "application/x-www-form-urlencoded");
          var urlencoded = new URLSearchParams();
         
          urlencoded.append("CenterId",CenterId);
         
          urlencoded.append("Supply_date", supply_date);
         // alert(date)
          var CENTERGETSUPPLY = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
          };
          
          fetch(ApiConstants.CENTERGETSUPPLY, CENTERGETSUPPLY)
          .then(dataWrappedByPromise => dataWrappedByPromise.json())
            .then(response => 
      
      {

        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Origin", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
       
        urlencoded.append("center_id",CenterId);
       
        let subscribebyCenter_id = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
        
        fetch(ApiConstants.subscribebyCenter_id, subscribebyCenter_id)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
          .then(Subscriptionresponse => 
    
    {

      this.VENDORSBYCENTER(CenterId)
        
this.setState({Subscriptionresponse:Subscriptionresponse})
//console.log(JSON.stringify(response.response))


      let  TotalCenterSupply =response.TotalSuppy[0].TotalCenter
      let  TotalUnsold =response.TotalSuppy[0].TotalUnsold
         // alert(CETERBOOKRES.TotalCenter[0].TotalCenter)
          let Total_transactions=[];

//alert(CETERBOOKRES.type)

          if(CETERBOOKRES.type==1)
          {
   
  var si=0;
             Total_transactions = CETERBOOKRES.Vendor.map(function (data) {
          
              let transaction = CETERBOOKRES.Item.map(function (data) {

            
                if(data.NonSalesDays!=undefined && data.NonSalesDays.indexOf(supply_date)<0)
{
 
                si=si+1;
                return {
               id:data.id,
                  Item:data.FormalName,
                  Price:data.Price,
                  Supply_Copies:0,
                  Sold_Copies:0,
                  Total:'Rs. 0',
                  Return_Copies:0,
                 
                 
                  ReturnPrice:0,
                  ReturnTotal:"Rs. 0",
                  Subscription_Copies:0,
                  
                }
              }



              });
              transaction = transaction.filter(function( element ) {
                return element !== undefined;
             });
           //   si=si+1;
        //   //console.log(data.Fld1)
              return {
             
                id:data.id,
                Vendor_Code:data.Vendor_Code,
                Total_Items:transaction.length,
                Total:'Rs. 0',
                Vendor_Name:data.Vendor_Name,
                Total_Supply_Copies:0,
                Total_Return_Copies:0,
                Total_Sold_Copies:0,
                Amount_Paid:0,
                Paid_Source:"Cash",
                Total_Subscription_Copies:0,
                User:localStorage.getItem('username'),
                Approved: 'New',
                transaction:transaction,
                Fld1:data.Fld1,
                ReturnTotalAll:"0"
              }
            });
            if(CETERBOOKRES.TotalCenter!=undefined || CETERBOOKRES.TotalCenter!=null)
            {
            this.setState({TotalCenter:CETERBOOKRES.TotalCenter[0].TotalCenter})
            if(CETERBOOKRES.TotalCenter[0].TotalCenter==null)
            {
              this.setState({TotalCenter:0})
            }
          }else{
  
            this.setState({TotalCenter:0})
          }
          }
          else
          
          {
            var typeweeks=CETERBOOKRES[2]
            if(typeweeks!=undefined)
            {
            typeweeks=typeweeks.toString().replace('\\', '')
            typeweeks=JSON.parse(typeweeks)
            
            //  console.log(JSON.stringify(typeweek.typeweek))
            typeweek=typeweeks.typeweek
            }

            Total_transactions=CETERBOOKRES[0].All_JSON_Date
         
      //   Total_transactions=Total_transactions.toString().replace('\\', '')

         Total_transactions = Total_transactions.replace(/\\n/g, "\\n")  
         .replace(/\\'/g, "\\'")
         .replace(/\\"/g, '\\"')
         .replace(/\\&/g, "\\&")
         .replace(/\\r/g, "\\r")
         .replace(/\\t/g, "\\t")
         .replace(/\\b/g, "\\b")
         .replace(/\\f/g, "\\f");
// remove non-printable and other non-valid JSON chars
Total_transactions = Total_transactions.replace(/[\u0000-\u0019]+/g,""); 
 Total_transactions = JSON.parse(Total_transactions);


        //  Total_transactions=JSON.parse(Total_transactions)
       
        columnsItem=[]
         if( Total_transactions[0].Approved == 'Approved' || Total_transactions[0].Approved == 'Waiting' || Total_transactions[0].Approved =='Billed'  && typeweek != 2)
         {


          let    ExelDatas=[]


       
        var e = document.getElementById("CentersSel");
        var value = e.options[e.selectedIndex].value;
        var text = e.options[e.selectedIndex].text;

        let CenterName=text
if(CenterName==undefined)
{
  CenterName=this.state.Centers[0].Name
}this.setState({CenterName:CenterName})
//alert(CenterName)
          let items=CETERBOOKRES[1]
          items=items.toString().replace('\\', '')
          items=JSON.parse(items)
          items=items.items;
          
          
          
          let j=0; let k=3;
          columnsItem[j]={
            title: 'Date', widthPx:80, dataIndex: 'Date' 
            }
            j=j+1;
          columnsItem[j]={
          title: 'Center', widthPx:80, dataIndex: 'Center' 
          }
          j=j+1;
          columnsItem[j]={
            title: 'Vendor', width: {wpx: 180}, dataIndex: 'Vendor' 
            }
          j=j+1;
          columnsItem[j]={
            title: 'Cash', widthPx:80, dataIndex: 'Cash' 
            }  
            
            j=j+1;
            columnsItem[j]={
              title: 'Bank', widthPx:80, dataIndex: 'Bank' 
              }

            
        
          j=j+1;
          for(let i=0; i<items.length; i++)
          {
          
           
            j=j+1;
          columnsItem[j]={
          //  title: items[i].FormalName + " Supply", widthPx:80, dataIndex: items[i].id+"Supply"
            title: items[i].FormalName,  dataIndex: items[i].id+"Supply", style: {font: {bold: true}}
            }
        
          //  ExelData[0].push(items[i].id+"Supply")


            // j=j+1;
            // columnsItem[j]={
            //   title: items[i].FormalName + " SC", widthPx:80, dataIndex: items[i].id+"SC"
            //   }
           
            //   j=j+1;
            //   columnsItem[j]={
            //     title: items[i].FormalName + " Return", widthPx:80, dataIndex: items[i].id+"Return"
            //     }

          

          }
          // j=j+1;
          // columnsItem[j]={
          //   title: 'Date', widthPx:80, dataIndex: 'Date' 
          //   }
          j=j+1;
          columnsItem[j]={
            title: 'Total', widthPx:80, dataIndex: 'TotalSupply' , style: {font: {bold: true}}
          }
          // j=j+1;
          // columnsItem[j]={
          //   title: 'Total Subscription', widthPx:80, dataIndex: 'TotalSubscription' 
          // }
          // j=j+1;
          // columnsItem[j]={
          //   title: 'Total Return', widthPx:80, dataIndex: 'TotalReturn' 

        let orgnllength=Total_transactions.length;
let lentth=Total_transactions.length;
lentth=lentth+5;

let totalsupplyCopies=[];
let totalsrCopies=[];
let totalretnCopies=[];
let Total_Supply_Copiesall=0
let Amount_Paid=0
let Amount_Paid_Bank=0
let v=0;
for(let s=0; s<lentth; s++)
{
  let ExelData = {
    "Date": supply_date,
    "Center": CenterName,
    "Vendor": "",
    "Cash":"",
    "Bank":"",
   }
 // ExelData.push(Total_transactions[s].Vendor_Code)
if(s<orgnllength)
{
  if(s==2)
  {
    console.log(Total_transactions[s].Vendor_Name)
    console.log(Total_transactions[s].Approved)
  }
  if( Total_transactions[s].Approved == 'Approved' || Total_transactions[s].Approved == 'Waiting' ||Total_transactions[s].Approved =='Billed')
    {
  ExelData["Vendor"]=Total_transactions[s].Vendor_Name

if(Total_transactions[s].Paid_Source=="Cash")
{
  ExelData["Cash"]="Rs."+Total_transactions[s].Amount_Paid
 
  Amount_Paid=Amount_Paid+parseFloat(Total_transactions[s].Amount_Paid==undefined|| Total_transactions[s].Amount_Paid==""?0:Total_transactions[s].Amount_Paid)

}
else if(Total_transactions[s].Paid_Source=="Cheque" || Total_transactions[s].Paid_Source=="PhonePe" || Total_transactions[s].Paid_Source=="GooglePay" || Total_transactions[s].Paid_Source=="UPI" || Total_transactions[s].Paid_Source=="Bank" ){
  ExelData["Bank"]="Rs."+Total_transactions[s].Amount_Paid
 
  Amount_Paid_Bank=Amount_Paid_Bank+parseFloat(Total_transactions[s].Amount_Paid==undefined|| Total_transactions[s].Amount_Paid==""?0:Total_transactions[s].Amount_Paid)

}
else{
  ExelData["Cash"]="Rs."+Total_transactions[s].Amount_Paid
 
  Amount_Paid=Amount_Paid+parseFloat(Total_transactions[s].Amount_Paid==undefined|| Total_transactions[s].Amount_Paid==""?0:Total_transactions[s].Amount_Paid)

}
  for(let b=0; b<Total_transactions[s].transaction.length; b++)
  {
  
  
    for(let i=0; i<items.length; i++)
    {
  if(Total_transactions[s].transaction[b].id==items[i].id)
  {
    k=k+1;
    // ExelData[items[i].id+"Supply"] = items[i].FormalName;
    // ExelData[items[i].id+"SC"] = items[i].FormalName;
    // ExelData[items[i].id+"Return"] = items[i].FormalName;
//   let itemSupply=items.id+"Supply"
// let str=itemSupply+": "+Total_transactions[s].transaction[b].Supply_Copies
//     Object.assign(ExelData, str);

ExelData[items[i].id+"Supply"]=Total_transactions[s].transaction[b].Supply_Copies
  //  ExelData[items[i].id+"Supply"]=Total_transactions[s].transaction[b].Supply_Copies
if(totalsupplyCopies[i]!=undefined&&totalsupplyCopies[i]!="" && totalsupplyCopies[i]>0)
{

}else{
  totalsupplyCopies[i]=0;
}
  

    totalsupplyCopies[i]=parseInt(totalsupplyCopies[i])+parseInt(Total_transactions[s].transaction[b].Supply_Copies)


//SCSCSCSCSCSCSCSC
    
  //  ExelData[items[i].id+"SC"]=Total_transactions[s].transaction[b].Subscription_Copies==undefined?0:Total_transactions[s].transaction[b].Subscription_Copies
    
    if(totalsrCopies[i]!=undefined&&totalsrCopies[i]!="" && totalsrCopies[i]>0)
{

}else{
  totalsrCopies[i]=0;
}
      
    totalsrCopies[i]=parseInt(totalsrCopies[i])+parseInt(Total_transactions[s].transaction[b].Subscription_Copies==undefined?0:Total_transactions[s].transaction[b].Subscription_Copies)
   


    //Return

//     ExelData[items[i].id+"Return"]=Total_transactions[s].transaction[b].Return_Copies


      
//     if(totalretnCopies[i]!=undefined&&totalretnCopies[i]!="" && totalretnCopies[i]>0)
// {

// }else{
//   totalretnCopies[i]=0;
// }
  
//     totalretnCopies[i]=parseInt(totalretnCopies[i])+parseInt(Total_transactions[s].transaction[b].Return_Copies)

    
  }
 // }
}
}

ExelData["TotalSupply"]=Total_transactions[s].Total_Supply_Copies

  
Total_Supply_Copiesall=parseInt(Total_Supply_Copiesall)+parseInt(Total_transactions[s].Total_Supply_Copies)



ExelData["TotalSubscription"]=Total_transactions[s].Total_Subscription_Copies==undefined?0:Total_transactions[s].Total_Subscription_Copies

//totalsrCopies=Total_transactions[s].Total_Subscription_Copies==undefined?0:parseInt(totalsrCopies)+parseInt(Total_transactions[s].Total_Subscription_Copies)



ExelData["TotalReturn"]=Total_transactions[s].Total_Return_Copies
}
}

else{

  let Alldiff=0

if(v==0)
{
    ExelData["Date"]=""

    ExelData["Center"]=""
    


  ExelData[items[0].id+"Supply"]=""
  //ExelData[items[0].id+"SC"]=""
    // ExelData[items[0].id+"Return"]=""
    ExelData["TotalSupply"]=""
 ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}

if(v==1)
{
    ExelData["Date"]=""

    ExelData["Center"]=""
    ExelData["Vendor"]="Total"
    ExelData["Cash"]= "Rs."+ parseFloat(Amount_Paid).toFixed(2)
    ExelData["Bank"]= "Rs."+ parseFloat(Amount_Paid_Bank).toFixed(2)
    
    for(let i=0; i<items.length; i++)
    {
  ExelData[items[i].id+"Supply"]=totalsupplyCopies[i]
    // ExelData[items[i].id+"SC"]=totalsrCopies[i]
    // ExelData[items[i].id+"Return"]=totalretnCopies[i]

    }

    ExelData["TotalSupply"]=Total_Supply_Copiesall
 ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}

if(v==2)
{
    ExelData["Date"]=""

    ExelData["Center"]=""
    ExelData["Vendor"]="UnSold Copies"
    
let supplyitem=response.response
// console.log(supplyitem)
// console.log(supplyitem[0].TotalSuppy)
for(let j=0; j<supplyitem.length; j++)
{
    for(let i=0; i<items.length; i++)
    {
      if(supplyitem[j].id==items[i].id)
      {
  ExelData[items[i].id+"Supply"]=supplyitem[j].UnsoldCopies
    // ExelData[items[i].id+"SC"]=""
    // ExelData[items[i].id+"Return"]=""
      }
    }
  }

    ExelData["TotalSupply"]=response.TotalSuppy[0].TotalUnsold
 ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}


if(v==3)
{
  let supplyitem=response.response
    ExelData["Date"]=""

    ExelData["Center"]=""
    ExelData["Vendor"]="Center Supply"
    
    for(let j=0; j<supplyitem.length; j++)
    {
        for(let i=0; i<items.length; i++)
        {
          if(supplyitem[j].id==items[i].id)
          {
      ExelData[items[i].id+"Supply"]=supplyitem[j].TotalSuppy
        // ExelData[items[i].id+"SC"]=""
        // ExelData[items[i].id+"Return"]=""
          }
        }
      }

    

    ExelData["TotalSupply"]=response.TotalSuppy[0].TotalCenter
ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}

if(v==4)
{
  let supplyitem=response.response

    ExelData["Date"]=""

    ExelData["Center"]=""
    ExelData["Vendor"]="Difference"
    
    for(let j=0; j<supplyitem.length; j++)
    {
        for(let i=0; i<items.length; i++)
        {
          if(supplyitem[j].id==items[i].id)
          {
            ExelData[items[i].id+"Supply"]=(parseInt(supplyitem[j].TotalSuppy)-(parseInt(supplyitem[j].UnsoldCopies) + parseInt(totalsupplyCopies[i]))).toString()
            
            if(isNaN(ExelData[items[i].id+"Supply"])==false)
            {
            Alldiff=Alldiff+(parseInt(supplyitem[j].TotalSuppy)-(parseInt(supplyitem[j].UnsoldCopies) + parseInt(totalsupplyCopies[i])))
            }
        // ExelData[items[i].id+"SC"]=""
        // ExelData[items[i].id+"Return"]=""
          }
        }
      }

      ExelData["TotalSupply"]=Alldiff

   ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}
v=v+1;
}


ExelDatas.push(ExelData)

}


//ExelDatas.push({})

//console.log(ExelDatas)

          this.setState({items:items,ExelHeader:columnsItem,ExelData:ExelDatas})
  
        }
  
  
  
        }

          
        var itemsnext= CETERBOOKRES.Item

        let pricebydate=response.Daily_Item_Price

        let preitem=[]
        
        if(itemsnext!=undefined)
        {
          itemsnext.map(function(datapric)
         {
          pricebydate.map(function(pricebydate)
          {
            if(pricebydate.ItemId==datapric.id)
            {
              datapric.Price=pricebydate.Selling_Price
            }
          preitem.push(datapric)
          })
         })
         if(preitem!=undefined && preitem.length>0)
         {
          itemsnext=preitem;
         }
         
        
        }
        if(itemsnext==undefined)
        {
          itemsnext=CETERBOOKRES[1]
          itemsnext=itemsnext.toString().replace('\\', '')
          itemsnext=JSON.parse(itemsnext)
        }

        
     
                this.FeatchItem(Total_transactions, itemsnext,supply_date,Subscriptionresponse,pricebydate )
         this.calctotals()
         if(TotalCenterSupply==undefined)
         {
           TotalCenterSupply=0
         }
         this.setState({ TotalCenterSupply:TotalCenterSupply,loading: true ,TotalUnsold:TotalUnsold})

         this.setState({CETERBOOKRESVendor:2})
if(Total_transactions.length>0)
{
this.setState({CETERBOOKRESVendor:1})
}else
{
  this.setState({CETERBOOKRESVendor:0})
}
         //   //console.log(Total_transactions)
        })
      })

      })
    
      .then(result => console.log(result))
      .catch(error =>  { this.ErrorhandelAPI(error.message, "CETERBOOKRESVendor-API"  )});

  }
  getreturnprice ()
  {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
      


        
        
        let date = new Date();
        date.setDate(date.getDate() -1);
     //  alert(date)
       urlencoded.append("Selling_Day", formatDate(date, 'dddd'));
       
        var GETCETERBOOK = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };




        
        fetch(ApiConstants.ReturnItemswithPrice, GETCETERBOOK)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(ReturnItemswithPrice => {
       //   console.log(ReturnItemswithPrice.Item)
          this.setState({
            ReturnItemswithPrice:ReturnItemswithPrice.Item
          })
        })
      
  }
  componentDidMount() {

  

    try {

    typeweek=0;
    this.setState({ loading: false })

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

  

    var VENDOR = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };







this.getreturnprice();



    fetch(ApiConstants.VIEWCENTER, VENDOR)
      .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(Centersresponse => {

      //  alert(JSON.stringify(Centersresponse))
        if(Centersresponse.data[0]!=undefined)
{

  this. VENDORSBYCENTER(Centersresponse.data[0].id);
this.setState({CenterId:Centersresponse.data[0].id,Centers:Centersresponse.data})
        var urlencoded = new URLSearchParams();
      


        urlencoded.append("CenterId", Centersresponse.data[0].id);
        urlencoded.append("supply_date", formatDate(new Date(), 'DD-MM-YYYY'));
        urlencoded.append("Selling_Day", formatDate(new Date(), 'dddd'));
        
        let date = new Date();
        date.setDate(date.getDate() -7);
     // alert(date)
       urlencoded.append("weeklast_date", formatDate(date, 'DD-MM-YYYY'));
       
        var GETCETERBOOK = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };




        
        fetch(ApiConstants.GETCETERBOOK, GETCETERBOOK)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(CETERBOOKRES => {
        //  alert(JSON.stringify(CETERBOOKRES.Vendor[0]))
//console.log(JSON.stringify(CETERBOOKRES))
         // //console.log(CETERBOOKRES.TotalCenter!=undefined)
        //  if(CETERBOOKRES.Vendor[0]!=undefined )
        //  {
        //    if(CETERBOOKRES.Item[0]!=undefined)
        //  {
         var myHeaders = new Headers();
         myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
         myHeaders.append("Origin", "application/x-www-form-urlencoded");
         var urlencoded = new URLSearchParams();
        
         urlencoded.append("CenterId", Centersresponse.data[0].id);
        
         urlencoded.append("Supply_date", formatDate(new Date(), 'DD-MM-YYYY'));
        // alert(date)
         var CENTERGETSUPPLY = {
           method: 'POST',
           headers: myHeaders,
           body: urlencoded,
           redirect: 'follow'
         };
         
         fetch(ApiConstants.CENTERGETSUPPLY, CENTERGETSUPPLY)
         .then(dataWrappedByPromise => dataWrappedByPromise.json())
           .then(response => 
            {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("Origin", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
           
            urlencoded.append("center_id",Centersresponse.data[0].id);
           
            let subscribebyCenter_id = {
              method: 'POST',
              headers: myHeaders,
              body: urlencoded,
              redirect: 'follow'
            };
            
            fetch(ApiConstants.subscribebyCenter_id, subscribebyCenter_id)
            .then(dataWrappedByPromise => dataWrappedByPromise.json())
              .then(Subscriptionresponse => 
        
    
    
     {
       try{
     let  TotalCenterSupply =response.TotalSuppy[0].TotalCenter
     let  TotalUnsold =response.TotalSuppy[0].TotalUnsold
if(TotalCenterSupply==undefined)
{
  TotalCenterSupply=0
}
         this.setState({CETERBOOKRESVendor:2,TotalUnsold:TotalUnsold,TotalCenterSupply:TotalCenterSupply})
       
          if(CETERBOOKRES.TotalCenter!=undefined || CETERBOOKRES.TotalCenter!=null)
          {
          this.setState({TotalCenter:CETERBOOKRES.TotalCenter[0].TotalCenter})
          if(CETERBOOKRES.TotalCenter[0].TotalCenter==null)
          {
            this.setState({TotalCenter:0})
          }
        }else{

          this.setState({TotalCenter:0})
        }
        let Total_transactions=[];
let item=[];

        if(CETERBOOKRES.type==1)
        {
 
var si=0;
item=CETERBOOKRES.item;

           Total_transactions = CETERBOOKRES.Vendor.map(function (data) {
        
            let transaction = CETERBOOKRES.Item.map(function (data) {
              si=si+1;
            
if(data.NonSalesDays!=undefined &&  data.NonSalesDays.indexOf(formatDate(new Date(), 'DD-MM-YYYY'))<0)
{
  
              return {
             id:data.id,
                Item:data.FormalName,
                Price:data.Price,
                Supply_Copies:0,
                Sold_Copies:0,
                Total:'Rs. 0',
         
                Return_Copies:0,
                ReturnPrice:0,
                ReturnTotal:"Rs. 0",
                Subscription_Copies:0,
            
              }


            }

            });

            transaction = transaction.filter(function( element ) {
              return element !== undefined;
           });
         //   si=si+1;
            return {
           
              id:data.id,
              Vendor_Code:data.Vendor_Code,
              Total_Items:transaction.length,
              Total:'Rs. 0',
              Vendor_Name:data.Vendor_Name,
              Total_Supply_Copies:0,
              Total_Return_Copies:0,
              Total_Sold_Copies:0,
              Amount_Paid:0,
              Paid_Source:"Cash",
             Total_Subscription_Copies:0,
              User:localStorage.getItem('username'),
              Approved:  'New',
              transaction:transaction,
              Fld1:data.Fld1,
              ReturnTotalAll:"0"
            }
          });

         
        }
        
        else{

          var typeweeks=CETERBOOKRES[2]
          if(typeweeks!=undefined)
            {
            typeweeks=typeweeks.toString().replace('\\', '')
            typeweeks=JSON.parse(typeweeks)
            
            //  console.log(JSON.stringify(typeweek.typeweek))
            typeweek=typeweeks.typeweek
            }


         
          Total_transactions=CETERBOOKRES[0].All_JSON_Date
          
        Total_transactions=Total_transactions.toString().replace('\\', '')
        Total_transactions=JSON.parse(Total_transactions)


        }
            

        if( Total_transactions[0].Approved == 'Approved' || Total_transactions[0].Approved == 'Waiting' ||Total_transactions[0].Approved =='Billed' && typeweek != 2)
        {


         let    ExelDatas=[]


      
       var e = document.getElementById("CentersSel");
       var value = e.options[e.selectedIndex].value;
       var text = e.options[e.selectedIndex].text;

       let CenterName=text
if(CenterName==undefined)
{
 CenterName=this.state.Centers[0].Name
}this.setState({CenterName:CenterName})
//alert(CenterName)
         let items=CETERBOOKRES[1]
         items=items.toString().replace('\\', '')
         items=JSON.parse(items)
         items=items.items;

         
         let j=0; let k=3;
         columnsItem[j]={
           title: 'Date', widthPx:80, dataIndex: 'Date' 
           }
           j=j+1;
         columnsItem[j]={
         title: 'Center', widthPx:80, dataIndex: 'Center' 
         }
         j=j+1;
         columnsItem[j]={
           title: 'Vendor', width: {wpx: 80}, dataIndex: 'Vendor' 
           }
         j=j+1;
         columnsItem[j]={
           title: 'Cash', widthPx:80, dataIndex: 'Cash' 
           }

           j=j+1;
           columnsItem[j]={
            title: 'Bank', widthPx:80, dataIndex: 'Bank' 
            }
         j=j+1;
         for(let i=0; i<items.length; i++)
         {
         
          
           j=j+1;
         columnsItem[j]={
      
           title: items[i].FormalName, widthPx:80, dataIndex: items[i].id+"Supply"
           }
       
    
         

         }
       
         j=j+1;
         columnsItem[j]={
           title: 'Total', widthPx:80, dataIndex: 'TotalSupply' 
         }
       
       let orgnllength=Total_transactions.length;
let lentth=Total_transactions.length;
lentth=lentth+5;

let totalsupplyCopies=[];
let totalsrCopies=[];
let totalretnCopies=[];
let Total_Supply_Copiesall=0
let Amount_Paid=0
let Amount_Paid_Bank=0
let v=0;
for(let s=0; s<lentth; s++)
{
 let ExelData = {
 "Date": formatDate(new Date(), 'DD-MM-YYYY'),
   "Center": CenterName,
   "Vendor": "",
   "Cash":"",
   "Bank":"",
  }
// ExelData.push(Total_transactions[s].Vendor_Code)
if(s<orgnllength)
{
 ExelData["Vendor"]=Total_transactions[s].Vendor_Name
 if(Total_transactions[s].Paid_Source=="Cash")
 {
   ExelData["Cash"]="Rs."+Total_transactions[s].Amount_Paid
  
   Amount_Paid=Amount_Paid+parseFloat(Total_transactions[s].Amount_Paid==undefined?0:Total_transactions[s].Amount_Paid)
 
 }else{
   ExelData["Bank"]="Rs."+Total_transactions[s].Amount_Paid
  
   Amount_Paid_Bank=Amount_Paid_Bank+parseFloat(Total_transactions[s].Amount_Paid==undefined?0:Total_transactions[s].Amount_Paid)
 
 }
 for(let b=0; b<Total_transactions[s].transaction.length; b++)
 {

   for(let i=0; i<items.length; i++)
   {
 if(Total_transactions[s].transaction[b].id==items[i].id)
 {
   k=k+1;
   // ExelData[items[i].id+"Supply"] = items[i].FormalName;
   // ExelData[items[i].id+"SC"] = items[i].FormalName;
   // ExelData[items[i].id+"Return"] = items[i].FormalName;
//   let itemSupply=items.id+"Supply"
// let str=itemSupply+": "+Total_transactions[s].transaction[b].Supply_Copies
//     Object.assign(ExelData, str);

ExelData[items[i].id+"Supply"]=Total_transactions[s].transaction[b].Supply_Copies
 //  ExelData[items[i].id+"Supply"]=Total_transactions[s].transaction[b].Supply_Copies
if(totalsupplyCopies[i]!=undefined&&totalsupplyCopies[i]!="" && totalsupplyCopies[i]>0)
{

}else{
 totalsupplyCopies[i]=0;
}
 

   totalsupplyCopies[i]=parseInt(totalsupplyCopies[i])+parseInt(Total_transactions[s].transaction[b].Supply_Copies)


//SCSCSCSCSCSCSCSC
   
  // ExelData[items[i].id+"SC"]=Total_transactions[s].transaction[b].Subscription_Copies==undefined?0:Total_transactions[s].transaction[b].Subscription_Copies
   
    if(totalsrCopies[i]!=undefined&&totalsrCopies[i]!="" && totalsrCopies[i]>0)
{

}else{
  totalsrCopies[i]=0;
}
     
    totalsrCopies[i]=parseInt(totalsrCopies[i])+parseInt(Total_transactions[s].transaction[b].Subscription_Copies==undefined?0:Total_transactions[s].transaction[b].Subscription_Copies)
  


   //Return

//     ExelData[items[i].id+"Return"]=Total_transactions[s].transaction[b].Return_Copies


     
//     if(totalretnCopies[i]!=undefined&&totalretnCopies[i]!="" && totalretnCopies[i]>0)
// {

// }else{
//   totalretnCopies[i]=0;
// }
 
//     totalretnCopies[i]=parseInt(totalretnCopies[i])+parseInt(Total_transactions[s].transaction[b].Return_Copies)

   
 }
 }
}

ExelData["TotalSupply"]=Total_transactions[s].Total_Supply_Copies

 
Total_Supply_Copiesall=parseInt(Total_Supply_Copiesall)+parseInt(Total_transactions[s].Total_Supply_Copies)



ExelData["TotalSubscription"]=Total_transactions[s].Total_Subscription_Copies==undefined?0:Total_transactions[s].Total_Subscription_Copies
ExelData["TotalReturn"]=Total_transactions[s].Total_Return_Copies
}else{



if(v==0)
{
   ExelData["Date"]=""

   ExelData["Center"]=""
   
   

 ExelData[items[0].id+"Supply"]=""
   // ExelData[items[0].id+"SC"]=""
   // ExelData[items[0].id+"Return"]=""
   ExelData["TotalSupply"]=""
 ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}

if(v==1)
{
   ExelData["Date"]=""

   ExelData["Center"]=""
   ExelData["Vendor"]="Total"
   ExelData["Cash"]= "Rs."+ parseFloat(Amount_Paid).toFixed(2)
   ExelData["Bank"]= "Rs."+ parseFloat(Amount_Paid_Bank).toFixed(2)
   for(let i=0; i<items.length; i++)
   {
    if(totalsupplyCopies[i]==undefined)
    {
      totalsupplyCopies[i]=0
    }
 ExelData[items[i].id+"Supply"]=totalsupplyCopies[i]
  // ExelData[items[i].id+"SC"]=totalsrCopies[i]
   // ExelData[items[i].id+"Return"]=totalretnCopies[i]

   }

   ExelData["TotalSupply"]=Total_Supply_Copiesall
 ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}

if(v==2)
{
   ExelData["Date"]=""

   ExelData["Center"]=""
   ExelData["Vendor"]="UnSold Copies"
   
let supplyitem=response.response
console.log(response)
// console.log(supplyitem[0].TotalSuppy)
for(let j=0; j<supplyitem.length; j++)
{
   for(let i=0; i<items.length; i++)
   {
     if(supplyitem[j].id==items[i].id)
     {
 ExelData[items[i].id+"Supply"]=supplyitem[j].UnsoldCopies
   // ExelData[items[i].id+"SC"]=""
   // ExelData[items[i].id+"Return"]=""
     }
   }
 }

   ExelData["TotalSupply"]=response.TotalSuppy[0].TotalUnsold
ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}


if(v==3)
{
 let supplyitem=response.response
   ExelData["Date"]=""

   ExelData["Center"]=""
   ExelData["Vendor"]="Center Supply"
   
   for(let j=0; j<supplyitem.length; j++)
   {
       for(let i=0; i<items.length; i++)
       {
         if(supplyitem[j].id==items[i].id)
         {
     ExelData[items[i].id+"Supply"]=supplyitem[j].TotalSuppy
       // ExelData[items[i].id+"SC"]=""
       // ExelData[items[i].id+"Return"]=""
         }
       }
     }

   

   ExelData["TotalSupply"]=response.TotalSuppy[0].TotalCenter
 ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}

if(v==4)
{
 let supplyitem=response.response

   ExelData["Date"]=""

   ExelData["Center"]=""
   ExelData["Vendor"]="Difference"
   let Alldiff=0;
   for(let j=0; j<supplyitem.length; j++)
   {
       for(let i=0; i<items.length; i++)
       {
         if(supplyitem[j].id==items[i].id)
         {
           ExelData[items[i].id+"Supply"]=(parseInt(supplyitem[j].TotalSuppy)-(parseInt(supplyitem[j].UnsoldCopies) + parseInt(totalsupplyCopies[i]))).toString()
   
           if(isNaN(ExelData[items[i].id+"Supply"])==false)
           {
           Alldiff=Alldiff+(parseInt(supplyitem[j].TotalSuppy)-(parseInt(supplyitem[j].UnsoldCopies) + parseInt(totalsupplyCopies[i])))
           }
           // ExelData[items[i].id+"SC"]=""
       // ExelData[items[i].id+"Return"]=""
         }
       }
     }

   

   //ExelData["TotalSupply"]=(parseInt(response.TotalSuppy[0].TotalCenter)-parseInt(response.TotalSuppy[0].TotalUnsold)).toString() 
   ExelData["TotalSupply"]=Alldiff
 
  
   ExelData["TotalSubscription"]=""
// ExelData["TotalReturn"]=""
}
v=v+1;
}
ExelDatas.push(ExelData)

}


//ExelDatas.push({})

//console.log(ExelDatas)

         this.setState({items:items,ExelHeader:columnsItem,ExelData:ExelDatas})
 
       }
        //alert(response.message)
        item=CETERBOOKRES.Item;
       
 let pricebydate=response.Daily_Item_Price

let preitem=[]

if(item!=undefined)
{
 item.map(function(datapric)
 {
  pricebydate.map(function(pricebydate)
  {
    if(pricebydate.ItemId==datapric.id)
    {
      datapric.Price=pricebydate.Selling_Price
    }
  preitem.push(datapric)
  })
 })
 if(preitem!=undefined && preitem.length>0)
 {
  item=preitem;
 }


}
var itemsnext= item
if(itemsnext==undefined)
{
  itemsnext=CETERBOOKRES[1]

  //console.log(itemsnext)
  itemsnext=itemsnext.toString().replace('\\', '')
  itemsnext=JSON.parse(itemsnext)
}
//alert(itemsnext)
     
                this.FeatchItem(Total_transactions, itemsnext,formatDate(new Date(), 'DD-MM-YYYY'),Subscriptionresponse,pricebydate)
        

       //  //console.log(JSON.stringify(Total_transactions.transaction))
        // this.setState({    

        // Total_transactions:Total_transactions
        // })
        this.calctotals()
        this.setState({ loading: true })
    //console.log(Total_transactions)


         
         this.setState({CETERBOOKRESVendor:2})
if(Total_transactions.length>0)
{
this.setState({CETERBOOKRESVendor:1})
}else
{
  this.setState({CETERBOOKRESVendor:0})
}
       }
       catch (err) {
        //console.error(err.message);
        this.ErrorhandelAPI(err.message, "Total_transactions"  )
      }
      })
      .then(result => console.log(result))
    .catch(error =>  { this.ErrorhandelAPI(error.message, "ApiConstants.CENTERGETSUPPLY"  )});
      })
      .then(result => console.log(result))
      .catch(error =>  { this.ErrorhandelAPI(error.message, "ApiConstants.GETCETERBOOK"  )});



    // }else{
    //   alert("No Items available. Add Items Now")
    //   window.location='/#/Items'

    // }
  // }else{
  //   alert("No Vendors are available. Add Vendors Now")
  //   window.location='/#/Vendors'

  // }

    })
    
      // .then(result => console.log(result))
      // .catch(error =>  { this.ErrorhandelAPI(error.message, "ApiConstants.VIEWCENTER"  )});

    }

    else{
    alert("No centers are available. Add Center Now")
    window.location='/#/AddCenter'
      this.setState({ loading: true })
    }

    })
  }
 catch (err) {
  //console.error(err.message);
  this.ErrorhandelAPI(err.message, "componentDidMount"  )
}
  }
  VENDORSBYCENTER(CenterId)
  {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
      


    urlencoded.append("CenterID", CenterId);

   
    var VENDOR = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(ApiConstants.VENDORSBYCENTER, VENDOR)
      .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(responsejson => {

        let VendorsItem = responsejson.response;
     
        this.setState({VendorsItem:VendorsItem})
      })
    }
  

  ErrorhandelAPI(message, Location  )
  {
    var templateParams = {
      message: message,
      location :Location 
      
  };
  emailjs.send("service_1kxut7s","template_we1hl6h",templateParams,"user_KcGcs7Qc4Bh1Q6VDi40xL");
  
this.setState({
  ErrorReturn:"Y",
  ErrorhandelAPIMessage:message,
  Location:Location,
})


  }
  SavetoJsonCal(colIndex,SendToServer,Approved)
  { 
    

    try{
    this.setState({ loading: false })
    var Total_transactions=this.state.Total_transactions
    var Total_Supply_Copies = 0;
    var Total_Return_Copies = 0;
    var Total_Sold_Copies = 0;
    var Totaltop = 0;
    var Total_Subscription_Copies=0;


    var ReturnTotalrsFloatTotal=0;
    var ReturnItemswithPrice=this.state.ReturnItemswithPrice;
//var SendToServer=0;
for (let k = 0; k< Total_transactions[colIndex].transaction.length; k++) {
  for (let j = 0; j < ReturnItemswithPrice.length; j++) {
if(Total_transactions[colIndex].transaction[k].id==ReturnItemswithPrice[j].id)
{
 Total_transactions[colIndex].transaction[k].ReturnPrice=ReturnItemswithPrice[j].Price;


}


}

}

//var SendToServer=0;
    for (var i = 0; i < Total_transactions[colIndex].transaction.length; i++) {


if (isNaN(Total_transactions[colIndex].transaction[i].Supply_Copies)==false && isNaN(Total_transactions[colIndex].transaction[i].Return_Copies)==false)
{
  var Return_Copies= parseFloat(Total_transactions[colIndex].transaction[i].Return_Copies)

      var Sold_Copies = parseFloat(Total_transactions[colIndex].transaction[i].Supply_Copies) 
      
    


      
      var Total=0;
    if(Total_transactions[colIndex].transaction[i].Price!="" && Total_transactions[colIndex].transaction[i].Price!=null)
    {
       Total = 'Rs. ' + (parseFloat(Sold_Copies) * parseFloat(Total_transactions[colIndex].transaction[i].Price)).toFixed(2) 
     
    }

    var ReturnTotal=0;


    if(Total_transactions[colIndex].transaction[i].ReturnPrice!="" && Total_transactions[colIndex].transaction[i].ReturnPrice!=null)
      {
        ReturnTotal = 'Rs. ' + (parseFloat(Return_Copies) * parseFloat(Total_transactions[colIndex].transaction[i].ReturnPrice)).toFixed(2) 
       
      }


    
        Total_transactions[colIndex].transaction[i].Sold_Copies = Sold_Copies;
        Total_transactions[colIndex].transaction[i].Total = Total;
        Total_transactions[colIndex].transaction[i].ReturnTotal = ReturnTotal;
  // Total_transactions[colIndex].transaction[i].Total_Subscription_Copies = Total_Subscription_Copies;
        

//console.log(Total_transactions[colIndex].transaction[i].Approved)

      Total_Sold_Copies = (Total_Sold_Copies + parseFloat(Total_transactions[colIndex].transaction[i].Sold_Copies));
      Total_Return_Copies = Total_Return_Copies + parseFloat(Total_transactions[colIndex].transaction[i].Return_Copies);
      Total_Supply_Copies = Total_Supply_Copies + parseFloat(Total_transactions[colIndex].transaction[i].Supply_Copies);
      Total_Subscription_Copies=Total_Subscription_Copies+ parseFloat(Total_transactions[colIndex].transaction[i].Subscription_Copies);
      let Totalrs=Total_transactions[colIndex].transaction[i].Total.toString().replace("Rs.", "")
      // let conTotal=parseFloat(Totalrs)
      // Totaltop = Totaltop+conTotal;
      
      let ReturnTotalrs=Total_transactions[colIndex].transaction[i].ReturnTotal.toString().replace("Rs.", "")
     
      let conTotal=parseFloat(Totalrs)
      Totaltop = parseFloat(Totaltop)+parseFloat(conTotal);
    
     Totaltop = parseFloat(Totaltop).toFixed(2)

     
     let ReturnTotalrsFloat=parseFloat(ReturnTotalrs)
     ReturnTotalrsFloatTotal = parseFloat(ReturnTotalrsFloatTotal)+parseFloat(ReturnTotalrsFloat);
   
     ReturnTotalrsFloatTotal = parseFloat(ReturnTotalrsFloatTotal).toFixed(2)
   //  alert(Totaltop)


}
else{
  alert("Supply/Return Copies should be numeric for Item : "+Total_transactions[colIndex].transaction[i].Item+" Item Code : "+Total_transactions[colIndex].transaction[i].id)
  SendToServer=1
  break;
}
    }
    //alert(Totaltop)
    Total_transactions[colIndex].Total_Supply_Copies = Total_Supply_Copies;
    Total_transactions[colIndex].Total_Return_Copies = Total_Return_Copies;
    Total_transactions[colIndex].Total_Sold_Copies = Total_Sold_Copies;
  Total_transactions[colIndex].Total_Subscription_Copies = Total_Subscription_Copies;
    
    Total_transactions[colIndex].Total = Totaltop;

    if(Total_transactions[colIndex].Approved!='Billed')
    {
      console.log(Total_transactions[colIndex].transaction[0].Pre_Status)
      if(Total_transactions[colIndex].transaction[0].Pre_Status=='Pre-Billed')
      {
        Total_transactions[colIndex].Approved = "Billed";
      }else{
        Total_transactions[colIndex].Approved = Approved;
      }
   
    }

    Total_transactions[colIndex].ReturnTotalAll = ReturnTotalrsFloatTotal;

for(let t=0; t<Total_transactions.length; t++)
{

    if(Total_transactions[t].Fld1[0].id==undefined)
    {
     Total_transactions[t].Fld1=JSON.parse(Total_transactions[t].Fld1)
    }
  }
   // const product = [...Total_transactions]
    this.setState({ Total_transactions: Total_transactions })

   // //console.log(JSON.stringify(Total_transactions))

if(SendToServer==0)
{
    this.savetojson()
}
 


}
  catch (err) {
    //console.error(err.message);
    this.ErrorhandelAPI(err.message, "SavetoJsonCal"  )
  }
  }

  SavetoJsonCalAll(Approved)
  {
  
try{
    let SendToServer=2;
    for (var i = 0; i < this.state.Total_transactions.length; i++) {

      if(((this.state.Total_transactions.length-1))==i)
      {
        SendToServer=0
      }
      
    this.SavetoJsonCal(i,SendToServer,Approved)


   }
  }
  catch (err) {
    //console.error(err.message);
    this.ErrorhandelAPI(err.message, "SavetoJsonCalAll"  )
  }
  }

  FeatchItem(Total_transactions,item,supply_date_field,Subscriptionresponse,pricebydate)
  {
  

    
// Total_transactions=Total_transactions.toString().replace('\\', '')

// Total_transactions=JSON.parse(Total_transactions)
//console.log("---------->"+JSON.stringify(item))
//alert(item.items.length)
let transactionnew=[];
let transactionnews=[]; 


if(Total_transactions.length>=1)
{
 
   if(Total_transactions!=undefined && Total_transactions.length>1&& Total_transactions[0].transaction.length>=1)

   {
    if(item.items!=undefined )

    {
 
  
for(var i=0; i<item.items.length; i++)
{
  
 var v=0;
    var v1=0;
  //  //console.log(JSON.stringify(Total_transactions[0].transaction[0].Item))
Total_transactions[0].transaction.filter(function (transactions) {

  v=v+1
  //transactions.push({ NonSalesDays:item.items[i].NonSalesDays})
  if(item.items[i].FormalName == transactions.Item)
  {

    
v1=v1+1;
  }
});



//console.log(Total_transactions[0].transaction)

// if(v1==0)
// {

         


//      transactionnew =  {
//       id:item.items[i].id,
//       NonSalesDays:item.items[i].NonSalesDays,
//         Item:item.items[i].FormalName,
//         Price:item.items[i].Price,
//         Supply_Copies:0,
//         Return_Copies:0,
//         Sold_Copies:0,
//         Total:'Rs. 0'
//       }
  
//       transactionnews.push(transactionnew)
    
//  //   si=si+1;
 

// }


}

for(var i=0; i<Total_transactions.length; i++)
{
 for(var j=0; j<transactionnews.length; j++)
 {

 
  Total_transactions[i].transaction.push(transactionnews[j])

 }
}


    }



}else{
  var si=1;

  let preitem=[]
  if(item!=undefined&& pricebydate.length>=1)
{

item.map(function(datapric)
{
pricebydate.map(function(pricebydate)
{
  if(pricebydate.ItemId==datapric.id)
  {
    datapric.Price=pricebydate.Selling_Price
  }
preitem.push(datapric)
})
})


if(preitem!=undefined && preitem.length>0)
 {
  item=preitem;
 }
}

  for(var i=0; i<item.length; i++)
  {
    
   
  
       transactionnew =  {
      
        id:item[i].id,
        NonSalesDays:item[i].NonSalesDays,
        
          Item:item[i].FormalName,
          Price:item[i].Price,
          Supply_Copies:0,
         
        
          Sold_Copies:0,
          Total:'Rs. 0',
          Return_Copies:0,
          ReturnPrice:0,
          ReturnTotal:"Rs. 0",
          Subscription_Copies:0,
        }
        si=si+1;
        transactionnews.push(transactionnew)
        //console.log(transactionnews)
   //   si=si+1;
      
  
  }
  ////console.log(JSON.stringify(transactionnews))
  for(var i=0; i<Total_transactions.length; i++)
  {

   for(var j=0; j<transactionnews.length; j++)
   {
  
   
    Total_transactions[i].transaction.push(transactionnews[j])

 
  
   }
 
  }

}
}else{


}

let ids=0;



   if(item.items!=undefined )

   {


let allitemnew=item.items;

var Total_transactionsnew = Total_transactions.map(function (data) {


  var transaction =data.transaction.map(function (transaction) {

    //console.log(JSON.stringify(transaction.id))
for(let i=0; i<item.items.length; i++)
{

  if(transaction.id==item.items[i].id)
  {
    //console.log(JSON.stringify(transaction.Item))

              if(allitemnew[i].NonSalesDays.indexOf(supply_date_field)<0)
{
  if( transaction.Price!=undefined && transaction.Price!="" && transaction.Price!=" ")
  {

    
    let Subscription_Copies=0;
    Subscriptionresponse.response.map(function (SubscriptionresponseData) {
  
      if(SubscriptionresponseData.vendor_id==data.Vendor_Code &&transaction.id== SubscriptionresponseData.itemId)
      {
        Subscription_Copies=SubscriptionresponseData.Subscriptionres
      }
    });

              return {
             
               
                id:transaction.id,
                Item:transaction.Item,
                Price:transaction.Price,
                Supply_Copies:transaction.Supply_Copies,
                Sold_Copies:transaction.Sold_Copies,
                Total:transaction.Total,

                Return_Copies:transaction.Return_Copies,

                ReturnPrice:transaction.ReturnPrice,
                ReturnTotal:transaction.ReturnTotal,
               Subscription_Copies:Subscription_Copies,
               PreBilled_Price:transaction.PreBilled_Price==undefined?0:transaction.PreBilled_Price,
               Pre_Status:transaction.Pre_Status==undefined?"None":transaction.Pre_Status,
               PreBilled_Supply_Copies:transaction.PreBilled_Supply_Copies==undefined?0:transaction.PreBilled_Supply_Copies,
               PreBilled_Return_Copies:transaction.PreBilled_Return_Copies==undefined?0:transaction.PreBilled_Return_Copies,
               PreBilled_ReturnPrice:transaction.PreBilled_ReturnPrice==undefined?0:transaction.PreBilled_ReturnPrice,
               PreBilled_ReturnTotal:transaction.PreBilled_ReturnTotal==undefined?0:transaction.PreBilled_ReturnTotal,
               Bliing_Total:transaction.Bliing_Total==undefined?0:transaction.Bliing_Total,
               
              }
            }
    
          }
        }
          }
        
            });
            transaction = transaction.filter(function( element ) {
              return element !== undefined;
           });
          
         //   si=si+1;
            return {
           
              id:data.id,
              Vendor_Code:data.Vendor_Code,
              Total_Items:data.Total_Items,
              Total:data.Total,
              Vendor_Name:data.Vendor_Name,
              Total_Supply_Copies:data.Total_Supply_Copies,
              Total_Return_Copies:data.Total_Return_Copies,
              Total_Sold_Copies:data.Total_Sold_Copies,
              Amount_Paid:data.Amount_Paid,
              Paid_Source:data.Paid_Source,
           Total_Subscription_Copies:data.Total_Subscription_Copies,
              User:data.User,
              Approved:  typeweek==2? 'Pre-week' :data.Approved,
              transaction:transaction,
              Fld1:data.Fld1,
              ReturnTotalAll:data.ReturnTotalAll==undefined?0:data.ReturnTotalAll,
        
              
            }
          });
  ////console.log(Total_transactionsnew)
  let ReturnItemswithPrice=this.state.ReturnItemswithPrice;
  for (let i = 0; i< Total_transactionsnew.length; i++) {
  for (let k = 0; k< Total_transactionsnew[i].transaction.length; k++) {
    for (let j = 0; j < ReturnItemswithPrice.length; j++) {
  if(Total_transactionsnew[i].transaction[k].id==ReturnItemswithPrice[j].id)
  {
    Total_transactionsnew[i].transaction[k].ReturnPrice=ReturnItemswithPrice[j].Price;
   
  }
  pricebydate.map(function(pricebydate)
  {
    if(Total_transactionsnew[i].transaction[k].id==pricebydate.ItemId)
    {
      Total_transactionsnew[i].transaction[k].Price=pricebydate.Selling_Price;
     
    }
  })
  }
}
  }
    this.setState({    

      Total_transactions:Total_transactionsnew,
      getSelected:Total_transactionsnew[0].transaction
      })
    }
    
    
    
    else{
  
 var Total_transactionsnew = Total_transactions.map(function (data) {


  let transaction =data.transaction.map(function (transaction) {
  
   
let Fldvar=JSON.parse(data.Fld1)

    // if(transaction.NonSalesDays.indexOf(supply_date_field)<0)
    // {

if(Fldvar!=null)
{


 let findvar= Fldvar.find(Fldvar=>Fldvar.id==transaction.id)

if(findvar!=undefined)
{

  if( transaction.Price!=undefined && transaction.Price!="" && transaction.Price!=" ")
  {

    let Subscription_Copies=0;
   Subscriptionresponse.response.map(function (SubscriptionresponseData) {
  
      if(SubscriptionresponseData.vendor_id==data.Vendor_Code &&transaction.id== SubscriptionresponseData.itemId)
      {
        Subscription_Copies=SubscriptionresponseData.Subscriptionres
      }
    });
              return {
             
        
                id:transaction.id,
                Item:transaction.Item,
                Price:transaction.Price,
                Supply_Copies:transaction.Supply_Copies,
                Sold_Copies:transaction.Sold_Copies,
                Total:transaction.Total,
                Return_Copies:transaction.Return_Copies,
                ReturnPrice:transaction.ReturnPrice,
                ReturnTotal:transaction.ReturnTotal,
                Subscription_Copies:Subscription_Copies,
                PreBilled_Price:transaction.PreBilled_Price==undefined?0:transaction.PreBilled_Price,
                Pre_Status:transaction.Pre_Status==undefined?"None":transaction.Pre_Status,
                PreBilled_Supply_Copies:transaction.PreBilled_Supply_Copies==undefined?0:transaction.PreBilled_Supply_Copies,
                PreBilled_Return_Copies:transaction.PreBilled_Return_Copies==undefined?0:transaction.PreBilled_Return_Copies,
                PreBilled_ReturnPrice:transaction.PreBilled_ReturnPrice==undefined?0:transaction.PreBilled_ReturnPrice,
                PreBilled_ReturnTotal:transaction.PreBilled_ReturnTotal==undefined?0:transaction.PreBilled_ReturnTotal,
                Bliing_Total:transaction.Bliing_Total==undefined?0:transaction.Bliing_Total,
              
              }
            }
            }
          

            ids=ids+1;

    }
  //}
            });
            
            transaction = transaction.filter(function( element ) {
              return element !== undefined;
           });
          
         //   si=si+1;
            return {
           
              id:data.id,
              Vendor_Code:data.Vendor_Code,
              Total_Items:data.Total_Items,
              Total:data.Total,
              Vendor_Name:data.Vendor_Name,
              Total_Supply_Copies:data.Total_Supply_Copies,
              Total_Return_Copies:data.Total_Return_Copies,
              Total_Sold_Copies:data.Total_Sold_Copies,
             Total_Subscription_Copies:data.Total_Subscription_Copies,
            Amount_Paid:data.Amount_Paid,
            Paid_Source:data.Paid_Source,
              User:data.User,
              Approved:  typeweek==2? 'Pre-week' :data.Approved,
              transaction:transaction,
              Fld1:data.Fld1,
              ReturnTotalAll:data.ReturnTotalAll==undefined?0:data.ReturnTotalAll,
           
            }
          });
  ////console.log(Total_transactionsnew)

  


  let ReturnItemswithPrice=this.state.ReturnItemswithPrice;
  for (let i = 0; i< Total_transactionsnew.length; i++) {
  for (let k = 0; k< Total_transactionsnew[i].transaction.length; k++) {
    for (let j = 0; j < ReturnItemswithPrice.length; j++) {
  if(Total_transactionsnew[i].transaction[k].id==ReturnItemswithPrice[j].id)
  {
    Total_transactionsnew[i].transaction[k].ReturnPrice=ReturnItemswithPrice[j].Price;
   
  }

  pricebydate.map(function(pricebydate)
  {
    if(Total_transactionsnew[i].transaction[k].id==pricebydate.ItemId)
    {
      Total_transactionsnew[i].transaction[k].Price=pricebydate.Selling_Price;
     
    }
  })

  }
}
  }
    this.setState({    

      Total_transactions:Total_transactionsnew,
      getSelected:Total_transactionsnew[0].transaction
      })
    }
  // }
  // catch (err) {
  //   //console.error(err.message);
  //   this.ErrorhandelAPI(err.message, "FeatchItem"  )
  // }
  }
  calctotals()
  
  {
    try{
   // alert(Total_transactions[0].Total_Supply_Copies)
    var Total_transactions=this.state.Total_transactions;
var CenterSupply=0;
var SoldCopies=0;
var ReturnSCopies=0;
var TotalAmout=0;
let  expanded=[];
if(Total_transactions.length>0)
{
    for(var i=0; i<Total_transactions.length; i++)
    {

      expanded[i]=Total_transactions[i].Vendor_Code;

         if(Total_transactions[i].Total_Supply_Copies!=null && Total_transactions[i].Total_Supply_Copies!=undefined)
      {
    
    CenterSupply= Number(CenterSupply)+ Number(Total_transactions[i].Total_Supply_Copies.toString().replace('Rs.',''))
      }
      TotalAmout=TotalAmout+ parseFloat(Total_transactions[i].Total.toString().replace('Rs.',''))
      ReturnSCopies= ReturnSCopies+Number(Total_transactions[i].Total_Return_Copies)

      SoldCopies=SoldCopies+ Number(Total_transactions[i].Total_Supply_Copies)

    }
  }

    this.setState({
      expanded:expanded,
      extenednon:expanded,
      CenterSupply:CenterSupply,
       SoldCopies:SoldCopies,
       ReturnSCopies:ReturnSCopies,
       TotalAmout:'Rs. '+ parseFloat(TotalAmout).toFixed(2),

    })
  }
  catch (err) {
    //console.error(err.message);
    this.ErrorhandelAPI(err.message, "calctotals"  )
  }
  }

  calctotalsReprocess(Total_transactions)
  
  {
   
   // alert(Total_transactions[0].Total_Supply_Copies)
    var Total_transactions=this.state.Total_transactions;
var CenterSupply=0;
var SoldCopies=0;
var ReturnSCopies=0;
var TotalAmout=0;
let  expanded=[];
if(Total_transactions.length>0)
{
    for(var i=0; i<Total_transactions.length; i++)
    {

      expanded[i]=Total_transactions[i].Vendor_Code;

         if(Total_transactions[i].Total_Supply_Copies!=null && Total_transactions[i].Total_Supply_Copies!=undefined)
      {
    
    CenterSupply= Number(CenterSupply)+ Number(Total_transactions[i].Total_Supply_Copies.toString().replace('Rs.',''))
      }
      TotalAmout=TotalAmout+ parseFloat(Total_transactions[i].Total.toString().replace('Rs.',''))
      ReturnSCopies= ReturnSCopies+Number(Total_transactions[i].Total_Return_Copies)

      SoldCopies=SoldCopies+ Number(Total_transactions[i].Total_Supply_Copies)

    }
  }

  this.savetojsonReprocess(Total_transactions)
  
}


  logout()
  {
    localStorage.clear();
        window.location='/'
  }
  // showFromMonth() {
  //   const { from, to } = this.state;
  //   if (!from) {
  //     return;
  //   }
  //   if (moment(to).diff(moment(from), 'months') < 2) {
  //     this.to.getDayPicker().showMonth(from);
  //   }
  // }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleChange = (event) => {
   // console.log(event.Name)
//console.log(event.target.Name)
    this.setState({CenterId:event.target.value,ExelHeader:[] })
    this.setState({Name:event.target.Name })
    //this.savetojson()
    this.GetData(event.target.value,this.state.supply_date);

  };
  handleDayChange=(selectedDay, modifiers, dayPickerInput)=> {
    var d = new Date(selectedDay);
      this.setState({supply_date:formatDate(new Date(selectedDay), 'DD-MM-YYYY')})
   // this.savetojson()
    this.GetData(this.state.CenterId, formatDate(new Date(selectedDay), 'DD-MM-YYYY'));

  }
  savetojson=()=>
  {
    

//alert( colIndex)
// //console.log(colIndex)
// this.updatecal(colIndex)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();

    urlencoded.append("CenterId", this.state.CenterId);
        urlencoded.append("supply_date", this.state.supply_date );

        
        urlencoded.append("All_JSON_Date", JSON.stringify(this.state.Total_transactions));

   // alert(date)
    var CENTERBOOK = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.SAVECENTERBOOK, CENTERBOOK)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 

{



  if(response.response.affectedRows>=1)
  {


    let VendorsItem=context.state.VendorsItem;

  
    let  Total_transactions=context.state.Total_transactions;

    Total_transactions.map(function(transactions)
    {
    
    
      for(let i=0; i< VendorsItem.length; i++)
                {
               //   alert(VendorsItem[i].id)
                if(transactions.Vendor_Code==VendorsItem[i].id)
                {
   
                 if(VendorsItem[i].Billing_Start_Date!=undefined && VendorsItem[i].Billing_Start_Date!="" && VendorsItem[i].Billing_Start_Date!=null)
    {

     
      if(transactions.Approved =='Waiting'  || transactions.Approved =='Approved'|| transactions.Approved =='Billed'  )
      {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Origin", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        let Amount_Payable=parseFloat(parseFloat(transactions.Total)-parseFloat(transactions.ReturnTotalAll)).toFixed(2)
        let Billing_Amount= parseFloat(parseFloat(transactions.Total)-parseFloat(transactions.ReturnTotalAll)).toFixed(2)  
 

        urlencoded.append("CenterId", context.state.CenterId);
        urlencoded.append("Billing_Date", context.state.supply_date);        
        urlencoded.append("VendorId", transactions.Vendor_Code);
        urlencoded.append("Total", transactions.Total);
        urlencoded.append("Balance_Amount", 0);
        urlencoded.append("Amount_Payable",isNaN(Amount_Payable)==true?0:Amount_Payable ,
        );
        urlencoded.append("Return_Amount", transactions.ReturnTotalAll);
        urlencoded.append("Billing_Amount",isNaN(Billing_Amount)==true?0:Billing_Amount ,
        );
        urlencoded.append("Amount_Paid", transactions.Amount_Paid);
        urlencoded.append("Payment_Mode", transactions.Paid_Source);
        urlencoded.append("Billing_date_mode", "Dashboard");
        urlencoded.append("SoldCopies", transactions.Total_Supply_Copies);
        urlencoded.append("Payment_Reference", transactions.Paid_id);
      
        urlencoded.append("Total_Subscription_Copies", transactions.Total_Subscription_Copies);
      

      
    var CENTERBOOKBilling = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.SAVEBILLING, CENTERBOOKBilling)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 

{

  var urlencoded = new URLSearchParams();


  urlencoded.append("CenterId", context.state.CenterId);
  urlencoded.append("Billing_Date", context.state.supply_date);        
  urlencoded.append("VendorId", transactions.Vendor_Code);
  urlencoded.append("amount_paid", transactions.Amount_Paid);
  urlencoded.append("payment_type", transactions.Paid_Source);
  urlencoded.append("Payment_Reference",transactions.Paid_Source);

  urlencoded.append("billing_id", "0");
  

// alert(date)
var CENTERBOOKBilling = {
method: 'POST',
headers: myHeaders,
body: urlencoded,
redirect: 'follow'
};

fetch(ApiConstants.SAVEPAYMENTHISTORY, CENTERBOOKBilling)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
.then(response11 => 

{
              //   console.log(response)
      
              
    
             
                              })
          })
        }
      }
    }
  }



})










   NotificationManager.success('Enterd data saved successfully', 'Saved Successfully!');
   // let Total_transactions=context.state.Total_transactions;
    this.setState({ Total_transactions: "" })
    this.setState({Total_transactions:Total_transactions,ExelData:[], ExelHeader:[],loading:true})

    this.GetData(this.state.CenterId, this.state.supply_date)


   // window.location.reload();
  }
  ////console.log(response.response.affectedRows)
////console.log(response.response.affectedRows>=1)

})
  }

  savetojsonReprocess=(Total_transactions)=>
  {
    

//alert( colIndex)
// //console.log(colIndex)
// this.updatecal(colIndex)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Origin", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();

    urlencoded.append("CenterId", this.state.CenterId);
        urlencoded.append("supply_date", this.state.supply_date );

        
        urlencoded.append("All_JSON_Date", JSON.stringify(Total_transactions));

   // alert(date)
    var CENTERBOOK = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.SAVECENTERBOOK, CENTERBOOK)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 

{



  if(response.response.affectedRows>=1)
  {


    let VendorsItem=context.state.VendorsItem;

  
    let  Total_transactions=context.state.Total_transactions;

    Total_transactions.map(function(transactions)
    {
    
    
      for(let i=0; i< VendorsItem.length; i++)
                {
               //   alert(VendorsItem[i].id)
                if(transactions.Vendor_Code==VendorsItem[i].id)
                {
   
                 if(VendorsItem[i].Billing_Start_Date!=undefined && VendorsItem[i].Billing_Start_Date!="" && VendorsItem[i].Billing_Start_Date!=null)
    {

     
      if(transactions.Approved =='Waiting'  || transactions.Approved =='Approved'|| transactions.Approved =='Billed'  )
      {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Origin", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        let Amount_Payable=parseFloat(parseFloat(transactions.Total)-parseFloat(transactions.ReturnTotalAll)).toFixed(2)
        let Billing_Amount= parseFloat(parseFloat(transactions.Total)-parseFloat(transactions.ReturnTotalAll)).toFixed(2)  
 

        urlencoded.append("CenterId", context.state.CenterId);
        urlencoded.append("Billing_Date", context.state.supply_date);        
        urlencoded.append("VendorId", transactions.Vendor_Code);
        urlencoded.append("Total", transactions.Total);
        urlencoded.append("Balance_Amount", 0);
        urlencoded.append("Amount_Payable",isNaN(Amount_Payable)==true?0:Amount_Payable ,
        );
        urlencoded.append("Return_Amount", transactions.ReturnTotalAll);
        urlencoded.append("Billing_Amount",isNaN(Billing_Amount)==true?0:Billing_Amount ,
        );
        urlencoded.append("Amount_Paid", transactions.Amount_Paid);
        urlencoded.append("Payment_Mode", transactions.Paid_Source);
        urlencoded.append("Billing_date_mode", "Dashboard");
        urlencoded.append("SoldCopies", transactions.Total_Supply_Copies);
        urlencoded.append("Payment_Reference", transactions.Paid_id);
      
        urlencoded.append("Total_Subscription_Copies", transactions.Total_Subscription_Copies);
      

      
    var CENTERBOOKBilling = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(ApiConstants.SAVEBILLING, CENTERBOOKBilling)
    .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(response => 

{

  var urlencoded = new URLSearchParams();


  urlencoded.append("CenterId", context.state.CenterId);
  urlencoded.append("Billing_Date", context.state.supply_date);        
  urlencoded.append("VendorId", transactions.Vendor_Code);
  urlencoded.append("amount_paid", transactions.Amount_Paid);
  urlencoded.append("payment_type", transactions.Paid_Source);
  urlencoded.append("Payment_Reference",transactions.Paid_Source);

  urlencoded.append("billing_id", "0");
  

// alert(date)
var CENTERBOOKBilling = {
method: 'POST',
headers: myHeaders,
body: urlencoded,
redirect: 'follow'
};

fetch(ApiConstants.SAVEPAYMENTHISTORY, CENTERBOOKBilling)
.then(dataWrappedByPromise => dataWrappedByPromise.json())
.then(response11 => 

{
              //   console.log(response)
      
              
    
             
                              })
          })
        }
      }
    }
  }



})










   NotificationManager.success('Enterd data saved successfully', 'Saved Successfully!');
   // let Total_transactions=context.state.Total_transactions;
    this.setState({ Total_transactions: "" })
    this.setState({Total_transactions:Total_transactions,ExelData:[], ExelHeader:[],loading:true})

    this.GetData(this.state.CenterId, this.state.supply_date)


   // window.location.reload();
  }
  ////console.log(response.response.affectedRows)
////console.log(response.response.affectedRows>=1)

})
  }
  
reProcessSales()
{

let supply_date=this.state.supply_date;

  this.setState({ loading: false})
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Origin", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      let newdate=supply_date.split('-')[2]+','+supply_date.split('-')[1]+','+supply_date.split('-')[0]
      urlencoded.append("CenterId",this.state.CenterId);
      urlencoded.append("supply_date",this.state.supply_date);
      urlencoded.append("Selling_Day",formatDate(new Date(newdate), 'dddd'));
     /// alert(formatDate(supply_date, 'DD-MM-YYYY'))

      let day=supply_date.toString().split('-')[0]
      let Month=supply_date.toString().split('-')[1]
      let year=supply_date.toString().split('-')[2]
       var date = new Date(year+"-"+Month+"-"+day);
     //  alert(year+"-"+Month+"-"+day)
      // date.setDate(date.getDate() -7);
      // let formatedate=formatDate(supply_date, 'DD-MM-YYYY');
    let days=supply_date.toString().split('-')[0];
      //alert(supply_date.toString().split('-')[0])
      date.setDate(date.getDate() -7);
   // alert(date)
     urlencoded.append("weeklast_date", formatDate(date, 'DD-MM-YYYY'));
    //  //console.log(CenterId)
     // //console.log(supply_date)
      var GETCETERBOOK = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };



    
      
      fetch(ApiConstants.GetItemTodaysPrice, GETCETERBOOK)
      .then(dataWrappedByPromise => dataWrappedByPromise.json())
      .then(CETERBOOKRES => {

        var urlencoded = new URLSearchParams();
        urlencoded.append("CenterID",this.state.CenterId);
        var VendorbyCenters = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };
 
        fetch(ApiConstants.VENDORSBYCENTER, VendorbyCenters)
        .then(dataWrappedByPromise => dataWrappedByPromise.json())
        .then(VendorbyCenter => {
        
   let Vendor=VendorbyCenter.response
let item=[];

let Total_transactions=this.state.Total_transactions;

let misseditem=[];

for(let i=0; i<Total_transactions.length; i++)
{
 

  for(let d=0; d<Vendor.length; d++)
  {
if(Vendor[d].id==Total_transactions[i].Vendor_Code)
{
  misseditem=JSON.parse(Vendor[d].Fld1)
  for(let k=0; k<misseditem.length; k++)
{
  let v=0

  item[k]=misseditem[k];
  for(let j=0; j<Total_transactions[i].transaction.length; j++)
  {
    if(item[k].id==Total_transactions[i].transaction[j].id)
    {
v=1
  }
}



if(v==0)
{

  Total_transactions[i].transaction.push({
             
               
    id:item[k].id,
    Item:item[k].FormalName,
    Price:0,
    Supply_Copies:0,
    Sold_Copies:0,
    Total:0,
    Return_Copies:0,
    ReturnPrice:0,
    ReturnTotal:0,
   Subscription_Copies:0,
  
  }
  )
}
}
}
}

}

Total_transactions = Total_transactions.filter(function( element ) {
              return element !== undefined;
           });
 item=CETERBOOKRES.Item;

 let pricebydate=CETERBOOKRES.Daily_Item_Price
let preitem=[]
 item.map(function(datapric)
 {
  pricebydate.map(function(pricebydate)
  {
    if(pricebydate.ItemId==datapric.id)
    {
      datapric.Price=pricebydate.Selling_Price
    }
  preitem.push(datapric)
  })
 })
if(preitem!=undefined && preitem.length>0)
{
 item=preitem;
}

var Total_transactionsnew = Total_transactions.map(function (data) {


  var transaction =data.transaction.map(function (transaction) {

    //console.log(JSON.stringify(transaction.id))


for(let i=0; i<item.length; i++)
{

  if(item[i].id==transaction.id)
 {

  if(item[i].Price!=undefined && item[i].Price!="" && item[i].Price!=0 && item[i].Price!="0"  && isNaN(item[i].Price)==false )
{
              return {
             
               
                id:transaction.id,
                Item:transaction.Item,
                Price:item[i].Price,
                Supply_Copies:transaction.Supply_Copies,
                Sold_Copies:transaction.Sold_Copies,
                Total:transaction.Total,
                Return_Copies:transaction.Return_Copies,
                ReturnPrice:transaction.ReturnPrice,
                ReturnTotal:transaction.ReturnTotal,
               Subscription_Copies:transaction.Subscription_Copies,
               PreBilled_Price:transaction.PreBilled_Price==undefined?0:transaction.PreBilled_Price,
               Pre_Status:transaction.Pre_Status==undefined?"None":transaction.Pre_Status,
               PreBilled_Supply_Copies:transaction.PreBilled_Supply_Copies==undefined?0:transaction.PreBilled_Supply_Copies,
               PreBilled_Return_Copies:transaction.PreBilled_Return_Copies==undefined?0:transaction.PreBilled_Return_Copies,
               PreBilled_ReturnPrice:transaction.PreBilled_ReturnPrice==undefined?0:transaction.PreBilled_ReturnPrice,
               PreBilled_ReturnTotal:transaction.PreBilled_ReturnTotal==undefined?0:transaction.PreBilled_ReturnTotal,
               Bliing_Total:transaction.Bliing_Total==undefined?0:transaction.Bliing_Total,
              }
            
            }
          }
}
         
            });
          //   transaction = transaction.filter(function( element ) {
          //     return element !== undefined;
          //  });
          
         //   si=si+1;
            return {
           
              id:data.id,
              Vendor_Code:data.Vendor_Code,
              Total_Items:data.Total_Items,
              Total:data.Total,
              Vendor_Name:data.Vendor_Name,
              Total_Supply_Copies:data.Total_Supply_Copies,
              Total_Return_Copies:data.Total_Return_Copies,
              Total_Sold_Copies:data.Total_Sold_Copies,
              Amount_Paid:data.Amount_Paid,
              Paid_Source:data.Paid_Source,
             Total_Subscription_Copies:data.Total_Subscription_Copies,
              User:data.User,
              Approved:  typeweek==2? 'Pre-week' :data.Approved,
              transaction:transaction,
              Fld1:data.Fld1,
              ReturnTotalAll:data.ReturnTotalAll==undefined?0:data.ReturnTotalAll,
            
            }
          });

          let ReturnItemswithPrice=this.state.ReturnItemswithPrice;
          for (let i = 0; i< Total_transactionsnew.length; i++) {
          for (let k = 0; k< Total_transactionsnew[i].transaction.length; k++) {
            for (let j = 0; j < ReturnItemswithPrice.length; j++) {

              if(Total_transactionsnew[i].transaction[k]!=undefined)
              {
             
          if(Total_transactionsnew[i].transaction[k].id==ReturnItemswithPrice[j].id)
          {
            Total_transactionsnew[i].transaction[k].ReturnPrice=ReturnItemswithPrice[j].Price;
           
          }
        }
          }
        }


        Total_transactionsnew[i].transaction = Total_transactionsnew[i].transaction.filter(function( element ) {
              return element !== undefined;
           });

          }

        //  this.calctotalsReprocess(Total_transactionsnew)
                   
       this.setState({ loading: true, Total_transactions:Total_transactionsnew})
       this.calctotalsReprocess(Total_transactionsnew)
     })
             
    })
     
              } 


  render() {
    const { ExelData } = this.state.ExelData
  
    const { header } = this.state.ExelHeader
    const headerDiv=[];
    const dataDiv=[];
    const { CenterName } = this.state.CenterName
if(this.state.ExelHeader!=undefined && this.state.ExelHeader!=""  && this.state.ExelHeader!=null)
{
  
 
    //state:{ExelData:this.state.ExelData,    header:this.state.ExelHeader,     CenterName:this.state.CenterName }
    

    let filtered = this.state.ExelHeader.filter(function (el) {
      return el != null;
    });
    let Alldata =this.state.ExelData

   // const headerobj=JSON.parse(header);
  //let headerlent=(filtered.length-3)
  //alert(headerlent)


 

for(let i=2; i<filtered.length; i++)
{
  headerDiv.push(
            <th scope="col">{filtered[i].title}</th>
    )

    }


    for(let i=0; i<Alldata.length; i++)
    {
 
let dataDivtd=[]
   
    
      for(let h=2; h<filtered.length; h++)
      {
        let v=0;
        for(let j=2; j<filtered.length; j++)
        {
if(filtered[h].dataIndex==Object.keys(Alldata[i])[j])
{ v=1;
 // console.log(v+".,"+filtered[h].dataIndex+","+Object.keys(Alldata[i])[j])
 
      dataDivtd.push(
        <td>{Object.values(Alldata[i])[j]}</td>
        )

      }
    }
    if(v==0)
    {
     // console.log(v+".,"+filtered[h].dataIndex)
      dataDivtd.push(
        <td>0</td>
        )
    }
    
        }

dataDiv.push( <tr>{dataDivtd}</tr>)


      }


    }
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    let Options = this.state.Centers.map(v => (
      <option name={v.Name} value={v.id}>{v.Name}</option>
    ));
   
    var config = {
       width:"100%",// width of chart
       headers: ["Code", "Item","Supply Price","Supply Copies","Sold Copies", "Supply Amount","Return Copies","Return Price", "Return Amount","Subscription Copies" ],
      
      // headers: ["Code", "Item","Price","Supply Copies","Return Copies","Sold Copies", "Total Amount"],
     
      headerConfig: {"Code":{width:"80px", align:"center"},"Item":{width:"100px", align:"center"}, "Price":{width:"100px", align:"center"},  "Total":{align:"right"}},// configure style properties of header with and align etc (more fetures will support)
     
      columnsToHide:"PreBilled_Price,Pre_Status,Sold_Copies,PreBilled_Supply_Copies,PreBilled_Return_Copies,PreBilled_ReturnPrice,PreBilled_ReturnTotal,PreBilled_ReturnTotal,Bliing_Total" ,
     
       // formatters: {
     
      //   Price: function (val) {
      //     return val.toFixed(2) ;
      //   }
      // },
   //   analyticalColumns: "Price", //Comma seprated string, to show sum of numerical columns  **optional
     // rowFocus: fa, // Default  false   **optional
       //mode: "Multi", //multi,single,none   Default  none   **optional,
      fieldsToEdit:"Subscription_Copies,Supply_Copies,Return_Copies",//Comma seprated string, these fields can edit from grid  **optional
     
     // fieldsToEdit:"Supply_Copies,Return_Copies",
    }
   
 
    
  if(window.navigator.onLine==true && this.state.ErrorReturn=="N")
  {
    return (
      <div>

        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <h1 class="h3 mb-0 text-gray-800">Center Book</h1>
          {/*Sidebar Toggle (Topbar)*/}


          <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
          </button>

          {/*Topbar Search*/}


          {/*Topbar Navbar*/}
          <ul class="navbar-nav ml-auto">

            <div class="topbar-divider d-none d-sm-block"></div>

            {/*Nav Item - User Information*/}
            <li onClick={this.logout}


              class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">Logout</span>
              </a>
              {/*Dropdown - User Information*/}
            </li>

          </ul>

        </nav>
        <div class="container-fluid" style={{textAlign: 'center', }}>

      
          <div style={{ backgroundColor: '#fff',padding: '10px' }} class="row d-sm-flex align-items-center justify-content-between mb-4">
         {
           parseInt(this.state.TotalCenterSupply)==parseInt(this.state.CenterSupply)
           ?
        
    <div class="h6 col-12 col-md-5 col-sm-4 col-lg-2 btn btn-sm btn-success" style={{ fontWeight: 'bold' }}><i class="fas fa-hand-point-right"></i>
    
    {this.state.TotalCenterSupply>0 && typeweek!=2?
    <span>
     Center Supply : {this.state.TotalCenterSupply} - {this.state.TotalUnsold} = {Number(this.state.TotalCenterSupply)-Number(this.state.TotalUnsold)}
     </span>
  : <span> 0</span>}
      
     </div>
  :
    <div class="h6 col-12 col-md-5 col-sm-4 col-lg-3 btn btn-sm btn-primary" style={{ fontWeight: 'bold' }}><i class="fas fa-hand-point-right"></i> Center Supply : 
    
    {this.state.TotalCenterSupply>0&& typeweek!=2?
    <span>
    {this.state.TotalCenterSupply} - {this.state.TotalUnsold} = {Number(this.state.TotalCenterSupply)-Number(this.state.TotalUnsold)}
  </span>:<span> 0</span>}</div>
  }
           
           
            <div class="h6 col-12 col-sm-4 col-md-5 col-lg-2 btn btn-sm btn-outline-primary" style={{ fontWeight: 'bold' }}><i class="fas fa-hand-point-right"></i>  Sold Copies :{typeweek!=2 ?this.state.SoldCopies:0}</div>
            <div class="h6 col-12 col-sm-4 col-md-5 col-lg-2 btn btn-sm btn-outline-primary" style={{ fontWeight: 'bold' }}><i class="fas fa-hand-point-right"></i> Unsold Copies : {typeweek!=2 ?  parseInt(this.state.TotalCenterSupply)>parseInt(this.state.CenterSupply)? parseInt(this.state.TotalCenterSupply)-parseInt(this.state.CenterSupply):0:0}</div>
            <div class="h6 col-12 col-sm-4 col-md-5 col-lg-2 btn btn-sm btn-outline-primary" style={{ fontWeight: 'bold' }}><i class="fas fa-hand-point-right"></i> Return Copies : {typeweek!=2 ?this.state.ReturnSCopies:0}</div>
            <div class="h6 col-12 col-sm-4  col-md-5  col-lg-2 btn btn-sm btn-outline-primary" style={{ fontWeight: 'bold' }}><i class="fas fa-hand-point-right"></i> Amount: {typeweek!=2? this.state.TotalAmout:0}</div>
          </div>







          <ToolkitProvider
            keyField="id"
            data={this.state.Total_transactions}
            columns={columns}
            // search

            pagination={paginationFactory()}

          >
            {/*Page Heading*/}


            {/*Content Row*/}

            {
              props => (
                <div>


                  <div class="d-sm-flex align-items-center justify-content-between mb-4">
{/* {this.state.loading? */}
                    <div className="">
                      <Helmet>
                        <style>{`
          .DayPicker-Day--birthday {
            background-color: #00bcd4;
            color: white;
          }
          .DayPicker-Day--monday {
            color: #00bcd4;
          }
          .DayPickerInput{
            width:250px;
           
          }
          .DayPickerInput input{
            width:100%;
          }
          
          `}</style>
                      </Helmet>
                      <DayPickerInput
                        formatDate={formatDate}

                        inputProps={{ disabled: this.state.loading==true?false:true }}
                        parseDate={parseDate}
                        format="dddd MMMM Do YYYY"
                        placeholder={`${formatDate(new Date(), 'dddd MMMM Do YYYY')}`}
                        dayPickerProps={{
                  
                          localeUtils: MomentLocaleUtils,
                        }}
                      
                        onDayChange={this.handleDayChange} 
                      />

                    </div>
  {/* :  <a href="/#Centerbook" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" style={{ marginRight: 10 }}><i class="fas fa-file-invoice fa-sm text-white-50"></i> Refresh</a>
} */}

                    <div>
                      <a href="/#NewInvoice" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" style={{ marginRight: 10 }}><i class="fas fa-file-invoice fa-sm text-white-50"></i> New Bill</a>
                      <a href="/#SalesInvoice" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-file-invoice fa-sm text-white-50"></i> Generated Bills</a>

                      {
          this.state.loading?      <a style={{color:'#fff',  fontWeight:'bold', marginLeft:10}} onClick={(e) => { this.reProcessSales()}} class=" btn btn-sm btn-primary"><i class="fas  fa-refresh fa-sm text-50"></i> Re-Process Sales</a>

                   :null}


                      {/* <ExportCSVButton  { ...props.csvProps }>  
           
             <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Export CSV</a>
           </ExportCSVButton>
            */}

                    </div>     </div>


                  <hr />
                  <div class="row">
                    <div class="form-group col-4">
                      <label style={{ float: 'left', padding: ' 5px ', }} for="sel1">Centers:</label>

                      <select disabled={this.state.loading==true?false:true } id="CentersSel" style={{ padding: ' 5px 10px', float: 'left', height: 'auto', width: 'auto', }} class="form-control" value={this.state.value} onChange={this.handleChange}>
                        {Options}
                      </select>

                    </div>




                  {  this.state.loading ?

                   <div class="col-8">
                     
                      {/* <SearchBar {...props.searchProps} /> */}
                      {
  this.state.loading &&this.state.ExelHeader!=undefined && this.state.ExelHeader!=""  && this.state.ExelHeader!=null
  ?

//   <Link
//   to={{
//     pathname: "/centerReport",
//     state:{ExelData:this.state.ExelData,    header:this.state.ExelHeader,     CenterName:this.state.CenterName }
//   }}
// >
          <Button   onClick={(e) => { this.setState({show:true})}} style={{marginTop:10,marginBottom:10,marginRight:10}} className='btn btn-primary color_green'><i class="fas fa-link fa-sm text-white-50"></i>Center Report</Button>
          // </Link>
  :

        <Button onClick={(e) => { {alert("Please wait a while and try again!!!")}}}  style={{   backgroundColor:' #ccc',marginRight:10}} className='btn btn-default'><i class="fas fa-link fa-sm text-white-50"></i>Center report</Button>
}
{
  this.state.loading &&this.state.ExelHeader!=undefined && this.state.ExelHeader!=""  && this.state.ExelHeader!=null
  ?
                         <ExportSheet
                     
          header={this.state.ExelHeader}
          fileName={this.state.CenterName}
          dataSource={this.state.ExelData}
          xlsx={XLSX} >
          <Button  style={{marginTop:10,marginBottom:10,marginRight:10}} className='btn btn-primary color_green'><i class="fas fa-download fa-sm text-white-50"></i>  Download Center Report</Button>
        </ExportSheet>
  :

        <Button onClick={(e) => { {alert("Please Save all and try again!!!")}}}  style={{    backgroundColor:' #ccc', marginRight:10}} className='btn btn-default'><i class="fas fa-download fa-sm text-white-50"></i>  Download center report</Button>
}
                      
                      {this.state.exp==undefined || this.state.exp==0?
                      <Button onClick={(e) => { {

this.setState({
  expanded:[],
  exp:1,
})

                      }}} style={{marginTop:10,marginBottom:10,marginRight:10}} className='btn btn-outline-warning'>Expand <i class="fas fa-toggle-off  fa-sm text-white-50"></i></Button>
        
                   :  <Button onClick={(e) => { {

                    this.setState({
                      expanded:this.state.extenednon,
                     
                      exp:0,
                    })

                  }}} style={{marginRight:10}} className='btn btn-primary color_green'>Expand <i class="fas fa-toggle-on  fa-sm text-white-50"></i> </Button>
    }

                      <Button  onClick={(e) => { this.SavetoJsonCalAll("Approved")}} className='btn btn-success color_green'>Save All</Button>
        
                      </div> 
:null}

                  </div>



                  {/* <div>
      <button onClick={this.getall}>Get Selected Items</button><br /><br />
     {this.state.getSelected.length>0?
      <List items={this.state.getSelected} config={config} />
     :null}
    </div> */}

    {
          this.state.loading?


  this.state.CETERBOOKRESVendor==1?



                  <BootstrapTable
                    expandRow={{

                      renderer: (rowtop, colIndex) => (

                      //  alert(colIndex),
         
                        <div style={{ backgroundColor: '#fff' }}>



<List items={this.state.Total_transactions[colIndex].transaction} config={config} />

<div style={{ textAlign: 'right',padding:10, fontWeight:'bold' }}>
                    
{this.state.Total_transactions[colIndex].Paid_Source} : {this.state.Total_transactions[colIndex].Amount_Paid}
                             </div> 
                          <div style={{ textAlign: 'center' }}>
                           
{/*                            
                            <Button  onClick={(e) => { this.SavetoJsonCal(colIndex,0,"Approved")}} style={{ margin:10, marginTop:20,marginBottom:50,}} className='btn btn-success color_green'>Approve</Button>
                            */}
                           
                            {/* <Button  onClick={(e) => { this.SavetoJsonCal(colIndex,0,"Waiting")}} style={{margin:10,marginTop:20,marginBottom:50,}} className='btn btn-primary'>Waiting</Button>
                            */}
                            {/* <Button  onClick={(e) => { this.SavetoJsonCal(colIndex,0,"Pre-week")}} style={{margin:10,marginTop:20,marginBottom:50,}} className='btn btn-danger '>Pre-week</Button>
                            */}
                            {/* <Button  onClick={(e) => { this.SavetoJsonCal(colIndex,0,"Disapprove")}} style={{margin:10,marginTop:20,marginBottom:50,}} className='btn btn-danger '>Disapprove</Button>
                         */}
                            
                          </div>
                        </div>
                      ),

                      showExpandColumn: true,
                      // onExpandAll: (isExpandAll, results, e) => {

                      // }

                      expanded: this.state.expanded
                    }}
                    cellEdit={cellEditFactory({
                      mode: 'click',
                      blurToSave: true,
                      nonEditableRows: () => [0, 3]
                    })}
                    {...props.baseProps}
                  />
                  :
                  
                  
                  <div  class="loader"></div>  
                  :
                  
                  this.state.CETERBOOKRESVendor==0?
                  <div style={{flex:1, textAlign:'center', flexDirection:'row', color:'red', padding:10, fontSize:30,}}>No Vendors Found!
                  
                  <br/>


                  <a href="/#AddVendor" class="d-none d-sm-inline-block btn btn-primary" style={{marginRight:10, marginTop:16, height:40}}><i style={{fontSize:14, paddingRight:10,}} class="fas fa-file-invoice fa-sm text-white-50"></i>Add Vendor</a>
  

                  </div>
               
                 : <div  class="loader"></div>

            }
                </div>
              )
            }
          </ToolkitProvider>
    
{/* 
          <Button  onClick={(e) => { this.SavetoJsonCalAll()}} style={{margin:'auto', textAlign:'center', marginTop:20,marginBottom:50,}} className='btn btn-success color_green'>Approve All</Button>
           */}

{
          this.state.loading?   <div>
          <Button  onClick={(e) => { this.SavetoJsonCalAll("Approved")}} style={{ margin:10, marginTop:20,marginBottom:50,}} className='btn btn-success color_green'>Approve All </Button>
                            <Button  onClick={(e) => { this.SavetoJsonCalAll("Waiting")}} style={{margin:10,marginTop:20,marginBottom:50,}} className='btn btn-primary'>Waiting  All</Button>
                        
                            <Button  onClick={(e) => { this.SavetoJsonCalAll("Disapprove")}} style={{margin:10,marginTop:20,marginBottom:50,}} className='btn btn-danger '>Disapprove All </Button>
                     
                            </div>    :null}
        </div>
    
    
    { this.state.ExelHeader!=undefined && this.state.ExelHeader!=""  && this.state.ExelHeader!=null
  ?    <Modal
        show={this.state.show}
        onHide={() => this.setState({show:false})}
        fullscreen={true}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        left
      >
        <Modal.Header closeButton>
          <Modal.Title >
           Daily Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:10, minWidth:1000, fontSize:13 }}>
      <button style={{    margin: 5,}} onClick={this.printDiv}>Print It</button>
    


      <div id="PrintDiv" >
<div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
     <div style={{fontWeight:'bold', fontSize:18}}>
{this.state.ExelData[0].Date}
      </div>

      <div style={{fontWeight:'bold', fontSize:18}}>
{

formatDate(new Date(this.state.ExelData[0].Date.split('-')[1]+'/'+this.state.ExelData[0].Date.split('-')[0]+'/'+this.state.ExelData[0].Date.split('-')[2]), 'dddd') }
      </div>
      <div style={{fontWeight:'bold', fontSize:18}}>
{this.state.CenterName}
      </div>
</div>
<div style={{overflow:'overlay'}}>
<table style={{}} class="table table-striped table-dark">
  <thead>
    <tr>
      {headerDiv}
      {/* <th scope="col">Total Supply</th> */}
    </tr>
  </thead>
  <tbody>
  {dataDiv}
  </tbody>
</table></div>
</div>
</div> 
        </Modal.Body>
      </Modal>:null}


      
      </div>

    )
          }

          if(window.navigator.onLine==false)
          {
            return (
              <div style={{textAlign:'center', margin:'auto'}}>
              <div style={{width:550,margin:'auto', borderRadius:6, marginTop:'10%', border:'1px solid #eee', backgroundColor:'#D32000',  }}>
<div style={{fontSize:16, fontWeight:'bold', color:'#fff',padding:15, borderWidth:0, borderBottomWidth:1,borderStyle:'solid', borderBottomColor:'#fff'}}>Internet Disconnected</div>

<p style={{marginTop:50,fontSize:26, padding:10, fontWeight:'bold', color:'#fff',}}>
  Your Internet is Disconnected <br></br>
Please connect internet and try again
</p><Button onClick={() => { window.location.reload();}} style={{margin:10}}>Try Again</Button></div>

</div>


            )
          }
          if( this.state.ErrorReturn=="Y")
          {
            return (


              
<div>
<div style={{textAlign:'center', margin:'auto'}}>
              <div style={{width:550,margin:'auto', borderRadius:6, marginTop:'10%', border:'1px solid #eee', backgroundColor:'#D32000',  }}>
<div style={{fontSize:16, fontWeight:'bold', color:'#fff',padding:15, borderWidth:0, borderBottomWidth:1,borderStyle:'solid', borderBottomColor:'#fff'}}>Something Went wrong</div>

<p style={{marginTop:50,fontSize:26, padding:10, fontWeight:'bold', color:'#fff',}}>

<div style={{fontSize:14, padding:5, fontWeight:'normal', color:'#fff',}}>{this.state.ErrorhandelAPIMessage}</div>
<div style={{fontSize:14, padding:5, fontWeight:'normal', color:'#fff',}}>{this.state.Location}</div>
Something went wrong <br></br>
{/* Please call to 9740 473 829 for quick Technical Support<br></br> */}
</p><Button onClick={() => { window.location.reload();}} style={{margin:10}}>Try Again</Button></div>

</div>



</div>

            )
          }

  }

}

